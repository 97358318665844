/*
 --- PAGE HELP ----------------------------------
*/

.help {
  border-bottom: 7px solid #000000;

  .wrap {
    flex-direction: column;
    max-width: 990px;
  }

  &__title {
    margin-bottom: 25px;
    font-size: 35px;
    line-height: 40px;
    text-align: center;
  }

  &__search {
    display: flex;
    width: 100%;
    max-width: 525px;
    margin: 0 auto 85px auto;

    &__submit {
      flex: 0 0 auto;
      margin-left: 5px;
    }

    &__field {
      flex: 1 1 auto;

      &.input input {
        margin-bottom: 0;
      }
    }
  }

  &__categories {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -3px 90px -3px;

    &__one {
      flex: 0 0 50%;
      padding: 0 3px 6px 3px;
      position: relative;

      span {
        display: flex;
        align-items: center;
        width: 100%;
        height: 100%;
        padding: 17px 14px 13px 120px;
        background: @color-light;
        font-size: 12px;
        line-height: 23px;
        font-weight: 700;
        letter-spacing: 0.08em;
        position: relative;
        cursor: pointer;
      }

      &.double {
        flex: 0 0 100%;
      }

      &.red {

        span {
          background: @pink;
        }
      }

      &__list {
        display: none;
        margin-top: -6px;
        padding: 3px 22px 34px 120px;
        background: @color-light;
        font-size: 12px;
        line-height: 16px;
        position: absolute;
        top: 100%;
        right: 3px;
        left: 3px;
        z-index: 1;

        .active > & {
          display: block;
        }

        ul {

          li {
            padding: 8px 0;

            a {
              color: #000000;
              text-decoration: none;

              &:hover {
                color: #000000;
                text-decoration: underline;
              }
            }
          }
        }
      }
    }
  }

  &__faq {
    padding: 30px 105px 98px 105px;
    background: @pink;

    &__title {
      margin-bottom: 30px;
      font-size: 35px;
      line-height: 45px;
      font-weight: 200;
      text-align: center;
    }

    &__one {
      margin-bottom: 7px;

      span {
        display: block;
        padding: 24px 22px 20px 22px;
        background: #ffffff;
        font-size: 12px;
        line-height: 18px;
        font-weight: 700;
        text-align: center;
        letter-spacing: 0.08em;
        cursor: pointer;
      }
    }
  }

  &__other {
    padding: 30px 105px 98px 105px;

    &__title {
      margin-bottom: 30px;
      font-size: 35px;
      line-height: 45px;
      font-weight: 200;
      text-align: center;
    }

    &__list {
      display: flex;
      justify-content: space-around;
    }

    &__one {
      flex: 0 0 180px;

      a {
        display: block;
        padding-top: 63px;
        color: #000000;
        font-size: 12px;
        line-height: 18px;
        font-weight: 700;
        text-align: center;
        text-decoration: underline;
        letter-spacing: 0.08em;
        position: relative;
      }
    }
  }
}


@media screen and (max-width: 999px) {

  .help {
    padding-top: 15px;

    &__search {
      margin-bottom: 30px;

      &__field {

        &.input input {
          padding-top: 2px;
          padding-bottom: 0;
          margin-bottom: 0;
          height: 40px;
        }
      }

      &__submit {

        .button {
          padding-top: 9px;
          padding-bottom: 6px;
        }
      }
    }

    &__categories {
      margin-bottom: 45px;
    }

    &__faq {

      &__title {
        font-size: 26px;
        line-height: 1;
      }
    }
  }
}


@media screen and (max-width: 767px) {

  .help {

    &__search {
      flex-wrap: wrap;

      &__field {
        flex: 0 0 100%;

        &.input input {
          height: 40px;
          padding-top: 0;
          padding-bottom: 0;
          margin-bottom: 5px;
        }
      }

      &__submit {
        flex: 0 0 100%;
        margin-left: 0;

        input {
          width: 100%;
        }
      }
    }

    &__categories {

      &__one {
        flex: 0 0 100%;

        span {
          padding-left: 95px;
          line-height: 1.4;
        }

        .iconed_helptab:before {
          left: 25px;
        }

        &__list {
          padding-left: 30px;
        }
      }
    }

    &__faq {
      padding: 50px 15px;

      &__one {

        span {
          padding: 15px 10px;
        }
      }
    }

    &__other {
      padding: 30px 15px 20px;

      &__list {

        flex-wrap: wrap;
      }

      &__one {
        flex: 0 0 50%;
        padding-bottom: 30px;
      }
    }
  }
}
