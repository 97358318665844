/*
 --- POPUP --------------------------------------
*/

.popup {
  width: 460px;
  max-width: 100%;
  padding: 1px;
  border: 1px solid @gray-bg;
  background: #ffffff;
  color: @black-light;
  font-size: 15px;
  line-height: 20px;
  position: absolute;
  top: -1000px;
  left: 50%;
  z-index: 11;
  -webkit-transition: all 1s;
  -moz-transition: all 1s;
  -ms-transition: all 1s;
  -o-transition: all 1s;
  transition: all 1s;
  -webkit-transform: translate(-50%, 0);
  -moz-transform: translate(-50%, 0);
  -ms-transform: translate(-50%, 0);
  -o-transform: translate(-50%, 0);
  transform: translate(-50%, 0);

  &:after {
    display: block;
    content: " ";
    height: 6px;
    background: @color;
    position: absolute;
    right: 0;
    bottom: -6px;
    left: 0;
  }

  &__pretitle {
    padding: 15px 30px;
    background: @color-light;
    font-size: 15px;
    line-height: 22px;
    font-weight: 700;
  }

  &__wrap {
    padding: 30px;
  }

  &__title {
    color: @black;
    font-size: 25px;
    line-height: 40px;
  }

  &__subtitle {
    margin-bottom: 20px;
  }

  &__form {
    width: 190px;

    &__field {
      margin-bottom: 25px;

      label {
        display: block;
        margin-bottom: 10px;
      }

      select {
        display: block;
        width: 100%;
        padding: 11px 36px 12px 12px;
        border: 1px solid @gray-bg;
        background: #ffffff url("../i/select.png") no-repeat 100% 50%;
        font-size: 12px;
        line-height: 12px;
        font-family: inherit;
        position: relative;
      }
    }

    &__note {
      margin-bottom: 25px;
      color: @gray-lighter;
      font-size: 12px;
      line-height: 18px;

      &.important {
        padding-left: 30px;
        position: relative;

        &:before, &:after {
          display: block;
          width: 18px;
          height: 18px;
          position: absolute;
          top: 0;
          left: 0;
        }

        &:before {
          content: " ";
          background: @color;
          -webkit-border-radius: 50%;
          -moz-border-radius: 50%;
          border-radius: 50%;
        }

        &:after {
          content: "!";
          color: #ffffff;
          font-size: 14px;
          line-height: 20px;
          font-weight: 900;
          text-align: center;
        }
      }
    }

    &__submit {
      padding-top: 10px;
    }
  }

  &__close {
    position: absolute;
    top: 21px;
    right: 16px;
    cursor: pointer;
  }

  &.upload {
    width: 100%;
    max-width: 950px;
    padding: 0;
    border: none;
    border-top: 7px solid @gray-bg;

    &:after {
      display: none;
    }
  }
}

@media screen and (max-width: @sm) {
  .popup__close {
    top: 13px;
  }
}

.upload {

  &__title {
    margin: 10px auto 35px auto;
  }

  &__steps {
    display: flex;
    justify-content: space-around;
    max-width: 516px;
    margin: 0 auto 20px auto;

    &__one {
      width: 90px;
      font-size: 13px;
      line-height: 14px;
      text-align: center;

      span {
        display: block;
        height: 73px;
        margin-bottom: 5px;
        font-size: 25px;
        line-height: 80px;
        font-weight: 200;
      }

      &.active span {
        color: @color;
        font-weight: 900;
      }
    }
  }

  &__form {
    padding: 40px 20px 50px 20px;
    border-top: 1px solid @black;

    &__list {
      display: flex;
      justify-content: space-between;
      width: 100%;
      max-width: 650px;
      margin: auto;
      padding-top: 35px;
      font-size: 11px;
      line-height: 15px;
      font-weight: 900;
      text-align: center;
      letter-spacing: 0.05em;

      a, .link {
        display: block;
        color: inherit;
        text-decoration: underline;

        &:hover {
          color: inherit;
          text-decoration: none;
        }
      }
    }
  }

  &__learnmore {
    padding: 35px 20px;
    border-top: 1px solid @black;
    font-size: 14px;
    line-height: 18px;
    text-align: center;
    font-style: italic;
    font-family: "Times New Roman", serif;

    a {
      text-decoration: underline;
    }
  }
}
