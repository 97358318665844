/*
 --- ACCOUNT ------------------------------------
*/

.account {

  &__top {
    padding-bottom: 10px;
    font-size: 12px;
    line-height: 18px;

    .wrap {
      align-items: flex-end;
    }

    .cart & {
      border-bottom: 7px solid @gray-bg;
    }
  }

  &__back {
    flex: 0 0 200px;
    font-size: 14px;
    font-style: italic;
    font-family: "Times New Roman", serif;

    a {
      color: @color;
      text-decoration: underline;

      &:hover {
        color: @color;
        text-decoration: none;
      }
    }
  }

  &__total {
    flex: 0 0 200px;
    font-size: 13px;
    font-weight: 700;
    text-align: right;
  }

  &__head {
    flex: 1 1 auto;
    text-align: center;

    a {
      margin: 0 5px;
      color: @text;
      text-decoration: none;

      &:hover {
        color: @color;
        text-decoration: none;
      }
    }
  }

  &__title {
    margin-bottom: 5px;
    font-size: 35px;
    line-height: 40px;
    text-align: center;
  }

  &__main {
    display: flex;
    width: 100%;
    max-width: 1050px;
    margin: 20px auto 0 auto;

    &__nav {
      flex: 0 0 360px;
      margin-right: 15px;
      font-size: 12px;
      line-height: 15px;
      font-weight: 700;
      text-transform: uppercase;

      &__head {
        padding: 15px 40px 10px 60px;
        background: @color-light;
        position: relative;

        span {
          display: block;
          margin: 5px 0 0 -2px;
          font-size: 30px;
          line-height: 33px;
          font-weight: 200;
          text-transform: none;
        }

        &.withimage {
          padding-right: 110px;
        }

        &__userpic {
          width: 90px;
          height: 90px;
          margin: auto;
          position: absolute;
          right: 10px;
          top: 0;
          bottom: 0;

          img {
            display: block;
            width: 100%;
            height: auto;
            -webkit-border-radius: 50%;
            -moz-border-radius: 50%;
            border-radius: 50%;
          }
        }
      }

      ul {

        li {
          padding-top: 5px;

          &.divider {
            padding-top: 10px;
          }

          a, span {
            display: block;
            padding: 21px 20px 17px 130px;
            background: @color-light;
            color: #000000;
            text-decoration: none;
            position: relative;

            &.active {
              background: @color;
              color: #ffffff;
              text-decoration: none;
            }

            &.black {
              background: #000000;
              color: #ffffff;
            }

            &:before, &:after {
              margin-top: auto;
              margin-bottom: auto;
              position: absolute;
              top: 0;
              bottom: 0;
              left: 50px;
            }
          }

          a {

            &:hover {
              color: @color;
              text-decoration: underline;
            }
          }
        }
      }
    }

    &__image {
      flex: 1 1 auto;
      background: url("../i/account.jpg") no-repeat 100% 0;
      -webkit-background-size: cover;
      background-size: cover;
    }

    &__block {
      flex: 1 1 auto;

      &__title {
        font-size: 12px;
        line-height: 23px;
        font-weight: 700;
        text-transform: uppercase;
      }
    }

    &__head {
      display: flex;
      align-items: center;
      // height: 111px;
      margin-bottom: 4px;
      padding: 32px 30px 32px 45px;
      background: #ffffff;

      &.nopad {
        margin-bottom: 0;
      }

      &.imaged {
        height: auto;
        padding: 0;

        img {
          display: block;
          width: 100%;
          height: auto;
        }
      }

      &__icon {
        flex: 0 0 auto;
      }

      &__title {
        flex: 1 1 auto;
        font-size: 30px;
        line-height: 36px;
        font-weight: 200;
      }

      &__text {
        flex: 0 0 240px;
        font-size: 12px;
        line-height: 14px;
      }
    }

    &__content {
      padding: 0 30px 0 45px;
      background: #ffffff;
    }

    &__chat {

      &-list {
        display: flex;
        justify-content: space-between;
        padding-bottom: 60px;
        color: @gray-dark;
        font-size: 12px;
        line-height: 16px;
        text-align: center;

        &__one {
          flex: 0 0 auto;
          max-width: 130px;
          display: flex;
          flex-direction: column;
          margin-top: 45px;

          &__person {
            flex: 1 1 auto;
            margin-bottom: 5px;
            padding: 0 10px;
            letter-spacing: 0.05em;
          }

          &__image {
            flex: 0 0 125px;

            img {
              display: block;
              width: 110px;
              height: 110px;
              margin: auto;
              -webkit-border-radius: 50%;
              -moz-border-radius: 50%;
              border-radius: 50%;
            }
          }

          &__action {
            flex: 0 0 50px;
            padding: 8px 10px 6px 10px;
            border: 1px solid @gray-bg;
            color: @gray-dark;
            font-size: 11px;
            line-height: 18px;
            font-weight: 700;
            letter-spacing: 0.12em;

            &:hover {
              border-color: @color-light;
              color: @color;
            }

            &.soon {
              border-color: @color;
              background: @color;
              color: #ffffff;
            }
          }
        }
      }
    }

    &__chat {
      margin-top: 10px;
      border-top: 3px solid @color;
      border-bottom: 3px solid @color;
      background: #ffffff url("../i/bg-chat.gif") repeat 2px 0;

      &__wrap {
        padding: 60px 18px 60px 20px;
        border-right: 2px solid #ffffff;
        border-left: 2px solid @color-darker;

        &:after {
          display: block;
          content: " ";
          overflow: hidden;
          clear: both;
        }
      }

      &__message {
        display: flex;
        flex-wrap: nowrap;
        float: left;
        width: 100%;
        max-width: 444px;
        margin-bottom: 30px;
        padding: 30px 30px 30px 5px;
        border: 1px solid @color-darker;
        background: #ffffff;
        overflow: hidden;
        clear: both;

        &.answer {
          flex-direction: row-reverse;
          float: right;
          padding-right: 5px;
          padding-left: 30px;
          border: 1px solid #ffffff;
          background: @color-darker;
        }

        &__userpic {
          flex: 0 0 100%;
          max-width: 110px;
          height: 110px;
          margin-right: 25px;
          color: @gray-darkfest;
          font-size: 13px;
          line-height: 15px;
          text-align: center;
          -webkit-border-radius: 50%;
          -moz-border-radius: 50%;
          border-radius: 50%;

          &.iconed {
            background: @color-darker;

            &:before {
              margin: 10px auto 5px auto;
            }
          }

          img {
            display: block;
            width: 100%;
            max-width: 110px;
            height: auto;
            -webkit-border-radius: 50%;
            -moz-border-radius: 50%;
            border-radius: 50%;
          }
        }

        &__text {
          flex: 1 1 auto;
          color: @gray-darkest;
          font-size: 13px;
          line-height: 25px;

          textarea& {
            margin-right: -20px;
            border: none;
            font-weight: 400;
            resize: none;
          }
        }
      }

      &__submit {
        width: 215px;
        padding-top: 20px;
        overflow: hidden;
        clear: both;
      }
    }

    &__bonuses {
      padding: 35px 0 75px 0;

      &__cardinfo {
        display: flex;
        margin-bottom: 30px;

        &__text {
          flex: 1 1 auto;
          font-size: 30px;
          line-height: 33px;
          font-weight: 200;
        }

        &__date {
          margin-top: 5px;
          font-size: 13px;
          line-height: 18px;
          font-weight: 400;
        }

        &__image {
          flex: 0 0 auto;
          margin-left: 30px;

          img {
            display: block;
            margin: 0;
            padding: 0;
          }
        }
      }

      &__gratz {
        margin-bottom: 30px;
        color: @gray-lighter;
        font-size: 13px;
        line-height: 16px;

        &__title {
          margin-bottom: 5px;
          color: @color;
          font-size: 30px;
          line-height: 33px;
          font-weight: 200;
        }

        a {
          color: inherit;
          text-decoration: underline;

          &:hover {
            color: @color;
            text-decoration: underline;
          }
        }
      }

      &__terms {
        margin-bottom: 30px;

        &__one {
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-bottom: 10px;
          padding: 8px 10px 8px 25px;
          background: @color-light;

          &:after {
            display: none;
          }

          &__name {
            flex: 0 0 165px;
            font-size: 12px;
            line-height: 16px;
            font-weight: 700;
            text-transform: uppercase;
          }

          &__where {
            flex: 1 1 170px;
            font-size: 13px;
            line-height: 16px;

            & + & {
              flex: 0 0 auto;
            }

            a {
              color: inherit;
              text-decoration: underline;

              &:hover {
                text-decoration: none;

              }
            }

            span {
              display: inline-block;
              vertical-align: middle;
            }
          }

          &__percents {
            flex: 0 0 140px;
            height: 32px;
            font-size: 40px;
            line-height: 36px;
            font-weight: 200;
          }

          &__info {
            font-size: 13px;
            line-height: 16px;
          }
        }
      }

      &__stats {
        margin-bottom: 30px;

        &__one {
          display: flex;
          flex-wrap: wrap;
          padding: 10px 0 10px 25px;

          &__title {
            flex: 0 0 100%;
            font-size: 12px;
            line-height: 18px;
            font-weight: 700;
            text-transform: uppercase;
          }

          &__text {
            flex: 0 0 auto;
            color: @gray-lighter;
            font-size: 11px;
            line-height: 16px;
          }

          &__dots {
            flex: 1 1 auto;
            margin-left: 7px;
            background: url("../i/bg-dots.gif") repeat-x 0 10px;
          }
        }
      }

      &__summ {
        width: 130px;
        flex: 0 0 130px;
        margin-left: 10px;
        font-size: 18px;
        line-height: 14px;
        font-weight: 700;
      }

      &__forupgrade {
        display: flex;
        padding: 17px 0 15px 50px;
        background: @pink;
        font-size: 13px;
        line-height: 18px;
        position: relative;

        &:before {
          margin-top: -12px;
          position: absolute;
          top: 50%;
          left: 25px;
        }

        &__text {
          flex: 1 1 auto;
          text-align: center;
        }
      }

      &__faqlink {
        padding-top: 50px;
        font-size: 14px;
        line-height: 18px;
        text-align: right;
        font-style: italic;
        font-family: "Times New Roman", serif;

        a {
          text-decoration: underline;

          &:hover {
            text-decoration: none;
          }
        }
      }
    }
  }

  &__block {
    padding: 35px 45px;
    background: #ffffff;

    & + & {
      margin-top: 10px;
    }
  }

  &__section {
    padding: 30px 0;

    &__title {
      font-size: 30px;
      line-height: 36px;
      font-weight: 200;
    }

    &__descr {
      padding-right: 60px;
      font-size: 13px;
      line-height: 18px;
    }

    &__fields {
      display: flex;
      flex-wrap: wrap;
      align-items: center;

      & + & {
        margin-top: 25px;
        border-top: 1px solid @gray-bd;

        &.nobd {
          border: none;
        }
      }

      &__title {
        flex: 0 0 100%;
        margin-top: 25px;
        font-size: 12px;
        line-height: 18px;
        font-weight: 700;
      }

      &__one {
        flex: 0 0 33.33%;
        margin-top: 25px;
        font-size: 13px;
        line-height: 16px;

        &.upload {
          font-size: 12px;
          line-height: 16px;
          font-weight: 700;

          a {
            color: inherit;
            text-decoration: underline;

            &:hover {
              color: inherit;
              text-decoration: none;
            }
          }
        }

        &.lowpad {
          margin-top: 10px;
        }

        &.wide {
          flex: 0 0 100%;
        }
      }
    }
  }

  &__submit {
    padding: 30px 0 50px 0;
  }

  &__card {
    padding: 80px 35px 80px 50px;

    &__title {
      font-size: 12px;
      line-height: 18px;
      font-weight: 700;

      &.pad {
        margin-top: 15px;
      }
    }

    &__stroke {
      display: flex;
      justify-content: space-between;
      padding: 3px 0;
      font-size: 13px;
      line-height: 18px;

      &__action {

        a {
          font-size: 14px;
          line-height: 18px;
          font-style: italic;
          text-decoration: underline;
          font-family: "Times New Roman", serif;

          &:hover {
            text-decoration: none;
          }
        }
      }
    }

    &__note {
      margin-top: 30px;
      color: @gray;
      font-size: 11px;
      line-height: 13px;
    }

    &_form {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      padding-right: 38px;
      padding-left: 23px;

      .account__submit {
        margin-top: 30px;
      }
    }

    &__field, &_form &__title, &_form &__note, &_form .account__submit {
      flex: 0 0 100%;
      padding: 0 7px;
      -webkit-box-sizing: border-box;
      -moz-box-sizing: border-box;
      box-sizing: border-box;

      &.half {
        flex: 0 0 50%;
      }

      &.third {
        flex: 0 0 33.33%;
      }
    }

    &__field {

      input[type=checkbox] + label {
        margin-bottom: 15px;
      }
    }

    &_form &__note {
      margin-top: 0;
      margin-bottom: 15px;
    }

    &_cards {
      padding-top: 10px;
      text-align: center;

      &__wrap {
        width: 100%;
        max-width: 390px;
        margin: 15px auto 0 auto;
      }

      &__text {
        font-size: 12px;
        line-height: 23px;
        font-weight: 700;
        letter-spacing: 0.08em;
        text-transform: uppercase;
      }

      &__icon {
        display: block;
        margin: 35px auto 45px auto;
      }

      &__button {
        margin: 20px 20px 0 20px;
      }

      &__note {
        margin: 30px 20px 0 20px;
        text-align: right;

        a {
          font-size: 14px;
          line-height: 18px;
          font-style: italic;
          text-decoration: underline;
          font-family: "Times New Roman", serif;

          &:hover {
            text-decoration: none;
          }
        }
      }
    }
  }

  &__wishlist {
    background: none;
    padding: 0;

    &__item {
      display: flex;
      flex-wrap: wrap;
      margin-bottom: 10px;
      padding: 20px 20px 30px 30px;
      background: #ffffff;

      .catalogue__item {

        &__price {
          margin-bottom: 10px;
        }

        &__colors {
          margin-bottom: 12px;

        }

        &__sizes {
          margin-bottom: 10px;
        }
      }

      &__old {
        flex: 0 0 100%;
        margin-bottom: 5px;
        color: @color;
        font-size: 14px;
        line-height: 18px;
        font-style: italic;
        font-family: "Times New Roman", serif;
      }

      &__image {
        flex: 0 0 222px;
        margin-right: 20px;
        position: relative;

        .img {
          display: block;
          width: 100%;
          height: auto;
          margin: 0;
          padding: 0;
        }

        a.link {
          display: block;
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          z-index: 1;
        }
      }

      &__status {
        padding: 18px 35px;
        background: rgba(98, 181, 177, 0.59);
        font-size: 12px;
        line-height: 14px;
        letter-spacing: 0.05em;
        text-align: center;
        position: absolute;
        right: 0;
        bottom: 0;
        left: 0;

        &.red {
          background: rgba(233, 196, 203, 0.81);
        }

        &:before {
          display: block;
          margin: auto;
          position: absolute;
          top: 0;
          right: 12px;
          bottom: 0;
        }
      }

      &__info {
        flex: 1 1 200px;
        display: flex;
        justify-content: space-between;
        flex-direction: column;

        &__wrap {
          display: flex;
        }

        &__params {
          flex: 1 1 100px;
        }

        &__promo {
          flex: 0 0 120px;
          margin-left: 20px;
          color: @color;
          font-size: 14px;
          line-height: 18px;
          text-align: right;
          font-style: italic;
          font-family: "Times New Roman", serif;

          a {
            color: inherit;
            text-decoration: underline;

            &:hover {
              color: inherit;
              text-decoration: none;
            }
          }
        }
      }

      &__buttons {
        display: flex;
        padding: 0 5px;

        .button {
          flex: 0 0 50%;
          height: auto;
          margin-left: -5px;
          padding: 17px 0 15px 0;

          + .button {
            margin-right: -5px;
            margin-left: 0;
          }
        }
      }

      &__remove {
        position: absolute;
        top: 10px;
        left: 10px;
        z-index: 2;
        cursor: pointer;

        &:before {
          margin: auto;
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
        }
      }

      &__gone {
        flex: 0 0 100%;
        min-height: 14px;
        margin-top: 5px;
        color: @red-dark;
        font-size: 11px;
        line-height: 14px;

        .catalogue__item__colors & {
          margin-left: 6px;
        }
      }
    }
  }
}

@media screen and (max-width: 999px) {
  .account {

    &__top {
      padding-top: 20px;

      .wrap {
        align-items: center;
      }
    }

    &__back {
      flex: 0 0 125px;
    }

    &__total {
      flex: 0 0 125px;
      font-size: 12px;
      line-height: 16px;
    }

    &__title {
      font-size: 25px;
      line-height: 30px;
    }

    &__main {

      &__nav {
        flex: 0 0 275px;
        margin-right: 8px;

        &__head {
          padding-right: 20px !important;
          padding-left: 30px;
        }

        ul {

          li {

            a, span {
              padding-left: 95px;

              &:before, &:after {
                left: 20px;
              }
            }
          }
        }
      }

      &__head {
        // height: 68px;
        padding: 13px 25px;

        &__title {
          font-size: 25px;
          line-height: 25px;
        }

        &__text {
          flex: 0 0 190px;
        }
      }

      &__content {
        padding: 0 25px;
      }

      &__bonuses {
        padding-bottom: 25px;

        &__cardinfo {

          &__text {
            font-size: 24px;
            line-height: 26px;
          }
        }

        &__gratz {
          margin-bottom: 15px;

          &__title {
            font-size: 25px;
            line-height: 25px;
          }
        }

        &__terms {
          margin: 35px 0 0 0;

          &__one {
            flex-wrap: wrap;
            padding: 15px 35px 15px 25px;
            position: relative;

            &:after {
              display: block;
              position: absolute;
              top: 18px;
              right: 15px;
            }

            &__name {
              flex: 0 0 100%;
            }

            &__where, &__percents, &__info {
              display: none;
              flex: 0 0 100%;
              margin-top: 10px;

              .active > & {
                display: block;
              }
            }
          }
        }

        &__stats {
          margin-bottom: 10px;

          &__one {
            padding-left: 0;
          }
        }

        &__forupgrade {
          align-items: flex-end;
          padding: 11px 0 9px 55px;
          font-size: 13px;
          line-height: 16px;

          &:before {
            left: 15px;
          }

          &__text {
            text-align: left;
          }

          .account__main__bonuses__summ {
            margin-bottom: 5px;
          }
        }

        &__faqlink {
          padding-top: 5px;
          font-size: 12px;
          line-height: 18px;
        }
      }

      &__chat-list {
        flex-wrap: wrap;

        &__one {
          flex-direction: row;
          flex-wrap: wrap;
          align-items: center;
          flex: 1 0 100%;
          max-width: 100%;
          margin-top: 30px;
          position: relative;
          padding-left: 80px;

          &__person {
            flex: 1 0 100%;
            max-width: 50%;
            text-align: left;
            padding-left: 0;
          }

          &__image {
            flex: 0 1 125px;
            position: absolute;
            top: 50%;
            left: 0;
            transform: translateY(-50%);

            img {
              margin: 0;
              max-width: 60px;
              height: auto;
            }
          }

          &__action {
            margin: 0 auto;
            flex: 1 0 100%;
            max-width: 50%;
          }
        }
      }

      &__chat {

        &__wrap {
          padding: 30px 15px;
        }

        &__message {
          padding: 10px 10px 10px 5px;

          &__userpic {
            width: 100%;
            height: 60px;
            max-width: 60px;
            margin-right: 15px;

            &.iconed {
              color: fade(#fff, 0);
            }
          }

          &__text {
            line-height: 1.4;
          }

          textarea&__text {
            margin-right: 0;
            padding: 0;
          }
        }
      }
    }

    &__section {
      padding: 25px 0;

      &__title {
        font-size: 23px;
        line-height: 24px;
      }

      &__fields {

        &__one {
          flex: 0 0 50%;

          &.upload {
            flex: 0 0 33.33%;
            font-size: 9px;
            line-height: 12px;
          }
        }
      }
    }

    &__submit {

      .button_big {
        font-size: 13px;
      }
    }

    &__wishlist {
      padding: 0;

      &__item {
        align-items: flex-start;
        padding: 10px 20px 30px 20px;

        &__info {
          flex: 1 1 100px;
          position: relative;

          .catalogue__item__brand {
            margin-bottom: 10px;
          }

          .catalogue__item__colors {
            min-height: 39px;
          }

          .catalogue__item__sizes {
            min-height: 33px;
          }

          &__params {
            flex: 0 0 100%;
          }

          &__promo {
            width: 222px;
            margin: 0;
            font-size: 12px;
            line-height: 18px;
            text-align: left;
            position: absolute;
            top: 289px;
            left: -242px;
          }
        }

        &__buttons {
          flex-wrap: wrap;
          padding: 0;

          .button {
            flex: 0 0 100%;
            margin: 12px 0 0 0;
          }
        }
      }
    }

    &__card {
      padding: 40px 15px 0;

      &_cards {
        padding: 40px 0 0;

        &__button {
          margin: 20px 0;
        }
      }

      &_form {
        padding: 40px 0;
      }
    }


  }
}

@media screen and (max-width: 767px) {
  .account {

    .wrap {
      justify-content: center;
    }

    &__main {
      flex-direction: column-reverse;
      width: auto;
      margin: 0 -15px;

      &.alternate {
        flex-direction: column;
      }

      &__nav {
        flex: 0 0 auto;
        margin-right: 0;
      }

      &__currentpage {
        padding: 21px 70px 17px 70px;
        background: @color;
        color: #ffffff;
        font-size: 12px;
        line-height: 14px;
        font-weight: 700;
        text-transform: uppercase;
        text-align: center;
        position: relative;

        a, span {
          color: #ffffff;
          text-decoration: none;
        }

        .iconed {

          &:before, &:after {
            margin: auto;
            position: absolute;
            top: 0;
            bottom: 0;
            left: 15px;
          }

          &:after {
            left: 35px;
          }
        }
      }

      &__image {
        flex: 0 0 155px;
        // width: 150%;
        height: 155px;
        // margin-left: -50%;
        background-position: 100%  -50px;
        margin-bottom: 5px;
      }

      &__content {
        padding: 0 15px;
      }

      &__head {
        height: auto;
        padding: 15px;

        &__text {
          flex: 0 0 100%;
        }
      }

      &__bonuses {

        &__cardinfo {
          flex-wrap: wrap;

          &__text {
            margin-bottom: 10px;
            font-size: 30px;
            line-height: 33px;
          }

          &__image {
            margin-left: 0;
          }
        }

        &__stats {
          margin-right: -15px;
          margin-bottom: 0;
          margin-left: -15px;

          &__one {
            padding: 15px;
            border-top: 1px solid #000000;
          }
        }

        &__forupgrade {
          flex-wrap: wrap;
          margin-right: -15px;
          margin-left: -15px;
          padding: 15px 50px 15px 15px;
          border-top: 1px solid #000000;

          &:before {
            right: 10px;
            left: auto;
          }
        }

        &__summ {
          flex: 0 0 100%;
          margin: 10px 0 0 0;
        }

        &__terms {
          margin: 10px -15px 0 -15px;
        }
      }

      &__chat-list {

        &__one {

          &__person {
            max-width: 100%;
          }

          &__action {
            margin: 0;
            flex: 0 0 auto;
            max-width: 100%;
          }
        }
      }
    }

    &__section {

      &__fields {

        &.upload {
          justify-content: space-between;
        }

        &__one {
          flex: 0 0 100%;

          &.upload {
            flex: 0 0 auto;
            font-size: 11px;
            line-height: 14px;
            text-align: center;

            .iconed:before {
              display: block;
              margin-right: auto;
              margin-left: auto;
            }
          }
        }
      }
    }

    &__wishlist {
      padding: 0;

      &__item {
        margin-bottom: 4px;

        &__image {
          flex: 0 0 135px;
          margin-right: 10px;

          img {
            width: 100%;
            height: auto;
          }
        }

        &__status {
          padding: 10px;

          &:before {
            display: none;
          }
        }

        &__info {

          &__wrap {
            flex-direction: column;
          }

          &__promo {
            flex: 0 0 auto;
            width: auto;
            margin-right: -10px;
            margin-left: -155px;
            text-align: center;
            position: static;
            top: auto;
            right: auto;
            bottom: auto;
            left: auto;
          }
        }

        &__buttons {
          justify-content: space-between;
          flex-direction: row-reverse;
          margin-left: -145px;
          padding: 0 2px;

          .button {
            flex: 0 0 50%;
            margin-right: -2px;
            font-size: 9px;
            line-height: 15px;

            + .button {
              margin-right: 0;
              margin-left: -2px;
            }
          }
        }
      }
    }

    &__card {

      &__field {

        &.half {
          flex: 0 0 100%;
        }

        &.third {
          flex: 0 0 100%;
        }
      }

      &_form & {

        &__field {

          &.phone {
            flex: 0 0 100%;
          }
        }

        &__note {

          &.half {
            flex: 0 0 100%;
          }
        }
      }
    }
  }
}
