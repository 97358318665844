/* styles */

html, body {
  background: #ffffff;
  color: @text;
  font-size: 16px;
  line-height: 29px;
  font-weight: 400;
  font-family: "Circe", Tahoma, Arial, Verdana, Helvetica, sans-serif;
}

a, .link {
  color: @color;
  text-decoration: none;
  cursor: pointer;
}

.title {
  font-family: "CircusDidot", Georgia, serif;
}

.page {
  display: flex;
  flex-direction: column;
  min-width: 320px;
  min-height: 100%;
  overflow: hidden;

  &.bg {




    background: @gray-bg;
  }

  & > * {
    flex: 0 0 auto;
  }

  .flexfill {
    flex: 1 0 auto;
  }
}

.wrap {
  display: flex;
  width: 100%;
  max-width: 1280px;
  margin: auto;
  padding: 0 15px;
}

.textlinks {

  a, .link {
    color: inherit;

    &:hover {
      color: @color;
      text-decoration: none;
    }

    &.colored {
      color: @color;

      &:hover {
        color: inherit;
      }
    }
  }
}

.colored {
  color: @color;
}

.banner {

  a& img, & a img, a {
    display: block;
    margin: 0;
    padding: 0;
  }
}

.topbanner {
  height: 100%;
  max-height: 30px;
  position: relative;
  overflow: hidden;
  -webkit-transition: all 1s;
  -moz-transition: all 1s;
  -ms-transition: all 1s;
  -o-transition: all 1s;
  transition: all 1s;

  &.active {
    max-height: 1000px;
  }

  &__arrow {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    cursor: pointer;

    .icon {
      margin: auto;
      position: absolute;
      right: 0;
      bottom: 8px;
      left: 0;
      -webkit-transition: all 1s;
      -moz-transition: all 1s;
      -ms-transition: all 1s;
      -o-transition: all 1s;
      transition: all 1s;
    }
  }
}

.sitepanel {
  padding: 5px 0;
  border-bottom: 1px solid #ffffff;
  background: @black;
  color: #ffffff;
  font-size: 10px;
  line-height: 15px;
  position: relative;
  z-index: 2;

  &__settings {
    display: flex;
    justify-content: space-between;
    width: 24%;
    min-width: 340px;

    a, .link {

      &:hover {
        color: inherit;
        text-decoration: none;
      }
    }

    &__geo, &__lang {
      display: flex;
    }

    &__one {
      margin: 0 -5px;
      padding: 0 5px;
      position: relative;

      &:first-child {
        padding-left: 0;
      }

      &:last-child {
        padding-right: 0;
      }

      > a, > .link {
        margin: 0 5px;
        text-decoration: underline;

        &:hover {
          text-decoration: underline;
        }
      }

      &__dropdown {
        max-height: 0;
        margin-top: -1px;
        padding-top: 8px;
        font-size: 12px;
        line-height: 14px;
        position: absolute;
        top: 100%;
        left: -5px;
        z-index: 3;
        overflow: hidden;
        -webkit-transition: all 1s;
        -moz-transition: all 1s;
        -ms-transition: all 1s;
        -o-transition: all 1s;
        transition: all 1s;

        ul, &__wrap {
          width: 120px;
          padding: 10px;
          background: @gray-bg-dark;
          color: @black;
          position: relative;
          -webkit-border-radius: 2px;
          -moz-border-radius: 2px;
          border-radius: 2px;

          &:before {
            display: block;
            content: " ";
            width: 0;
            height: 0;
            border: 5px solid transparent;
            border-bottom-color: @gray-bg-dark;
            position: absolute;
            top: 0;
            left: 15px;
            -webkit-transition: all 1s;
            -moz-transition: all 1s;
            -ms-transition: all 1s;
            -o-transition: all 1s;
            transition: all 1s;
          }
        }

        &__wrap {
          width: 170px;
          padding: 10px 15px;
        }

        ul {

          li {
            margin: 0 -10px;
            padding: 3px 0;

            a {
              display: block;
              padding: 1px 10px 0 20px;
              color: @black;
              text-decoration: none;

              &:hover {
                background: @gray-bg;
                color: @black;
                text-decoration: none;
              }

              &:before {
                margin: 2px 8px 0 -10px;
                vertical-align: top;
              }
            }
          }
        }
      }

      &:hover &__dropdown {
        max-height: 300px;

        ul, &__wrap {

          &:before {
            top: -10px;
          }
        }
      }
    }
  }

  &__promo {
    flex: 1 1 auto;
    color: @color;
    font-size: 13px;
    line-height: 15px;
    text-align: center;

    strong {
      font-weight: 900;
    }

    &__more {
      display: inline-block;
      margin: -3px 0 0 7px;
      vertical-align: middle;
      font-size: 9px;
      text-decoration: underline;
      cursor: pointer;

      &:hover {
        text-decoration: none;
      }

      .icon {
        margin-left: 18px;

        -webkit-transition: all 1s;
        -moz-transition: all 1s;
        -ms-transition: all 1s;
        -o-transition: all 1s;
        transition: all 1s;
      }
    }

    &__offer {
      max-height: 0;
      position: absolute;
      top: 100%;
      left: 50%;
      overflow: hidden;
      -webkit-transform: translate(-50%, 0);
      -moz-transform: translate(-50%, 0);
      -ms-transform: translate(-50%, 0);
      -o-transform: translate(-50%, 0);
      transform: translate(-50%, 0);
      -webkit-transition: all 1s;
      -moz-transition: all 1s;
      -ms-transition: all 1s;
      -o-transition: all 1s;
      transition: all 1s;

      a {
        display: inline-block;
        max-width: 100%;

        img {
          display: block;
          max-width: 100%;
          height: auto;
          margin: auto;
        }
      }

      &.active {
        max-height: 800px;
      }
    }
  }

  &__phone {
    width: 190px;
    font-size: 11px;
    text-align: right;

    a {
      margin-left: 11px;
    }
  }
}

.userpanel {
  background: @gray-bg;
  z-index: 1;

  .wrap {
    justify-content: space-between;
    position: relative;
  }

  &__block {
    display: flex;
    position: relative;

    &__one, a, .link {
      padding: 13px 0;
      color: #050000;
      font-size: 12px;
      line-height: 18px;
      font-weight: 700;
      text-decoration: none;
      position: relative;

      &:hover {
        color: @color;
        text-decoration: none;
      }

      & + & {
        margin-left: 30px;
      }

      span.iconed {
        color: @color;
        font-size: 11px;
        line-height: 14px;
        font-weight: 700;
        font-style: italic;
        font-family: Arial, sans-serif;
      }

      .icon, .iconed:before {
        margin-left: 2px;
        vertical-align: middle;
      }
    }

    &__info {
      max-height: 0;
      padding: 0 15px;
      border: 1px solid transparent;
      background: transparent;
      font-size: 11px;
      line-height: 16px;
      position: absolute;
      top: 100%;
      left: -5px;
      overflow: hidden;
      -webkit-transition: all 1s;
      -moz-transition: all 1s;
      -ms-transition: all 1s;
      -o-transition: all 1s;
      transition: all 1s;

      .hover & {
        max-height: 200px;
        margin-top: -2px;
        padding: 8px 15px;
        border-color: @gray-bg;
        background: #ffffff;
      }

      ul li {
        padding: 4px 0;

        a {
          color: @gray-darker;
          font-weight: 400;
          white-space: nowrap;

          &:hover {
            color: @color;
          }
        }
      }
    }

    &__dropdown {
      display: block;
      width: 320px;
      max-height: 0;
      margin-top: -1px;
      position: absolute;
      top: 100%;
      left: 15px;
      z-index: 2;
      overflow: hidden;
      -webkit-transition: all 1s;
      -moz-transition: all 1s;
      -ms-transition: all 1s;
      -o-transition: all 1s;
      transition: all 1s;

      .active & {
        max-height: 500px;
      }

      &__wrap {
        padding: 15px 25px 30px 25px;
        border: 1px solid @gray-bg;
        background: #ffffff;
      }

      &__title {
        margin-bottom: 5px;
        color: @color;
        font-size: 35px;
        line-height: 40px;
        font-weight: 200;
        text-align: center;
      }

      &__button {
        margin-bottom: 15px;

        a {
          display: block;
          padding: 19px 10px;
          background: @color;
          color: #ffffff;
          font-size: 10px;
          line-height: 14px;
          letter-spacing: 0.04em;
          text-align: center;
          -webkit-transition: all 1s;
          -moz-transition: all 1s;
          -ms-transition: all 1s;
          -o-transition: all 1s;
          transition: all 1s;

          &:hover {
            color: #ffffff;
            background: lighten(@color, 10%);
          }
        }
      }

      &__nav {
        margin-bottom: 20px;

        ul {

          li {
            padding: 10px 0;
            font-size: 10px;
            line-height: 14px;
            text-align: center;

            a {
              padding: 0;
              font-size: 10px;
              line-height: 14px;

              &:after {
                margin-left: 5px;
                vertical-align: middle;
              }
            }
          }
        }
      }

      &__contactus {
        margin-bottom: 10px;
        font-size: 12px;
        line-height: 14px;
        letter-spacing: 0.08em;
        text-align: center;

        &__shedule {
          margin-top: 5px;
          color: @color;
          font-size: 11px;
          line-height: 14px;
          letter-spacing: normal;
        }
      }

      &__buttons {

        ul {

          li {
            padding-top: 1px;

            a {
              display: block;
              padding: 19px;
              background: @gray-bg;
              color: @text;
              font-size: 11px;
              line-height: 14px;
              text-align: center;
              position: relative;

              &:before {
                position: absolute;
                top: 12px;

                left: 13px;
              }
            }
          }
        }
      }

      &__close {
        position: absolute;
        top: 9px;
        right: 9px;
        cursor: pointer;
      }
    }
  }
}

.carousel {
  display: flex;
  text-align: center;

  &__item {
    flex: 0 0 135px;
    margin: 0 18px;

    a {
      display: flex;
      flex-direction: column;

      img {
        display: block;
        max-width: 100%;
        height: auto;
        margin: auto;
      }
    }

    &__brand {
      margin-bottom: 10px;
      font-size: 10px;
      line-height: 14px;
      font-weight: 700;
      text-align: center;
      text-transform: uppercase;
    }

    &__name {
      flex: 1 0 auto;
      margin-bottom: 10px;
      font-size: 11px;
      line-height: 14px;
      text-align: center;
    }

    &__price {
      height: 36px;
      font-size: 11px;
      line-height: 16px;
      font-weight: 700;
      text-align: center;

      .old {
        display: block;
        color: @gray-dark;
        text-decoration: line-through;
      }
    }
  }
}

.infoblock {
  font-size: 16px;
  line-height: 18px;
  text-align: center;

  &__title {
    margin-bottom: 20px;
    font-size: 35px;
    line-height: 40px;
  }

  &__descr {
    margin-bottom: 20px;
  }

  &__button {
    margin-top: 15px;
  }
}

.header {
  margin-bottom: 24px;
  background: #ffffff;

  &__welcome {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 24px 0 32px 33.3333%;

    &__logo {
      width: 50%;
      text-align: center;

      img {
        display: block;
        margin: auto;
      }
    }

    &__text {
      color: @gray;
      font-size: 12px;
      line-height: 14px;
      font-weight: 700;

      a {
        margin-left: 6px;
      }
    }
  }

  &__navbar {

    background: @color-light;
    font-size: 11px;
    line-height: 14px;
    font-weight: 700;
    text-transform: uppercase;
    position: relative;

    &__nav {
      flex: 1 0 auto;

      > ul {
        display: flex;

        > li {
          flex: 1 0 auto;

          > a {
            display: block;
            padding: 14px 0 13px 0;
            color: #000000;
            font-size: 11px;
            line-height: 14px;
            font-weight: 700;
            text-align: center;
            text-decoration: none;
            text-transform: uppercase;
          }

          &:hover > a {
            background: #ffffff;
            color: #000000;
          }
        }
      }
    }

    &__search {
      width: 190px;
      background: #ffffff;
      position: relative;

      input {
        display: block;
        float: right;
        width: 100%;
        height: 40px;
        padding: 9px 61px 9px 21px;
        border: 4px solid #ffffff;
        background-color: #ffffff;
        color: @color;
        font-size: 12px;
        line-height: 14px;
        font-weight: 700;
        text-align: left;
        font-style: normal;
        text-transform: uppercase;
        font-family: "Circe", sans-serif;
        -webkit-transition: all 1s;
        -moz-transition: all 1s;
        -ms-transition: all 1s;
        -o-transition: all 1s;
        transition: all 1s;

        &::-webkit-input-placeholder { color: @color-light; }
        &::-moz-placeholder          { color: @color-light; }
        &:-moz-placeholder           { color: @color-light; }
        &:-ms-input-placeholder      { color: @color-light; }

        &.icon {
          width: 28px;
          height: 23px;
          padding: 0;
          border: none;
          position: absolute;
          top: 8px;
          right: 20px;
          cursor: pointer;
        }

        &.active {
          width: 350px;
          height: 48px;
          margin-top: -4px;
          margin-bottom: -4px;
          padding-top: 13px;
          padding-bottom: 13px;
          border-color: @color-light;
        }
      }
    }

    &__dropdown {
      display: none;
      background: #ffffff;
      font-weight: 400;
      text-transform: none;
      position: absolute;
      top: 100%;
      right: 0;
      left: 0;
      z-index: 5;
      overflow: hidden;

      &:after {
        display: block;
        content: " ";
        height: 6px;
        background: @color-light;
        position: absolute;
        right: 0;
        bottom: 0;
        left: 0;
      }

      li.hover > & {
        display: block;
        z-index: 6;
      }

      .wrap {
        padding-top: 30px;
        padding-bottom: 36px;
        justify-content: space-between;

        &:after {
          display: block;
          content: " ";
        }

        &.alternate {
          justify-content: space-between;

          &:after {
            display: none;
          }
        }
      }

      &__title {
        margin-bottom: 10px;
        color: #000000;
        font-size: 12px;
        line-height: 14px;
        font-weight: 700;
        text-transform: uppercase;

        ul + & {
          margin-top: 20px;
        }
      }

      &__categories {
        padding-left: 20px;
        flex: 0 0 174px;
        font-size: 12px;
        line-height: 20px;
      }

      ul {

        li {
          padding: 5px 0;

          &.header__navbar__dropdown__title {
            margin-bottom: 10px;
            padding: 0;
          }

          &.divider {
            margin: 15px 0;
            background: url("../i/nav-divider.png") repeat-x 0 50%;
          }
        }
      }

      &__items {
        display: flex;
        justify-content: space-around;
        flex: 1 0 auto;
        max-width: 764px;
        color: @gray-dark;
        text-align: center;

        &__one {
          max-width: 302px;

          .title {
            color: #000000;
          }

          &:hover {

            .title {
              color: inherit;
            }
          }
        }
      }

      &__blocks {
        display: flex;
        justify-content: space-around;
        align-items: center;
        flex: 1 0 auto;
        padding: 0 35px;
      }

      &__block {
        display: flex;
        justify-content: center;
        align-items: center;
        flex: 0 1 auto;
        max-width: 285px;
        margin: 0 10px;
        padding-bottom: 20px;
        position: relative;

        &__button {
          max-width: 206px;
          margin: auto;
          padding: 13px 10px;
          background: @color;
          color: #ffffff;
          font-size: 10px;
          line-height: 16px;
          text-align: center;
          letter-spacing: 0.08em;
          position: absolute;
          right: 0;
          bottom: 0;
          left: 0;
        }

        &__wrap {
          display: flex;
          max-width: 215px;
          flex-direction: column;
          justify-content: center;
          text-align: center;
        }

        &__title {
          margin-bottom: 10px;
          font-size: 25px;
          line-height: 25px;
        }

        &__text {
          font-size: 11px;
          line-height: 20px;
          letter-spacing: 0.08em;
        }
      }

      &__featureditem {
        max-width: 302px;
        margin: 0 15px;
        text-align: center;
      }

      &__list {
        display: flex;
        justify-content: space-between;

        &__one {
          width: 150px;
          margin: 0 20px;

          &.alternate {
            width: 210px;
          }

          &__cards {
            margin: 0 0 0 -7px;

            img {
              margin: 0 0 10px 7px;
            }
          }
        }
      }

      .whatandhow__items {
        display: flex;
      }
    }
  }
}

.footer {
  padding: 40px 0 25px 0;
  background: @black;
  color: #ffffff;

  &__nav {
    justify-content: space-between;
    font-size: 12px;
    line-height: 16px;

    &__section {
      padding: 0 15px;
      text-align: center;

      .parent {
        display: block;
        margin-bottom: 15px;
        font-weight: 700;
        text-transform: uppercase;
      }

      ul li {
        margin-top: 2px;
      }
    }
  }

  &__links {
    margin-top: 25px;
    padding-top: 10px;
    border-top: 1px solid @black-light;
    font-size: 9px;
    line-height: 16px;
    font-weight: 700;
    text-transform: uppercase;

    .wrap {
      justify-content: space-around;
    }

    &__one {
      text-align: center;

      .icon {
        display: block;
        margin: 0 auto 2px auto;
      }
    }
  }

  &-payments {
    padding: 10px 0 25px 0;
    justify-content: space-between;
    align-items: center;
    font-size: 12px;
    line-height: 18px;
    text-align: center;

    a {

      &, &:hover {
        text-decoration: underline;
      }
    }

    &__secure {
      margin-right: 40px;
      position: relative;

      &:after {
        display: block;
        content: " ";
        width: 1px;
        height: 30px;
        background: @black;
        position: absolute;
        right: -40px;
        bottom: 0;
      }
    }

    &__text {
      margin-top: 15px;
      padding: 0 40px;
    }

    &__methods {
      margin: 15px 0 0 40px;
      position: relative;

      &:after {
        display: block;
        content: " ";
        width: 1px;
        height: 30px;
        background: @black;
        position: absolute;
        left: -40px;
        bottom: 0;
      }
    }
  }
}

#fader {
  display: none;
  background: rgba(255, 255, 255, 0.72);
  position: fixed;
  top: -100px;
  right: 0;
  bottom: -100px;
  left: 0;
  z-index: 10;

  &.alternate {
    background: rgba(0, 0, 0, 0.51);
  }
}

.owl {

  &-stage {
    display: flex;
    max-width: 100%;

    &-outer {
      overflow: hidden;
    }
  }

  &-item {
    display: flex;
  }

  &-dots {
    display: none;
  }

  &-nav {
    position: absolute;
    top: 50%;
    right: 0;
    left: 0;
  }

  &-prev, &-next {
    width: 37px;
    height: 68px;
    position: absolute;
    left: 0;
    cursor: pointer;

    &:before {
      display: block;
      content: " ";
      width: 17px;
      height: 48px;
      margin: -24px 0 0 -9px;
      background: url(@sprite) no-repeat -60px -40px;
      position: absolute;
      top: 50%;
      left: 50%;
    }

    &.disabled {
      .opacity(0.25);
      cursor: default;
    }
  }

  &-next {
    right: 0;
    left: auto;

    &:before {
      margin-left: -8px;
      background-position: -80px -40px;
    }
  }
}

.indexblock {
  display: flex;
  width: 100%;
  margin-bottom: 40px;
  background: @gray-bg;

  &__summary {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 20px;
    padding: 10px 0;
    background: #ffffff;
    text-align: center;

    &__statistic {
      display: flex;
      justify-content: space-between;
      margin-bottom: 25px;
      padding-bottom: 25px;
      position: relative;

      &:before {
        display: block;
        content: " ";
        width: 60px;
        height: 1px;
        margin-left: -30px;
        background: #514d4e;
        position: absolute;
        bottom: 0;
        left: 50%;
      }

      &__one {
        flex: 30%;
        font-size: 11px;
        line-height: 13px;

        span {
          display: block;
          font-size: 22px;
          line-height: 26px;
          font-weight: 300;
        }
      }
    }

    &__title {
      margin-bottom: 25px;
      font-size: 33px;
      line-height: 40px;
    }

    &__items {
      width: 290px;
      margin-right: auto;
      margin-left: auto;

      .carousel__item {
        margin: 0 5px;
      }
    }
  }

  &__banner {
    flex: 1 1 50%;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    -webkit-background-size: cover;
    background-size: cover;
    overflow: hidden;

    a {
      display: block;
      width: 100%;
      height: 100%;
    }
  }

  &__text {
    display: flex;
    flex: 1 0 25%;
    flex-direction: column;
    justify-content: center;
    padding: 15px;
  }
}

.advantages {
  margin-bottom: 40px;
  padding: 15px 0;
  background: @color-light;
  font-size: 11px;
  line-height: 18px;

  .wrap {
    justify-content: space-between;
  }

  &__one {
    max-width: 240px;
    position: relative;


    &_1 {
      padding-left: 31px;
    }

    &_2 {
      padding-left: 26px;
    }

    &_3 {
      max-width: 300px;
      padding-left: 22px;
    }

    &_4 {
      padding-left: 32px;
    }

    &__title {
      margin-bottom: 3px;
      font-weight: 700;
    }

    &__links {

      a {

        &:after {
          display: inline-block;
          content: " ";
          width: 4px;
          height: 6px;
          margin: -1px 0 0 5px;
          background: url(@sprite) no-repeat -50px -50px;
          vertical-align: middle;
        }

        &.alternate {
          display: inline-block;
          margin-top: 5px;
          font-weight: 700;

          &:after {
            width: 4px;
            height: 8px;
            margin-top: -3px;
            background-position: -50px -40px;
          }
        }
      }
    }
  }
}

.actual {
  margin-bottom: 60px;
  padding: 20px 0 75px 0;
  background: @gray-bg;

  .wrap {
    flex-direction: column;
  }

  &__title {
    font-size: 50px;
    line-height: 60px;
    text-align: center;
  }

  &__text {
    font-size: 14px;
    line-height: 18px;
    text-align: center;
    letter-spacing: 0.08em;
  }

  &__carousel {
    display: flex;
    width: 100%;
    max-width: 1086px;
    margin: 25px auto 0 auto;
    padding: 0 60px;
    font-size: 11px;
    line-height: 16px;
    text-align: center;
    position: relative;

    &__one {
      flex: 0 0 33.3333%;
      width: 33.3333%;
      height: 100%;
      padding: 0 10px;

      .owl-item > & {
        flex: 0 0 100%;
        max-width: 302px;
        margin: auto;
      }

      a {
        display: flex;
        flex-direction: column;
        height: 100%;
        background: #ffffff;

        img {
          display: block;
          width: 100%;
          height: auto;
          margin: 0;
          padding: 0;
        }
      }

      &__info {
        display: flex;
        flex: 1 0 auto;
        flex-direction: column;
        align-items: center;
        padding: 10px 20px 15px 20px;
      }

      &__title {
        display: block;
        margin-bottom: 5px;
        font-size: 25px;
        line-height: 30px;
      }

      &__text {
        display: flex;
        flex: 1 0 auto;
        align-items: center;
        padding: 0 20px;
      }
    }
  }

  .owl {

    &-item {
      flex: 0 0 33.33%;
      width: 33.33%;
    }

    &-nav {
      margin-top: -24px;
    }

    &-prev {
      left: 10px;
    }

    &-next {
      right: 10px;
    }
  }
}

.mixedblock {
  margin-bottom: 48px;

  .wrap {
    flex-direction: column;
  }

  .bestsellers, .lastchance {
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;
    justify-content: center;
    text-align: center;

    &__title {
      margin-bottom: 25px;
      font-size: 50px;
      line-height: 60px;
    }

    &__categories {
      margin-bottom: 50px;
      font-size: 12px;
      line-height: 16px;
      font-weight: 700;
      text-transform: uppercase;

      a {
        margin: 0 20px;
        white-space: nowrap;

        &:after {
          vertical-align: middle;
        }
      }
    }

    &__items {

      &__wrap {
        width: 825px;
        margin: auto;
        position: relative;
      }
    }

    .owl {

      &-item {
        flex: 0 0 165px;
        width: 165px;
      }

      &-nav {
        top: 62px;
      }

      &-prev {
        left: -40px;
      }

      &-next {
        right: -40px;
      }
    }
  }
}

.likeme {
  display: flex;
  margin-bottom: 60px;
  padding: 15px 10px;
  background: @gray-bg;

  &__text, &__one {
    display: flex;
    flex: 1 1 25%;
    flex-direction: column;
    justify-content: center;
    padding: 15px;
  }

  &__one {
    display: flex;

    img {
      display: block;
      max-width: 100%;
      height: auto;
      margin: auto;
    }
  }
}

.whatandhow {
  margin-bottom: 120px;

  &__title {
    margin-bottom: 35px;
    font-size: 50px;
    line-height: 60px;
    text-align: center;
  }

  &__gray {
    padding: 20px 0 30px 0;
    background: @gray-bg;

    .wrap {
      flex-direction: column;
    }
  }

  &__switcher {
    display: flex;
    justify-content: space-between;
    width: 610px;
    margin: auto;

    &__one {
      position: relative;
      cursor: pointer;

      img {
        display: block;
      }

      &.active {

        &:before {
          display: block;
          content: " ";
          height: 7px;
          margin-top: 23px;
          background: #000000;
          position: absolute;
          top: 100%;
          right: 0;
          left: 0;
        }

        &:after {
          display: block;
          content: " ";
          width: 0;
          height: 0;
          margin: 30px 0 0 -10px;
          border: 10px solid transparent;
          border-top-color: #000000;
          position: absolute;
          top: 100%;
          left: 50%;
        }
      }
    }
  }

  &__items {
    display: none;
    width: 100%;
    max-width: 960px;
    margin: auto;
    padding-top: 15px;
    font-size: 13px;
    line-height: 17px;

    &.active {
      display: flex;
    }

    &__one {
      display: flex;
      flex: 0 0 25%;
      padding: 0 18px;

      a {
        display: block;

        img {
          display: block;
          width: 100%;
          height: auto;
          margin-bottom: 25px;
        }
      }

      &__brand {
        display: block;
        margin-bottom: 5px;
        font-size: 12px;
        line-height: 16px;
        font-weight: 700;
        text-transform: uppercase;
      }

      &__name {
        display: block;
      }

      &__price {
        font-weight: 700;
      }
    }
  }
}

.categories {
  flex-direction: column;
  margin-bottom: 50px;
  text-align: center;

  &__title {
    font-size: 50px;
    line-height: 60px;
  }

  &__descr {
    font-size: 16px;
    line-height: 24px;
  }

  &__list {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    margin: 0 -23px;
    font-size: 13px;
    line-height: 22px;
    font-weight: 700;
    text-transform: uppercase;

    &__one {
      margin: 60px 20px 10px 20px;
      max-width: 145px;

      a {
        display: block;
        width: 100%;
        background-repeat: no-repeat;
        -webkit-background-size: cover;
        background-size: cover;
        overflow: hidden;

        img {
          display: block;
          margin-bottom: 25px;
        }
      }
    }

    &__one.double {
      max-width: 330px;
    }
  }
}

.about {
  flex-direction: column;
  padding-top: 55px;
  padding-bottom: 55px;

  &__logo {
    margin-bottom: 60px;
    font-size: 13px;
    line-height: 16px;
    font-weight: 700;
    text-align: center;
    letter-spacing: 0.05em;
    text-transform: uppercase;

    &:before {
      display: block;
      content: " ";
      width: 255px;
      height: 31px;
      margin: 0 auto 10px auto;
      background: url(@sprite) no-repeat -340px 0;
    }
  }

  &__text {
    margin-bottom: 50px;
    font-size: 16px;
    line-height: 29px;
    text-align: center;
    letter-spacing: 0.05em;
  }

  &__banner {
    margin-bottom: 65px;

    .about__bottom + & {
      margin-top: 65px;

      margin-bottom: 0;
    }

    a {
      display: block;

      img {
        display: block;
        width: 100%;
        height: auto;
      }
    }
  }

  &__bottom {
    display: flex;
    justify-content: space-around;
    align-items: center;

    &__left, &__right {
      display: flex;
      justify-content: space-around;
      flex: 0 0 29%;
    }

    &__logo {
      width: 116px;
      height: 21px;
      background: url(@sprite) no-repeat -600px 0;
    }

    &__socials {
      display: flex;
      justify-content: space-between;
      flex: 0 0 34%;

      a {
        display: block;
      }
    }
  }
}

.widgets {
  padding: 25px 0;
  background: @color-light;

  &__wrap {
    padding: 35px 0;
    background: #ffffff;
  }

  &__one {
    flex: 1 1 1px;
    margin: 0 20px;

    &__title {
      margin-bottom: 30px;
      font-size: 12px;
      line-height: 18px;
      font-weight: 700;
      text-align: center;
      letter-spacing: 0.08em;
      text-transform: uppercase;

      .db {
        display: block;

        .colored {
          font-size: 20px;
          line-height: 28px;

          span {
            font-size: 17px;
          }
        }
      }

      sup {
        margin-left: 3px;
        font-size: 1em;
        line-height: 1.5em;
        font-family: "Times New Roman", serif;
      }
    }

    &__divider {
      width: 45px;
      height: 1px;
      margin-right: auto;
      margin-left: auto;
      background: #000000;
    }

    &_text {
      text-align: center;

      &__title {
        margin-bottom: 10px;
        padding-top: 45px;
        font-size: 12px;
        line-height: 25px;
        font-weight: 700;
        letter-spacing: 0.12em;
        text-transform: uppercase;
        position: relative;
      }

      &__info {
        margin-bottom: 10px;
        color: @gray-darkfest;
        font-size: 11px;
        line-height: 18px;
        letter-spacing: 0.08em;
      }

      &__link {

        a {
          color: @gray-lighter;
          font-size: 14px;
          line-height: 22px;
          font-style: italic;
          text-decoration: underline;
          font-family: "Times New Roman", serif;
        }
      }
    }
  }

  &__divider {
    flex: 0 0 90px;
    position: relative;

    &:before {
      display: block;
      content: " ";
      width: 1px;
      background: #000000;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 50%;
    }
  }

  &__subscribe {

    &__form {

      &:after {
        display: block;
        content: " ";
        width: 93px;
        height: 33px;
        margin: 15px auto 0 auto;
        background: url(@sprite) no-repeat -700px -40px;
      }

      &__field {
        margin-top: 10px;
      }

      &__label {
        margin-top: 20px;
        font-size: 14px;
        line-height: 18px;
        font-style: italic;
        text-align: center;
        font-family: "Times New Roman", serif;
      }

      &__radios {
        display: flex;
        justify-content: space-between;
        margin-top: 25px;

        input + label {
          flex: 0 0 auto;
          padding: 22px 0 0 0;

          &:before {
            margin-left: -7px;
            left: 50%;
          }

          &:after {
            margin-left: -4px;
            left: 50%;
          }
        }
      }

      &__submit {
        margin-top: 35px;
      }

      &__note {
        margin-top: 20px;
        color: @gray-light;
        font-size: 14px;
        line-height: 18px;
        font-style: italic;
        text-align: center;
        font-family: "Times New Roman", serif;
      }
    }
  }

  &__promo {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    text-align: center;

    &:after {
      display: block;
      content: " ";
      width: 100%;
      height: 40px;
    }

    &__icon {
      margin: 20px auto 20px auto;
    }

    &__list {
      font-size: 12px;
      line-height: 25px;
      font-weight: 700;
      text-transform: uppercase;
    }
  }

  &__persons {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    text-align: center;

    .widgets__one__title {
      margin-bottom: 0;
    }

    &__list {
      width: 190px;
      margin-right: auto;
      margin-left: auto;
      padding-bottom: 40px;
      position: relative;

      &__item {

        .owl-item > & {
          width: 100%;
        }

        &__userpic {
          margin-bottom: 50px;

          img {
            display: block;
            margin: auto;
            -webkit-border-radius: 50%;
            -moz-border-radius: 50%;
            border-radius: 50%;
          }
        }

        &__name {
          margin-bottom: 10px;
          font-size: 16px;
          line-height: 20px;
        }

        &__job {
          width: 150px;
          margin: 0 auto 25px auto;
          font-size: 14px;
          line-height: 18px;
          font-style: italic;
          font-family: "Times New Roman", serif;
        }

        &__social {

          a {
            display: block;
            margin-right: auto;
            margin-left: auto;
          }
        }
      }
    }

    .owl {

      &-item {
        flex: 0 0 100%;
        width: 100%;
      }

      &-nav {
        margin-top: -37px;
      }

      &-prev, &-next {
        left: -37px;

        &:before {
          width: 5px;
          height: 7px;
          margin: -4px 0 0 -3px;
          background-position: 0 -30px;
        }
      }

      &-next {
        right: -37px;
        left: auto;

        &:before {
          margin-left: -2px;
          background-position: -10px -30px;
        }
      }
    }
  }
}

.adnav {
  display: none;
}

.breadcrumbs {
  flex: 0 0 100%;
  margin-bottom: 24px;
  color: @gray;
  font-size: 14px;
  line-height: 16px;
  font-style: italic;
  font-family: "Times New Roman", serif;

  a {
    color: @gray;
    text-decoration: none;
  }
}

.pagination {
  font-size: 12px;
  line-height: 18px;

  &_right {
    text-align: right;
  }

  a, span {
    display: inline-block;
    margin: 0 5px;
    vertical-align: top;
    color: @gray-darkest;
    text-decoration: none;

    &.active {
      color: #000000;
    }
  }

  &__prev, &__next {
    width: 8px;
    height: 11px;
    margin-top: 3px !important;
    background: url(@sprite) no-repeat;
  }

  &__prev {
    background-position: -980px -80px;

    &.disabled {
      background-position: -980px -100px;
    }
  }

  &__next {
    background-position: -990px -80px;

    &.disabled {
      background-position: -990px -100px;
    }
  }
}

.go2top {
  padding: 5px 0;
  border-top: 1px solid @gray;
  color: @gray;
  font-size: 14px;
  line-height: 18px;
  font-style: italic;
  text-align: center;
  font-family: "Times New Roman", serif;

  a {
    color: @gray;
    text-decoration: underline;

  }
}

@media screen and (min-width: 1250px) {
  .mixedblock {

    .wrap {
      flex-direction: row;
    }

    .lastchance {
      flex: 0 0 300px;
      width: 300px;
      padding: 30px;
      background: @color-light;
      text-align: center;

      &__wrap {
        padding: 30px;
        background: #ffffff;
      }

      &__title {
        margin-bottom: 13px;
        font-size: 33px;
        line-height: 40px;
      }

      &__descr {
        margin-bottom: 22px;
        font-size: 14px;
        line-height: 26px;
      }

      &__items {

        &__wrap {
          width: 165px;
          margin: auto;
          position: relative;
        }

        &__one {
          margin: 0 15px;
        }
      }

      .owl {

        &-item {
          flex: 0 0 100%;
          width: 100%;
        }

        &-nav {
          top: 170px;
        }

        &-prev, &-next {
          left: -30px;

          &:before {
            width: 5px;
            height: 7px;
            margin: -4px 0 0 -3px;
            background-position: 0 -30px;
          }
        }

        &-next {
          right: -30px;
          left: auto;

          &:before {
            margin-left: -2px;
            background-position: -10px -30px;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1249px) {
  .mixedblock {

    .lastchance {
      margin-top: 40px;
      padding-top: 40px;
      position: relative;

      &:before {
        display: block;
        content: " ";
        height: 6px;
        background: @color-light;
        position: absolute;
        top: 0;
        right: -100%;
        left: -100%;
      }
    }
  }

  .widgets {

    &__divider {
      flex: 0 0 40px;
    }
  }
}

@media screen and (min-width: 1000px) {
  .nodesktop {
    display: none !important;
  }
}

@media screen and (min-width: 768px) and (max-width: 999px) {
  .notablet {
    display: none !important;
  }
}

@media screen and (max-width: 999px) {
  .flex-btt {
    display: flex;
    flex-direction: column-reverse;
  }

  .sitepanel {

    .wrap {
      padding: 0;
    }

    &__promo {
      width: 100%;
      margin: -5px 0;
      padding: 5px 0;
      font-size: 10px;

      &__offer {
        width: 100%;
      }
    }
    &__search {
      position: absolute;
      top: 4px;
      right: 7px;
    }
  }

  .userpanel {
    background: none;
    padding: 12px 0;

    &.alternate {
      background: #ffffff;
      border-bottom: 1px solid #d8d9d9;
    }

    .wrap {
      justify-content: space-between;
      align-items: center;
    }

    &__navtrigger {
      flex: 0 0 33.33%;

      span {
        display: block;
        width: 16px;
        height: 10px;
        cursor: pointer;
      }

      i {
        display: block;
        width: 16px;
        height: 2px;
        background: @black;
        position: relative;

        &:before, &:after {
          display: block;
          content: " ";
          width: 100%;
          height: 100%;
          background: inherit;
          position: absolute;
        }

        &:before {
          top: 4px;
          left: 0;
        }

        &:after {
          top: 8px;
          left: 0;
        }
      }
    }

    &__logo {
      flex: 0 0 128px;
      width: 128px;
      height: 24px;
      background: url(@sprite) no-repeat -500px -100px;
    }

    &__block {
      flex: 0 0 33.33%;
      justify-content: flex-end;
      position: relative;

      &__one, a, .link {
        position: static;

        & + & {
          margin-left: 15px;
        }
      }

      &__info {
        right: -6px;
        left: auto;
      }
    }
  }

  .header {
    margin: 0;

    &__navbar {
      display: none; // TODO mobile nav
      margin: 0;
    }
  }

  .freeshipping {
    height: 14px;
    border-top: 1px solid;
    border-bottom: 1px solid;
    color: @gray;
    font-size: 8px;
    line-height: 14px;
    text-align: center;
    letter-spacing: 0.08em;
  }

  .mobilenav {
    display: flex;

    a {
      display: block;
      flex: 0 0 33.33%;
      padding: 11px 40px 11px 40px;
      background: @color-light;
      font-size: 11px;
      line-height: 18px;
      font-weight: 700;
      text-align: center;
      letter-spacing: 0.12em;
      position: relative;

      &:after {
        margin: -5px 0 0 0;
        position: absolute;
        top: 50%;
        right: 30px;
      }

      + a {
        border-left: 1px solid #ffffff;
      }
    }
  }

  .advantages {

    .wrap {
      padding: 0 40px;
    }

    &__one {
      flex: 0 0 216px;
      padding-right: 46px;
    }
  }

  .actual {
    margin-bottom: 0;
    padding-bottom: 55px;

    &__title {
      margin-bottom: 5px;
      font-size: 35px;
      line-height: 32px;
    }

    &__text {
      margin-bottom: 20px;
      font-size: 11px;
      line-height: 13px;
    }

    &__carousel {
      margin: auto;
      padding: 0 20px;

      &__one {
        padding-right: 7px;
        padding-left: 7px;

        &__info {
          padding-right: 10px;
          padding-left: 10px;
        }

        &__title {
          font-size: 19px;
          line-height: 24px;
        }

        &__text {
          font-size: 10px;
          line-height: 12px;
        }
      }
    }

    .owl {

      &-prev {
        left: -12px;
      }

      &-next {
        right: -12px;
      }
    }
  }

  .mixedblock {

    .bestsellers, .lastchance {

      &__title {
        margin-bottom: 10px;
        font-size: 35px;
        line-height: 32px;
      }

      &__categories {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-around;
        margin-bottom: 20px;

        a {
          margin: 5px 0;
          font-size: 11px;
        }
      }

      &__descr {
        margin-bottom: 20px;
        font-size: 12px;
        line-height: 16px;
      }

      &__items {

        &__wrap {
          width: 660px;
        }
      }
    }
  }

  .whatandhow {

    &__title {
      font-size: 35px;
      line-height: 40px;
    }
  }

  .about {
    padding: 0 0 45px 0;

    &__banner {
      margin-bottom: 0;
      padding-right: 9px;
      padding-left: 9px;
    }

    &__bottom {
      margin-top: 35px;

      &__socials {
        flex: 0 0 250px;
      }
    }

    &__bottom + &__banner {
      margin-top: 35px;
    }
  }

  .widgets {
    padding: 6px 0 0 0;

    &__one {
      flex: 0 0 335px;
      margin: auto;
    }
  }

  .footer {
    padding: 0;

    &__nav {
      flex-direction: column;
      padding: 0;

      &__section {
        border-top: 1px solid #ffffff;

        &:first-child {
          border: none;
        }

        ul {
          display: none;
          padding: 6px 0 16px 0;

          li {
            margin: 0;
            padding: 8px 0;
            font-size: 12px;
            line-height: 18px;
          }
        }

        .parent {
          margin: 0;
          padding: 11px 0;
          font-size: 11px;
          line-height: 15px;
          position: relative;
          cursor: pointer;

          &:after {
            display: block;
            content: " ";
            width: 10px;
            height: 6px;
            margin-top: -3px;
            background: url(@sprite) no-repeat -30px 0;
            position: absolute;
            top: 50%;
            right: 0;
          }

          &.active {

            &:after {
              width: 9px;
              height: 9px;
              margin-top: -4px;
              background-position: -40px 0;
            }

            + ul {
              display: block;
            }
          }
        }
      }
    }

    &-payments {
      flex-direction: column;

      &__text {
        padding: 0 15px;
        font-size: 10px;
        line-height: 18px;
      }

      &__methods {
        margin: 20px auto 0 auto;

        &:after {
          display: none;
        }
      }
    }
  }

  .mobilesearch {
    display: none;
    background: #ffffff;
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    z-index: 7;
  }

  .adnav {
    display: block;
    position: fixed;
    width: 320px;
    height: 100%;
    background: @color-light;
    top: 0;
    left: -320px;
    overflow-y: auto;
    z-index: 3;
    -webkit-transition: all 1s;
    -moz-transition: all 1s;
    -ms-transition: all 1s;
    -o-transition: all 1s;
    transition: all 1s;

    &.active {
      left: 0;
      box-shadow: 0 0 5px 0 fade(@color, 50%);
    }

    &__signin {
      display: block;
      height: 50px;
      margin-right: 50px;
      padding: 14px 20px;
      background: #ffffff;
      color: @color;
      font-size: 12px;
      line-height: 22px;
      font-weight: 700;
    }

    &__close {
      width: 50px;
      height: 50px;
      background: @color;
      position: absolute;
      top: 0;
      right: 0;
      cursor: pointer;

      &:before {
        display: block;
        content: " ";
        width: 14px;
        height: 14px;
        background: url(@sprite) no-repeat -700px -80px;
        position: absolute;
        top: 18px;
        left: 18px;
      }

      &.black:before {
        background-position: -720px -80px;
      }
    }

    &__flex {
      display: flex;
      -webkit-transition: all 1s;
      -moz-transition: all 1s;
      -ms-transition: all 1s;
      -o-transition: all 1s;
      transition: all 1s;

      &.active {
        margin-left: -320px;
      }

      &__one {
        flex: 0 0 320px;
      }
    }

    &__geo {
      display: flex;
      justify-content: space-between;
      padding: 15px;
      font-size: 12px;
      line-height: 16px;
      font-weight: 700;
      letter-spacing: 0.05em;

      span {
        flex: 0 0 auto;
      }

      .link {
        color: #000000;
        letter-spacing: normal;

        &:after {
          margin-top: -2px;
        }
      }
    }

    &__locale {
      display: flex;
      background: @color;

      &__one {
        flex: 0 0 50%;
        position: relative;

        & + & {
          border-left: 1px solid #ffffff;
        }
      }

      .sitepanel__settings__one__dropdown {
        margin-top: -8px;
        left: 15px;
      }

      .link {
        display: block;
        padding: 15px 10px 15px 15px;
        color: #ffffff;
        font-size: 12px;
        line-height: 16px;
        font-weight: 700;
        letter-spacing: 0.05em;

        &:after {
          float: right;
          margin-top: 6px;
        }

        &:hover + .sitepanel__settings__one__dropdown {
          max-height: 300px;

          & > ul, &__wrap {

            &:before {
              top: -10px;
            }
          }
        }
      }
    }

    &__nav {
      font-size: 11px;
      line-height: 17px;
      font-weight: 700;
      letter-spacing: 0.14em;

      &.parent {
        text-transform: uppercase;
      }

      a, .link {
        display: block;
        padding: 14px 30px;
        color: @text;
      }

      &__section {
        margin-top: 20px;
        padding: 20px 0;
        border-top: 1px solid #757676;
        border-bottom: 1px solid #757676;

        a, .link {
          padding: 7px 30px;
        }
      }
    }

    &__black {
      margin-top: 35px;
      padding: 15px 0;
      background: #000000;

      a, .link {
        padding: 7px 30px;
        color: #ffffff;
      }

      &__giftcard {
        margin: 15px 0;
        border-top: 1px solid #757676;
        border-bottom: 1px solid #757676;

        a, .link {
          padding: 19px 30px;
        }
      }

      &__more {
        text-transform: none;
        letter-spacing: 0.05em;

        a, .link {
          padding: 5px 30px;

          &.tdu {
            text-decoration: underline;
          }

          span {
            margin-left: 10px;
          }
        }
      }
    }

    &__back {
      margin-bottom: 10px;
      padding: 19px 30px;
      border-bottom: 1px solid #878e8e;
      color: #000000;
      font-size: 11px;
      line-height: 15px;
      font-weight: 700;
      text-decoration: underline;
    }
  }

  .popup {

    &.alternate {
      width: 320px;
      margin-top: 0;
      padding: 0;
      border: none;
      left: 0;
      -webkit-transform: none;
      -moz-transform: none;
      -ms-transform: none;
      -o-transform: none;
      transform: none;

      &:after {
        display: none;
      }
    }

    .userpanel__block__dropdown__wrap {
      border: none;
    }
  }

  .breadcrumbs {
    padding-top: 10px;
    font-size: 12px;
    line-height: 18px;
    text-align: center;
  }
}

@media screen and (max-width: 767px) {
  .nomobile {
    display: none !important;
  }

  .userpanel {
    padding: 0;

    &__logo {
      flex: 0 0 94px;
      width: 94px;
      height: 18px;
      background: url(@sprite) no-repeat -440px -81px;
    }

    &__block {
      margin-right: -9px;

      &__one, a, .link {
        padding: 8px 0;

        & + & {
          margin-left: 3px;
        }

        .icon {
          margin-right: 3px;
          margin-left: 0;
        }

        .iconed:before {
          margin-right: 3px;
          margin-left: 0;
        }
      }
    }
  }

  .mobilenav {
    flex-direction: column;

    a {
      border-left: none !important;
      border-bottom: 1px solid #ffffff;
    }
  }

  .actual {
    margin-bottom: 0;
    padding-bottom: 45px;

    &__title {
      margin-bottom: 10px;
      font-size: 30px;
      line-height: 27px;
    }

    &__text {
      margin-bottom: 15px;
    }

    &__carousel {
      padding: 0 28px;

      &__one {

        &__title {
          font-size: 19px;
          line-height: 24px;
        }

        &__text {
          font-size: 10px;
          line-height: 12px;
        }
      }
    }

    .owl {

      &-item {
        flex: 0 0 100%;
      }

      &-prev {
        left: -10px;
      }

      &-next {
        right: -10px;
      }
    }
  }

  .mixedblock {

    .bestsellers, .lastchance {

      &__title {
        font-size: 30px;
        line-height: 27px;
      }

      &__items {

        &__wrap {
          width: 165px;
        }
      }

      .owl {

        &-stage {

          &-outer {
            overflow: visible;
          }
        }

        &-nav {
          display: none;
        }
      }
    }
  }

  .advantages {
    margin-bottom: 30px;
    padding: 8px 0;

    .wrap {
      padding-right: 15px;
      padding-left: 15px;
    }

    &__one {
      flex: 0 0 130px;
      width: 50%;
      padding-right: 0;

      &:before {
        top: 4px;
      }

      &__title {
        margin-bottom: 0;
        font-size: 8px;
        line-height: 12px;
      }
    }
  }

  .about {

    &__banner {
      margin-top: 45px;
      padding-right: 0;
      padding-left: 0;

      &.alternate {
        margin-top: 0;
      }
    }

    &__bottom + &__banner {
      margin-top: 35px;
    }
  }

  .whatandhow {

    &__title {
      margin-bottom: 20px;
      font-size: 30px;
    }

    &__switcher {
      width: auto;
      margin-right: -24px;
      margin-left: -24px;

      &__one {
        width: 50%;
        padding: 0 9px;

        &.active:before {
          right: 9px;
          left: 9px;
        }

        img {
          display: block;
          width: 100%;
          height: auto;
        }
      }
    }

    &__items {
      flex-wrap: wrap;

      &__one {
        flex: 0 0 50%;
      }
    }
  }

  .widgets {

    &__one {
      flex: 0 0 100%;
    }
  }

  .popup {
    margin-top: 0;
    padding: 0;
    border: none;
    font-size: 12px;
    line-height: 14px;
    box-shadow: 0 5px 10px 0 fade(#000, 10%);

    &__pretitle {
      padding: 8px 40px 8px 20px;
      font-size: 13px;
      line-height: 18px;
    }

    &__wrap {
      padding: 20px;
    }

    &__title {
      margin-bottom: 5px;
      font-size: 18px;
      line-height: 20px;
    }

    &__subtitle {
      font-size: 12px;
      line-height: 14px;
    }
  }

  .upload {

    &__form {

      &__list {
        flex-wrap: wrap;
        justify-content: space-around;
        padding-top: 10px;
      }

      &__one {
        margin-top: 25px;
      }
    }
  }
}

.slick {

  &-prev, &-next {
    width: 100%;
    height: 31px;
    margin-top: -10px;
    border: none;
    background: none;
    position: relative;
    cursor: pointer;

    &:before {
      display: block;
      content: " ";
      width: 31px;
      height: 11px;
      margin: -5px 0 0 -15px;
      background: url(@sprite) no-repeat -160px -280px;
      position: absolute;
      top: 50%;
      left: 50%;
    }

    &.slick-disabled {
      .opacity(0.25);
      cursor: default;
    }
  }

  &-next {
    margin-top: 0;

    &:before {
      background-position: -160px -260px;
    }
  }
}

.separator {
  width: 100%;
  max-width: 950px;
  height: 8px;
  margin: auto;
  background: #000000;
}



/* UPD 11.06.2020 */
.cart__element {
  position: relative;
}
.cart__element:hover .cart__box {
  display: block;
}
.cart__box {
  display: none;
  position: absolute;
  top: 44px;
  right: 0;
  width: 288px;
  height: 496px;
  padding: 24px;
  background: #e3edee;
}
.cart__box:before {
  display: block;
  content: '';
  position: absolute;
  top: 7px;
  right: 35px;
  width: 0;
  height: 0;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-bottom: 10px solid #fff;
}
.cart__box_inner {
  background: #fff;
  padding-bottom: 15px;
}
.cart__box_title {
  font-size: 17px;
  font-weight: 800;
  color: #000;
  text-transform: uppercase;
  padding: 25px 15px 20px;
  border-bottom: 2px solid #e3edee;
}
.cart__box_content {
  height: 318px;
  padding: 0 15px;
  overflow: hidden;
  overflow-y: auto;
}
.cart__box_item {
  display: flex;
  align-items: flex-start;
  padding: 25px 0 !important;
}
.cart__box_item:not(:last-child) {
  border-bottom: 2px solid #e3edee;
}
.cart__box_left {
  width: 86px;
  min-width: 86px;
  height: 109px;
  overflow: hidden;
  margin-right: 25px;
}
.cart__box_left img {
  width: 100%;
  height: auto;
  margin: 0 auto;
}
.cart__box_name {
  margin-bottom: 10px;
  font-size: 10px;
  line-height: 14px;
  font-weight: 700;
  text-align: center;
  text-transform: uppercase;
}
.cart__box_desc {
  -webkit-box-flex: 1;
  -webkit-flex: 1 0 auto;
  -ms-flex: 1 0 auto;
  flex: 1 0 auto;
  margin-bottom: 10px;
  font-size: 11px;
  line-height: 14px;
  text-align: center;
}
.cart__box_price {
  height: 36px;
  font-size: 11px;
  line-height: 16px;
  font-weight: 700;
  text-align: center;
}
.cart__box_price .old {
  display: block;
  color: #7d7d7d;
  text-decoration: line-through;
}
.cart__box_button {
  display: block;
  color: #fff !important;
  margin: 10px 15px 0;
}
@media screen and (max-width: 680px) {
  .cart__box {
    display: none !important;
  }
}

.size__popup_title {
  font-size: 34px;
  font-weight: 300;
  color: #444142;
  line-height: 35px;
  margin-bottom: 15px;
}
.size__popup_pic {
  display: block;
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;
}
.size__popup_pic:not(:last-child) {
  margin-bottom: 35px;
}
.size__popup_wrap:not(:last-child) {
  margin-bottom: 52px;
}
.size__popup {
  width: 597px;
  top: -3000px;
}


.thanx {
  margin: 114px 0 114px;
}
.thanx__inner {
  width: 100%;
}
.thanx__title {
  display: block;
  font-family: "CircusDidot", Georgia, serif;
  font-size: 50px;
  line-height: 60px;
  text-align: center;
  margin-bottom: 35px;
}
.thanx__desc {
  font-size: 20px;
  line-height: 35px;
  margin-bottom: 87px;
  text-align: center;
}
.thanx__buttons {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: center;
}
.thanx__btn {
  display: block;
  min-width: 360px;
  width: 366px;
  margin: 0 10px;
}
.thanx__btn:first-child {
  margin-left: 0;
}
.thanx__btn:last-child {
  margin-right: 0;
}


.intro__slider {
  width: 100%;
}
.intro__slider .owl-nav {
  top: 40%;
  transform: translateY(-50%);
  margin: 0 30px;
}
.intro__slider .owl-stage {
  margin: 0 auto;
}
.intro__slider .owl-item {
  min-width: 100%;
}
.intro__slider .carousel__item {
  margin: 0 auto;
}
.intro__slider .owl-stage-outer {
  width: 100%;
}
.intro__slider_item {
  flex-basis: 100%;
}
.indexblock__slider {
  background: #fff;
}

.intro__after {
  margin: 40px auto 80px;
}
.intro__after_banners {
  display: flex;
  flex-wrap: wrap;
  margin-left: -15px;
  margin-right: -15px;
}
.intro__after_banner {
  display: block;
  text-align: center;
  position: relative;
  min-height: 1px;
  padding-left: 15px;
  padding-right: 15px;
}
.intro__after_banner:hover {
  text-decoration: underline;
}

.intro__after_banner img {
  display: block;
  margin-bottom: 22px;
  margin-left: auto;
  margin-right: auto;
}
.intro__after_banner .title {
    font-family: "Times New Roman";
    font-size: 25px;
    font-weight: bold;
    color: #010101;
}
.intro__after_banner .podpis {
  font-family: Arial;
  font-size: 12px;
  font-style: italic;
  color: #010101;
}

@media screen and (min-width: 768px) {
  .intro__after_banner  {
    width: 33.33333333%;
  }
}
@media screen and (max-width: 768px) {
  .intro__after_banner  {
    width: 100%;
  }

  .intro__after {
    margin: 30px auto 40px;
  }
}


// @import "_register";
// @import "_catalogue";
// @import "_itemcard";
// @import "_cart";
// @import "_account";
// @import "_help";
// @import "_news";
// @import "_trends";
// @import "_kaknamne";
// @import "_getalook";
// @import "_guestbook";
