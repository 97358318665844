/*
 --- SPRITE -------------------------------------
*/

.icon, .image, .iconed:before, .iconed:after {
  background: url(@sprite) no-repeat;
}

.iconed {

  &:before, &:after {
    display: inline-block;
    vertical-align: middle;
  }
}

.icon {
  display: inline-block;
  vertical-align: middle;

  &_banner {
    width: 37px;
    height: 18px;
    background-position: -100px 0;

    .active & {
      background-position: -100px -20px;
    }
  }

  &_more {
    display: inline-block;
    width: 12px;
    height: 12px;
    background-position: -800px -40px;
    vertical-align: middle;

    .active & {
      background-position: -800px -60px;
    }
  }

  &_close {
    width: 9px;
    height: 9px;
    background-position: -70px 0;
  }

  &_favorites {
    width: 17px;
    height: 12px;
    background-position: -80px -20px;
  }

  &_discount {
    width: 13px;
    height: 13px;
    background-position: 0 -60px;
  }

  &_social {
    width: 57px;
    height: 57px;

    &_fb {
      background-position: -100px -40px;
    }

    &_vk {
      background-position: -160px -40px;
    }

    &_ok {
      background-position: -220px -40px;
    }

    &_ig {
      background-position: -280px -40px;
    }

    &_yt {
      background-position: -340px -40px;
    }
  }

  &_social2 {
    width: 46px;
    height: 46px;

    &_fb {
      background-position: 0 -100px;
    }

    &_vk {
      background-position: -60px -100px;
    }

    &_ok {
      background-position: -120px -100px;
    }

    &_ig {
      background-position: -180px -100px;
    }

    &_yt {
      background-position: -240px -100px;
    }
  }

  &_remove {
    display: block;
    width: 31px;
    height: 30px;
    background-position: -880px -180px;
  }

  &_add2fav {
    display: block;
    width: 31px;
    height: 30px;
    background-position: -120px -280px;
  }

  &_sizes {
    display: block;
    width: 14px;
    height: 33px;
    background-position: -980px -180px;
  }

  &_kaknamne {
    display: block;
    width: 293px;
    height: 41px;
    background-position: 0 -320px;
  }

  &_uploadtitle {
    display: block;
    width: 166px;
    height: 43px;
    background-position: -760px -260px;
  }

  &_video {
    display: block;
    width: 74px;
    height: 73px;
    background-position: -540px -320px;
  }

  &ed {

    &_goto:after {
      content: " ";
      width: 5px;
      height: 7px;
      margin: -3px 0 0 7px;
      /*vertical-align: top;*/
      background-position: -10px -30px;
    }

    &_goto_white:after {
      background-position: -50px -60px;
      vertical-align: middle;
    }

    &_goto_colored:after {
      width: 4px;
      height: 8px;
      background-position: -50px -40px;
    }

    &_goto2:after {
      content: " ";
      width: 6px;
      height: 10px;
      margin: -3px 0 0 7px;
      vertical-align: top;
      background-position: -40px -60px;
    }

    &_goto3:after {
      content: " ";
      width: 6px;
      height: 8px;
      margin: -3px 0 0 7px;
      background-position: -160px -300px;
    }

    &_goback3:before {
      content: " ";
      width: 6px;
      height: 8px;
      margin: -3px 7px 0 0;
      background-position: -170px -300px;
    }

    &_buttons {

      &:before {
        content: " ";
        width: 30px;
        height: 30px;
      }

      &_email:before {
        background-position: -860px -40px;
      }

      &_chat:before {
        background-position: -900px -40px;
      }

      &_phone:before {
        background-position: -820px -40px;
      }
    }

    &_bonuses {

      &:before {
        content: " ";
        width: 12px;
        height: 12px;
        margin-top: -2px;
        margin-right: 6px;
        background-position: -60px -20px;
      }

      &_black:after {
        content: " ";
        width: 15px;
        height: 15px;
        margin: -2px 0 0 5px;
        background-position: -960px -160px;
        vertical-align: middle;
      }
    }

    &_cart {
      padding-top: 3px;

      &:before {
        content: " ";
        width: 17px;
        height: 17px;
        margin-top: -7px;
        margin-right: 2px;
        background-position: -80px 0;
      }
    }

    &_advantages {

      &:before {
        content: " ";
        position: absolute;
        top: 1px;
        left: 0;
      }

      &_1:before {
        content: " ";
        width: 21px;
        height: 14px;
        background-position: -30px -20px;
      }

      &_2:before {
        content: " ";
        width: 16px;
        height: 14px;
        background-position: 0 -40px;
      }

      &_3:before {
        content: " ";
        width: 12px;
        height: 12px;
        margin-top: 1px;
        background-position: -60px -20px;
      }

      &_4:before {
        content: " ";
        width: 22px;
        height: 14px;
        background-position: -20px -40px;
      }
    }

    &_flag {

      &:before {
        content: " ";
        width: 16px;
        height: 10px;
        margin-right: 10px;
      }

      &_ru:before {
        content: " ";
        background-position: -20px -60px;
      }

      &_en:before {
        content: " ";
        background-position: -420px -80px;
      }

      &_de:before {
        content: " ";
        background-position: -400px -80px;
      }

      &_fr:before {
        content: " ";
        background-position: 0 -80px;
      }

      &_it:before {
        content: " ";
        background-position: -40px -80px;
      }

      &_es:before {
        content: " ";
        background-position: -20px -80px;
      }
    }

    &_dropdown {

      &:after {
        content: " ";
        width: 7px;
        height: 3px;
        margin-left: 5px;
        background-position: -60px 0;
      }

      &_black:after {
        background-position: -50px 0;
      }
    }

    &_signin:before {
      content: " ";
      width: 22px;
      height: 22px;
      margin-right: 22px;
      background-position: -940px -40px;
    }

    &_gift {

      &:after {
        float: right;
        content: " ";
        width: 13px;
        height: 16px;
        margin: -2px -15px 0 0;
        background-position: -980px -60px;
      }

      &_black:after {
        background-position: -980px -40px;
      }
    }

    &_social {
      display: block;
      content: " ";
      width: 135px;
      padding: 17px 0 15px 0;
      color: #ffffff;
      font-size: 11px;
      line-height: 18px;
      text-align: center;
      letter-spacing: 0.12em;
      text-transform: uppercase;
      position: relative;

      &:before {
        content: " ";
        position: absolute;
        top: 50%;
        left: 20px;
      }

      &_fb {
        padding-left: 35px;
        background: #40569f;

        &:before {
          width: 14px;
          height: 24px;
          margin-top: -12px;
          background-position: -640px -95px;
        }
      }

      &_gp {
        padding-left: 55px;
        background: #d65038;

        &:before {
          width: 39px;
          height: 26px;
          margin-top: -13px;
          background-position: -660px -93px;
        }
      }

      &_vk {
        padding-left: 43px;
        background: #5077a4;

        &:before {
          width: 33px;
          height: 20px;
          margin-top: -10px;
          background-position: -820px -99px;
          left: 10px;
        }
      }
    }

    &_upload {

      &:before {
        display: inline-block;
        content: " ";
        margin-right: 10px;
        vertical-align: middle;
      }

      &_ig:before {
        width: 27px;
        height: 28px;
        background-position: -880px -140px;
      }

      &_fb:before {
        width: 10px;
        height: 21px;
        background-position: -920px -140px;
      }

      &_device:before {
        width: 33px;
        height: 25px;
        background-position: -660px -180px;
      }
    }

    &_save {

      &:before {
        display: block;
        content: " ";
        width: 22px;
        height: 22px;
        margin: 0 auto 5px auto;
        background-position: -920px -97px;
      }
    }

    &_view {

      &:before {
        display: block;
        content: " ";
        width: 27px;
        height: 22px;
        margin: 0 auto 5px auto;
        background-position: -950px -97px;
      }
    }

    &_featured:after {
      display: block;
      content: " ";
      width: 15px;
      height: 14px;
      background-position: -960px -80px;
    }

    &_totop:after {
      content: " ";
      width: 11px;
      height: 8px;
      margin-left: 10px;
      background-position: -740px -120px;
    }

    &_filter:after {
      content: " ";
      width: 6px;
      height: 9px;
      margin: -3px 0 0 5px;
      background-position: -780px -120px;
    }

    &_shipping:before {
      content: " ";
      width: 24px;
      height: 16px;
      margin-right: 15px;
      background-position: -800px -120px;

      .red > & {
        width: 26px;
        background-position: -830px -120px;
      }
    }

    &_attention {

      &:before {
        content: " ";
        width: 16px;
        height: 16px;
        background-position: -860px -120px;
      }

      &_red:before {
        background-position: -880px -120px;
      }
    }

    &_warning {

      &:before {
        content: " ";
        width: 24px;
        height: 24px;
        background-position: -440px -140px;
      }
    }

    &_balance:after {
      content: " ";
      width: 15px;
      height: 18px;
      margin-left: 7px;
      background-position: -980px -120px;
      vertical-align: top;
    }

    &_gotofav:after {
      content: " ";
      width: 22px;
      height: 22px;
      margin-left: 7px;
      background-position: -620px -140px;
    }

    &_acc {

      &:before, &:after {
        display: block;
        content: " ";
      }

      &:after {
        display: none;
      }

      &.active {

        &:before {
          display: none;
        }

        &:after {
          display: block;
        }
      }

      &_myaccount {

        &:before, &:after {
          width: 22px;
          height: 28px;
          margin-left: 17px;
          background-position: 0 -190px;
        }

        &:after {
          background-position: -30px -190px;
        }
      }

      &_orders {

        &:before, &:after {
          width: 29px;
          height: 22px;
          margin-left: 14px;
          background-position: -200px -160px;
        }

        &:after {
          background-position: -240px -160px;
        }
      }

      &_data {

        &:before, &:after {
          width: 30px;
          height: 20px;
          margin-left: 13px;
          background-position: -120px -195px;
        }

        &:after {
          background-position: -160px -195px;
        }
      }

      &_password {

        &:before, &:after {
          width: 23px;
          height: 26px;
          margin-left: 17px;
          background-position: -200px -190px;
        }

        &:after {
          background-position: -240px -190px;
        }
      }

      &_addresses {

        &:before, &:after {
          width: 24px;
          height: 24px;
          margin-left: 16px;
          background-position: -380px -180px;
        }

        &:after {
          background-position: -420px -180px;
        }
      }

      &_payments {

        &:before, &:after {
          width: 33px;
          height: 29px;
          margin-left: 12px;
          background-position: -460px -180px;
        }

        &:after {
          background-position: -500px -180px;
        }
      }

      &_socials {

        &:before, &:after {
          width: 30px;
          height: 24px;
          margin-left: 13px;
          background-position: -540px -180px;
        }

        &:after {
          background-position: -580px -180px;
        }
      }

      &_contacts {

        &:before, &:after {
          width: 30px;
          height: 27px;
          margin-left: 13px;
          background-position: -120px -160px;
        }

        &:after {
          background-position: -160px -160px;
        }
      }

      &_gifts {

        &:before, &:after {
          width: 33px;
          height: 19px;
          margin-left: 12px;
          background-position: -620px -180px;
        }

        &:after {
          background-position: -620px -200px;
        }
      }

      &_favs {

        &:after {
          display: block;
          content: " ";
          width: 35px;
          height: 35px;
          margin-left: 11px;
          background-position: -340px -180px;
        }
      }

      &_bonuses {

        &:after {
          display: block;
          content: " ";
          width: 35px;
          height: 35px;
          margin-left: 11px;
          background-position: -300px -180px;
        }
      }

      &_chat {

        &:after {
          display: block;
          content: " ";
          width: 56px;
          height: 53px;
          background-position: -700px -140px;
          bottom: auto !important;;
        }
      }

      &_signout {

        &:after {
          display: block;
          content: " ";
          width: 25px;
          height: 23px;
          margin-left: 16px;
          background-position: -660px -140px;
        }
      }

      &_goback {

        &:before {
          display: block !important;
          content: " ";
          width: 6px;
          height: 10px;
          background-position: -340px -280px;
        }
      }
    }

    &_info {

      &:before {
        display: inline-block;
        content: " ";
        width: 18px;
        height: 18px;
        background-position: -980px -140px;
        vertical-align: middle;
      }

      &.red:before {
        background-position: -960px -140px;
      }
    }

    &_userpic {

      &:before {
        display: block;
        content: " ";
        width: 33px;
        height: 43px;
        background-position: -280px -220px;
      }
    }

    &_stores {

      &:before {
        content: " ";
        width: 25px;
        height: 28px;
        margin-right: 20px;
        margin-left: 5px;
        background-position: -740px -200px;
      }
    }

    &_online {

      &:before {
        content: " ";
        width: 36px;
        height: 27px;
        margin-right: 15px;
        background-position: -780px -200px;
      }
    }

    &_cardterms {

      &:before {
        content: " ";
        width: 37px;
        height: 26px;
        margin: -10px 12px 0 -4px;
        background-position: -820px -200px;
      }
    }

    &_howtopay {

      &:before {
        content: " ";
        width: 27px;
        height: 30px;
        margin: -8px 18px 0 0;
        background-position: -860px -220px;
      }
    }

    &_help {

      &:before {
        display: block;
        content: " ";
        margin: auto;
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
      }

      &_chat:before {
        width: 70px;
        height: 44px;
        background-position: -500px -260px;
      }

      &_email:before {
        width: 39px;
        height: 39px;
        background-position: -80px -280px;
        top: 3px;
      }

      &_vk:before {
        width: 46px;
        height: 46px;
        background-position: -380px -260px;
      }

      &_fb:before {
        width: 46px;
        height: 46px;
        background-position: -440px -260px;
      }
    }

    &_helptab {

      &:before {
        display: block;
        content: " ";
        margin: auto;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 30px;
      }

      &_1:before {
        width: 48px;
        height: 26px;
        background-position: -380px -220px;
      }

      &_2:before {
        width: 49px;
        height: 36px;
        background-position: -440px -220px;
      }

      &_3:before {
        width: 54px;
        height: 28px;
        background-position: -560px -220px;
      }

      &_4:before {
        width: 43px;
        height: 38px;
        background-position: -500px -220px;
      }

      &_5:before {
        width: 36px;
        height: 37px;
        background-position: -900px -220px;
      }

      &_6:before {
        width: 43px;
        height: 37px;
        background-position: -620px -220px;
      }

      &_7:before {
        width: 48px;
        height: 25px;
        background-position: -940px -220px;
      }

      &_8:before {
        width: 41px;
        height: 36px;
        background-position: -680px -220px;
      }

      &_9:before {
        width: 37px;
        height: 35px;
        background-position: -40px -280px;
      }
    }

    &_search {

      &:before {
        display: block;
        content: " ";
        width: 29px;
        height: 28px;
        margin: auto;
        background-position: 0 -280px;
        position: absolute;
        top: 50%;
        margin-top: -16px;
        right: 20px;
        // bottom: 15px;
      }
    }

    &_rate {

      &:before {
        display: block;
        content: " ";
        margin: auto;
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
      }

      &_1:before {
        width: 56px;
        height: 54px;
        background-position: -580px -260px;
        top: 7px;
      }

      &_2:before {
        width: 53px;
        height: 62px;
        background-position: -940px -256px;
      }

      &_3:before {
        width: 51px;
        height: 50px;
        background-position: -640px -260px;
        top: 11px;
      }

      .alternate& {

        &:before {

        }

        &_1:before {
          width: 80px;
          height: 76px;
          background-position: -620px -320px;
          top: 9px;
        }

        &_2:before {
          width: 75px;
          height: 89px;
          background-position: -780px -320px;
        }

        &_3:before {
          width: 71px;
          height: 70px;
          background-position: -701px -320px;
          top: 17px;
        }
      }
    }

    &_widget {

      &:before {
        display: block;
        content: " ";
        margin: auto;
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
      }

      &_shipping:before {
        width: 25px;
        height: 16px;
        background-position: -740px -240px;
      }

      &_return:before {
        width: 23px;
        height: 20px;
        background-position: -780px -239px;
      }

      &_help:before {
        width: 19px;
        height: 19px;
        background-position: -980px -160px;
      }
    }

    &_add2fav:after {
      display: inline-block;
      content: " ";
      width: 24px;
      height: 17px;
      margin: -2px 0 0 10px;
      background-position: -820px -240px;
      vertical-align: middle;
    }

    &_video {

      &:after {
        display: block;
        content: " ";
        width: 47px;
        height: 47px;
        background-position: -700px -260px;
      }
    }

    &_video2 {

      &:after {
        display: inline-block;
        content: " ";
        width: 20px;
        height: 20px;
        margin-left: 20px;
        background-position: -899px -319px;
        vertical-align: middle;
      }
    }

    &_uploadstep {
      position: relative;

      &:before {
        display: block;
        content: " ";
        margin: auto;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: -1;
      }

      &_1:before {
        width: 68px;
        height: 62px;
        background-position: -300px -320px;
      }

      &_2:before {
        width: 72px;
        height: 67px;
        background-position: -380px -320px;
      }

      &_3:before {
        width: 67px;
        height: 73px;
        background-position: -460px -320px;
      }
    }

    &_upload2 {

      &:before {
        display: block;
        content: " ";
        height: 25px;
        margin: 0 auto 20px auto;
      }

      &_ig:before {
        width: 23px;
        background-position: 0 -365px;
      }

      &_vk:before {
        width: 27px;
        background-position: -40px -365px;
      }

      &_fb:before {
        width: 11px;
        background-position: -80px -365px;
      }

      &_ok:before {
        width: 15px;
        background-position: -100px -365px;
      }

      &_device:before {
        width: 32px;
        background-position: -120px -365px;
      }
    }

    &_spoiler {

      &:after {
        display: inline-block;
        content: " ";
        width: 10px;
        height: 6px;
        background-position: -360px -280px;
      }

      &.active:after {
        background-position: -360px -290px;
      }
    }
  }

  &_acc {

    &_myaccount {
      width: 36px;
      height: 46px;
      margin-right: 24px;
      background-position: -780px -140px;
    }

    &_addresses {
      width: 45px;
      height: 45px;
      margin-right: 30px;
      background-position: 0 -220px;
    }

    &_favs {
      width: 51px;
      height: 51px;
      margin-right: 26px;
      background-position: -60px -220px;
    }

    &_data {
      width: 56px;
      height: 36px;
      margin-right: 26px;
      background-position: -920px -180px;
    }

    &_password {
      width: 39px;
      height: 46px;
      margin-right: 34px;
      background-position: -120px -220px;
    }

    &_cards {
      width: 57px;
      height: 29px;
      background-position: -160px -220px;
    }

    &_chat {
      width: 56px;
      height: 54px;
      margin-right: 29px;
      background-position: -220px -220px;
    }

    &_bonuses {
      width: 49px;
      height: 49px;
      margin-right: 26px;
      background-position: -320px -220px;
    }
  }

  &_search {
    width: 28px;
    height: 23px;
    background-position: 0 0;
  }

  &_search2 {
    width: 20px;
    height: 17px;
    background-position: -980px 0;
  }

  &_foot {
    width: 40px;
    height: 40px;

    &_1 {
      background-position: -400px -40px;
    }

    &_2 {
      background-position: -440px -40px;
    }

    &_3 {
      background-position: -480px -40px;
    }

    &_4 {
      background-position: -520px -40px;
    }

    &_5 {
      background-position: -560px -40px;
    }
  }

  &_shipping {
    width: 38px;
    height: 26px;
    background-position: -360px -140px;
  }

  &_payment {
    width: 50px;
    height: 37px;
    background-position: -300px -140px;
  }

  &_mastercard {
    width: 44px;
    height: 27px;
    background-position: -480px -140px;
  }

  &_visa {
    width: 42px;
    height: 14px;
    background-position: -900px -120px;
  }

  &_qiwi {
    width: 49px;
    height: 20px;
    background-position: 0 -160px;
  }

  &_yandex {
    width: 63px;
    height: 24px;
    background-position: -540px -140px;
  }

  &_alfa {
    width: 48px;
    height: 42px;
    background-position: -60px -160px;
  }
}

.image {

  &_logo {
    width: 197px;
    height: 36px;
    background-position: -140px 0;
  }

  &_secure {
    width: 81px;
    height: 47px;
    background-position: -605px -40px;
  }

  &_payments {
    width: 250px;
    height: 29px;
    background-position: -720px 0;
  }
}

@media screen and (max-width: 999px) {
  .icon {

    &_social {
      width: 33px;
      height: 33px;

      &_fb {
        background-position: -300px -100px;
      }

      &_vk {
        background-position: -340px -100px;
      }

      &_ok {
        background-position: -380px -100px;
      }

      &_ig {
        background-position: -420px -100px;
      }

      &_yt {
        background-position: -460px -100px;
      }
    }

    &_acc {

      &_orders {
        width: 29px;
        height: 22px;
        margin-right: 10px;
        background-position: -200px -160px;
      }

      &_payments {
        width: 33px;
        height: 29px;
        margin-right: 10px;
        background-position: -460px -180px;
      }

      &_socials {
        width: 30px;
        height: 24px;
        margin-right: 10px;
        background-position: -540px -180px;
      }

      &_contacts {
        width: 30px;
        height: 27px;
        margin-right: 10px;
        background-position: -120px -160px;
      }

      &_gifts {
        width: 33px;
        height: 19px;
        margin-right: 10px;
        background-position: -620px -180px;
      }

      &_signout {
        width: 25px;
        height: 23px;
        margin-right: 10px;
        background-position: -660px -140px;
      }

      /* TODO: иконки раздела, внизу - готовые, вверху - заготовки */

      &_myaccount {
        width: 22px;
        height: 28px;
        margin-right: 11px;
        background-position: 0 -190px;
      }

      &_addresses {
        width: 24px;
        height: 24px;
        margin-right: 10px;
        background-position: -380px -180px;
      }

      &_favs {
        width: 35px;
        height: 35px;
        margin-right: 10px;
        background-position: -340px -180px;
      }

      &_data {
        width: 30px;
        height: 20px;
        margin-right: 10px;
        background-position: -120px -195px;
      }

      &_password {
        width: 23px;
        height: 26px;
        margin-right: 10px;
        background-position: -200px -190px;
      }

      &_cards {
      }

      &_chat {
        width: 56px;
        height: 53px;
        margin-right: 10px;
        background-position: -700px -140px;
      }

      &_bonuses {
        width: 35px;
        height: 35px;
        margin-right: 10px;
        background-position: -300px -180px;
      }
    }

    &ed {

      &_social {
        width: 109px;
        padding: 14px 0 12px 0;
        font-size: 9px;
        line-height: 14px;

        &:before {
          left: 15px;
        }

        &_fb {
          padding-left: 30px;

          &:before {
            width: 12px;
            height: 22px;
            margin-top: -11px;
            background-position: -700px -97px;
          }
        }

        &_gp {
          padding-left: 45px;

          &:before {
            width: 32px;
            height: 22px;
            margin-top: -11px;
            background-position: -720px -97px;
          }
        }

        &_vk {
          padding-left: 35px;

          &:before {
            width: 27px;
            height: 18px;
            margin-top: -9px;
            background-position: -780px -80px;
            left: 8px;
          }
        }
      }

      &_upload {

        &:before {
          display: inline-block;
          content: " ";
          margin-right: 10px;
          vertical-align: middle;
        }

        &_ig:before {
          width: 20px;
          height: 20px;
          background-position: -275px -195px;
        }

        &_fb:before {
          width: 10px;
          height: 21px;
          background-position: -920px -140px;
        }

        &_device:before {
          width: 24px;
          height: 18px;
          background-position: -700px -200px;
        }
      }
    }
  }
}

@media screen and (max-width: 767px) {
  .icon {

    &_shipping {
      width: 24px;
      height: 16px;
      background-position: -800px -120px;
    }

    &_payment {
      width: 31px;
      height: 24px;
      background-position: -860px -320px;
    }

    &ed {

      &_social {
        width: 100px;
        padding: 13px 0 11px 0;
        font-size: 8px;
        line-height: 13px;

        &_fb {
          padding-left: 25px;

          &:before {
            width: 11px;
            height: 21px;
            background-position: -760px -98px;
            left: 13px;
          }
        }

        &_gp {
          padding-left: 42px;

          &:before {
            width: 30px;
            height: 20px;
            margin-top: -10px;
            background-position: -780px -99px;
          }
        }

        &_vk {
          padding-left: 32px;

          &:before {
            width: 26px;
            height: 16px;
            background-position: -820px -80px;
            left: 7px;
          }
        }
      }

      &_upload {

        &:before {
          display: inline-block;
          content: " ";
          margin-right: 10px;
          vertical-align: middle;
        }

        &_ig:before {
          width: 20px;
          height: 20px;
          margin-top: 2px;
          margin-bottom: 7px;
          background-position: -275px -195px;
        }

        &_fb:before {
          width: 10px;
          height: 21px;
          margin-top: 2px;
          margin-bottom: 6px;
          background-position: -920px -140px;
        }

        &_device:before {
          width: 14px;
          height: 24px;
          margin-bottom: 5px;
          background-position: -940px -140px;
        }
      }
    }
  }

  .alternate {

    &.iconed_rate {

      &_1:before {
        width: 56px;
        height: 54px;
        background-position: -580px -260px;
        top: 7px;
      }

      &_2:before {
        width: 53px;
        height: 62px;
        background-position: -940px -256px;
      }

      &_3:before {
        width: 51px;
        height: 50px;
        background-position: -640px -260px;
        top: 11px;
      }
    }
  }
}
