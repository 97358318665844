/*
 --- PAGE GUEST BOOK ----------------------------
*/

.guestbook {

  > .wrap {
    flex-direction: column;
    max-width: 1060px;
  }

  .account__top {


    border-bottom: 1px solid @black;
  }

  &__list {

    &__one {
      display: flex;
      padding: 25px 0;

      &__image {
        margin-right: 60px;

        img {
          display: block;
        }
      }

      &__chat {
        flex: 1 1 auto;
        padding-top: 50px;
        border-top: 3px solid @gray-bg;

        &__list {

          &__question {
            display: flex;

            .account__main__chat__message__userpic {
              margin: 23px 45px 0 0;
            }

            &__message {

              &__name {
                font-size: 19px;
                line-height: 35px;
                font-weight: 200;
                text-align: right;
              }

              &__text {
                padding: 15px 10px;
                border-top: 3px solid @color-darker;
                border-bottom: 3px solid @color-darker;
                color: @gray-darkest;
                font-size: 13px;
                line-height: 25px;
                position: relative;

                img {
                  vertical-align: middle;
                }

                &:before, &:after {
                  display: block;
                  content: " ";
                  width: 35px;
                  height: 28px;
                  background: url(@sprite) no-repeat -260px -280px;
                  position: absolute;
                  top: -30px;
                  left: 0;
                }

                &:after {
                  background-position: -300px -280px;
                  top: auto;
                  right: -1px;
                  bottom: -30px;
                  left: auto;
                }
              }

              &__date {
                color: @color;
                font-size: 11px;
                line-height: 25px;
                font-weight: 700;
              }
            }
          }

          &__answer {
            display: flex;
            flex-direction: row-reverse;
            margin-top: 35px;
            padding-bottom: 25px;

            .account__main__chat__message__userpic {
              margin: 7px 0 0 60px;
            }

            &__message {
              padding: 4px 20px 15px 15px;
              background: @color-darker;
              position: relative;
              -webkit-border-radius: 10px;
              -moz-border-radius: 10px;
              border-radius: 10px;

              &:before {
                display: block;
                content: " ";
                width: 0;
                height: 0;
                margin-top: -15px;
                border: 15px solid transparent;
                border-left-color: @color-darker;
                position: absolute;
                top: 50%;
                left: 100%;
              }

              &:after {
                display: block;
                content: " ";
                width: 42px;
                height: 31px;
                background: url(@sprite) no-repeat -940px -320px;
                position: absolute;
                right: -17px;
                bottom: -27px;
              }

              &__name {
                margin-left: 50px;
                border-bottom: 3px solid #ffffff;
                font-size: 19px;
                line-height: 35px;
                font-weight: 200;
                text-align: right;
              }

              &__text {
                padding-top: 15px;
                color: @gray-darkest;
                font-size: 13px;
                line-height: 25px;

                img {
                  vertical-align: middle;
                }

                &:before {
                  display: block;
                  content: " ";
                  width: 42px;
                  height: 34px;
                  background: url(@sprite) no-repeat -200px -280px;
                  position: absolute;
                  top: 9px;
                  left: 15px;
                }
              }

              .guestbook__list__one__chat__list__question__message__date {
                position: absolute;
                bottom: -30px;
                left: 15px;
              }
            }
          }
        }

        &__doreg {
          display: flex;
          justify-content: flex-end;
          align-items: center;
          margin-bottom: 70px;

          &__warning {
            flex: 0 0 430px;
            padding-left: 50px;
            color: @color;
            font-size: 11px;
            line-height: 14px;
            position: relative;

            &:before {
              display: block;
              margin: auto;
              position: absolute;
              top: 0;
              right: auto;
              bottom: 0;
              left: 0;
            }

            a {
              text-decoration: underline;
            }
          }

          &__start {
            flex: 0 0 auto;
            width: auto;
            margin-left: 25px;
            padding-left: 80px;
            font-size: 12px;
            line-height: 18px;
            font-weight: 700;
            text-decoration: underline;
            position: relative;
            cursor: pointer;

            &:before {
              left: 0;
              right: auto;
            }

            &:hover {
              text-decoration: none;
            }
          }
        }
      }

      &:first-child &__chat {
        padding-top: 0;
        border: none;
      }

      &:last-child &__chat {
        padding-bottom: 50px;
        border-bottom: 3px solid @gray-bg;
      }
    }
  }

  &-popup {
    width: 600px;
    padding: 29px 39px 49px 39px;
    border: none;

    &:after {
      display: none;
    }

    &__title {
      margin-bottom: 10px;
      font-size: 34px;
      line-height: 40px;
      font-weight: 200;
      padding-right: 30px;
    }

    &__note {
      margin-bottom: 25px;
      color: @color;
      font-size: 14px;
      line-height: 18px;
      font-style: italic;
      font-family: "Times New Roman", serif;
    }

    &__form {

      &__smiles {
        display: flex;
        justify-content: space-between;
        margin-bottom: 40px;
        overflow: hidden;

        > * {
          display: block;
          margin-bottom: 10px;
        }
      }
    }
  }
}

.ui-widget.ui-autocomplete {
  padding: 10px 0 !important;
  border: 4px solid @color-light !important;
  border-top: none !important;

  li {
    padding: 0 !important;
    border: none !important;
    font-size: 12px;
    line-height: 20px;
    font-family: "Circe", sans-serif;

    &:before {
      display: none;
    }

    &.ui-autocomplete-divider {
      height: 4px;
      margin: 10px 0 !important;
      padding: 0 !important;
      background: @color-light;
    }

    a {
      display: block !important;
      margin: 0 !important;
      padding: 10px !important;
      background: none !important;
      border: none !important;
      color: @black !important;

      &:hover {
        background: @color-light !important;
      }
    }
  }
}


@media screen and (max-width: 999px) {
  .guestbook {

    &__list {

      &__one {

        &__image {
          margin-right: 30px;

          img {
            width: 100%;
            height: auto;
          }
        }

        &__chat {

          &__list {

            &__question {
              .account__main__chat__message__userpic {
                margin-right: 15px;
              }
            }

            &__answer {
              .account__main__chat__message__userpic {
                margin-left: 30px;
              }
            }
          }
        }
      }
    }
  }
}


@media screen and (max-width: 767px) {
  .guestbook {

    &__list {

      &__one {

        &__chat {
          padding-top: 30px;
          padding-bottom: 0;

          &__doreg {
            flex-wrap: wrap;
            margin-bottom: 50px;

            &__warning {
              flex: 0 0 100%;
              margin-bottom: 15px;
            }
          }

          &__list {

            &__question {

              .account__main__chat__message__userpic {
                margin-right: 5px;
              }

              &__message {

                &__name {
                  padding-left: 40px;
                  line-height: 1.2;
                  min-height: 28px
                }

                &__text {
                  line-height: 1.5;
                }
              }
            }

            &__answer {
              .account__main__chat__message__userpic {
                margin-left: 15px;
              }

              &__message {
                padding-top: 10px;

                &__name {
                  line-height: 1.2;
                  min-height: 34px;
                }

                &__text {
                  line-height: 1.5;
                }
              }
            }
          }
        }
      }
    }

    &-popup {
      padding: 15px;

      &__title {
        font-size: 26px;
      }
    }
  }
}
