/*
 --- PAGE ITEM CARD -----------------------------
*/

.lookalso {
  padding-top: 40px;
  border-top: 1px solid @gray-light;

  .whatandhow + & {
    margin-top: -45px;
  }

  .wrap {
    flex-direction: column;
  }

  &__controls {
    display: flex;
    align-items: center;
    padding: 80px 0 70px 0;

    &__left {
      flex: 0 0 50%;
      padding-right: 15px;
      text-align: right;
    }

    &__right {
      flex: 0 0 50%;
      padding-left: 15px;
      font-size: 11px;
      line-height: 16px;
      font-weight: 700;
      letter-spacing: 0.05em;

      a {
        margin: 05px;
        color: inherit;
        text-decoration: none;

        &:hover {
          color: inherit;
          text-decoration: underline;
        }
      }
    }
  }
}

.history {
  padding: 35px 0 65px 0;
  border-top: 1px solid @gray-light;

  .wrap {
    flex-direction: column;
  }

  &__title {
    margin-bottom: 50px;
    font-size: 50px;
    line-height: 60px;
    text-align: center;
  }

  &__carousel {
    display: flex;
    width: 100%;
    max-width: 900px;
    margin: auto;
    position: relative;

    &__one {
      flex: 0 0 150px;
      position: relative;

      > a {
        display: block;
        width: 135px;
        margin: auto;
      }

      &__actions {
        display: none;
        width: 129px;
        margin: auto;
        position: absolute;
        top: 3px;
        right: 0;
        bottom: auto;
        left: 0;
        z-index: 1;

        a {
          display: block;
          position: absolute;
          top: 0;
          right: 0;

          + a {
            right: auto;
            left: 0;
          }

          img {
            display: block;
            margin: auto;
          }
        }
      }

      &:hover &__actions {
        display: block;
      }
    }

    .owl-stage {
      display: flex;
      padding-bottom: 50px;
    }

    .owl-item {
      flex: 0 0 150px;
    }

    .owl-nav {
      margin: -59px -30px 0 -30px;
    }

    .owl-dots {
      display: flex;
      justify-content: center;
      position: absolute;
      right: 0;
      bottom: 0;
      left: 0;
    }

    .owl-dot {
      width: 14px;
      height: 14px;
      margin: 0 7px;
      background: @color-light;
      cursor: pointer;
      -webkit-border-radius: 50%;
      -moz-border-radius: 50%;
      border-radius: 50%;

      &.active {
        background-color: @color;
        cursor: default;
      }
    }
  }
}

.itemcard {

  .wrap {
    flex-wrap: wrap;
    justify-content: space-between;
  }

  &__left {
    flex: 0 0 100px;
    width: 100px;
    padding-right: 40px;
    margin-bottom: 45px;

    &__carousel {
      width: 100%;
      margin-bottom: 30px;

      &__one {
        margin: 6px 0;
        cursor: pointer;

        img {
          display: block;
          width: 100%;
          height: auto;
        }

        &.active {
          position: relative;
          cursor: default;

          &:before {
            display: block;
            content: " ";
            border: 1px solid @gray-bd;
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
          }
        }
      }
    }

    &__video {
      width: 47px;
      margin: auto;
      color: @gray;
      font-size: 14px;
      line-height: 18px;
      font-style: italic;
      text-align: center;
      font-family: "Times New Roman", serif;

      a {
        display: block;

        &:after {
          margin-top: 12px;
        }
      }
    }
  }

  &__image {
    flex: 1 0 425px;
    padding-right: 40px;
    margin-bottom: 45px;

    img {
      display: block;
      width: 100%;
      height: auto;
      margin: 0;
      padding: 0;
    }

    &__share {
      display: flex;
      align-items: center;
      padding-top: 15px;

      a {
        margin-right: 15px;
      }

      span {
        margin-left: 5px;
        color: @gray;
        font-size: 14px;
        line-height: 18px;
        font-style: italic;
        font-family: "Times New Roman", serif;
      }
    }
  }

  &__right {
    flex: 1 1 645px;
    position: relative;
    margin-bottom: 45px;
  }

  &__info {
    flex: 0 0 100%;
  }

  .catalogue__item {

    &__brand {
      padding: 0 180px;
      font-size: 25px;
      line-height: 30px;
      font-weight: 400;
      text-align: center;
      text-transform: none;
    }

    &__name {
      font-size: 14px;
      line-height: 22px;
      text-align: center;
    }

    &__price {
      display: flex;
      flex-direction: column-reverse;
      margin-top: 5px;
      font-size: 18px;
      text-align: center;

      span {
        margin-top: 10px;
        font-size: 13px;
      }
    }
  }

  &__subtitle {
    margin-top: 25px;
    font-size: 13px;
    line-height: 23px;
    font-weight: 700;
    text-align: center;
    text-transform: uppercase;

    &.colored {
      color: @color;
    }
  }

  &__colors {
    text-align: center;

    label {
      display: inline-block !important;
      float: none !important;
      width: 35px !important;
      height: 35px !important;

      &:before {
        width: 33px !important;
        height: 33px !important;
      }

      &:after {
        width: 39px !important;
        height: 39px !important;
        background: none !important;
      }
    }
  }

  &__sizes {
    text-align: center;
    position: relative;

    input[type=radio] {

      + label {
        display: inline-block;
        margin: 0 7px !important;
        padding: 8px 8px 4px 8px;
        font-size: 19px;
        line-height: 25px;

        &:before, &:after {
          display: none !important;
        }
      }

      &:checked + label {
        position: relative;

        &:before {
          display: block !important;
          width: auto !important;
          height: auto !important;
          border: 1px solid;
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          -webkit-border-radius: 0 !important;
          -moz-border-radius: 0 !important;
          border-radius: 0 !important;
        }
      }
    }

    &__guide {
      margin: auto;
      color: @gray;
      font-size: 14px;
      line-height: 18px;
      font-style: italic;
      font-family: "Times New Roman", serif;
      position: absolute;
      top: 35px;
      right: 0;
      bottom: 0;

      a {
        text-decoration: underline;

        &:hover {
          text-decoration: underline;
        }
      }
    }

    &__note {
      margin: 3px 0 10px 0;
      font-size: 14px;
      line-height: 22px;
      text-align: center;

      .icon {
        margin: 5px auto;
      }

      a {
        color: @gray;
        font-size: 14px;
        line-height: 18px;
        font-style: italic;
        text-decoration: underline;
        font-family: "Times New Roman", serif;

        &:hover {
          color: @color;
          text-decoration: underline;
        }
      }
    }
  }

  &__bonuses {
    width: 180px;
    line-height: 18px;
    position: absolute;
    top: 0;
    left: 0;

    &__info {
      margin-top: 5px;
      color: @gray;
      font-size: 14px;
      line-height: 18px;
      font-style: italic;
      font-family: "Times New Roman", serif;

      a {
        text-decoration: underline;

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }

  &__shipping {
    width: 180px;
    color: @gray;
    font-size: 14px;
    line-height: 18px;
    text-align: right;
    font-style: italic;
    font-family: "Times New Roman", serif;
    position: absolute;
    top: 0;
    right: 0;

    a {
      text-decoration: underline;

      span {
        color: @color;
        text-decoration: underline;
      }

      &:hover {
        text-decoration: underline;
      }
    }
  }

  &__buttons {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -5px;

    &__one {
      flex: 0 0 100%;
      padding: 10px 5px 0 5px;

      & + & {
        flex: 0 0 50%;
      }

      .button {
        display: block;
        width: 100%;
        padding-right: 0;
        padding-left: 0;

        &_blank {
          line-height: 23px;
        }
      }
    }
  }

  &__promo {
    margin-top: 30px;
    font-size: 14px;
    line-height: 18px;
    font-style: italic;
    text-align: center;
    font-family: "Times New Roman", serif;

    a {
      text-decoration: underline;
    }
  }

  &__about {
    margin-top: 35px;
    text-align: center;

    &__title {
      margin-bottom: 10px;
      font-size: 13px;
      line-height: 23px;
      font-weight: 700;
      text-transform: uppercase;
    }

    &__list {
      color: @gray-lighter;
      font-size: 14px;
      line-height: 22px;
      font-style: italic;
      font-family: "Times New Roman", serif;

      a {
        text-decoration: underline;
      }
    }
  }

  &__info {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: 35px;
    font-size: 12px;
    line-height: 28px;

    &__title {
      margin: 20px 0 5px 0;
      font-size: 25px;

      &:after {
        display: none;
      }
    }

    &__descr {
      margin-bottom: 5px;
      color: @gray;
      font-size: 14px;
      line-height: 22px;
      font-style: italic;
      font-family: "Times New Roman", serif;

      a {
        text-decoration: underline;

        &:hover {
          text-decoration: underline;
        }
      }
    }

    &__list {

      ul li {
        margin-top: 12px;
        padding-left: 20px;
        line-height: 16px;

        &:before {
          display: block;
          content: " ";
          float: left;
          width: 4px;
          height: 4px;
          margin: 6px 0 0 -20px;
          background: #000000;
          -webkit-border-radius: 50%;
          -moz-border-radius: 50%;
          border-radius: 50%;
        }
      }
    }

    &__about {
      flex: 0 0 380px;
    }

    &__aboutbrand {
      flex: 0 0 315px;
    }

    &__care {
      flex: 0 0 255px;
    }

    &__button {
      flex: 0 0 100%;
      margin-top: 55px;
      text-align: center;

      .button {
        display: inline-block;
        width: auto;
        max-width: none;
      }
    }
  }
}

@media screen and (max-width: 999px) {
  .itemcard {

    &__left, &__image {
      display: none;
    }

    &__right {
      flex: 0 0 100%;
      width: 100%;
    }

    &__info {
      margin-bottom: 0;

      &__about, &__aboutbrand, &__care {
        flex: 0 0 100%;
      }

      &__title {
        margin: 0;
        padding: 15px 0;
        border-top: 1px solid @gray-bd;
        text-align: center;
        position: relative;

        &:after {
          display: block;
          margin: auto;
          position: absolute;
          top: 0;
          right: 30px;
          bottom: 0;
        }
      }

      &__section {

        &__content {
          display: none;
          padding: 10px 40px 30px 40px;
        }

        &.active &__content {
          display: block;
        }
      }
    }

    &__shipping, &__bonuses__info {
      font-size: 12px;
      line-height: 18px;
    }

    &__gallery {
      display: flex;
      margin-right: -15px;
      margin-left: -15px;
      position: relative;

      .owl-stage-outer {
        padding: 0 25%;
      }

      &__one {
        flex: 0 0 50%;
        padding: 0 5px;

        .owl-item > & {
          flex: 0 0 100%;
        }

        img {
          display: block;
          width: 100%;
          height: auto;
        }
      }

      .owl {

        &-item {
          flex: 0 0 100%;
          width: 100%;
        }

        &-nav {
          margin-top: -24px;
        }

        &-prev, &-next {
          width: 65px;
          height: 65px;
          background: rgba(255, 255, 255, 0.50);
          -webkit-border-radius: 50%;
          -moz-border-radius: 50%;
          border-radius: 50%;
        }

        &-prev {
          left: -20px;
        }

        &-next {
          right: -20px;
        }
      }
    }

    &__videolink {
      margin-right: -15px;
      margin-left: -15px;
      padding: 3px 0;
      background: @gray-bg;
      font-size: 14px;
      line-height: 18px;
      font-style: italic;
      text-align: center;
      font-family: "Times New Roman", serif;

      a {
        color: @gray;
        text-decoration: none;

        &:hover {
          color: @gray;
          text-decoration: underline;
        }
      }
    }

    &__colors, &__sizes {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 10px 0;
    }

    &__sizes {
      border-top: 1px solid @gray-bd;

      input[type=radio] {

        + label {
          margin: 0 4px !important;
          padding: 6px 5px 4px 5px;
          font-size: 16px;
          line-height: 20px;
        }
      }

      &__guide {
        font-size: 12px;
        line-height: 18px;
        top: 22px;
      }
    }

    &__subtitle {
      margin: 0 20px 0 0;
      color: inherit !important;
      font-size: 11px;
    }
  }

  .lookalso {

    .whatandhow + & {

    }
  }

  .history {

    &__title {
      font-size: 35px;
      line-height: 40px;
    }

    &__carousel {

      .owl-item {
        flex: 0 0 20%;
      }

      .owl-nav {
        display: none;
      }

      &__one {

        &__actions {
          display: block;
        }
      }
    }
  }
}

@media screen and (max-width: 767px) {
  .itemcard {

    &__right {
      margin-top: 15px;
      padding-top: 25px;
    }

    .catalogue__item {

      &__brand {
        padding: 0;
        font-size: 19px;
        line-height: 29px;
      }
    }

    &__gallery {

      &__one {
        padding: 0;
      }

      .owl-stage-outer {
        padding: 0;
      }
    }

    &__sizes {
      margin-right: -15px;
      margin-left: -15px;

      .itemcard__subtitle {
        padding-bottom: 10px;
      }

      &__guide {
        right: auto;
        top: 27px;
        left: 15px;
      }
    }

    &__buttons {
      margin-right: -15px;
      margin-left: -15px;

      &__one {

        &:first-child {

          .button {
            font-size: 14px;
          }
        }

        & + & {
          flex: 1 1 auto;
        }

        & + & + & {
          flex: 0 0 60px;

          .iconed:after {
            margin: 0;
          }
        }
      }
    }

    &__promo {
      margin-top: 15px;
    }

    &__info {
      flex: 1 1 100%;
      margin-right: -15px;
      margin-left: -15px;

      &__title {

        &:after {
          right: 15px;
        }
      }

      &__section {

        &__content {
          padding-right: 15px;
          padding-left: 15px;
        }
      }
    }
  }

  .lookalso {

    &__controls {
      flex-direction: column;
      padding: 50px 0 30px 0;

      &__left {
        flex: 0 0 100%;
        margin: 0;
        padding: 0;
        text-align: center;
      }

      &__right {
        margin-top: 20px;
      }
    }
  }

  .history {
    padding-bottom: 35px;

    &__title {
      margin-bottom: 30px;
    }

    .owl-item {
      flex: 0 0 50%;
    }
  }
}
