/*
 --- CSS RESET AND PRESTYLES --------------------
*/

*{
    outline: none; /*убираем рамку вокруг ссылок и кнопок*/
    -webkit-text-size-adjust: none; /*фиксим баг в IOS cо шкалированием в landscape*/
    -webkit-touch-callout: none; /*если надо, то запрещаем выделение текста*/
    -webkit-tap-highlight-color:rgba(0,0,0,0); /*убираем фон подсветки ссылок и кнопок*/
    -webkit-appearance: none; /*убираем стили форм iOS*/
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, font, img, ins, kbd, q, s, samp, small, strike, strong, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, caption, header, nav, section, aside, article, time, footer {
    background: transparent;
    border: 0;
    padding: 0;
    margin: 0;
    outline: 0;
}

html, body {
    width: 100%;
    height: 100%;
}

form, header, nav, section, aside, article, time, footer {
    display: block;
}

a img {
    border: none;
}

.clear {
    display: block;
    font-size: 0;
    height: 0;
    clear: both;
    overflow: hidden;
}

ul, li {
    display: block;
    margin: 0;
    padding: 0;
    list-style: none;
}

ul li {
    padding-left: 14px;
}

ul li:before {
    display: inline-block;
    content: "\2013";
    width: 14px;
    margin-left: -14px;
    vertical-align: baseline;
}

ul.country-list li:before {
  display: none;
}

ol li {
    display: list-item;
    list-style: decimal inside;
}

ol li ul li {
    margin: 0 0 0 30px;
    list-style: none;
}

ol.unstyled, ul.unstyled {
    display: block;
    margin: 0;
    padding: 0;
}

ol.unstyled > li, ul.unstyled > li {
    display: block;
    margin: 0;
    padding: 0;
}

ol.unstyled > li:before, ul.unstyled > li:before {
    display: none;
}

.pos-r {
    position: relative;
}

.fl-l {
    float: left;
}

.fl-r {
    float: right;
}

.ta-l {
    text-align: left;
}

.ta-r {
    text-align: right;
}

.ta-c {
    text-align: center;
}

.tt-u {
    text-transform: uppercase;
}

.animate {
  transition: all 250ms cubic-bezier(0, 0, 0, 1);
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    /* display: none; <- Crashes Chrome on hover */
    -webkit-appearance: none;
    margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

@font-face {
  font-family: "Circe";
  src: url("../fonts/Circe-Regular.eot");
  src: url("../fonts/Circe-Regular.eot?#iefix") format("embedded-opentype"), url("../fonts/Circe-Regular.woff") format("woff"), url("../fonts/Circe-Regular.ttf") format("truetype"), url("../fonts/Circe-Regular.svg#Circe-Regular") format("svg");
  font-style: normal;
  font-weight: 400;
  text-rendering: optimizeLegibility;
}

@font-face {
  font-family: "Circe";
  src: url("../fonts/Circe-Bold.eot");
  src: url("../fonts/Circe-Bold.eot?#iefix") format("embedded-opentype"), url("../fonts/Circe-Bold.woff") format("woff"), url("../fonts/Circe-Bold.ttf") format("truetype"), url("../fonts/Circe-Bold.svg#Circe-Bold") format("svg");
  font-style: normal;
  font-weight: 700;
  text-rendering: optimizeLegibility;
}

@font-face {
  font-family: "Circe";
  src: url("../fonts/Circe-ExtraBold.svg");
  src: url("../fonts/Circe-ExtraBold.eot?#iefix") format("embedded-opentype"), url("../fonts/Circe-ExtraBold.woff") format("woff"), url("../fonts/Circe-ExtraBold.ttf") format("truetype"), url("../fonts/Circe-ExtraBold.svg#Circe-ExtraBold") format("svg");
  font-style: normal;
  font-weight: 900;
  text-rendering: optimizeLegibility;
}

@font-face {
  font-family: "Circe";
  src: url("../fonts/Circe-Light.eot");
  src: url("../fonts/Circe-Light.eot?#iefix") format("embedded-opentype"), url("../fonts/Circe-Light.woff") format("woff"), url("../fonts/Circe-Light.ttf") format("truetype"), url("../fonts/Circe-Light.svg#Circe-Light") format("svg");
  font-style: normal;
  font-weight: 300;
  text-rendering: optimizeLegibility;
}

@font-face {
  font-family: "Circe";
  src: url("../fonts/Circe-ExtraLight.eot");
  src: url("../fonts/Circe-ExtraLight.eot?#iefix") format("embedded-opentype"), url("../fonts/Circe-ExtraLight.woff") format("woff"), url("../fonts/Circe-ExtraLight.ttf") format("truetype"), url("../fonts/Circe-ExtraLight.svg#Circe-ExtraLight") format("svg");
  font-style: normal;
  font-weight: 200;
  text-rendering: optimizeLegibility;
}

@font-face {
  font-family: "CircusDidot";
  src: url("../fonts/CircusDidot.eot");
  src: url("../fonts/CircusDidot.eot?#iefix") format("embedded-opentype"), url("../fonts/CircusDidot.woff") format("woff"), url("../fonts/CircusDidot.ttf") format("truetype"), url("../fonts/CircusDidot.svg#CircusDidot") format("svg");
  font-style: normal;
  font-weight: normal;
  text-rendering: optimizeLegibility;
}
