/*
 --- GET A LOOK ---------------------------------
*/

.getalook {
  padding-top: 20px;

  > .wrap {
    flex-direction: column;
  }

  &__title {
    font-size: 35px;
    line-height: 40px;
    text-align: center;
  }

  &__subtitle {
    width: 100%;
    max-width: 1090px;
    margin: auto;
    padding: 0 15px;
    font-size: 30px;
    line-height: 33px;
    font-weight: 200;
    text-align: center;
    overflow: hidden;

    span {
      display: inline-block;
      position: relative;

      &:before, &:after {
        display: block;
        content: " ";
        width: 2000px;
        height: 1px;
        background: @black;
        position: absolute;
        top: 10px;
      }

      &:before {
        margin-right: -4px;
        right: 100%;
      }

      &:after {
        margin-left: -9px;
        top: 24px;
        left: 100%;
      }
    }
  }

  &__list {
    padding-top: 20px;

    .wrap {
      justify-content: space-between;
      width: 100%;
      max-width: 1120px;
      flex-wrap: wrap;
    }

    &__one {
      flex: 0 0 (100%/3);
      max-width: 336px;
      margin-bottom: 70px;

      &__name {
        min-height: 40px;
        margin-bottom: 15px;
        font-size: 18px;
        line-height: 20px;
        font-weight: 300;
        text-align: center;
      }

      img {
        display: block;
        width: 100%;
        height: auto;
      }

      &__info {
        padding-bottom: 45px;
        border: 1px solid @gray-bg;
        border-top: none;
      }

      &__items {
        display: flex;
        justify-content: space-around;
        padding: 15px 0 20px 0;

        &__one {
          flex: 0 0 (100%/3);
        }
      }

      &__date {
        width: 100%;
        max-width: 230px;
        margin: auto;
        padding: 10px 0;
        border-top: 1px solid @black;
        color: @gray-darkest;
        font-size: 12px;
        line-height: 14px;
        font-weight: 700;
        text-align: center;
      }

      &__lookhow {
        font-size: 12px;
        line-height: 23px;
        font-weight: 700;
        text-align: center;

        a {
          color: inherit;
          text-decoration: underline;

          &:hover {
            color: inherit;
            text-decoration: none;
          }
        }
      }
    }

    &__divider {
      margin-bottom: 45px;
      border-top: 1px solid @gray-light;
    }
  }

  &-item {
    padding-top: 20px;
    position: relative;

    .wrap {
      flex-direction: column;
    }

    &__title {
      font-size: 60px;
      line-height: 70px;
      text-align: center;
    }

    &__subtitle {
      margin-bottom: 35px;
      font-size: 35px;
      line-height: 40px;
      font-weight: 200;
      text-align: center;
    }

    &__divider {
      width: 100%;
      max-width: 230px;
      margin: 0 auto 35px auto;
      border-top: 1px solid @black;
    }

    &__description {
      color: @gray-darkest;
      font-size: 16px;
      line-height: 30px;
      text-align: center;
    }

    &__main {
      display: flex;
      justify-content: space-between;
      width: 100%;
      max-width: 1020px;
      margin: auto;
      padding-top: 80px;
      position: relative;

      &__name {
        margin-right: -92px;
        font-size: 110px;
        line-height: 110px;
        font-weight: 900;
        text-align: right;
        position: absolute;
        top: 35px;
        right: 50%;
      }

      &__photos {
        flex: 0 0 500px;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-top: 200px;

        &__one {
          margin-bottom: 45px;

          img {
            display: block;
          }
        }
      }

      &__bigphoto {
        flex: 0 0 504px;

        img {
          display: block;
          width: 100%;
          height: auto;
        }
      }

      &__items {
        display: flex;
        justify-content: space-between;
        margin-top: 45px;

        &__one {
          text-align: center;

          &__name {
            margin-top: 10px;
            font-size: 15px;
            line-height: 19px;
          }

          &__buy {
            margin-top: 10px;
            font-size: 12px;
            line-height: 16px;
            font-weight: 700;

            a {
              text-decoration: underline;
            }
          }
        }
      }
    }

    &__nav {
      display: block;
      width: 275px;
      min-height: 140px;
      margin-top: 100px;
      padding: 20px;
      background: #ffffff;
      color: inherit;
      position: absolute;
      top: 50%;
      -webkit-box-shadow: 0 0 8px rgba(0, 0, 0, 0.40);
      -moz-box-shadow: 0 0 8px rgba(0, 0, 0, 0.40);
      box-shadow: 0 0 8px rgba(0, 0, 0, 0.40);

      img {
        margin: auto;
        position: absolute;
        top: 0;
        bottom: 0;
      }

      &.prev {
        padding-right: 15px;
        padding-left: 135px;
        text-align: right;
        left: 0;

        img {
          left: 20px;
        }
      }

      &.next {
        padding-right: 135px;
        padding-left: 15px;
        right: 0;

        img {
          right: 20px;
        }
      }

      &__title {
        display: block;
        margin-bottom: 10px;
        font-size: 12px;
        line-height: 14px;
        font-weight: 700;
        position: relative;

        &:before, &:after {
          position: absolute;
          top: 50%;
          margin: -4px 0 0 0;
        }

        .prev & {
          margin-left: 25px;

          &:before {
            left: 0;
          }
        }

        .next & {
          margin-right: 25px;

          &:after {
            right: 0;
          }
        }
      }

      &__name {
        display: block;
        margin-bottom: 5px;
        font-size: 12px;
        line-height: 23px;
        font-weight: 700;
      }

      &__category {
        display: block;
        color: @gray-darkest;
        font-size: 13px;
        line-height: 16px;
      }
    }

    &.alternate {

      .wrap {
        max-width: 1010px;
      }
    }

    .alternate & {

      &__title {
        font-size: 85px;
        line-height: 95px;
        font-weight: 100;
      }

      &__main {

        &__photos {
          flex: 0 0 360px;
        }

        &__bigphoto {
          flex: 0 0 590px;
        }

        &__items {
          justify-content: space-around;
        }
      }
    }
  }
}


@media screen and (max-width: 1249px) {
  .getalook {

    &-item {
      padding-bottom: 230px;

      &__main {
        flex-wrap: wrap;

        &__name {
          margin-right: 0;
          font-size: 50px;
          line-height: 1.4;
          position: static;
          text-align: left;
          flex: 0 0 100%;

          br {
            display: none;
          }
        }

        &__bigphoto {
          flex: 0 0 50%;
          padding: 0 5px;
        }

        &__photos {
          padding: 0 5px;
          flex: 0 0 50%;

          &__one {
            margin-bottom: 15px;

            img {
              width: 100%;
              height: auto;
            }
          }
        }
      }

      &__nav {
        top: auto;
        bottom: 15px;
        margin-top: 0;
      }
    }

    &__list {

      &__one {
        padding: 0 5px;
      }
    }
  }
}


@media screen and (max-width: 999px) {
  .getalook {

    &-item {

      .wrap {
        margin-bottom: 50px;
      }

      &__title {
        font-size: 25px;
        line-height: 1.6;
      }

      &__subtitle {
        font-size: 20px;
        line-height: 1.3;
        margin-bottom: 15px;
      }

      &__description {
        line-height: 1.5;
      }

      &__main {
        padding-top: 30px;

        &__name {
          font-size: 28px;
        }

        &__bigphoto {
          padding: 0;
        }

        &__photos {
          padding: 0;
        }

        &__items {

          &__one {
            display: flex;
            flex-direction: column;
            justify-content: space-between;

            &__name {
              font-size: 12px;
            }
          }
        }
      }

      &.alternate & {

        &__divider {
          margin-bottom: 0;
        }

        &__title {
          font-size: 25px;
          line-height: 1.6;
        }

        &__main {

          &__bigphoto {
            flex: 0 0 50%;
            padding: 0 5px;
          }

          &__photos {
            flex: 0 0 50%;
            padding: 0 5px;

            &__one {
              width: 100%;
            }
          }
        }
      }
    }
  }
}


@media screen and (max-width: 767px) {
  .getalook {

    &-item {
      padding-bottom: 0;

      &__main {
        flex-direction: row;
        padding-top: 40px;

        &__bigphoto {
          flex: 0 0 100%;
        }

        &__photos {
          flex: 0 0 100%;
        }
      }

      &__nav {
        position: relative;
        bottom: 0;
        margin-top: 15px;
        width: 100%;

        &.next {
          margin-left: auto;
          margin-bottom: 15px;
        }

        &.prev {
          margin-bottom: 15px;
        }

        &__name {
          line-height: 1.5;
        }
      }

      &.alternate & {

        &__main {

          &__bigphoto {
            flex: 0 0 100%;
            padding: 0;
          }

          &__photos {
            flex: 0 0 100%;
            padding: 0;
          }
        }
      }
    }

    &__list {

      .wrap {
        justify-content: center;
      }

      &__one {
        flex: 0 0 100%;
        margin-bottom: 50px;

        &__info {
          padding-bottom: 15px;
        }
      }
    }
  }
}
