/*
 --- PAGE REGISTER ------------------------------
*/

.register {
  padding-bottom: 100px;
  border-bottom: 2px solid @gray-bg;

  .wrap {
    padding: 0 40px;
    justify-content: space-around;
  }

  &__one {
    flex: 0 0 420px;
    width: 420px;
  }

  &__title {
    margin-bottom: 15px;
    font-size: 35px;
    line-height: 40px;    font-weight: 200;

  }

  &__descr {
    margin-bottom: 25px;
    font-size: 12px;
    line-height: 23px;
    font-weight: 700;
    text-transform: uppercase;
  }

  &__form {

    &__restore {
      margin-bottom: 10px;
      font-size: 11px;
      line-height: 14px;
      text-align: right;

      a, .link {
        color: @gray;
        text-decoration: underline;

        &:hover {
          color: @color;
        }
      }
    }

    &__submit {
      margin-top: 10px;
    }
  }

  &__note {
    margin-top: 35px;
    color: @gray-darkest;
    font-size: 13px;
    line-height: 16px;

    &__title {
      margin-bottom: 15px;
      color: @black;
      font-size: 12px;
      line-height: 16px;
      font-weight: 700;
      text-transform: uppercase;
    }
  }

  &__socials {
    display: flex;
    justify-content: space-between;
    margin-top: 35px;
  }

  &__doreg {
    margin-top: 35px;
  }
}

@media screen and (max-width: 999px) {
  .register {
    padding-top: 30px;
    padding-bottom: 60px;

    .wrap {
      padding: 0 30px;
      justify-content: space-between;
    }

    &__one {
      flex: 0 0 335px;
      width: 335px;
    }

    &__title {
      font-size: 28px;
      line-height: 34px;
    }

    &__descr {
      font-size: 10px;
      line-height: 18px;
    }

    &__form {

    }

    &__note {
      margin-top: 25px;
      font-size: 10px;
      line-height: 13px;

      &__title {
        font-size: 10px;
        line-height: 13px;
      }
    }

    &__socials {
      margin-top: 25px;
    }

    &__doreg {
      margin-top: 25px;
    }
  }

  .notyou {
    background: @color-light;

    + .about {
      padding-bottom: 10px;
    }

    &__head {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    &__title {
      padding: 17px 35px;
      font-size: 30px;
      line-height: 34px;
      font-weight: 200;
    }

    &__signout {
      padding-right: 35px;
      font-size: 14px;
      line-height: 18px;
      font-style: italic;
      font-family: "Times New Roman", serif;

      a {
        color: @gray;
        text-decoration: underline;
      }
    }

    &__links {

      a {
        display: block;
        padding: 21px 35px;
        border-top: 1px solid @black;
        color: @black;
        font-size: 12px;
        line-height: 18px;
        font-weight: 700;
        letter-spacing: 0.15em;
        text-transform: uppercase;

        &:after {
          float: right;
          margin: 4px 0 0 0;
        }
      }
    }
  }
}

@media screen and (max-width: 767px) {
  .register {
    padding: 0;
    border: none;

    .wrap {
      flex-direction: column;
      padding: 0;
    }

    &__one {
      flex: 0 0 100%;
      width: 100%;
      padding: 30px 5px 0 5px;
    }

    &__signin {
      padding-bottom: 45px;
      border-bottom: 2px solid @gray-bg;
    }

    &__title {
      margin-bottom: 15px;
      font-size: 24px;
      line-height: 28px;
      text-align: center;
    }

    &__descr {
      margin-bottom: 15px;
      font-size: 11px;
      line-height: 18px;
      text-align: center;
    }

    &__form {

      .input, &__submit {
        margin-right: -5px;
        margin-left: -5px;
      }

      .input {

        input, textarea {
          margin-bottom: 2px;
        }
      }

      &__restore {
        padding-top: 8px;
        padding-right: 10px;
      }
    }

    &__note {
      margin-top: 15px;
      text-align: center;

      ul {
        text-align: left;
      }
    }

    &__socials {
      margin-top: 15px;
    }

    &__doreg {
      margin: 15px -5px 0 -5px;
    }
  }

  .notyou {

    + .about {
      padding-bottom: 50px;
    }

    &__head {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding: 15px 8px;
    }

    &__title {
      padding: 0;
      font-size: 20px;
      line-height: 25px;

      span {
        display: block;
      }
    }

    &__signout {
      padding: 5px 0;
    }

    &__links {

      a {
        padding: 20px 15px 18px 8px;
        font-size: 11px;
        line-height: 16px;

        &:after {
          margin: 2px 0 0 0;
        }
      }
    }
  }
}
