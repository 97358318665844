/*
 --- PAGE CATALOGUE -----------------------------
*/

.catalogue {

  .wrap {
    flex-direction: column;
  }

  &__top {
    display: flex;
    align-items: center;
    margin-bottom: 10px;

    &__title {
      font-size: 35px;
      line-height: 42px;
    }

    &__text {
      flex: 0 0 290px;
      width: 290px;
      margin-right: 25px;
      font-size: 14px;
      line-height: 25px;

      a {
        color: inherit;
        text-decoration: underline;

        &:hover {
          color: @color;
          text-decoration: underline;
        }
      }
    }

    &__banner {
      flex: 1 1 auto;
      overflow: hidden;

      img {
        display: block;
        width: auto;
        height: auto;
        min-width: 100%;
        min-height: 100%;
      }
    }
  }

  &__settings {
    display: flex;
    height: 23px;
    margin-bottom: 10px;

    &__view {
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex: 0 0 290px;
      width: 290px;
      margin-right: 25px;

      span {
        flex: 0 0 142px;
        width: 142px;
        border: 1px solid @color;
        font-size: 13px;
        line-height: 21px;
        text-align: center;
        letter-spacing: 0.05em;
        cursor: pointer;
        background: none;
        color: @color;

        &.active {
          background: @color;
          color: #ffffff;
        }
      }

      &.alternate {
        flex: 0 0 auto;
        width: auto;
        color: @gray;
        font-size: 14px;
        line-height: 23px;
        font-style: italic;
        font-family: "Times New Roman", serif;

        span {
          display: flex;
          flex: 0 0 auto;
          width: auto;
          margin-left: 8px;
          padding: 4px 4px 4px 0;
          background: none;
          border-color: #e1eeee;

          &:before, &:after, i {
            display: block;
            content: " ";
            width: 13px;
            height: 13px;
            margin-left: 4px;
            background: #e1eeee;
          }

          &.active {
            border-color: @color;

            &:before, &:after, i {
              background: @color;
            }
          }
        }
      }
    }

    &__other {
      display: flex;
      justify-content: space-between;
      flex: 1 1 auto;

      &__right {
        display: flex;
        align-items: center;
      }

      &__sort {
        display: block;

        select {
          display: block;
          margin: 0;
          padding: 0 15px 0 10px;
          background: #f8f8f8 url("../i/select2.png") no-repeat 100% 50%;
          color: @gray;
          font-size: 14px;
          line-height: 18px;
          font-style: italic;
          font-family: "Times New Roman", serif;
          -webkit-border-radius: 3px;
          -moz-border-radius: 3px;
          border-radius: 3px;
        }
      }

      &__result {
        margin-left: 20px;
        color: @gray;
        font-size: 14px;
        line-height: 23px;
        font-style: italic;
        font-family: "Times New Roman", serif;
      }

      .pagination {
        margin-left: 15px;
      }
    }
  }

  &__main {
    padding-top: 10px;

    &__title {
      margin: 0 0 10px 315px;
      font-size: 25px;
      line-height: 30px;
      text-align: center;

      span {
        padding: 0 10px;
        position: relative;

        &:before, &:after {
          display: block;
          content: " ";
          width: 2000px;
          height: 1px;
          background: @gray-light;
          position: absolute;
          top: 33%;
          left: 100%;
        }

        &:after {
          right: 100%;
          left: auto;
        }
      }
    }

    &__wrap {
      display: flex;
    }
  }

  &__filter {
    flex: 0 0 290px;
    width: 290px;
    margin-right: 10px;
    background: #ffffff;

    &__title {
      margin-bottom: 30px;
      font-size: 35px;
      line-height: 40px;
    }

    &__section {
      margin-bottom: 10px;
      background: @color-lighter;

      &:last-child {
        margin-bottom: 0;
      }

      &__name {
        padding: 13px 40px 11px 25px;
        font-size: 12px;
        line-height: 18px;
        font-weight: 700;
        text-transform: uppercase;
        letter-spacing: 0.08em;
        position: relative;
        cursor: pointer;

        &:after {
          display: block;
          content: " ";
          width: 14px;
          height: 14px;
          margin-top: -7px;
          background: url(@sprite) no-repeat -920px -80px;
          position: absolute;
          top: 50%;
          right: 15px;
        }

        .active > &:after {
          background-position: -940px -80px;
        }
      }

      &__list {
        display: none;
        padding-bottom: 25px;

        .active > & {
          display: block;
        }

        ul li {
          margin-top: 3px;
          padding: 4px 25px 2px 25px;
          font-size: 13px;
          line-height: 16px;

          a {
            color: @text;
          }

          li {
            padding-left: 14px;
          }

          &.active {
            background: @color-light;
            color: @color;
            font-weight: 700;

            li {
              font-weight: 400;
            }

            a {
              color: @color;
            }
          }
        }

        &.checks {
          padding-right: 25px;
          padding-left: 5px;

          ul {

            li {
              margin: 0 0 7px 20px;
              padding: 0;
            }
          }


          &.cols {
            padding-right: 5px;
            padding-left: 5px;

            ul {
              display: flex;
              flex-wrap: wrap;

              li {
                flex: 0 0 auto;
                width: 110px;
              }
            }
          }
        }

        &.inline {
          padding-right: 25px;
          padding-left: 20px;

          ul {
            display: flex;
            flex-wrap: wrap;

            li {
              margin: 0 0 0 5px;
              padding: 0;
              font-size: 15px;
              line-height: 22px;
              letter-spacing: 0.08em;

              a {
                padding-right: 5px;
                padding-left: 5px;
              }
            }
          }
        }

        .nums {
          display: flex;
          justify-content: space-between;
          width: 160px;

          &__one {
            width: 77px;

            input {
              border: 1px solid @black;
              background: none;

            }
          }
        }

        &__label {
          margin: 0 0 10px 5px;
          font-size: 13px;
          line-height: 16px;
        }

        &__links {
          padding-top: 25px;

          .checks > & {
            margin-left: 20px;
            padding-top: 0;
          }

          ul li {
            margin: 0 !important;
            padding: 6px 0 !important;
            font-size: 13px;
            line-height: 16px;
          }

          a {
            color: @text;
            font-size: 13px;
            line-height: 16px;
            text-decoration: underline;

            &:hover {
              color: @color;
              text-decoration: underline;
            }
          }
        }
      }
    }
  }

  &__list {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-content: flex-start;
    flex: 1 1 auto;

    &__wide {
      display: flex;
      justify-content: space-between;
      align-content: flex-start;
      flex: 1 0 100%;
      margin-left: -20px;
      -webkit-box-sizing: content-box;
      -moz-box-sizing: content-box;
      box-sizing: content-box;
    }

    &__more {
      flex: 0 0 100%;
      padding: 45px 0 0 15px;
    }

    &__nav {
      display: flex;
      justify-content: space-between;
      flex: 0 0 100%;
      padding: 25px 0 0 15px;

      &__totop {
        color: @gray;
        font-size: 14px;
        line-height: 18px;
        font-style: italic;
        font-family: "Times New Roman", serif;

        a {
          color: @gray;
          text-decoration: underline;

        }
      }

      &__pag {
        display: flex;
        align-items: center;

        .pagination {
          margin-left: 15px;
        }
      }
    }
  }

  &__item {
    flex: 1 1 25%;
    padding: 0 0 45px 15px;

    .catalogue__list.wide & {
      flex: 1 1 33.33%;
      padding: 0 0 45px 35px;
    }

    &__image {
      margin-bottom: 10px;
      position: relative;

      .img {
        display: block;
        width: 100%;
        height: auto;
      }

      .link {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 1;
      }
    }

    &__panel {
      display: none;
      justify-content: space-between;
      padding: 5px 10px;
      background: rgba(255, 255, 255, 0.59);
      font-size: 11px;
      line-height: 14px;
      position: absolute;
      top: 0;
      right: 0;
      left: 0;
      z-index: 1;

      .catalogue__list.wide & {
        padding: 10px 30px;
      }

      a {
        color: @text;
        text-decoration: none;

        &:before {
          display: block;
        }

        &:hover {
          color: @color;
          text-decoration: none;
        }
      }
    }

    &__image:hover &__panel {
      display: flex;
    }

    &__info {
      padding: 2px 10px;
      background: @color-light;
      font-size: 9px;
      line-height: 13px;
      position: absolute;
      top: 0;
      left: 0;

      &.gone {
        background: @red;
        color: #ffffff;
      }

      &.almost {
        background: @red-light;
        color: #ffffff;
      }
    }

    &__discount {
      padding: 6px 10px 4px 10px;
      background: @color;
      color: #ffffff;
      font-size: 10px;
      line-height: 13px;
      text-align: center;
      position: absolute;
      bottom: 0;
      left: 0;

      &.wide {
        right: 0;

        .catalogue__list.wide & {
          right: auto;
        }
      }
    }

    &__featured {
      font-size: 13px;
      line-height: 15px;
      position: absolute;
      bottom: 35px;
      left: 10px;

      span {
        display: block;
        width: 15px;
        white-space: nowrap;
        -webkit-transform: rotate(-90deg);
        -moz-transform: rotate(-90deg);
        -ms-transform: rotate(-90deg);
        -o-transform: rotate(-90deg);
        transform: rotate(-90deg);
      }

      &:after {
        margin-top: 5px;
      }
    }

    &__brand {
      margin-bottom: 5px;
      font-size: 12px;
      line-height: 16px;
      font-weight: 700;
      text-transform: uppercase;
    }

    &__name {
      margin-bottom: 5px;
      font-size: 13px;
      line-height: 16px;
    }

    &__price {
      margin-bottom: 5px;
      font-size: 13px;
      line-height: 18px;
      font-weight: 700;

      .old {
        margin-right: 10px;
        color: @gray;
        text-decoration: line-through;
      }
    }

    &__colors {
      display: flex;
      flex-wrap: wrap;
      margin: 0 0 10px -6px;

      span {
        display: block;
        width: 14px;
        height: 14px;
        margin-left: 6px;
        background-position: 50% 50%;
        position: relative;
        -webkit-border-radius: 50%;
        -moz-border-radius: 50%;
        border-radius: 50%;

        &.active {
          margin-left: 9px;
          margin-right: 3px;

          &:before {
            display: block;
            content: " ";
            border: 1px solid;
            position: absolute;
            top: -3px;
            right: -3px;
            bottom: -3px;
            left: -3px;
            -webkit-border-radius: 50%;
            -moz-border-radius: 50%;
            border-radius: 50%;
          }
        }

        &.gone {
          color: @red-dark;
        }
      }

      &.big > span {
        width: 20px;
        height: 20px;
      }
    }

    &__sizes {
      color: @gray-darkest;
      font-size: 11px;
      line-height: 14px;
      word-spacing: 5px;

      span {
        position: relative;

        &.active {
          margin-left: 4px;
          margin-right: 3px;

          &:before {
            display: block;
            content: " ";
            border: 1px solid;
            position: absolute;
            top: -4px;
            right: -3px;
            bottom: -3px;
            left: -4px;
          }
        }

        &.gone {
          color: @red-dark;
        }
      }
    }
  }
}

@media screen and (max-width: 999px) {
  .catalogue {
    position: relative;

    &__settings {
      height: auto;
      margin-right: -5px;
      margin-bottom: -10px;
      margin-left: -5px;

      &__other {
        display: block;

        &__right {
          justify-content: space-between;
          padding-top: 10px;
        }
      }

      &__filter {
        padding-top: 2px;
        color: @gray;
        font-size: 11px;
        line-height: 18px;
        text-decoration: underline;
      }
    }

    &__main {

      &__title {
        margin-left: 0;
      }
    }

    &__filter {
      margin: 0;
      padding: 10px;
      position: absolute;
      top: 35px;
      right: -320px;
      z-index: 9;
      -webkit-transition: right 1s;
      -moz-transition: right 1s;
      -ms-transition: right 1s;
      -o-transition: right 1s;
      transition: right 1s;
      -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.10);
      -moz-box-shadow: 0 0 10px rgba(0, 0, 0, 0.10);
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.10);

      &.active {
        right: 0;
      }
    }

    &__list {
      padding-right: 20px;
      padding-left: 5px;

      &__total {
        flex: 0 0 100%;
        margin: -5px 0 10px 0;
        color: @gray;
        font-size: 12px;
        line-height: 18px;
        text-align: center;
        font-style: italic;
        font-family: "Times New Roman", serif;
      }
    }

    &__item {
      flex: 0 0 33.33%;
    }
  }
}

@media screen and (max-width: 767px) {
  .catalogue {

    &__settings {
      margin-bottom: 0;

      &__other {

        &__result {
          margin: -125px 0 0 0;
          padding: 0;
          text-align: center;
          position: absolute;
          right: 0;
          left: 0;
        }
      }
    }

    &__main {

      &__title {
        font-size: 19px;
        line-height: 19px;
      }
    }

    &__list {
      margin-right: -5px;
      margin-left: -15px;
      padding-right: 0;
      padding-left: 0;

      &__wide {
        margin-left: 0;
      }

      &__more {
        flex: 0 0 100%;
        padding-left: 0;

        .button {
          padding-right: 5px;
          padding-left: 5px;
          -webkit-box-sizing: content-box;
          -moz-box-sizing: content-box;
          box-sizing: content-box;
        }
      }
    }

    &__item {
      flex: 0 0 50%;
      padding: 0 0 35px 10px;

      .catalogue__list.wide & {
        flex: 0 0 50%;
        padding: 0 0 35px 10px;
      }

      &__panel {
        padding: 5px 10px;
        font-size: 7px;
        line-height: 10px;
      }

      &__featured {
        font-size: 9px;
        line-height: 9px;
        left: 5px;
      }

      &__discount {
        font-size: 7px;
        line-height: 9px;
      }
    }
  }
}
