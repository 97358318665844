/*
 --- FORMS --------------------------------------
*/
.button {
  display: block;
  width: 100%;
  max-width: 206px;
  margin: auto;
  padding: 12px 20px;
  border: none;
  background: @color;
  color: #ffffff;
  font-size: 12px;
  line-height: 18px;
  font-weight: 700;
  text-align: center;
  letter-spacing: 0.08em;
  text-transform: uppercase;
  font-family: Circe, sans-serif;
  cursor: pointer;

  &.alternate {
    display: inline-block;
    width: auto;
    padding: 6px 25px;
    background: @black;
    font-weight: 300;
    letter-spacing: 0.12em;
  }

  &_big {
    max-width: none;
    padding: 15px 20px 11px 20px;
    font-size: 20px;
    line-height: 27px;
    letter-spacing: 0.12em;
  }

  &_large {
    display: inline-block;
    width: auto;
    max-width: none;
    padding: 15px 25px 11px 25px;
    font-size: 13px;
    line-height: 25px;
    letter-spacing: 0.12em;

    &_wide {
      display: block;
      width: 100%;
    }
  }

  &_pink {
    background: @pink-dark;
  }

  &_form {
    max-width: 100%;
    padding: 17px 20px 15px 20px;
    font-size: 13px;
    line-height: 18px;
    letter-spacing: 0.12em;

    &_light {
      background: @color-light;
      color: @black;
    }

    &_white {
      background: #ffffff;
      color: @black;
    }
  }

  &_blank {
    border: 1px solid @gray-bg-dark;
    background: none;
    color: @gray-darkest;
  }
}

.input {
  position: relative;
}

input[type=text], input[type=email], input[type=tel], input[type=password], textarea {
  display: block;
  width: 100%;
  margin: 0;
  padding: 5px 10px;
  border: 1px solid @black;
  background: none;
  font-size: 12px;
  line-height: 14px;
  font-weight: 700;
  font-family: Circe, sans-serif;

  .input & {
    margin-bottom: 15px;
    padding: 16px 20px 12px 20px;
    border: none;
    background: @color-light;
    font-size: 12px;
    line-height: 23px;
    font-weight: 700;
    text-align: left;
    font-style: normal;
    font-family: Circe, sans-serif;

    &.error {
      padding: 12px 18px;
      border: 2px solid @red;

      + label.error {
        margin-bottom: 15px;
        padding: 10px;
        border: 2px solid @red;
        background: @pink;
        font-size: 13px;
        line-height: 16px;
        position: absolute;
        bottom: 100%;
        left: 0;

        &:before, &:after {
          display: block;
          content: " ";
          width: 0;
          height: 0;
          border: 9px solid transparent;
          border-top-color: @red;
          position: absolute;
          bottom: -18px;
          left: 15px;
        }

        &:after {
          border-top-color: @pink;
          bottom: -16px;
        }
      }
    }
  }

  .input.alternate & {
    color: @color;

    &::-webkit-input-placeholder { color: @color; }
    &::-moz-placeholder          { color: @color; }
    &:-moz-placeholder           { color: @color; }
    &:-ms-input-placeholder      { color: @color; }
  }

  .input.iconed & {
    padding-right: 60px;
  }

  &.styled {
    display: block;
    width: 100%;
    margin: 0;
    padding: 5px 10px;
    border: none;
    border-bottom: 1px solid #000000;
    background: #ffffff;
    color: @color;
    font-size: 13px;
    line-height: 18px;
    font-style: italic;
    text-align: center;
    font-family: "Times New Roman", serif;

    &::-webkit-input-placeholder { color: @color; }
    &::-moz-placeholder          { color: @color; }
    &:-moz-placeholder           { color: @color; }
    &:-ms-input-placeholder      { color: @color; }
  }

  &.cartinput {
    border: none;
    padding: 15px 20px 11px 20px;
    background: #ffffff;
    color: @text;
    font-size: 13px;
    line-height: 25px;
    letter-spacing: 0.12em;
  }

  &.acc {
    width: 100%;
    font-size: 30px;
    line-height: 38px;
    font-weight: 200;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
  }
}

textarea {
  text-align: left;
  resize: vertical;
}

select {

  .input & {
    width: 100%;
    margin-bottom: 15px;
    padding: 16px 20px 12px 20px;
    border: none;
    background: @color-light;
    font-size: 12px;
    line-height: 23px;
    font-weight: 700;
    text-align: left;
    font-style: normal;
    font-family: Circe, sans-serif;

    &:after {
      display: block;
      content: " ";
      width: 20px;
      height: 20px;
      position: absolute;
      background: url(@sprite) no-repeat 0 0;
    }

    &.error {
      padding: 12px 18px;
      border: 2px solid @red;

      + label.error {
        margin-bottom: 15px;
        padding: 10px;
        border: 2px solid @red;
        background: @pink;
        font-size: 13px;
        line-height: 16px;
        position: absolute;
        bottom: 100%;
        left: 0;

        &:before, &:after {
          display: block;
          content: " ";
          width: 0;
          height: 0;
          border: 9px solid transparent;
          border-top-color: @red;
          position: absolute;
          bottom: -18px;
          left: 15px;
        }

        &:after {
          border-top-color: @pink;
          bottom: -16px;
        }
      }
    }
  }

  .input.alternate & {
    color: @color;

    &::-webkit-input-placeholder { color: @color; }
    &::-moz-placeholder          { color: @color; }
    &:-moz-placeholder           { color: @color; }
    &:-ms-input-placeholder      { color: @color; }
  }
}

input[type=radio] {
  position: absolute;
  left: -999%;

  + label {
    padding-left: 24px;
    position: relative;
    cursor: pointer;

    &:before, &:after {
      display: block;
      content: " ";
      margin-top: 1px;
      position: absolute;
      -webkit-border-radius: 50%;
      -moz-border-radius: 50%;
      border-radius: 50%;
    }

    &:before {
      width: 12px;
      height: 12px;
      border: 1px solid #000000;
      left: 0;
      top: 0;
    }

    &:after {
      width: 8px;
      height: 8px;
      top: 3px;
      left: 3px;
    }
  }

  &:checked + label {

    &:after {
      background: @color;
    }
  }

  &.big {

    + label {
      padding-left: 50px;

      &:before {
        width: 25px;
        height: 25px;
        border-width: 2px;
      }

      &:after {
        width: 9px;
        height: 9px;
        top: 10px;
        left: 10px;
      }
    }

    &:checked + label {

      &:after {
        background: #000000;
      }
    }

    &_acc {

      + label {
        display: flex;
        align-items: center;
        padding-left: 0;
        color: @gray-dark;

        &:before {
          flex: 0 0 24px;
          width: 24px;
          height: 24px;
          margin-right: 10px;
          border-color: @gray-dark;
          position: static;
          top: auto;
          right: auto;
          bottom: auto;
          left: auto;
        }

        &:after {
          width: 10px;
          height: 10px;
          margin-top: -4px;
          top: 50%;
          left: 9px;
        }

        span {
          flex: 1 1 auto;
          margin-right: 10px;
        }
      }

      &:checked + label {

        &:after {
          background: @gray-dark;
        }
      }
    }
  }

  &.color {

    + label {
      display: block;
      float: left;
      margin: 5px 5px 5px 5px;
      padding: 0;
      width: 25px;
      height: 25px;
      line-height: 25px;
      position: relative;
      -webkit-border-radius: 50%;
      -moz-border-radius: 50%;
      border-radius: 50%;

      &:before, &:after {
        display: none;
        content: " ";
        margin: 0;
        padding: 0;
        background: none;
        position: absolute;
        -webkit-border-radius: 50%;
        -moz-border-radius: 50%;
        border-radius: 50%;
      }
    }

    &.border + label:before {
      display: block;
      width: 25px;
      height: 25px;
      border: 1px solid @gray-bg;
      top: 0;
      left: 0;
    }

    &:checked + label:after {
      display: block;
      width: 29px;
      height: 29px;
      border: 1px solid #000000;
      top: -3px;
      left: -3px;
    }
  }
}

input[type=checkbox] {
  position: absolute;
  left: -999%;

  + label {
    display: inline-block;
    padding-left: 32px;
    font-size: 13px;
    line-height: 19px;
    position: relative;

    &:before {
      display: block;
      content: " ";
      width: 17px;
      height: 17px;
      border: 1px solid @black;
      position: absolute;
      top: 0;
      left: 0;
    }

    &:after {
      display: none;
      content: " ";
      width: 16px;
      height: 13px;
      background: url(@sprite) no-repeat -760px -120px;
      position: absolute;
      top: 2px;
      left: 1px;
    }
  }

  &:checked {

    + label {

      &:before {
        background: @black;
      }

      &:after {
        display: block;
      }
    }
  }

  &.styled {

    + label {
      display: inline-block;
      padding: 6px 0 4px 37px;
      color: @color;
      font-size: 14px;
      line-height: 18px;
      font-style: italic;
      font-family: "Times New Roman", serif;
      position: relative;
      cursor: pointer;

      &:before, &:after {
        display: block;
        content: " ";
        background: url(@sprite) no-repeat -890px -80px;
        position: absolute;
      }

      &:before {
        width: 28px;
        height: 27px;
        border: none;
        left: 0;
        top: 0;
      }

      &:after {
        display: none;
        width: 16px;
        height: 11px;
        background-position: -740px -80px;
        top: 8px;
        left: 6px;
      }
    }

    &:checked + label:after {
      display: block;
      .opacity(1);
    }
  }
}

@media screen and (min-width: 1000px) {

  input[type=radio] {

    + label {

      &:hover:after {
        //background: @color-light;
      }
    }

    &:checked + label {

      &:after {
        //background: @color;
      }
    }
  }

  input[type=checkbox] {

    &.styled {

      + label {

        &:hover:after {
          display: block;
          .opacity(0.50);
        }
      }

      &:checked + label:after {
        display: block;
        .opacity(1);
      }
    }
  }
}

@media screen and (min-width: 768px) and (max-width: 999px) {
  .button {

    &_form {
      max-width: 100%;
      padding: 14px 20px 12px 20px;
      font-size: 10px;
      line-height: 14px;
      letter-spacing: 0.12em;
    }
  }

  input[type=text], input[type=email], input[type=tel], input[type=password], textarea {

    .input & {
      margin-bottom: 12px;
      padding: 11px 16px;
      font-size: 10px;
      line-height: 18px;
    }
  }

  input[type=checkbox] {

    &.styled {

      + label {
        padding: 5px 0 3px 30px;
        font-size: 11px;
        line-height: 14px;

        &:before {
          width: 22px;
          height: 22px;
          background-position: -860px -80px;
        }

        &:after {
          width: 13px;
          height: 9px;
          background-position: -760px -80px;
          top: 7px;
          left: 4px;
        }
      }
    }
  }
}

@media screen and (max-width: 767px) {

  input[type=checkbox] {

    &.styled {

      + label {
        font-size: 12px;
      }
    }
  }
}
