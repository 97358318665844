@color: #63b5b2;
@color-light: #e0eded;
@color-lighter: #f6fafa;
@color-dark: #79b6b6;
@color-darker: #b1dfdb;

@red: #e23c55;
@red-light: #fc7488;
@red-dark: #ee7086;

@pink: #f4e8ea;
@pink-dark: #e9c4cb;

@text: #050708;
@gray: #9e9f9f;
@gray-bd: #c6c7c7;
@gray-light: #d9dada;
@gray-lighter: #a5a5a5;
@gray-dark: #7d7d7d;
@gray-darker: #888888;
@gray-darkest: #636363;
@gray-darkfest: #515555;
@gray-bg: #f3f3f3;
@gray-bg-dark: #cfcece;
@black: #232323;
@black-light: #333333;

@sprite: "../i/sprite.png";

/* mixins */

.opacity(@opacity) {
  -webkit-opacity: @opacity;
  opacity: @opacity;
  // IE8 filter
  @opacity-ie: (@opacity * 100);
  filter: ~"alpha(opacity=@{opacity-ie})";
}

@sm: 767px;
