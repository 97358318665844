/*
 --- PAGE TRENDS --------------------------------
*/

.trends {
  background: none;

  &__title {
    font-size: 60px;
    line-height: 70px;
    text-align: center;
  }

  &-dresscode {
    max-width: 990px;
    margin: auto;
    padding-top: 20px;
    text-align: center;

    &__title {
      margin-top: 45px;
      font-size: 60px;
      line-height: 70px;
    }

    &__subtitle {
      margin-bottom: 40px;
      font-size: 18px;
      line-height: 20px;
      text-transform: uppercase;
    }

    &__image {

      img {
        display: block;
        width: 100%;
        height: auto;
      }
    }
  }
}
