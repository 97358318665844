/*
 --- PAGE CART ----------------------------------
*/

.cart {
  .account__card_form {
    padding: 50px 0px;

    .plain {
      padding: 0px !important;
    }
  }

  .account__card__field {
    padding: 0px;

    .bx-ui-sls-fake {
      margin: 0px !important;
    }

    .bx-ui-sls-pane {
      position: absolute;
      width: 100%;
      background-color: #63b5b2;
    }

    .dropdown-item {
      padding: 16px 20px 12px 20px;
    }

    .bx-ui-sls-error {
      padding: 16px 20px 12px 20px;
    }

    .dropdown-item-text, .bx-ui-sls-error {
      font-size: 12px;
      line-height: 23px;
      font-weight: 700;
      text-align: left;
      font-style: normal;
      font-family: Circe, sans-serif;
      color: white;
    }
  }

  .account__card__field:nth-child(odd) {
    padding-right: 7px;
  }

  .account__card__field:nth-child(even) {
    padding-left: 7px;
  }

  form > .wrap {
    flex-direction: column;
  }

  .bx-soa-section > .wrap {
    flex-direction: column;
  }

  .bx-soa-section > .wrap.delivery_content_block {
    flex-direction: row;
  }

  .bx-soa-section > .wrap.payment_content_block {
    flex-direction: row;
  }

  &__items {
    width: 100%;
  }

  &__item {
    display: flex;
    width: 100%;
    padding: 45px 0 20px;
    border-top: 3px solid @gray-bg;

    &:first-child {
      border-top: none;
    }

    &__image {
      display: flex;
      flex-direction: column;
      flex: 0 0 122px;
      margin-right: 45px;

      img {
        display: block;
        margin: 0;
        padding: 0;
      }

      &__info {
        padding: 3px 10px 1px 10px;
        background: @color;
        color: #ffffff;
        font-size: 9px;
        line-height: 14px;
        text-align: center;

        &.red {
          background: @red;
        }

        &.pink {
          background: @red-light;
        }
      }
    }

    &__info {
      flex: 1 1 auto;

      &__wrap {
        display: flex;
        width: 100%;
      }
    }

    &__params {
      display: flex;
      justify-content: space-between;
      flex: 1 1 auto;

      &__name {
        display: flex;
        flex-direction: column;
        flex: 1 0 220px;
        margin-right: 20px;
      }

      &__color {
        display: flex;
        flex-direction: column;
        flex: 0 1 100px;
        margin-right: 20px;
      }

      &__size {
        display: flex;
        flex-direction: column;
        flex: 0 1 100px;
      }

      &__quantity {
        display: flex;
        flex-direction: column;
        flex: 0 1 230px;
      }
    }

    &__price {
      display: flex;
      flex-direction: column;
      flex: 0 0 120px;
      margin-left: 25px;

      &__old {
        min-height: 16px;
        color: @gray-dark;
        font-size: 13px;
        line-height: 16px;
        font-weight: 700;
        text-decoration: line-through;
      }

      &__value {
        font-size: 18px;
        line-height: 22px;
        font-weight: 700;
        letter-spacing: 0.08em;
      }

      &__bonuses {
        min-height: 17px;

        span {
          display: inline-block;
          padding: 1px 10px 0 10px;
          border: 1px solid @gray-light;
          color: @color;
          font-size: 11px;
          line-height: 14px;
        }
      }

      &__value + &__bonuses {
        margin-top: 5px;
      }
    }

    &__title {
      flex: 0 0 auto;
      margin-bottom: 10px;
      font-size: 12px;
      line-height: 14px;
      font-weight: 700;
      text-transform: uppercase;
    }

    &__descr {
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      flex: 1 1 auto
    }

    &__name {
      margin-bottom: 5px;
      font-size: 13px;
      line-height: 14px;
    }

    &__size {
      display: flex;

      &__minus, &__plus {
        width: 25px;
        height: 25px;
        border: 1px solid #000000;
        position: relative;
        cursor: pointer;

        &:before, &:after {
          display: block;
          content: " ";
          width: 7px;
          height: 1px;
          background: #000000;
          position: absolute;
          top: 11px;
          left: 8px;
        }

        &:after {
          width: 1px;
          height: 7px;
          top: 8px;
          left: 11px;
        }

        &.disabled {
          border-color: @gray-bg;
          background: @gray-bg;
          cursor: default;
        }
      }

      &__minus:after {
        display: none;
      }

      &__value {
        float: left;
        width: 35px;
        font-size: 12px;
        line-height: 25px;
        text-align: center;
        letter-spacing: 0.08em;

        span {
          display: none;

          &.active {
            display: block;
          }
        }
      }
    }

    &__promo {
      color: @color;
      font-size: 14px;
      line-height: 18px;
      font-style: italic;
      font-family: "Times New Roman", serif;

      a {
        color: @color;
        text-decoration: underline;

        &:hover {
          color: @color;
          text-decoration: none;
        }
      }
    }

    &__discount {
      display: inline-block;
      padding: 4px 10px;
      background: @color;
      color: #ffffff;
      font-size: 10px;
      line-height: 14px;
    }

    &__tofav {
      margin-top: 15px;
      color: @gray;
      font-size: 11px;
      line-height: 14px;

      .iconed {

        &:before {
          display: inline-block;
          margin: 0 10px 0 0;
          vertical-align: middle;
        }

        span {
          display: inline-block;
          vertical-align: middle;
        }
      }
    }

    &__delete {
      float: right;
      width: 210px;
      //padding-right: 112px;
      font-size: 14px;
      line-height: 18px;
      font-style: italic;
      font-family: "Times New Roman", serif;

      a {
        color: @color;
        text-decoration: underline;

        &:hover {
          color: @color;
          text-decoration: none;
        }
      }
    }
  }

  &__message {
    margin-top: 30px;
    padding: 25px;
    background: @color-light;
    color: @color;
    font-size: 15px;
    line-height: 18px;
    font-weight: 700;
    text-align: center;
    letter-spacing: 0.12em;

    &.red {
      background: @pink;
      color: @text;
    }
  }

  &__warning {
    padding: 15px 55px 20px 35px;
    background: @pink;
    font-size: 13px;
    line-height: 18px;
    position: relative;

    &:before {
      margin-top: -12px;
      position: absolute;
      top: 50%;
      right: 15px;
    }
  }

  &__summary {

    &__bg {
      display: flex;
      flex-direction: row-reverse;
      margin: 50px 0 70px 0;
      background: @color-light;
    }

    &__info {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      flex: 0 0 50%;
      padding-left: 15px;
      background: #ffffff;

      &__top {
        display: flex;
        justify-content: space-between;

        &__values {
          flex: 0 0 210px;
          margin: -8px 0 0 20px;
        }
      }

      &__title {
        font-size: 13px;
        line-height: 13px;
        font-weight: 700;
        text-transform: uppercase;
      }
    }

    &__note {
      max-width: 385px;
      padding-left: 30px;
      color: @gray;
      font-size: 11px;
      line-height: 14px;
      position: relative;

      &:before {
        display: block;
        position: absolute;
        top: -3px;
        left: 0;
      }
    }

    &__payment {

      &__methods {
        padding-top: 15px;

        .icon_caption {
          padding-bottom: 15px;
          float: left;
        }

        i {
          margin-right: 20px;
          .opacity(0.70);
        }
      }
    }

    &__auth {
      flex: 0 0 50%;
      padding: 40px 40px 20px 40px;

      &__note {
        margin-bottom: 7px;
        color: @gray;
        font-size: 11px;
        line-height: 14px;
      }

      .button {
        margin-bottom: 20px;
      }
    }
  }

  &__shipping.bx-step-completed, &__shipping[data-visited=false] {
    padding: 0px !important;
    border-top: 0px solid #f3f3f3;
  }

  &__shipping {
    padding: 40px 0 60px 0;
    border-top: 7px solid @gray-bg;

    .wrap {
      flex-direction: row;
      flex-wrap: wrap;
    }

    &__title {
      flex: 0 0 100%;
      margin-bottom: 65px;
      font-size: 35px;
      line-height: 40px;
      text-align: center;
    }

    &__methods {
      flex: 1 1 auto;

      label {
        display: block;
        margin-bottom: 25px;
        padding-top: 5px;
        font-size: 12px;
        line-height: 18px;
        font-weight: 700;

        .title-method {
          font-size: 12px;
          line-height: 18px;
          font-weight: 700;
          text-transform: uppercase;
        }

        a {
          color: @text;
          font-weight: 400;
          text-decoration: underline;

          &:hover {
            color: @color;
            text-decoration: underline;
          }
        }

        .price {
          font-size: 18px;
        }

        .free {
          margin-left: 5px;
          color: @color;
          font-size: 12px;
          text-transform: uppercase;
        }

        .small {
          display: block;
          padding-top: 5px;
          color: @gray;
          font-size: 11px;
          line-height: 14px;
          font-weight: 400;

          &.iconed {

            &:before {
              margin: -3px 10px 0 0;
            }
          }
        }
      }

      &.alternate > label {
        padding-right: 200px;
        position: relative;
      }

      &__icon {
        width: 200px;
        position: absolute;
        top: 0;
        right: 0;

        .icon {
          margin-left: 25px;
        }
      }
    }

    &__price {
      flex: 0 0 500px;
      padding-left: 15px;

      &__top {
        display: flex;
        justify-content: space-between;
        margin-bottom: 30px;

        &.alternate {
          align-items: center;
        }

        &__left {
          flex: 0 0 140px;
          padding: 5px 0;
          color: @color;
          font-size: 13px;
          line-height: 18px;

          &__total {
            color: @text;
            font-size: 13px;
            line-height: 23px;
            font-weight: 700;
            text-transform: uppercase;
          }
        }

        &__icon {
          display: flex;
          flex: 0 0 75px;
          width: 75px;
          height: 75px;
          background: @color-light;
          position: relative;
          -webkit-border-radius: 50%;
          -moz-border-radius: 50%;
          border-radius: 50%;

          .icon {
            margin: auto;
          }
        }

        &__right {
          flex: 0 0 210px;
          padding: 5px 0;
          color: @gray;
          font-size: 11px;
          line-height: 14px;

          &__price {
            margin-bottom: 5px;
            color: @color;
            font-size: 18px;
            line-height: 20px;
            font-weight: 700;
            text-transform: uppercase;
            letter-spacing: 0.08em;
          }
        }
      }

      .cart__warning {
        margin: 10px 0;
      }
    }
  }

  &__codes {
    padding: 30px 0 40px 0;
    background: @color-light;

    .wrap {
      justify-content: space-around;
    }

    &__one {
      flex: 0 0 430px;
    }

    &__title {
      margin-bottom: 5px;
      font-size: 35px;
      line-height: 40px;
    }

    &__notice {
      margin-bottom: 25px;
      padding: 2px 0 0 25px;
      color: @gray;
      font-size: 11px;
      line-height: 14px;
      position: relative;

      &:before {
        position: absolute;
        top: 0;
        left: 0;
      }
    }

    &__label {
      margin-bottom: 10px;
      font-size: 12px;
      line-height: 18px;
      font-weight: 700;
      text-transform: uppercase;
    }

    &__field {
      margin-bottom: 30px;
    }
  }

  &__bottom {
    padding: 55px 0 30px 0;
    border-top: 7px solid @gray-bg;

    .wrap {
      align-items: center;
    }

    &__notice {
      flex: 1 1 auto;

      &__text {
        width: 425px;
        margin: auto;
        padding-left: 40px;
        color: @gray-dark;
        font-size: 13px;
        line-height: 18px;
        position: relative;

        &:before {
          margin: auto;
          position: absolute;
          top: 0;
          bottom: 0;
          left: 0;
        }

        a {
          color: @gray-dark;
          text-decoration: underline;

          &:hover {
            color: @color;
            text-decoration: underline;
          }
        }
      }
    }

    &__controls {
      flex: 0 0 500px;
      padding-left: 15px;
    }

    &__title {
      padding-bottom: 10px;
      border-bottom: 3px solid @gray-bg;
      font-size: 13px;
      line-height: 18px;
      font-weight: 700;
      text-transform: uppercase;
    }

    &__priceinfo {
      margin: 5px 0;
      color: @gray;
      font-size: 11px;
      line-height: 14px;
    }

    .cart__item__price__value {
      margin-top: 20px;
    }

    &__button {
      margin-top: 25px;
    }

    &__attention {
      margin-top: 12px;
      color: @gray;
      font-size: 11px;
      line-height: 14px;

      &:before {
        margin: -2px 10px 0 0;
      }
    }
  }

  &__controls {
    padding: 45px 0;
    border-top: 7px solid @gray-bg;

    .wrap {
      justify-content: space-between;
    }

    &__gifts {

      a {
        display: block;
        padding: 14px 13px 13px 43px;
        background: @pink-dark;
        color: #ffffff;
        font-size: 11px;
        line-height: 13px;
        text-decoration: none;
        letter-spacing: 0.05em;
        text-transform: uppercase;
        position: relative;

        &:before {
          display: block;
          content: " ";
          width: 22px;
          height: 27px;
          margin: auto;
          background: url(@sprite) no-repeat -400px -140px;
          position: absolute;
          top: 0;
          bottom: 3px;
          left: 11px;
        }
      }
    }

    &__submit {
      flex: 0 0 500px;
      padding-left: 15px;
    }
  }

  &__subtotal {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 15px;

    &__summary {
      flex: 0 0 auto;
      margin-right: 20px;

      &__text {
        font-size: 12px;
        line-height: 14px;
        font-weight: 700;
        text-align: right;
        letter-spacing: 0.08em;

        &__label {
          float: left;
          text-transform: uppercase;
        }
      }
    }

    &__attention {
      flex: 0 0 275px;
      padding-left: 30px;
      color: @gray;
      font-size: 11px;
      line-height: 14px;
      position: relative;

      &:before {
        position: absolute;
        top: 0;
        left: 0;
      }
    }

    &__button {
      flex: 0 0 auto;
      margin-left: 20px;
    }
  }

  &__gotofav {
    padding: 35px 0 50px 0;
    border-top: 7px solid @gray-bg;

    .wrap {
      flex-direction: column;
    }

    &__note {
      margin-bottom: 12px;
      color: @gray;
      font-size: 11px;
      line-height: 14px;
      text-align: center;
    }

    &__button {

      .button {
        width: 100%;
      }
    }
  }
}

@media screen and (max-width: 999px) {
  .cart {

    form > .wrap {
      flex-direction: column-reverse;
    }

    &__message {
      margin: 15px -15px 0 -15px;
      padding: 10px 15px;
      font-size: 12px;
      line-height: 18px;
    }

    &__items {
      width:100%;
    }

    &__item {
      min-height: 278px;
      position: relative;

      &__image {
        margin-right: 65px;
      }

      &__info__wrap {
        height: 100%;
      }

      &__title {
        margin-bottom: 5px;
      }

      &__params {
        flex: 1 1 auto;
        justify-content: flex-start;
        flex-direction: column;
        padding-bottom: 54px;
        position: relative;

        &__name {
          flex: 0 0 auto;
        }

        &__color {
          flex: 0 0 auto;
          order: 50;
          margin-top: 15px;
        }

        &__size {
          flex: 0 0 auto;
          margin-top: 15px;
        }

        &__quantity {
          flex: 0 0 auto;
          margin-top: 15px;
        }
      }

      &__name {
        margin: 0;
      }

      &__price {
        flex: 0 0 170px;
      }

      &__descr {
        flex: 0 0 auto;
      }

      &__promo, &__discount {
        position: absolute;
        bottom: 0;
        left: 0;
      }

      &__tofav {
        margin: 0;
        position: absolute;
        top: 230px;
        left: 0;
      }

      &__delete {
        width: 170px;
        padding-right: 62px;
        position: absolute;
        right: 0;
        bottom: 20px;
      }
    }

    &__warning {
      padding: 15px 15px 15px 50px;

      &:before {
        right: auto;
        left: 15px;
      }
    }

    &__summary {
      border-top: 7px solid @gray-bg;

      &__bg {
        flex-direction: column;
        margin: 30px -15px 0 -15px;
      }

      &__info {
        flex: 0 0 auto;
        padding-left: 0;

        &__top {
          margin-bottom: 10px;
          padding: 0 15px;

          &__values {
            flex: 0 0 170px;
          }
        }
      }

      &__note {
        max-width: none;
        margin-bottom: 15px;
        margin-left: 15px;
        padding-left: 35px;
        padding-right: 280px;
      }

      &__auth {
        max-width: 540px;
        margin: auto;
        padding: 35px 25px 25px 25px;

        .button {
          padding: 17px 20px 16px 20px;
          font-size: 13px;
          line-height: 18px;
          letter-spacing: 0.05em;
        }
      }

      .cart__warning {

        &:before {
          margin: 0;
          top: 17px;
          left: 15px;
        }
      }
    }

    &__shipping {
      border-top: none;
      padding: 25px 0 0 0;

      .wrap {
        flex-direction: column;
      }

      &__title {
        margin-bottom: 30px;
      }

      &__methods {
        flex: 0 0 auto;
      }

      &__price {
        flex: 0 0 auto;
        margin: 10px -15px 0 -15px;
        padding: 0;

        &__top {
          margin: 0;
          padding: 20px 15px;
          background: @color-light;

          &__left {
            flex: 1 1 auto;
          }

          &__icon {
            margin-right: 40px;
          }
        }

        .cart__warning {
          margin: 0;
        }
      }
    }

    &__codes {

      &__one {
        flex: 0 0 260px;
      }

      &__title {
        line-height: 30px;
      }

      &__notice {
        line-height: 15px;
      }

      &__button {

        .button {
          width: 100%;
          padding-right: 15px;
          padding-left: 15px;
        }
      }
    }

    &__bottom {

      &__notice {

        &__text {
          width: 350px;
        }
      }

      &__controls {
        flex: 0 0 340px;
      }

      &__attention {
        padding-left: 25px;
        position: relative;

        &:before {
          margin: auto;
          position: absolute;
          top: -4px;
          bottom: 0;
          left: 0;
        }
      }
    }

    &__controls {

      &__submit {
        flex: 0 0 340px;
      }
    }
  }
}

@media screen and (max-width: 767px) {
  .cart {

    &__subtotal {
      flex-direction: column;
      margin-right: -15px;
      margin-left: -15px;

      &__summary {
        width: 100%;
        margin: 0 0 10px 0;
        padding: 0 15px;

        &__bg {
          margin-top: 15px;
        }
      }

      &__attention {
        flex: 0 0 auto;
        margin: 0 15px 10px 15px;
      }

      &__button {
        width: 100%;
        margin: 0;

        .button {
          display: block;
          width: 100%;
        }
      }
    }

    &__message {
      margin-top: 0;
    }

    &__item {

      &__image {
        margin-right: 20px;
      }

      &__tofav {

        .iconed:before {
          margin-right: 5px;
        }
      }

      &__delete {
        width: 123px;
        margin: 0;
        padding: 0;
        font-size: 12px;
        line-height: 18px;
        position: absolute;
        top: 265px;
        left: 0;
      }

      &__params {
        padding-bottom: 0;

        &__name {
          margin-right: 0;
        }
      }

      &__price {
        flex: 0 0 auto;
        margin: 0 0 10px 0;
      }

      &__promo, &__discount {
        margin-top: 10px;
        position: static;
        top: auto;
        right: auto;
        bottom: auto;
        left: auto;
      }

      &__info {

        &__wrap {
          flex-direction: column-reverse;
        }
      }
    }

    &__warning {
      font-size: 11px;
      line-height: 18px;
    }

    &__summary {

      &__note {
        margin-right: 15px;
        padding: 20px 0 0 0;
      }

      &__info {

        .cart__warning {
          padding-left: 15px;

          &:before {
            display: none;
          }
        }
      }

      &__auth {
        padding: 15px 15px 0 15px;
      }
    }

    &__shipping {

      & + & {
        margin-top: 25px;
      }

      &__title {
        font-size: 30px;
        line-height: 34px;
      }

      &__methods {

        &.alternate {

          label {
            display: flex;
            flex-direction: column;
            padding-right: 0;
          }

          .iconed_attention {
            display: block;
            order: 5;
            margin: 10px 0 0 -26px;
            padding: 0 0 0 26px;
            position: relative;

            &:before {
              margin: auto !important;
              position: absolute;
              top: 0;
              bottom: 0;
              left: 0;
            }
          }
        }

        &__icon {

          .alternate & {
            order: 3;
            margin-top: 10px;
            position: static;
            top: auto;
            right: auto;
            bottom: auto;
            left: auto;

            .icon:first-child {
              margin-left: 0;
            }
          }
        }
      }

      &__price {

        &__top {
          position: relative;

          &.alternate {
            align-items: flex-start;
            padding-top: 25px;
          }

          &__left {
            flex: 0 0 50%;
            padding: 25px 5px 0 0;

            &__total {
              margin-bottom: 5px;
              font-size: 14px;
              line-height: 16px;
            }
          }

          &__icon {
            flex: 0 0 auto;
            width: auto;
            height: auto;
            margin: 0 0 0 5px;
            padding: 0;
            position: absolute;
            top: 20px;
            left: 50%;
          }

          &__right {
            flex: 0 0 50%;
            padding: 25px 0 0 5px;

            &__price {
              font-size: 14px;
              line-height: 16px;
            }
          }
        }
      }
    }

    &__codes {

      .wrap {
        flex-direction: column;
      }

      &__one {

        & + & {
          margin-top: 35px;
        }
      }

      &__title {
        margin-bottom: 15px;
        font-size: 30px;
      }
    }

    &__bottom {
      padding: 25px 0;

      .wrap {
        flex-direction: column;
      }

      &__notice {

        &__text {
          width: auto;
          margin-bottom: 20px;
        }
      }

      &__controls {
        flex: 0 0 auto;
        padding: 0;
      }

      &__button {
        margin-right: -15px;
        margin-left: -15px;

        .button {
          padding-right: 10px;
          padding-left: 10px;
        }
      }
    }

    &__controls {
      padding: 25px 0;

      .wrap {
        flex-direction: column;
      }

      &__back {

        .button {
          width: 100%;
        }
      }

      &__gifts {
        margin: 10px 0 0 0;
      }

      &__submit {
        flex: 0 0 auto;
        margin: 10px 0 0 0;
        padding: 0;
      }
    }

    &__gotofav {

      &__button {
        margin-right: -5px;
        margin-left: -5px;

        .button {
          padding-right: 5px;
          padding-left: 5px;
          font-size: 12px;
          letter-spacing: 0.08em;
        }
      }
    }
  }
}
