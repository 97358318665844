/*
 --- NEWS ---------------------------------------
*/

.news {
  padding-top: 35px;

  .wrap {
    max-width: 1100px;
  }

  &__pretitle {
    margin-bottom: 5px;
    font-size: 12px;
    line-height: 18px;
    font-weight: 700;
    letter-spacing: 0.05em;
  }

  &__title {
    margin-bottom: 10px;
    font-size: 33px;
    line-height: 36px;
    font-weight: 200;
  }

  &__author {
    margin-bottom: 10px;
    color: @color;
    font-size: 12px;
    line-height: 14px;
  }

  &__main {

    &__side {
      flex: 0 0 330px;
      margin-left: 30px;

      .lastchance {
        width: 100%;
      }

      .actual__carousel__one {
        width: auto;
        height: auto;
        padding: 60px 15px 0 15px;

        &__text {
          margin-top: 5px;
          font-size: 11px;
          line-height: 16px;
        }
      }
    }

    &__content {
      flex: 1 1 auto;
      display: flex;
      flex-direction: column;
      padding-top: 22px;
      border-top: 4px solid @gray-bg;
    }

    &__text {
      margin-bottom: 35px;
      font-size: 12px;
      line-height: 25px;
      letter-spacing: 0.07em;
    }

    &__carousel {
      width: 100%;
      max-width: 670px;
      margin: auto;
      padding-bottom: 70px;
      position: relative;

      .owl-item {
        flex: 0 0 50%;
      }

      .owl-nav {
        top: 235px;
        right: -30px;
        left: -30px;
      }

      &__one {
        padding: 0 5px;

        &__person {
          margin-bottom: 10px;
          color: @gray-lighter;
          font-size: 12px;
          line-height: 14px;
        }

        &__photo {
          position: relative;

          img {
            display: block;
            width: 100%;
            height: auto;
          }

          &:before {
            display: block;
            content: " ";
            height: 7px;
            background: #000000;
            position: absolute;
            top: 100%;
            right: 0;
            left: 0;
          }

          &:after {
            display: block;
            content: " ";
            width: 0;
            height: 0;
            margin: 7px 0 0 -10px;
            border: 10px solid transparent;
            border-top-color: #000000;
            position: absolute;
            top: 100%;
            left: 50%;
          }
        }

        &__items {
          display: flex;
          padding-top: 50px;

          a {
            flex: 0 0 50%;
          }
        }
      }
    }

    &__video {
      padding-bottom: 65px;

      &__video {
        padding-top: 20px;

        iframe {
          display: block;
          width: 100%;
          height: 400px;
        }
      }
    }
  }

  &__rate {
    padding: 55px 0;
    border-top: 4px solid @gray-bg;
    text-align: center;

    .wrap {
      flex-direction: column;
    }

    &__title {
      font-size: 25px;
      line-height: 29px;

      .alternate & {
        font-size: 50px;
        line-height: 60px;
      }
    }

    &__text {
      margin-top: 5px;
      font-size: 11px;
      line-height: 19px;

      .alternate & {
        margin-top: 0;
        font-size: 35px;
        line-height: 40px;
        font-weight: 200;
      }
    }

    &__actions {
      display: flex;
      justify-content: center;
      margin-top: 25px;

      &__one {
        flex: 0 0 115px;
        display: flex;
        flex-direction: column;
        padding-top: 75px;
        color: #000000;
        font-size: 11px;
        line-height: 16px;
        font-weight: 700;
        position: relative;

        span {
          font-size: 25px;
          line-height: 35px;
          font-weight: 200;
        }

        .alternate & {
          flex: 0 0 145px;
          padding-top: 105px;
          font-size: 12px;

          span {
            font-size: 35px;
            line-height: 40px;
          }
        }
      }
    }
  }

  &__tags {
    padding: 20px 0;
  }
}


@media screen and (max-width: 1249px) {
  .news {

    &__main {

      &__side {
        margin-left: 0;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;

        .lastchance {
          flex: 1 0 100%;
        }
      }
    }
  }
}


@media screen and (max-width: 767px) {
  .news {

    &__main {
      margin-bottom: 25px;

      &__carousel {

        .owl-item {
          flex: 0 0 100%;
        }

        .owl-next {
          right: 8px;
        }

        .owl-prev {
          left: 8px;
        }

        &__one {
          margin: 0 auto;

          &__items {
            justify-content: center;

            .carousel__item {
              margin: 0;
            }
          }
        }
      }

      &__side {
        .actual__carousel__one {
          flex: 0 0 50%;
          padding-left: 5px;
          padding-right: 5px;

          &__text {
            padding-left: 0;
            padding-right: 0;
          }
        }
      }

      &__image {

        img {
          max-width: 100%;
          height: auto;
        }
      }
    }

    &__title {
      line-height: 1.5;
      font-size: 18px;

      a {
        display: inline-block;
      }
    }

    &__rate {
      padding-bottom: 0;

      &__title {

        .alternate & {
          font-size: 25px;
          line-height: 29px;
        }
      }

       &__text {

        .alternate & {
          margin-top: 0;
          font-size: 11px;
          line-height: 19px;
          font-weight: 200;
        }
      }

      &__actions {

        &__one {

          .alternate & {
            flex: 0 0 (100%/3);
            padding-top: 75px;
            font-size: 12px;

            span {
              font-size: 25px;
              line-height: 35px;
            }
          }
        }
      }
    }
  }
}
