/*
 --- PAGE KAK NA MNE ----------------------------
*/

.kaknamne {

  .wrap {
    flex-direction: column;
  }

  &__title {
    font-size: 60px;
    line-height: 70px;
    text-align: center;
  }

  &__subtitle {
    max-width: 535px;
    margin: auto;
    font-size: 12px;
    line-height: 20px;
    font-weight: 700;
    text-align: center;
    letter-spacing: 0.07em;
  }

  &__text {
    max-width: 535px;
    margin: auto;
    font-size: 13px;
    line-height: 18px;
    font-weight: 300;
    text-align: center;
    letter-spacing: 0.08em;
  }

  &__morelink {
    width: 100%;
    max-width: 950px;
    margin: -18px auto 12px auto;
    font-size: 14px;
    line-height: 18px;
    font-style: italic;
    text-align: right;
    font-family: "Times New Roman", serif;

    a {
      text-decoration: underline;
    }
  }

  &__note {
    max-width: 950px;
    margin: 10px auto 25px auto;
    font-size: 11px;
    line-height: 16px;
    text-align: center;
    letter-spacing: 0.095em;
  }

  &__heart {
    margin: 0 auto 15px auto;
  }

  &__uploadlink {
    font-size: 11px;
    line-height: 15px;
    font-weight: 900;
    text-align: center;
    letter-spacing: 0.05em;

    a, .link {
      color: inherit;
      text-decoration: underline;

      &:hover {
        color: inherit;
        text-decoration: none;
      }
    }
  }

  &__list {
    max-width: 910px;
    margin: auto;
    padding: 25px 15px 0 0;
    flex-wrap: wrap;

    &__one {
      display: block;
      float: left;
      width: 33.33%;
      padding: 15px 0 0 15px;
      -webkit-box-sizing: border-box;
      -moz-box-sizing: border-box;
      box-sizing: border-box;

      &.double {
        width: 66.66%;
      }

      &.right {
        float: right;
      }

      a {
        display: block;
      }

      img {
        display: block;
        width: 100%;
        height: auto;
      }
    }

    &__morelink {
      margin: 55px 0 55px 15px;
    }
  }
}

@media screen and (max-width: 999px) {
  .kaknamne {

    .wrap {
      position: relative;
    }

    &__title {
      margin-top: 45px;
      font-size: 45px;
      line-height: 50px;
    }

    &__morelink {
      width: 80px;
      margin: 0;
      font-size: 12px;
      line-height: 18px;
      text-align: right;
      position: absolute;
      top: 45px;
      right: 15px;
    }

    &__list {
      margin-left: -10px;
      padding-right: 0;

      &__one {
        padding: 10px 0 0 10px;
      }

      &__morelink {
        margin-left: 10px;
      }
    }
  }
}

@media screen and (max-width: 767px) {
  .kaknamne {

    &__title {
      margin-top: 12px;
      font-size: 25px;
      line-height: 29px;
    }

    &__subtitle {
      font-size: 11px;
      line-height: 20px;
    }

    &__morelink {
      width: auto;
      margin-top: 5px;
      text-align: center;
      position: static;
      top: auto;
      right: auto;
      bottom: auto;
      left: auto;
    }

    .separator {
      width: auto;
      height: 5px;
      margin: 10px -15px;
    }

    &__heart {
      margin-top: 10px;
    }

    &__list {
      margin-right: -15px;
      margin-left: -16px;

      &__one {
        padding: 1px 0 0 1px;
      }

      &__morelink {
        margin: 70px 15px 20px 15px;

        .button {
          font-size: 15px;
          line-height: 18px;
        }
      }
    }
  }
}
