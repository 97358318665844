/* mixins */
/*
 --- CSS RESET AND PRESTYLES --------------------
*/
* {
  outline: none;
  /*убираем рамку вокруг ссылок и кнопок*/
  -webkit-text-size-adjust: none;
  /*фиксим баг в IOS cо шкалированием в landscape*/
  -webkit-touch-callout: none;
  /*если надо, то запрещаем выделение текста*/
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  /*убираем фон подсветки ссылок и кнопок*/
  -webkit-appearance: none;
  /*убираем стили форм iOS*/
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
font,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
caption,
header,
nav,
section,
aside,
article,
time,
footer {
  background: transparent;
  border: 0;
  padding: 0;
  margin: 0;
  outline: 0;
}
html,
body {
  width: 100%;
  height: 100%;
}
form,
header,
nav,
section,
aside,
article,
time,
footer {
  display: block;
}
a img {
  border: none;
}
.clear {
  display: block;
  font-size: 0;
  height: 0;
  clear: both;
  overflow: hidden;
}
ul,
li {
  display: block;
  margin: 0;
  padding: 0;
  list-style: none;
}
ul li {
  padding-left: 14px;
}
ul li:before {
  display: inline-block;
  content: "\2013";
  width: 14px;
  margin-left: -14px;
  vertical-align: baseline;
}
ul.country-list li:before {
  display: none;
}
ol li {
  display: list-item;
  list-style: decimal inside;
}
ol li ul li {
  margin: 0 0 0 30px;
  list-style: none;
}
ol.unstyled,
ul.unstyled {
  display: block;
  margin: 0;
  padding: 0;
}
ol.unstyled > li,
ul.unstyled > li {
  display: block;
  margin: 0;
  padding: 0;
}
ol.unstyled > li:before,
ul.unstyled > li:before {
  display: none;
}
.pos-r {
  position: relative;
}
.fl-l {
  float: left;
}
.fl-r {
  float: right;
}
.ta-l {
  text-align: left;
}
.ta-r {
  text-align: right;
}
.ta-c {
  text-align: center;
}
.tt-u {
  text-transform: uppercase;
}
.animate {
  transition: all 250ms cubic-bezier(0, 0, 0, 1);
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  /* display: none; <- Crashes Chrome on hover */
  -webkit-appearance: none;
  margin: 0;
  /* <-- Apparently some margin are still there even though it's hidden */
}
@font-face {
  font-family: "Circe";
  src: url("../fonts/Circe-Regular.eot");
  src: url("../fonts/Circe-Regular.eot?#iefix") format("embedded-opentype"), url("../fonts/Circe-Regular.woff") format("woff"), url("../fonts/Circe-Regular.ttf") format("truetype"), url("../fonts/Circe-Regular.svg#Circe-Regular") format("svg");
  font-style: normal;
  font-weight: 400;
  text-rendering: optimizeLegibility;
}
@font-face {
  font-family: "Circe";
  src: url("../fonts/Circe-Bold.eot");
  src: url("../fonts/Circe-Bold.eot?#iefix") format("embedded-opentype"), url("../fonts/Circe-Bold.woff") format("woff"), url("../fonts/Circe-Bold.ttf") format("truetype"), url("../fonts/Circe-Bold.svg#Circe-Bold") format("svg");
  font-style: normal;
  font-weight: 700;
  text-rendering: optimizeLegibility;
}
@font-face {
  font-family: "Circe";
  src: url("../fonts/Circe-ExtraBold.svg");
  src: url("../fonts/Circe-ExtraBold.eot?#iefix") format("embedded-opentype"), url("../fonts/Circe-ExtraBold.woff") format("woff"), url("../fonts/Circe-ExtraBold.ttf") format("truetype"), url("../fonts/Circe-ExtraBold.svg#Circe-ExtraBold") format("svg");
  font-style: normal;
  font-weight: 900;
  text-rendering: optimizeLegibility;
}
@font-face {
  font-family: "Circe";
  src: url("../fonts/Circe-Light.eot");
  src: url("../fonts/Circe-Light.eot?#iefix") format("embedded-opentype"), url("../fonts/Circe-Light.woff") format("woff"), url("../fonts/Circe-Light.ttf") format("truetype"), url("../fonts/Circe-Light.svg#Circe-Light") format("svg");
  font-style: normal;
  font-weight: 300;
  text-rendering: optimizeLegibility;
}
@font-face {
  font-family: "Circe";
  src: url("../fonts/Circe-ExtraLight.eot");
  src: url("../fonts/Circe-ExtraLight.eot?#iefix") format("embedded-opentype"), url("../fonts/Circe-ExtraLight.woff") format("woff"), url("../fonts/Circe-ExtraLight.ttf") format("truetype"), url("../fonts/Circe-ExtraLight.svg#Circe-ExtraLight") format("svg");
  font-style: normal;
  font-weight: 200;
  text-rendering: optimizeLegibility;
}
@font-face {
  font-family: "CircusDidot";
  src: url("../fonts/CircusDidot.eot");
  src: url("../fonts/CircusDidot.eot?#iefix") format("embedded-opentype"), url("../fonts/CircusDidot.woff") format("woff"), url("../fonts/CircusDidot.ttf") format("truetype"), url("../fonts/CircusDidot.svg#CircusDidot") format("svg");
  font-style: normal;
  font-weight: normal;
  text-rendering: optimizeLegibility;
}
/*
 --- SPRITE -------------------------------------
*/
.icon,
.image,
.iconed:before,
.iconed:after {
  background: url("../i/sprite.png") no-repeat;
}
.iconed:before,
.iconed:after {
  display: inline-block;
  vertical-align: middle;
}
.icon {
  display: inline-block;
  vertical-align: middle;
}
.icon_banner {
  width: 37px;
  height: 18px;
  background-position: -100px 0;
}
.active .icon_banner {
  background-position: -100px -20px;
}
.icon_more {
  display: inline-block;
  width: 12px;
  height: 12px;
  background-position: -800px -40px;
  vertical-align: middle;
}
.active .icon_more {
  background-position: -800px -60px;
}
.icon_close {
  width: 9px;
  height: 9px;
  background-position: -70px 0;
}
.icon_favorites {
  width: 17px;
  height: 12px;
  background-position: -80px -20px;
}
.icon_discount {
  width: 13px;
  height: 13px;
  background-position: 0 -60px;
}
.icon_social {
  width: 57px;
  height: 57px;
}
.icon_social_fb {
  background-position: -100px -40px;
}
.icon_social_vk {
  background-position: -160px -40px;
}
.icon_social_ok {
  background-position: -220px -40px;
}
.icon_social_ig {
  background-position: -280px -40px;
}
.icon_social_yt {
  background-position: -340px -40px;
}
.icon_social2 {
  width: 46px;
  height: 46px;
}
.icon_social2_fb {
  background-position: 0 -100px;
}
.icon_social2_vk {
  background-position: -60px -100px;
}
.icon_social2_ok {
  background-position: -120px -100px;
}
.icon_social2_ig {
  background-position: -180px -100px;
}
.icon_social2_yt {
  background-position: -240px -100px;
}
.icon_remove {
  display: block;
  width: 31px;
  height: 30px;
  background-position: -880px -180px;
}
.icon_add2fav {
  display: block;
  width: 31px;
  height: 30px;
  background-position: -120px -280px;
}
.icon_sizes {
  display: block;
  width: 14px;
  height: 33px;
  background-position: -980px -180px;
}
.icon_kaknamne {
  display: block;
  width: 293px;
  height: 41px;
  background-position: 0 -320px;
}
.icon_uploadtitle {
  display: block;
  width: 166px;
  height: 43px;
  background-position: -760px -260px;
}
.icon_video {
  display: block;
  width: 74px;
  height: 73px;
  background-position: -540px -320px;
}
.iconed_goto:after {
  content: " ";
  width: 5px;
  height: 7px;
  margin: -3px 0 0 7px;
  /*vertical-align: top;*/
  background-position: -10px -30px;
}
.iconed_goto_white:after {
  background-position: -50px -60px;
  vertical-align: middle;
}
.iconed_goto_colored:after {
  width: 4px;
  height: 8px;
  background-position: -50px -40px;
}
.iconed_goto2:after {
  content: " ";
  width: 6px;
  height: 10px;
  margin: -3px 0 0 7px;
  vertical-align: top;
  background-position: -40px -60px;
}
.iconed_goto3:after {
  content: " ";
  width: 6px;
  height: 8px;
  margin: -3px 0 0 7px;
  background-position: -160px -300px;
}
.iconed_goback3:before {
  content: " ";
  width: 6px;
  height: 8px;
  margin: -3px 7px 0 0;
  background-position: -170px -300px;
}
.iconed_buttons:before {
  content: " ";
  width: 30px;
  height: 30px;
}
.iconed_buttons_email:before {
  background-position: -860px -40px;
}
.iconed_buttons_chat:before {
  background-position: -900px -40px;
}
.iconed_buttons_phone:before {
  background-position: -820px -40px;
}
.iconed_bonuses:before {
  content: " ";
  width: 12px;
  height: 12px;
  margin-top: -2px;
  margin-right: 6px;
  background-position: -60px -20px;
}
.iconed_bonuses_black:after {
  content: " ";
  width: 15px;
  height: 15px;
  margin: -2px 0 0 5px;
  background-position: -960px -160px;
  vertical-align: middle;
}
.iconed_cart {
  padding-top: 3px;
}
.iconed_cart:before {
  content: " ";
  width: 17px;
  height: 17px;
  margin-top: -7px;
  margin-right: 2px;
  background-position: -80px 0;
}
.iconed_advantages:before {
  content: " ";
  position: absolute;
  top: 1px;
  left: 0;
}
.iconed_advantages_1:before {
  content: " ";
  width: 21px;
  height: 14px;
  background-position: -30px -20px;
}
.iconed_advantages_2:before {
  content: " ";
  width: 16px;
  height: 14px;
  background-position: 0 -40px;
}
.iconed_advantages_3:before {
  content: " ";
  width: 12px;
  height: 12px;
  margin-top: 1px;
  background-position: -60px -20px;
}
.iconed_advantages_4:before {
  content: " ";
  width: 22px;
  height: 14px;
  background-position: -20px -40px;
}
.iconed_flag:before {
  content: " ";
  width: 16px;
  height: 10px;
  margin-right: 10px;
}
.iconed_flag_ru:before {
  content: " ";
  background-position: -20px -60px;
}
.iconed_flag_en:before {
  content: " ";
  background-position: -420px -80px;
}
.iconed_flag_de:before {
  content: " ";
  background-position: -400px -80px;
}
.iconed_flag_fr:before {
  content: " ";
  background-position: 0 -80px;
}
.iconed_flag_it:before {
  content: " ";
  background-position: -40px -80px;
}
.iconed_flag_es:before {
  content: " ";
  background-position: -20px -80px;
}
.iconed_dropdown:after {
  content: " ";
  width: 7px;
  height: 3px;
  margin-left: 5px;
  background-position: -60px 0;
}
.iconed_dropdown_black:after {
  background-position: -50px 0;
}
.iconed_signin:before {
  content: " ";
  width: 22px;
  height: 22px;
  margin-right: 22px;
  background-position: -940px -40px;
}
.iconed_gift:after {
  float: right;
  content: " ";
  width: 13px;
  height: 16px;
  margin: -2px -15px 0 0;
  background-position: -980px -60px;
}
.iconed_gift_black:after {
  background-position: -980px -40px;
}
.iconed_social {
  display: block;
  content: " ";
  width: 135px;
  padding: 17px 0 15px 0;
  color: #ffffff;
  font-size: 11px;
  line-height: 18px;
  text-align: center;
  letter-spacing: 0.12em;
  text-transform: uppercase;
  position: relative;
}
.iconed_social:before {
  content: " ";
  position: absolute;
  top: 50%;
  left: 20px;
}
.iconed_social_fb {
  padding-left: 35px;
  background: #40569f;
}
.iconed_social_fb:before {
  width: 14px;
  height: 24px;
  margin-top: -12px;
  background-position: -640px -95px;
}
.iconed_social_gp {
  padding-left: 55px;
  background: #d65038;
}
.iconed_social_gp:before {
  width: 39px;
  height: 26px;
  margin-top: -13px;
  background-position: -660px -93px;
}
.iconed_social_vk {
  padding-left: 43px;
  background: #5077a4;
}
.iconed_social_vk:before {
  width: 33px;
  height: 20px;
  margin-top: -10px;
  background-position: -820px -99px;
  left: 10px;
}
.iconed_upload:before {
  display: inline-block;
  content: " ";
  margin-right: 10px;
  vertical-align: middle;
}
.iconed_upload_ig:before {
  width: 27px;
  height: 28px;
  background-position: -880px -140px;
}
.iconed_upload_fb:before {
  width: 10px;
  height: 21px;
  background-position: -920px -140px;
}
.iconed_upload_device:before {
  width: 33px;
  height: 25px;
  background-position: -660px -180px;
}
.iconed_save:before {
  display: block;
  content: " ";
  width: 22px;
  height: 22px;
  margin: 0 auto 5px auto;
  background-position: -920px -97px;
}
.iconed_view:before {
  display: block;
  content: " ";
  width: 27px;
  height: 22px;
  margin: 0 auto 5px auto;
  background-position: -950px -97px;
}
.iconed_featured:after {
  display: block;
  content: " ";
  width: 15px;
  height: 14px;
  background-position: -960px -80px;
}
.iconed_totop:after {
  content: " ";
  width: 11px;
  height: 8px;
  margin-left: 10px;
  background-position: -740px -120px;
}
.iconed_filter:after {
  content: " ";
  width: 6px;
  height: 9px;
  margin: -3px 0 0 5px;
  background-position: -780px -120px;
}
.iconed_shipping:before {
  content: " ";
  width: 24px;
  height: 16px;
  margin-right: 15px;
  background-position: -800px -120px;
}
.red > .iconed_shipping:before {
  width: 26px;
  background-position: -830px -120px;
}
.iconed_attention:before {
  content: " ";
  width: 16px;
  height: 16px;
  background-position: -860px -120px;
}
.iconed_attention_red:before {
  background-position: -880px -120px;
}
.iconed_warning:before {
  content: " ";
  width: 24px;
  height: 24px;
  background-position: -440px -140px;
}
.iconed_balance:after {
  content: " ";
  width: 15px;
  height: 18px;
  margin-left: 7px;
  background-position: -980px -120px;
  vertical-align: top;
}
.iconed_gotofav:after {
  content: " ";
  width: 22px;
  height: 22px;
  margin-left: 7px;
  background-position: -620px -140px;
}
.iconed_acc:before,
.iconed_acc:after {
  display: block;
  content: " ";
}
.iconed_acc:after {
  display: none;
}
.iconed_acc.active:before {
  display: none;
}
.iconed_acc.active:after {
  display: block;
}
.iconed_acc_myaccount:before,
.iconed_acc_myaccount:after {
  width: 22px;
  height: 28px;
  margin-left: 17px;
  background-position: 0 -190px;
}
.iconed_acc_myaccount:after {
  background-position: -30px -190px;
}
.iconed_acc_orders:before,
.iconed_acc_orders:after {
  width: 29px;
  height: 22px;
  margin-left: 14px;
  background-position: -200px -160px;
}
.iconed_acc_orders:after {
  background-position: -240px -160px;
}
.iconed_acc_data:before,
.iconed_acc_data:after {
  width: 30px;
  height: 20px;
  margin-left: 13px;
  background-position: -120px -195px;
}
.iconed_acc_data:after {
  background-position: -160px -195px;
}
.iconed_acc_password:before,
.iconed_acc_password:after {
  width: 23px;
  height: 26px;
  margin-left: 17px;
  background-position: -200px -190px;
}
.iconed_acc_password:after {
  background-position: -240px -190px;
}
.iconed_acc_addresses:before,
.iconed_acc_addresses:after {
  width: 24px;
  height: 24px;
  margin-left: 16px;
  background-position: -380px -180px;
}
.iconed_acc_addresses:after {
  background-position: -420px -180px;
}
.iconed_acc_payments:before,
.iconed_acc_payments:after {
  width: 33px;
  height: 29px;
  margin-left: 12px;
  background-position: -460px -180px;
}
.iconed_acc_payments:after {
  background-position: -500px -180px;
}
.iconed_acc_socials:before,
.iconed_acc_socials:after {
  width: 30px;
  height: 24px;
  margin-left: 13px;
  background-position: -540px -180px;
}
.iconed_acc_socials:after {
  background-position: -580px -180px;
}
.iconed_acc_contacts:before,
.iconed_acc_contacts:after {
  width: 30px;
  height: 27px;
  margin-left: 13px;
  background-position: -120px -160px;
}
.iconed_acc_contacts:after {
  background-position: -160px -160px;
}
.iconed_acc_gifts:before,
.iconed_acc_gifts:after {
  width: 33px;
  height: 19px;
  margin-left: 12px;
  background-position: -620px -180px;
}
.iconed_acc_gifts:after {
  background-position: -620px -200px;
}
.iconed_acc_favs:after {
  display: block;
  content: " ";
  width: 35px;
  height: 35px;
  margin-left: 11px;
  background-position: -340px -180px;
}
.iconed_acc_bonuses:after {
  display: block;
  content: " ";
  width: 35px;
  height: 35px;
  margin-left: 11px;
  background-position: -300px -180px;
}
.iconed_acc_chat:after {
  display: block;
  content: " ";
  width: 56px;
  height: 53px;
  background-position: -700px -140px;
  bottom: auto !important;
}
.iconed_acc_signout:after {
  display: block;
  content: " ";
  width: 25px;
  height: 23px;
  margin-left: 16px;
  background-position: -660px -140px;
}
.iconed_acc_goback:before {
  display: block !important;
  content: " ";
  width: 6px;
  height: 10px;
  background-position: -340px -280px;
}
.iconed_info:before {
  display: inline-block;
  content: " ";
  width: 18px;
  height: 18px;
  background-position: -980px -140px;
  vertical-align: middle;
}
.iconed_info.red:before {
  background-position: -960px -140px;
}
.iconed_userpic:before {
  display: block;
  content: " ";
  width: 33px;
  height: 43px;
  background-position: -280px -220px;
}
.iconed_stores:before {
  content: " ";
  width: 25px;
  height: 28px;
  margin-right: 20px;
  margin-left: 5px;
  background-position: -740px -200px;
}
.iconed_online:before {
  content: " ";
  width: 36px;
  height: 27px;
  margin-right: 15px;
  background-position: -780px -200px;
}
.iconed_cardterms:before {
  content: " ";
  width: 37px;
  height: 26px;
  margin: -10px 12px 0 -4px;
  background-position: -820px -200px;
}
.iconed_howtopay:before {
  content: " ";
  width: 27px;
  height: 30px;
  margin: -8px 18px 0 0;
  background-position: -860px -220px;
}
.iconed_help:before {
  display: block;
  content: " ";
  margin: auto;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
}
.iconed_help_chat:before {
  width: 70px;
  height: 44px;
  background-position: -500px -260px;
}
.iconed_help_email:before {
  width: 39px;
  height: 39px;
  background-position: -80px -280px;
  top: 3px;
}
.iconed_help_vk:before {
  width: 46px;
  height: 46px;
  background-position: -380px -260px;
}
.iconed_help_fb:before {
  width: 46px;
  height: 46px;
  background-position: -440px -260px;
}
.iconed_helptab:before {
  display: block;
  content: " ";
  margin: auto;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 30px;
}
.iconed_helptab_1:before {
  width: 48px;
  height: 26px;
  background-position: -380px -220px;
}
.iconed_helptab_2:before {
  width: 49px;
  height: 36px;
  background-position: -440px -220px;
}
.iconed_helptab_3:before {
  width: 54px;
  height: 28px;
  background-position: -560px -220px;
}
.iconed_helptab_4:before {
  width: 43px;
  height: 38px;
  background-position: -500px -220px;
}
.iconed_helptab_5:before {
  width: 36px;
  height: 37px;
  background-position: -900px -220px;
}
.iconed_helptab_6:before {
  width: 43px;
  height: 37px;
  background-position: -620px -220px;
}
.iconed_helptab_7:before {
  width: 48px;
  height: 25px;
  background-position: -940px -220px;
}
.iconed_helptab_8:before {
  width: 41px;
  height: 36px;
  background-position: -680px -220px;
}
.iconed_helptab_9:before {
  width: 37px;
  height: 35px;
  background-position: -40px -280px;
}
.iconed_search:before {
  display: block;
  content: " ";
  width: 29px;
  height: 28px;
  margin: auto;
  background-position: 0 -280px;
  position: absolute;
  top: 50%;
  margin-top: -16px;
  right: 20px;
}
.iconed_rate:before {
  display: block;
  content: " ";
  margin: auto;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
}
.iconed_rate_1:before {
  width: 56px;
  height: 54px;
  background-position: -580px -260px;
  top: 7px;
}
.iconed_rate_2:before {
  width: 53px;
  height: 62px;
  background-position: -940px -256px;
}
.iconed_rate_3:before {
  width: 51px;
  height: 50px;
  background-position: -640px -260px;
  top: 11px;
}
.alternate.iconed_rate_1:before {
  width: 80px;
  height: 76px;
  background-position: -620px -320px;
  top: 9px;
}
.alternate.iconed_rate_2:before {
  width: 75px;
  height: 89px;
  background-position: -780px -320px;
}
.alternate.iconed_rate_3:before {
  width: 71px;
  height: 70px;
  background-position: -701px -320px;
  top: 17px;
}
.iconed_widget:before {
  display: block;
  content: " ";
  margin: auto;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
}
.iconed_widget_shipping:before {
  width: 25px;
  height: 16px;
  background-position: -740px -240px;
}
.iconed_widget_return:before {
  width: 23px;
  height: 20px;
  background-position: -780px -239px;
}
.iconed_widget_help:before {
  width: 19px;
  height: 19px;
  background-position: -980px -160px;
}
.iconed_add2fav:after {
  display: inline-block;
  content: " ";
  width: 24px;
  height: 17px;
  margin: -2px 0 0 10px;
  background-position: -820px -240px;
  vertical-align: middle;
}
.iconed_video:after {
  display: block;
  content: " ";
  width: 47px;
  height: 47px;
  background-position: -700px -260px;
}
.iconed_video2:after {
  display: inline-block;
  content: " ";
  width: 20px;
  height: 20px;
  margin-left: 20px;
  background-position: -899px -319px;
  vertical-align: middle;
}
.iconed_uploadstep {
  position: relative;
}
.iconed_uploadstep:before {
  display: block;
  content: " ";
  margin: auto;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
}
.iconed_uploadstep_1:before {
  width: 68px;
  height: 62px;
  background-position: -300px -320px;
}
.iconed_uploadstep_2:before {
  width: 72px;
  height: 67px;
  background-position: -380px -320px;
}
.iconed_uploadstep_3:before {
  width: 67px;
  height: 73px;
  background-position: -460px -320px;
}
.iconed_upload2:before {
  display: block;
  content: " ";
  height: 25px;
  margin: 0 auto 20px auto;
}
.iconed_upload2_ig:before {
  width: 23px;
  background-position: 0 -365px;
}
.iconed_upload2_vk:before {
  width: 27px;
  background-position: -40px -365px;
}
.iconed_upload2_fb:before {
  width: 11px;
  background-position: -80px -365px;
}
.iconed_upload2_ok:before {
  width: 15px;
  background-position: -100px -365px;
}
.iconed_upload2_device:before {
  width: 32px;
  background-position: -120px -365px;
}
.iconed_spoiler:after {
  display: inline-block;
  content: " ";
  width: 10px;
  height: 6px;
  background-position: -360px -280px;
}
.iconed_spoiler.active:after {
  background-position: -360px -290px;
}
.icon_acc_myaccount {
  width: 36px;
  height: 46px;
  margin-right: 24px;
  background-position: -780px -140px;
}
.icon_acc_addresses {
  width: 45px;
  height: 45px;
  margin-right: 30px;
  background-position: 0 -220px;
}
.icon_acc_favs {
  width: 51px;
  height: 51px;
  margin-right: 26px;
  background-position: -60px -220px;
}
.icon_acc_data {
  width: 56px;
  height: 36px;
  margin-right: 26px;
  background-position: -920px -180px;
}
.icon_acc_password {
  width: 39px;
  height: 46px;
  margin-right: 34px;
  background-position: -120px -220px;
}
.icon_acc_cards {
  width: 57px;
  height: 29px;
  background-position: -160px -220px;
}
.icon_acc_chat {
  width: 56px;
  height: 54px;
  margin-right: 29px;
  background-position: -220px -220px;
}
.icon_acc_bonuses {
  width: 49px;
  height: 49px;
  margin-right: 26px;
  background-position: -320px -220px;
}
.icon_search {
  width: 28px;
  height: 23px;
  background-position: 0 0;
}
.icon_search2 {
  width: 20px;
  height: 17px;
  background-position: -980px 0;
}
.icon_foot {
  width: 40px;
  height: 40px;
}
.icon_foot_1 {
  background-position: -400px -40px;
}
.icon_foot_2 {
  background-position: -440px -40px;
}
.icon_foot_3 {
  background-position: -480px -40px;
}
.icon_foot_4 {
  background-position: -520px -40px;
}
.icon_foot_5 {
  background-position: -560px -40px;
}
.icon_shipping {
  width: 38px;
  height: 26px;
  background-position: -360px -140px;
}
.icon_payment {
  width: 50px;
  height: 37px;
  background-position: -300px -140px;
}
.icon_mastercard {
  width: 44px;
  height: 27px;
  background-position: -480px -140px;
}
.icon_visa {
  width: 42px;
  height: 14px;
  background-position: -900px -120px;
}
.icon_qiwi {
  width: 49px;
  height: 20px;
  background-position: 0 -160px;
}
.icon_yandex {
  width: 63px;
  height: 24px;
  background-position: -540px -140px;
}
.icon_alfa {
  width: 48px;
  height: 42px;
  background-position: -60px -160px;
}
.image_logo {
  width: 197px;
  height: 36px;
  background-position: -140px 0;
}
.image_secure {
  width: 81px;
  height: 47px;
  background-position: -605px -40px;
}
.image_payments {
  width: 250px;
  height: 29px;
  background-position: -720px 0;
}
@media screen and (max-width: 999px) {
  .icon_social {
    width: 33px;
    height: 33px;
  }
  .icon_social_fb {
    background-position: -300px -100px;
  }
  .icon_social_vk {
    background-position: -340px -100px;
  }
  .icon_social_ok {
    background-position: -380px -100px;
  }
  .icon_social_ig {
    background-position: -420px -100px;
  }
  .icon_social_yt {
    background-position: -460px -100px;
  }
  .icon_acc {
    /* TODO: иконки раздела, внизу - готовые, вверху - заготовки */
  }
  .icon_acc_orders {
    width: 29px;
    height: 22px;
    margin-right: 10px;
    background-position: -200px -160px;
  }
  .icon_acc_payments {
    width: 33px;
    height: 29px;
    margin-right: 10px;
    background-position: -460px -180px;
  }
  .icon_acc_socials {
    width: 30px;
    height: 24px;
    margin-right: 10px;
    background-position: -540px -180px;
  }
  .icon_acc_contacts {
    width: 30px;
    height: 27px;
    margin-right: 10px;
    background-position: -120px -160px;
  }
  .icon_acc_gifts {
    width: 33px;
    height: 19px;
    margin-right: 10px;
    background-position: -620px -180px;
  }
  .icon_acc_signout {
    width: 25px;
    height: 23px;
    margin-right: 10px;
    background-position: -660px -140px;
  }
  .icon_acc_myaccount {
    width: 22px;
    height: 28px;
    margin-right: 11px;
    background-position: 0 -190px;
  }
  .icon_acc_addresses {
    width: 24px;
    height: 24px;
    margin-right: 10px;
    background-position: -380px -180px;
  }
  .icon_acc_favs {
    width: 35px;
    height: 35px;
    margin-right: 10px;
    background-position: -340px -180px;
  }
  .icon_acc_data {
    width: 30px;
    height: 20px;
    margin-right: 10px;
    background-position: -120px -195px;
  }
  .icon_acc_password {
    width: 23px;
    height: 26px;
    margin-right: 10px;
    background-position: -200px -190px;
  }
  .icon_acc_chat {
    width: 56px;
    height: 53px;
    margin-right: 10px;
    background-position: -700px -140px;
  }
  .icon_acc_bonuses {
    width: 35px;
    height: 35px;
    margin-right: 10px;
    background-position: -300px -180px;
  }
  .iconed_social {
    width: 109px;
    padding: 14px 0 12px 0;
    font-size: 9px;
    line-height: 14px;
  }
  .iconed_social:before {
    left: 15px;
  }
  .iconed_social_fb {
    padding-left: 30px;
  }
  .iconed_social_fb:before {
    width: 12px;
    height: 22px;
    margin-top: -11px;
    background-position: -700px -97px;
  }
  .iconed_social_gp {
    padding-left: 45px;
  }
  .iconed_social_gp:before {
    width: 32px;
    height: 22px;
    margin-top: -11px;
    background-position: -720px -97px;
  }
  .iconed_social_vk {
    padding-left: 35px;
  }
  .iconed_social_vk:before {
    width: 27px;
    height: 18px;
    margin-top: -9px;
    background-position: -780px -80px;
    left: 8px;
  }
  .iconed_upload:before {
    display: inline-block;
    content: " ";
    margin-right: 10px;
    vertical-align: middle;
  }
  .iconed_upload_ig:before {
    width: 20px;
    height: 20px;
    background-position: -275px -195px;
  }
  .iconed_upload_fb:before {
    width: 10px;
    height: 21px;
    background-position: -920px -140px;
  }
  .iconed_upload_device:before {
    width: 24px;
    height: 18px;
    background-position: -700px -200px;
  }
}
@media screen and (max-width: 767px) {
  .icon_shipping {
    width: 24px;
    height: 16px;
    background-position: -800px -120px;
  }
  .icon_payment {
    width: 31px;
    height: 24px;
    background-position: -860px -320px;
  }
  .iconed_social {
    width: 100px;
    padding: 13px 0 11px 0;
    font-size: 8px;
    line-height: 13px;
  }
  .iconed_social_fb {
    padding-left: 25px;
  }
  .iconed_social_fb:before {
    width: 11px;
    height: 21px;
    background-position: -760px -98px;
    left: 13px;
  }
  .iconed_social_gp {
    padding-left: 42px;
  }
  .iconed_social_gp:before {
    width: 30px;
    height: 20px;
    margin-top: -10px;
    background-position: -780px -99px;
  }
  .iconed_social_vk {
    padding-left: 32px;
  }
  .iconed_social_vk:before {
    width: 26px;
    height: 16px;
    background-position: -820px -80px;
    left: 7px;
  }
  .iconed_upload:before {
    display: inline-block;
    content: " ";
    margin-right: 10px;
    vertical-align: middle;
  }
  .iconed_upload_ig:before {
    width: 20px;
    height: 20px;
    margin-top: 2px;
    margin-bottom: 7px;
    background-position: -275px -195px;
  }
  .iconed_upload_fb:before {
    width: 10px;
    height: 21px;
    margin-top: 2px;
    margin-bottom: 6px;
    background-position: -920px -140px;
  }
  .iconed_upload_device:before {
    width: 14px;
    height: 24px;
    margin-bottom: 5px;
    background-position: -940px -140px;
  }
  .alternate.iconed_rate_1:before {
    width: 56px;
    height: 54px;
    background-position: -580px -260px;
    top: 7px;
  }
  .alternate.iconed_rate_2:before {
    width: 53px;
    height: 62px;
    background-position: -940px -256px;
  }
  .alternate.iconed_rate_3:before {
    width: 51px;
    height: 50px;
    background-position: -640px -260px;
    top: 11px;
  }
}
/*
 --- FORMS --------------------------------------
*/
.button {
  display: block;
  width: 100%;
  max-width: 206px;
  margin: auto;
  padding: 12px 20px;
  border: none;
  background: #63b5b2;
  color: #ffffff;
  font-size: 12px;
  line-height: 18px;
  font-weight: 700;
  text-align: center;
  letter-spacing: 0.08em;
  text-transform: uppercase;
  font-family: Circe, sans-serif;
  cursor: pointer;
}
.button.alternate {
  display: inline-block;
  width: auto;
  padding: 6px 25px;
  background: #232323;
  font-weight: 300;
  letter-spacing: 0.12em;
}
.button_big {
  max-width: none;
  padding: 15px 20px 11px 20px;
  font-size: 20px;
  line-height: 27px;
  letter-spacing: 0.12em;
}
.button_large {
  display: inline-block;
  width: auto;
  max-width: none;
  padding: 15px 25px 11px 25px;
  font-size: 13px;
  line-height: 25px;
  letter-spacing: 0.12em;
}
.button_large_wide {
  display: block;
  width: 100%;
}
.button_pink {
  background: #e9c4cb;
}
.button_form {
  max-width: 100%;
  padding: 17px 20px 15px 20px;
  font-size: 13px;
  line-height: 18px;
  letter-spacing: 0.12em;
}
.button_form_light {
  background: #e0eded;
  color: #232323;
}
.button_form_white {
  background: #ffffff;
  color: #232323;
}
.button_blank {
  border: 1px solid #cfcece;
  background: none;
  color: #636363;
}
.input {
  position: relative;
}
input[type=text],
input[type=email],
input[type=tel],
input[type=password],
textarea {
  display: block;
  width: 100%;
  margin: 0;
  padding: 5px 10px;
  border: 1px solid #232323;
  background: none;
  font-size: 12px;
  line-height: 14px;
  font-weight: 700;
  font-family: Circe, sans-serif;
}
.input input[type=text],
.input input[type=email],
.input input[type=tel],
.input input[type=password],
.input textarea {
  margin-bottom: 15px;
  padding: 16px 20px 12px 20px;
  border: none;
  background: #e0eded;
  font-size: 12px;
  line-height: 23px;
  font-weight: 700;
  text-align: left;
  font-style: normal;
  font-family: Circe, sans-serif;
}
.input input[type=text].error,
.input input[type=email].error,
.input input[type=tel].error,
.input input[type=password].error,
.input textarea.error {
  padding: 12px 18px;
  border: 2px solid #e23c55;
}
.input input[type=text].error + label.error,
.input input[type=email].error + label.error,
.input input[type=tel].error + label.error,
.input input[type=password].error + label.error,
.input textarea.error + label.error {
  margin-bottom: 15px;
  padding: 10px;
  border: 2px solid #e23c55;
  background: #f4e8ea;
  font-size: 13px;
  line-height: 16px;
  position: absolute;
  bottom: 100%;
  left: 0;
}
.input input[type=text].error + label.error:before,
.input input[type=email].error + label.error:before,
.input input[type=tel].error + label.error:before,
.input input[type=password].error + label.error:before,
.input textarea.error + label.error:before,
.input input[type=text].error + label.error:after,
.input input[type=email].error + label.error:after,
.input input[type=tel].error + label.error:after,
.input input[type=password].error + label.error:after,
.input textarea.error + label.error:after {
  display: block;
  content: " ";
  width: 0;
  height: 0;
  border: 9px solid transparent;
  border-top-color: #e23c55;
  position: absolute;
  bottom: -18px;
  left: 15px;
}
.input input[type=text].error + label.error:after,
.input input[type=email].error + label.error:after,
.input input[type=tel].error + label.error:after,
.input input[type=password].error + label.error:after,
.input textarea.error + label.error:after {
  border-top-color: #f4e8ea;
  bottom: -16px;
}
.input.alternate input[type=text],
.input.alternate input[type=email],
.input.alternate input[type=tel],
.input.alternate input[type=password],
.input.alternate textarea {
  color: #63b5b2;
}
.input.alternate input[type=text]::-webkit-input-placeholder,
.input.alternate input[type=email]::-webkit-input-placeholder,
.input.alternate input[type=tel]::-webkit-input-placeholder,
.input.alternate input[type=password]::-webkit-input-placeholder,
.input.alternate textarea::-webkit-input-placeholder {
  color: #63b5b2;
}
.input.alternate input[type=text]::-moz-placeholder,
.input.alternate input[type=email]::-moz-placeholder,
.input.alternate input[type=tel]::-moz-placeholder,
.input.alternate input[type=password]::-moz-placeholder,
.input.alternate textarea::-moz-placeholder {
  color: #63b5b2;
}
.input.alternate input[type=text]:-moz-placeholder,
.input.alternate input[type=email]:-moz-placeholder,
.input.alternate input[type=tel]:-moz-placeholder,
.input.alternate input[type=password]:-moz-placeholder,
.input.alternate textarea:-moz-placeholder {
  color: #63b5b2;
}
.input.alternate input[type=text]:-ms-input-placeholder,
.input.alternate input[type=email]:-ms-input-placeholder,
.input.alternate input[type=tel]:-ms-input-placeholder,
.input.alternate input[type=password]:-ms-input-placeholder,
.input.alternate textarea:-ms-input-placeholder {
  color: #63b5b2;
}
.input.iconed input[type=text],
.input.iconed input[type=email],
.input.iconed input[type=tel],
.input.iconed input[type=password],
.input.iconed textarea {
  padding-right: 60px;
}
input[type=text].styled,
input[type=email].styled,
input[type=tel].styled,
input[type=password].styled,
textarea.styled {
  display: block;
  width: 100%;
  margin: 0;
  padding: 5px 10px;
  border: none;
  border-bottom: 1px solid #000000;
  background: #ffffff;
  color: #63b5b2;
  font-size: 13px;
  line-height: 18px;
  font-style: italic;
  text-align: center;
  font-family: "Times New Roman", serif;
}
input[type=text].styled::-webkit-input-placeholder,
input[type=email].styled::-webkit-input-placeholder,
input[type=tel].styled::-webkit-input-placeholder,
input[type=password].styled::-webkit-input-placeholder,
textarea.styled::-webkit-input-placeholder {
  color: #63b5b2;
}
input[type=text].styled::-moz-placeholder,
input[type=email].styled::-moz-placeholder,
input[type=tel].styled::-moz-placeholder,
input[type=password].styled::-moz-placeholder,
textarea.styled::-moz-placeholder {
  color: #63b5b2;
}
input[type=text].styled:-moz-placeholder,
input[type=email].styled:-moz-placeholder,
input[type=tel].styled:-moz-placeholder,
input[type=password].styled:-moz-placeholder,
textarea.styled:-moz-placeholder {
  color: #63b5b2;
}
input[type=text].styled:-ms-input-placeholder,
input[type=email].styled:-ms-input-placeholder,
input[type=tel].styled:-ms-input-placeholder,
input[type=password].styled:-ms-input-placeholder,
textarea.styled:-ms-input-placeholder {
  color: #63b5b2;
}
input[type=text].cartinput,
input[type=email].cartinput,
input[type=tel].cartinput,
input[type=password].cartinput,
textarea.cartinput {
  border: none;
  padding: 15px 20px 11px 20px;
  background: #ffffff;
  color: #050708;
  font-size: 13px;
  line-height: 25px;
  letter-spacing: 0.12em;
}
input[type=text].acc,
input[type=email].acc,
input[type=tel].acc,
input[type=password].acc,
textarea.acc {
  width: 100%;
  font-size: 30px;
  line-height: 38px;
  font-weight: 200;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
textarea {
  text-align: left;
  resize: vertical;
}
.input select {
  width: 100%;
  margin-bottom: 15px;
  padding: 16px 20px 12px 20px;
  border: none;
  background: #e0eded;
  font-size: 12px;
  line-height: 23px;
  font-weight: 700;
  text-align: left;
  font-style: normal;
  font-family: Circe, sans-serif;
}
.input select:after {
  display: block;
  content: " ";
  width: 20px;
  height: 20px;
  position: absolute;
  background: url("../i/sprite.png") no-repeat 0 0;
}
.input select.error {
  padding: 12px 18px;
  border: 2px solid #e23c55;
}
.input select.error + label.error {
  margin-bottom: 15px;
  padding: 10px;
  border: 2px solid #e23c55;
  background: #f4e8ea;
  font-size: 13px;
  line-height: 16px;
  position: absolute;
  bottom: 100%;
  left: 0;
}
.input select.error + label.error:before,
.input select.error + label.error:after {
  display: block;
  content: " ";
  width: 0;
  height: 0;
  border: 9px solid transparent;
  border-top-color: #e23c55;
  position: absolute;
  bottom: -18px;
  left: 15px;
}
.input select.error + label.error:after {
  border-top-color: #f4e8ea;
  bottom: -16px;
}
.input.alternate select {
  color: #63b5b2;
}
.input.alternate select::-webkit-input-placeholder {
  color: #63b5b2;
}
.input.alternate select::-moz-placeholder {
  color: #63b5b2;
}
.input.alternate select:-moz-placeholder {
  color: #63b5b2;
}
.input.alternate select:-ms-input-placeholder {
  color: #63b5b2;
}
input[type=radio] {
  position: absolute;
  left: -999%;
}
input[type=radio] + label {
  padding-left: 24px;
  position: relative;
  cursor: pointer;
}
input[type=radio] + label:before,
input[type=radio] + label:after {
  display: block;
  content: " ";
  margin-top: 1px;
  position: absolute;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
}
input[type=radio] + label:before {
  width: 12px;
  height: 12px;
  border: 1px solid #000000;
  left: 0;
  top: 0;
}
input[type=radio] + label:after {
  width: 8px;
  height: 8px;
  top: 3px;
  left: 3px;
}
input[type=radio]:checked + label:after {
  background: #63b5b2;
}
input[type=radio].big + label {
  padding-left: 50px;
}
input[type=radio].big + label:before {
  width: 25px;
  height: 25px;
  border-width: 2px;
}
input[type=radio].big + label:after {
  width: 9px;
  height: 9px;
  top: 10px;
  left: 10px;
}
input[type=radio].big:checked + label:after {
  background: #000000;
}
input[type=radio].big_acc + label {
  display: flex;
  align-items: center;
  padding-left: 0;
  color: #7d7d7d;
}
input[type=radio].big_acc + label:before {
  flex: 0 0 24px;
  width: 24px;
  height: 24px;
  margin-right: 10px;
  border-color: #7d7d7d;
  position: static;
  top: auto;
  right: auto;
  bottom: auto;
  left: auto;
}
input[type=radio].big_acc + label:after {
  width: 10px;
  height: 10px;
  margin-top: -4px;
  top: 50%;
  left: 9px;
}
input[type=radio].big_acc + label span {
  flex: 1 1 auto;
  margin-right: 10px;
}
input[type=radio].big_acc:checked + label:after {
  background: #7d7d7d;
}
input[type=radio].color + label {
  display: block;
  float: left;
  margin: 5px 5px 5px 5px;
  padding: 0;
  width: 25px;
  height: 25px;
  line-height: 25px;
  position: relative;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
}
input[type=radio].color + label:before,
input[type=radio].color + label:after {
  display: none;
  content: " ";
  margin: 0;
  padding: 0;
  background: none;
  position: absolute;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
}
input[type=radio].color.border + label:before {
  display: block;
  width: 25px;
  height: 25px;
  border: 1px solid #f3f3f3;
  top: 0;
  left: 0;
}
input[type=radio].color:checked + label:after {
  display: block;
  width: 29px;
  height: 29px;
  border: 1px solid #000000;
  top: -3px;
  left: -3px;
}
input[type=checkbox] {
  position: absolute;
  left: -999%;
}
input[type=checkbox] + label {
  display: inline-block;
  padding-left: 32px;
  font-size: 13px;
  line-height: 19px;
  position: relative;
}
input[type=checkbox] + label:before {
  display: block;
  content: " ";
  width: 17px;
  height: 17px;
  border: 1px solid #232323;
  position: absolute;
  top: 0;
  left: 0;
}
input[type=checkbox] + label:after {
  display: none;
  content: " ";
  width: 16px;
  height: 13px;
  background: url("../i/sprite.png") no-repeat -760px -120px;
  position: absolute;
  top: 2px;
  left: 1px;
}
input[type=checkbox]:checked + label:before {
  background: #232323;
}
input[type=checkbox]:checked + label:after {
  display: block;
}
input[type=checkbox].styled + label {
  display: inline-block;
  padding: 6px 0 4px 37px;
  color: #63b5b2;
  font-size: 14px;
  line-height: 18px;
  font-style: italic;
  font-family: "Times New Roman", serif;
  position: relative;
  cursor: pointer;
}
input[type=checkbox].styled + label:before,
input[type=checkbox].styled + label:after {
  display: block;
  content: " ";
  background: url("../i/sprite.png") no-repeat -890px -80px;
  position: absolute;
}
input[type=checkbox].styled + label:before {
  width: 28px;
  height: 27px;
  border: none;
  left: 0;
  top: 0;
}
input[type=checkbox].styled + label:after {
  display: none;
  width: 16px;
  height: 11px;
  background-position: -740px -80px;
  top: 8px;
  left: 6px;
}
input[type=checkbox].styled:checked + label:after {
  display: block;
  -webkit-opacity: 1;
  opacity: 1;
  filter: alpha(opacity=100);
}
@media screen and (min-width: 1000px) {
  input[type=checkbox].styled + label:hover:after {
    display: block;
    -webkit-opacity: 0.5;
    opacity: 0.5;
    filter: alpha(opacity=50);
  }
  input[type=checkbox].styled:checked + label:after {
    display: block;
    -webkit-opacity: 1;
    opacity: 1;
    filter: alpha(opacity=100);
  }
}
@media screen and (min-width: 768px) and (max-width: 999px) {
  .button_form {
    max-width: 100%;
    padding: 14px 20px 12px 20px;
    font-size: 10px;
    line-height: 14px;
    letter-spacing: 0.12em;
  }
  .input input[type=text],
  .input input[type=email],
  .input input[type=tel],
  .input input[type=password],
  .input textarea {
    margin-bottom: 12px;
    padding: 11px 16px;
    font-size: 10px;
    line-height: 18px;
  }
  input[type=checkbox].styled + label {
    padding: 5px 0 3px 30px;
    font-size: 11px;
    line-height: 14px;
  }
  input[type=checkbox].styled + label:before {
    width: 22px;
    height: 22px;
    background-position: -860px -80px;
  }
  input[type=checkbox].styled + label:after {
    width: 13px;
    height: 9px;
    background-position: -760px -80px;
    top: 7px;
    left: 4px;
  }
}
@media screen and (max-width: 767px) {
  input[type=checkbox].styled + label {
    font-size: 12px;
  }
}
/*
 --- POPUP --------------------------------------
*/
.popup {
  width: 460px;
  max-width: 100%;
  padding: 1px;
  border: 1px solid #f3f3f3;
  background: #ffffff;
  color: #333333;
  font-size: 15px;
  line-height: 20px;
  position: absolute;
  top: -1000px;
  left: 50%;
  z-index: 11;
  -webkit-transition: all 1s;
  -moz-transition: all 1s;
  -ms-transition: all 1s;
  -o-transition: all 1s;
  transition: all 1s;
  -webkit-transform: translate(-50%, 0);
  -moz-transform: translate(-50%, 0);
  -ms-transform: translate(-50%, 0);
  -o-transform: translate(-50%, 0);
  transform: translate(-50%, 0);
}
.popup:after {
  display: block;
  content: " ";
  height: 6px;
  background: #63b5b2;
  position: absolute;
  right: 0;
  bottom: -6px;
  left: 0;
}
.popup__pretitle {
  padding: 15px 30px;
  background: #e0eded;
  font-size: 15px;
  line-height: 22px;
  font-weight: 700;
}
.popup__wrap {
  padding: 30px;
}
.popup__title {
  color: #232323;
  font-size: 25px;
  line-height: 40px;
}
.popup__subtitle {
  margin-bottom: 20px;
}
.popup__form {
  width: 190px;
}
.popup__form__field {
  margin-bottom: 25px;
}
.popup__form__field label {
  display: block;
  margin-bottom: 10px;
}
.popup__form__field select {
  display: block;
  width: 100%;
  padding: 11px 36px 12px 12px;
  border: 1px solid #f3f3f3;
  background: #ffffff url("../i/select.png") no-repeat 100% 50%;
  font-size: 12px;
  line-height: 12px;
  font-family: inherit;
  position: relative;
}
.popup__form__note {
  margin-bottom: 25px;
  color: #a5a5a5;
  font-size: 12px;
  line-height: 18px;
}
.popup__form__note.important {
  padding-left: 30px;
  position: relative;
}
.popup__form__note.important:before,
.popup__form__note.important:after {
  display: block;
  width: 18px;
  height: 18px;
  position: absolute;
  top: 0;
  left: 0;
}
.popup__form__note.important:before {
  content: " ";
  background: #63b5b2;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
}
.popup__form__note.important:after {
  content: "!";
  color: #ffffff;
  font-size: 14px;
  line-height: 20px;
  font-weight: 900;
  text-align: center;
}
.popup__form__submit {
  padding-top: 10px;
}
.popup__close {
  position: absolute;
  top: 21px;
  right: 16px;
  cursor: pointer;
}
.popup.upload {
  width: 100%;
  max-width: 950px;
  padding: 0;
  border: none;
  border-top: 7px solid #f3f3f3;
}
.popup.upload:after {
  display: none;
}
@media screen and (max-width: 767px) {
  .popup__close {
    top: 13px;
  }
}
.upload__title {
  margin: 10px auto 35px auto;
}
.upload__steps {
  display: flex;
  justify-content: space-around;
  max-width: 516px;
  margin: 0 auto 20px auto;
}
.upload__steps__one {
  width: 90px;
  font-size: 13px;
  line-height: 14px;
  text-align: center;
}
.upload__steps__one span {
  display: block;
  height: 73px;
  margin-bottom: 5px;
  font-size: 25px;
  line-height: 80px;
  font-weight: 200;
}
.upload__steps__one.active span {
  color: #63b5b2;
  font-weight: 900;
}
.upload__form {
  padding: 40px 20px 50px 20px;
  border-top: 1px solid #232323;
}
.upload__form__list {
  display: flex;
  justify-content: space-between;
  width: 100%;
  max-width: 650px;
  margin: auto;
  padding-top: 35px;
  font-size: 11px;
  line-height: 15px;
  font-weight: 900;
  text-align: center;
  letter-spacing: 0.05em;
}
.upload__form__list a,
.upload__form__list .link {
  display: block;
  color: inherit;
  text-decoration: underline;
}
.upload__form__list a:hover,
.upload__form__list .link:hover {
  color: inherit;
  text-decoration: none;
}
.upload__learnmore {
  padding: 35px 20px;
  border-top: 1px solid #232323;
  font-size: 14px;
  line-height: 18px;
  text-align: center;
  font-style: italic;
  font-family: "Times New Roman", serif;
}
.upload__learnmore a {
  text-decoration: underline;
}
/* styles */
html,
body {
  background: #ffffff;
  color: #050708;
  font-size: 16px;
  line-height: 29px;
  font-weight: 400;
  font-family: "Circe", Tahoma, Arial, Verdana, Helvetica, sans-serif;
}
a,
.link {
  color: #63b5b2;
  text-decoration: none;
  cursor: pointer;
}
.title {
  font-family: "CircusDidot", Georgia, serif;
}
.page {
  display: flex;
  flex-direction: column;
  min-width: 320px;
  min-height: 100%;
  overflow: hidden;
}
.page.bg {
  background: #f3f3f3;
}
.page > * {
  flex: 0 0 auto;
}
.page .flexfill {
  flex: 1 0 auto;
}
.wrap {
  display: flex;
  width: 100%;
  max-width: 1280px;
  margin: auto;
  padding: 0 15px;
}
.textlinks a,
.textlinks .link {
  color: inherit;
}
.textlinks a:hover,
.textlinks .link:hover {
  color: #63b5b2;
  text-decoration: none;
}
.textlinks a.colored,
.textlinks .link.colored {
  color: #63b5b2;
}
.textlinks a.colored:hover,
.textlinks .link.colored:hover {
  color: inherit;
}
.colored {
  color: #63b5b2;
}
a.banner img,
.banner a img,
.banner a {
  display: block;
  margin: 0;
  padding: 0;
}
.topbanner {
  height: 100%;
  max-height: 30px;
  position: relative;
  overflow: hidden;
  -webkit-transition: all 1s;
  -moz-transition: all 1s;
  -ms-transition: all 1s;
  -o-transition: all 1s;
  transition: all 1s;
}
.topbanner.active {
  max-height: 1000px;
}
.topbanner__arrow {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  cursor: pointer;
}
.topbanner__arrow .icon {
  margin: auto;
  position: absolute;
  right: 0;
  bottom: 8px;
  left: 0;
  -webkit-transition: all 1s;
  -moz-transition: all 1s;
  -ms-transition: all 1s;
  -o-transition: all 1s;
  transition: all 1s;
}
.sitepanel {
  padding: 5px 0;
  border-bottom: 1px solid #ffffff;
  background: #232323;
  color: #ffffff;
  font-size: 10px;
  line-height: 15px;
  position: relative;
  z-index: 2;
}
.sitepanel__settings {
  display: flex;
  justify-content: space-between;
  width: 24%;
  min-width: 340px;
}
.sitepanel__settings a:hover,
.sitepanel__settings .link:hover {
  color: inherit;
  text-decoration: none;
}
.sitepanel__settings__geo,
.sitepanel__settings__lang {
  display: flex;
}
.sitepanel__settings__one {
  margin: 0 -5px;
  padding: 0 5px;
  position: relative;
}
.sitepanel__settings__one:first-child {
  padding-left: 0;
}
.sitepanel__settings__one:last-child {
  padding-right: 0;
}
.sitepanel__settings__one > a,
.sitepanel__settings__one > .link {
  margin: 0 5px;
  text-decoration: underline;
}
.sitepanel__settings__one > a:hover,
.sitepanel__settings__one > .link:hover {
  text-decoration: underline;
}
.sitepanel__settings__one__dropdown {
  max-height: 0;
  margin-top: -1px;
  padding-top: 8px;
  font-size: 12px;
  line-height: 14px;
  position: absolute;
  top: 100%;
  left: -5px;
  z-index: 3;
  overflow: hidden;
  -webkit-transition: all 1s;
  -moz-transition: all 1s;
  -ms-transition: all 1s;
  -o-transition: all 1s;
  transition: all 1s;
}
.sitepanel__settings__one__dropdown ul,
.sitepanel__settings__one__dropdown__wrap {
  width: 120px;
  padding: 10px;
  background: #cfcece;
  color: #232323;
  position: relative;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px;
}
.sitepanel__settings__one__dropdown ul:before,
.sitepanel__settings__one__dropdown__wrap:before {
  display: block;
  content: " ";
  width: 0;
  height: 0;
  border: 5px solid transparent;
  border-bottom-color: #cfcece;
  position: absolute;
  top: 0;
  left: 15px;
  -webkit-transition: all 1s;
  -moz-transition: all 1s;
  -ms-transition: all 1s;
  -o-transition: all 1s;
  transition: all 1s;
}
.sitepanel__settings__one__dropdown__wrap {
  width: 170px;
  padding: 10px 15px;
}
.sitepanel__settings__one__dropdown ul li {
  margin: 0 -10px;
  padding: 3px 0;
}
.sitepanel__settings__one__dropdown ul li a {
  display: block;
  padding: 1px 10px 0 20px;
  color: #232323;
  text-decoration: none;
}
.sitepanel__settings__one__dropdown ul li a:hover {
  background: #f3f3f3;
  color: #232323;
  text-decoration: none;
}
.sitepanel__settings__one__dropdown ul li a:before {
  margin: 2px 8px 0 -10px;
  vertical-align: top;
}
.sitepanel__settings__one:hover .sitepanel__settings__one__dropdown {
  max-height: 300px;
}
.sitepanel__settings__one:hover .sitepanel__settings__one__dropdown ul:before,
.sitepanel__settings__one:hover .sitepanel__settings__one__dropdown__wrap:before {
  top: -10px;
}
.sitepanel__promo {
  flex: 1 1 auto;
  color: #63b5b2;
  font-size: 13px;
  line-height: 15px;
  text-align: center;
}
.sitepanel__promo strong {
  font-weight: 900;
}
.sitepanel__promo__more {
  display: inline-block;
  margin: -3px 0 0 7px;
  vertical-align: middle;
  font-size: 9px;
  text-decoration: underline;
  cursor: pointer;
}
.sitepanel__promo__more:hover {
  text-decoration: none;
}
.sitepanel__promo__more .icon {
  margin-left: 18px;
  -webkit-transition: all 1s;
  -moz-transition: all 1s;
  -ms-transition: all 1s;
  -o-transition: all 1s;
  transition: all 1s;
}
.sitepanel__promo__offer {
  max-height: 0;
  position: absolute;
  top: 100%;
  left: 50%;
  overflow: hidden;
  -webkit-transform: translate(-50%, 0);
  -moz-transform: translate(-50%, 0);
  -ms-transform: translate(-50%, 0);
  -o-transform: translate(-50%, 0);
  transform: translate(-50%, 0);
  -webkit-transition: all 1s;
  -moz-transition: all 1s;
  -ms-transition: all 1s;
  -o-transition: all 1s;
  transition: all 1s;
}
.sitepanel__promo__offer a {
  display: inline-block;
  max-width: 100%;
}
.sitepanel__promo__offer a img {
  display: block;
  max-width: 100%;
  height: auto;
  margin: auto;
}
.sitepanel__promo__offer.active {
  max-height: 800px;
}
.sitepanel__phone {
  width: 190px;
  font-size: 11px;
  text-align: right;
}
.sitepanel__phone a {
  margin-left: 11px;
}
.userpanel {
  background: #f3f3f3;
  z-index: 1;
}
.userpanel .wrap {
  justify-content: space-between;
  position: relative;
}
.userpanel__block {
  display: flex;
  position: relative;
}
.userpanel__block__one,
.userpanel__block a,
.userpanel__block .link {
  padding: 13px 0;
  color: #050000;
  font-size: 12px;
  line-height: 18px;
  font-weight: 700;
  text-decoration: none;
  position: relative;
}
.userpanel__block__one:hover,
.userpanel__block a:hover,
.userpanel__block .link:hover {
  color: #63b5b2;
  text-decoration: none;
}
.userpanel__block__one + .userpanel__block__one,
.userpanel__block__one + .userpanel__block a,
.userpanel__block__one + .userpanel__block .link,
.userpanel__block a + .userpanel__block__one,
.userpanel__block a + .userpanel__block a,
.userpanel__block a + .userpanel__block .link,
.userpanel__block .link + .userpanel__block__one,
.userpanel__block .link + .userpanel__block a,
.userpanel__block .link + .userpanel__block .link {
  margin-left: 30px;
}
.userpanel__block__one span.iconed,
.userpanel__block a span.iconed,
.userpanel__block .link span.iconed {
  color: #63b5b2;
  font-size: 11px;
  line-height: 14px;
  font-weight: 700;
  font-style: italic;
  font-family: Arial, sans-serif;
}
.userpanel__block__one .icon,
.userpanel__block a .icon,
.userpanel__block .link .icon,
.userpanel__block__one .iconed:before,
.userpanel__block a .iconed:before,
.userpanel__block .link .iconed:before {
  margin-left: 2px;
  vertical-align: middle;
}
.userpanel__block__info {
  max-height: 0;
  padding: 0 15px;
  border: 1px solid transparent;
  background: transparent;
  font-size: 11px;
  line-height: 16px;
  position: absolute;
  top: 100%;
  left: -5px;
  overflow: hidden;
  -webkit-transition: all 1s;
  -moz-transition: all 1s;
  -ms-transition: all 1s;
  -o-transition: all 1s;
  transition: all 1s;
}
.hover .userpanel__block__info {
  max-height: 200px;
  margin-top: -2px;
  padding: 8px 15px;
  border-color: #f3f3f3;
  background: #ffffff;
}
.userpanel__block__info ul li {
  padding: 4px 0;
}
.userpanel__block__info ul li a {
  color: #888888;
  font-weight: 400;
  white-space: nowrap;
}
.userpanel__block__info ul li a:hover {
  color: #63b5b2;
}
.userpanel__block__dropdown {
  display: block;
  width: 320px;
  max-height: 0;
  margin-top: -1px;
  position: absolute;
  top: 100%;
  left: 15px;
  z-index: 2;
  overflow: hidden;
  -webkit-transition: all 1s;
  -moz-transition: all 1s;
  -ms-transition: all 1s;
  -o-transition: all 1s;
  transition: all 1s;
}
.active .userpanel__block__dropdown {
  max-height: 500px;
}
.userpanel__block__dropdown__wrap {
  padding: 15px 25px 30px 25px;
  border: 1px solid #f3f3f3;
  background: #ffffff;
}
.userpanel__block__dropdown__title {
  margin-bottom: 5px;
  color: #63b5b2;
  font-size: 35px;
  line-height: 40px;
  font-weight: 200;
  text-align: center;
}
.userpanel__block__dropdown__button {
  margin-bottom: 15px;
}
.userpanel__block__dropdown__button a {
  display: block;
  padding: 19px 10px;
  background: #63b5b2;
  color: #ffffff;
  font-size: 10px;
  line-height: 14px;
  letter-spacing: 0.04em;
  text-align: center;
  -webkit-transition: all 1s;
  -moz-transition: all 1s;
  -ms-transition: all 1s;
  -o-transition: all 1s;
  transition: all 1s;
}
.userpanel__block__dropdown__button a:hover {
  color: #ffffff;
  background: #86c5c3;
}
.userpanel__block__dropdown__nav {
  margin-bottom: 20px;
}
.userpanel__block__dropdown__nav ul li {
  padding: 10px 0;
  font-size: 10px;
  line-height: 14px;
  text-align: center;
}
.userpanel__block__dropdown__nav ul li a {
  padding: 0;
  font-size: 10px;
  line-height: 14px;
}
.userpanel__block__dropdown__nav ul li a:after {
  margin-left: 5px;
  vertical-align: middle;
}
.userpanel__block__dropdown__contactus {
  margin-bottom: 10px;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0.08em;
  text-align: center;
}
.userpanel__block__dropdown__contactus__shedule {
  margin-top: 5px;
  color: #63b5b2;
  font-size: 11px;
  line-height: 14px;
  letter-spacing: normal;
}
.userpanel__block__dropdown__buttons ul li {
  padding-top: 1px;
}
.userpanel__block__dropdown__buttons ul li a {
  display: block;
  padding: 19px;
  background: #f3f3f3;
  color: #050708;
  font-size: 11px;
  line-height: 14px;
  text-align: center;
  position: relative;
}
.userpanel__block__dropdown__buttons ul li a:before {
  position: absolute;
  top: 12px;
  left: 13px;
}
.userpanel__block__dropdown__close {
  position: absolute;
  top: 9px;
  right: 9px;
  cursor: pointer;
}
.carousel {
  display: flex;
  text-align: center;
}
.carousel__item {
  flex: 0 0 135px;
  margin: 0 18px;
}
.carousel__item a {
  display: flex;
  flex-direction: column;
}
.carousel__item a img {
  display: block;
  max-width: 100%;
  height: auto;
  margin: auto;
}
.carousel__item__brand {
  margin-bottom: 10px;
  font-size: 10px;
  line-height: 14px;
  font-weight: 700;
  text-align: center;
  text-transform: uppercase;
}
.carousel__item__name {
  flex: 1 0 auto;
  margin-bottom: 10px;
  font-size: 11px;
  line-height: 14px;
  text-align: center;
}
.carousel__item__price {
  height: 36px;
  font-size: 11px;
  line-height: 16px;
  font-weight: 700;
  text-align: center;
}
.carousel__item__price .old {
  display: block;
  color: #7d7d7d;
  text-decoration: line-through;
}
.infoblock {
  font-size: 16px;
  line-height: 18px;
  text-align: center;
}
.infoblock__title {
  margin-bottom: 20px;
  font-size: 35px;
  line-height: 40px;
}
.infoblock__descr {
  margin-bottom: 20px;
}
.infoblock__button {
  margin-top: 15px;
}
.header {
  margin-bottom: 24px;
  background: #ffffff;
}
.header__welcome {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 24px 0 32px 33.3333%;
}
.header__welcome__logo {
  width: 50%;
  text-align: center;
}
.header__welcome__logo img {
  display: block;
  margin: auto;
}
.header__welcome__text {
  color: #9e9f9f;
  font-size: 12px;
  line-height: 14px;
  font-weight: 700;
}
.header__welcome__text a {
  margin-left: 6px;
}
.header__navbar {
  background: #e0eded;
  font-size: 11px;
  line-height: 14px;
  font-weight: 700;
  text-transform: uppercase;
  position: relative;
}
.header__navbar__nav {
  flex: 1 0 auto;
}
.header__navbar__nav > ul {
  display: flex;
}
.header__navbar__nav > ul > li {
  flex: 1 0 auto;
}
.header__navbar__nav > ul > li > a {
  display: block;
  padding: 14px 0 13px 0;
  color: #000000;
  font-size: 11px;
  line-height: 14px;
  font-weight: 700;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
}
.header__navbar__nav > ul > li:hover > a {
  background: #ffffff;
  color: #000000;
}
.header__navbar__search {
  width: 190px;
  background: #ffffff;
  position: relative;
}
.header__navbar__search input {
  display: block;
  float: right;
  width: 100%;
  height: 40px;
  padding: 9px 61px 9px 21px;
  border: 4px solid #ffffff;
  background-color: #ffffff;
  color: #63b5b2;
  font-size: 12px;
  line-height: 14px;
  font-weight: 700;
  text-align: left;
  font-style: normal;
  text-transform: uppercase;
  font-family: "Circe", sans-serif;
  -webkit-transition: all 1s;
  -moz-transition: all 1s;
  -ms-transition: all 1s;
  -o-transition: all 1s;
  transition: all 1s;
}
.header__navbar__search input::-webkit-input-placeholder {
  color: #e0eded;
}
.header__navbar__search input::-moz-placeholder {
  color: #e0eded;
}
.header__navbar__search input:-moz-placeholder {
  color: #e0eded;
}
.header__navbar__search input:-ms-input-placeholder {
  color: #e0eded;
}
.header__navbar__search input.icon {
  width: 28px;
  height: 23px;
  padding: 0;
  border: none;
  position: absolute;
  top: 8px;
  right: 20px;
  cursor: pointer;
}
.header__navbar__search input.active {
  width: 350px;
  height: 48px;
  margin-top: -4px;
  margin-bottom: -4px;
  padding-top: 13px;
  padding-bottom: 13px;
  border-color: #e0eded;
}
.header__navbar__dropdown {
  display: none;
  background: #ffffff;
  font-weight: 400;
  text-transform: none;
  position: absolute;
  top: 100%;
  right: 0;
  left: 0;
  z-index: 5;
  overflow: hidden;
}
.header__navbar__dropdown:after {
  display: block;
  content: " ";
  height: 6px;
  background: #e0eded;
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
}
li.hover > .header__navbar__dropdown {
  display: block;
  z-index: 6;
}
.header__navbar__dropdown .wrap {
  padding-top: 30px;
  padding-bottom: 36px;
  justify-content: space-between;
}
.header__navbar__dropdown .wrap:after {
  display: block;
  content: " ";
}
.header__navbar__dropdown .wrap.alternate {
  justify-content: space-between;
}
.header__navbar__dropdown .wrap.alternate:after {
  display: none;
}
.header__navbar__dropdown__title {
  margin-bottom: 10px;
  color: #000000;
  font-size: 12px;
  line-height: 14px;
  font-weight: 700;
  text-transform: uppercase;
}
ul + .header__navbar__dropdown__title {
  margin-top: 20px;
}
.header__navbar__dropdown__categories {
  padding-left: 20px;
  flex: 0 0 174px;
  font-size: 12px;
  line-height: 20px;
}
.header__navbar__dropdown ul li {
  padding: 5px 0;
}
.header__navbar__dropdown ul li.header__navbar__dropdown__title {
  margin-bottom: 10px;
  padding: 0;
}
.header__navbar__dropdown ul li.divider {
  margin: 15px 0;
  background: url("../i/nav-divider.png") repeat-x 0 50%;
}
.header__navbar__dropdown__items {
  display: flex;
  justify-content: space-around;
  flex: 1 0 auto;
  max-width: 764px;
  color: #7d7d7d;
  text-align: center;
}
.header__navbar__dropdown__items__one {
  max-width: 302px;
}
.header__navbar__dropdown__items__one .title {
  color: #000000;
}
.header__navbar__dropdown__items__one:hover .title {
  color: inherit;
}
.header__navbar__dropdown__blocks {
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex: 1 0 auto;
  padding: 0 35px;
}
.header__navbar__dropdown__block {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 0 1 auto;
  max-width: 285px;
  margin: 0 10px;
  padding-bottom: 20px;
  position: relative;
}
.header__navbar__dropdown__block__button {
  max-width: 206px;
  margin: auto;
  padding: 13px 10px;
  background: #63b5b2;
  color: #ffffff;
  font-size: 10px;
  line-height: 16px;
  text-align: center;
  letter-spacing: 0.08em;
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
}
.header__navbar__dropdown__block__wrap {
  display: flex;
  max-width: 215px;
  flex-direction: column;
  justify-content: center;
  text-align: center;
}
.header__navbar__dropdown__block__title {
  margin-bottom: 10px;
  font-size: 25px;
  line-height: 25px;
}
.header__navbar__dropdown__block__text {
  font-size: 11px;
  line-height: 20px;
  letter-spacing: 0.08em;
}
.header__navbar__dropdown__featureditem {
  max-width: 302px;
  margin: 0 15px;
  text-align: center;
}
.header__navbar__dropdown__list {
  display: flex;
  justify-content: space-between;
}
.header__navbar__dropdown__list__one {
  width: 150px;
  margin: 0 20px;
}
.header__navbar__dropdown__list__one.alternate {
  width: 210px;
}
.header__navbar__dropdown__list__one__cards {
  margin: 0 0 0 -7px;
}
.header__navbar__dropdown__list__one__cards img {
  margin: 0 0 10px 7px;
}
.header__navbar__dropdown .whatandhow__items {
  display: flex;
}
.footer {
  padding: 40px 0 25px 0;
  background: #232323;
  color: #ffffff;
}
.footer__nav {
  justify-content: space-between;
  font-size: 12px;
  line-height: 16px;
}
.footer__nav__section {
  padding: 0 15px;
  text-align: center;
}
.footer__nav__section .parent {
  display: block;
  margin-bottom: 15px;
  font-weight: 700;
  text-transform: uppercase;
}
.footer__nav__section ul li {
  margin-top: 2px;
}
.footer__links {
  margin-top: 25px;
  padding-top: 10px;
  border-top: 1px solid #333333;
  font-size: 9px;
  line-height: 16px;
  font-weight: 700;
  text-transform: uppercase;
}
.footer__links .wrap {
  justify-content: space-around;
}
.footer__links__one {
  text-align: center;
}
.footer__links__one .icon {
  display: block;
  margin: 0 auto 2px auto;
}
.footer-payments {
  padding: 10px 0 25px 0;
  justify-content: space-between;
  align-items: center;
  font-size: 12px;
  line-height: 18px;
  text-align: center;
}
.footer-payments a,
.footer-payments a:hover {
  text-decoration: underline;
}
.footer-payments__secure {
  margin-right: 40px;
  position: relative;
}
.footer-payments__secure:after {
  display: block;
  content: " ";
  width: 1px;
  height: 30px;
  background: #232323;
  position: absolute;
  right: -40px;
  bottom: 0;
}
.footer-payments__text {
  margin-top: 15px;
  padding: 0 40px;
}
.footer-payments__methods {
  margin: 15px 0 0 40px;
  position: relative;
}
.footer-payments__methods:after {
  display: block;
  content: " ";
  width: 1px;
  height: 30px;
  background: #232323;
  position: absolute;
  left: -40px;
  bottom: 0;
}
#fader {
  display: none;
  background: rgba(255, 255, 255, 0.72);
  position: fixed;
  top: -100px;
  right: 0;
  bottom: -100px;
  left: 0;
  z-index: 10;
}
#fader.alternate {
  background: rgba(0, 0, 0, 0.51);
}
.owl-stage {
  display: flex;
  max-width: 100%;
}
.owl-stage-outer {
  overflow: hidden;
}
.owl-item {
  display: flex;
}
.owl-dots {
  display: none;
}
.owl-nav {
  position: absolute;
  top: 50%;
  right: 0;
  left: 0;
}
.owl-prev,
.owl-next {
  width: 37px;
  height: 68px;
  position: absolute;
  left: 0;
  cursor: pointer;
}
.owl-prev:before,
.owl-next:before {
  display: block;
  content: " ";
  width: 17px;
  height: 48px;
  margin: -24px 0 0 -9px;
  background: url("../i/sprite.png") no-repeat -60px -40px;
  position: absolute;
  top: 50%;
  left: 50%;
}
.owl-prev.disabled,
.owl-next.disabled {
  -webkit-opacity: 0.25;
  opacity: 0.25;
  filter: alpha(opacity=25);
  cursor: default;
}
.owl-next {
  right: 0;
  left: auto;
}
.owl-next:before {
  margin-left: -8px;
  background-position: -80px -40px;
}
.indexblock {
  display: flex;
  width: 100%;
  margin-bottom: 40px;
  background: #f3f3f3;
}
.indexblock__summary {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 20px;
  padding: 10px 0;
  background: #ffffff;
  text-align: center;
}
.indexblock__summary__statistic {
  display: flex;
  justify-content: space-between;
  margin-bottom: 25px;
  padding-bottom: 25px;
  position: relative;
}
.indexblock__summary__statistic:before {
  display: block;
  content: " ";
  width: 60px;
  height: 1px;
  margin-left: -30px;
  background: #514d4e;
  position: absolute;
  bottom: 0;
  left: 50%;
}
.indexblock__summary__statistic__one {
  flex: 30%;
  font-size: 11px;
  line-height: 13px;
}
.indexblock__summary__statistic__one span {
  display: block;
  font-size: 22px;
  line-height: 26px;
  font-weight: 300;
}
.indexblock__summary__title {
  margin-bottom: 25px;
  font-size: 33px;
  line-height: 40px;
}
.indexblock__summary__items {
  width: 290px;
  margin-right: auto;
  margin-left: auto;
}
.indexblock__summary__items .carousel__item {
  margin: 0 5px;
}
.indexblock__banner {
  flex: 1 1 50%;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  -webkit-background-size: cover;
  background-size: cover;
  overflow: hidden;
}
.indexblock__banner a {
  display: block;
  width: 100%;
  height: 100%;
}
.indexblock__text {
  display: flex;
  flex: 1 0 25%;
  flex-direction: column;
  justify-content: center;
  padding: 15px;
}
.advantages {
  margin-bottom: 40px;
  padding: 15px 0;
  background: #e0eded;
  font-size: 11px;
  line-height: 18px;
}
.advantages .wrap {
  justify-content: space-between;
}
.advantages__one {
  max-width: 240px;
  position: relative;
}
.advantages__one_1 {
  padding-left: 31px;
}
.advantages__one_2 {
  padding-left: 26px;
}
.advantages__one_3 {
  max-width: 300px;
  padding-left: 22px;
}
.advantages__one_4 {
  padding-left: 32px;
}
.advantages__one__title {
  margin-bottom: 3px;
  font-weight: 700;
}
.advantages__one__links a:after {
  display: inline-block;
  content: " ";
  width: 4px;
  height: 6px;
  margin: -1px 0 0 5px;
  background: url("../i/sprite.png") no-repeat -50px -50px;
  vertical-align: middle;
}
.advantages__one__links a.alternate {
  display: inline-block;
  margin-top: 5px;
  font-weight: 700;
}
.advantages__one__links a.alternate:after {
  width: 4px;
  height: 8px;
  margin-top: -3px;
  background-position: -50px -40px;
}
.actual {
  margin-bottom: 60px;
  padding: 20px 0 75px 0;
  background: #f3f3f3;
}
.actual .wrap {
  flex-direction: column;
}
.actual__title {
  font-size: 50px;
  line-height: 60px;
  text-align: center;
}
.actual__text {
  font-size: 14px;
  line-height: 18px;
  text-align: center;
  letter-spacing: 0.08em;
}
.actual__carousel {
  display: flex;
  width: 100%;
  max-width: 1086px;
  margin: 25px auto 0 auto;
  padding: 0 60px;
  font-size: 11px;
  line-height: 16px;
  text-align: center;
  position: relative;
}
.actual__carousel__one {
  flex: 0 0 33.3333%;
  width: 33.3333%;
  height: 100%;
  padding: 0 10px;
}
.owl-item > .actual__carousel__one {
  flex: 0 0 100%;
  max-width: 302px;
  margin: auto;
}
.actual__carousel__one a {
  display: flex;
  flex-direction: column;
  height: 100%;
  background: #ffffff;
}
.actual__carousel__one a img {
  display: block;
  width: 100%;
  height: auto;
  margin: 0;
  padding: 0;
}
.actual__carousel__one__info {
  display: flex;
  flex: 1 0 auto;
  flex-direction: column;
  align-items: center;
  padding: 10px 20px 15px 20px;
}
.actual__carousel__one__title {
  display: block;
  margin-bottom: 5px;
  font-size: 25px;
  line-height: 30px;
}
.actual__carousel__one__text {
  display: flex;
  flex: 1 0 auto;
  align-items: center;
  padding: 0 20px;
}
.actual .owl-item {
  flex: 0 0 33.33%;
  width: 33.33%;
}
.actual .owl-nav {
  margin-top: -24px;
}
.actual .owl-prev {
  left: 10px;
}
.actual .owl-next {
  right: 10px;
}
.mixedblock {
  margin-bottom: 48px;
}
.mixedblock .wrap {
  flex-direction: column;
}
.mixedblock .bestsellers,
.mixedblock .lastchance {
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  justify-content: center;
  text-align: center;
}
.mixedblock .bestsellers__title,
.mixedblock .lastchance__title {
  margin-bottom: 25px;
  font-size: 50px;
  line-height: 60px;
}
.mixedblock .bestsellers__categories,
.mixedblock .lastchance__categories {
  margin-bottom: 50px;
  font-size: 12px;
  line-height: 16px;
  font-weight: 700;
  text-transform: uppercase;
}
.mixedblock .bestsellers__categories a,
.mixedblock .lastchance__categories a {
  margin: 0 20px;
  white-space: nowrap;
}
.mixedblock .bestsellers__categories a:after,
.mixedblock .lastchance__categories a:after {
  vertical-align: middle;
}
.mixedblock .bestsellers__items__wrap,
.mixedblock .lastchance__items__wrap {
  width: 825px;
  margin: auto;
  position: relative;
}
.mixedblock .bestsellers .owl-item,
.mixedblock .lastchance .owl-item {
  flex: 0 0 165px;
  width: 165px;
}
.mixedblock .bestsellers .owl-nav,
.mixedblock .lastchance .owl-nav {
  top: 62px;
}
.mixedblock .bestsellers .owl-prev,
.mixedblock .lastchance .owl-prev {
  left: -40px;
}
.mixedblock .bestsellers .owl-next,
.mixedblock .lastchance .owl-next {
  right: -40px;
}
.likeme {
  display: flex;
  margin-bottom: 60px;
  padding: 15px 10px;
  background: #f3f3f3;
}
.likeme__text,
.likeme__one {
  display: flex;
  flex: 1 1 25%;
  flex-direction: column;
  justify-content: center;
  padding: 15px;
}
.likeme__one {
  display: flex;
}
.likeme__one img {
  display: block;
  max-width: 100%;
  height: auto;
  margin: auto;
}
.whatandhow {
  margin-bottom: 120px;
}
.whatandhow__title {
  margin-bottom: 35px;
  font-size: 50px;
  line-height: 60px;
  text-align: center;
}
.whatandhow__gray {
  padding: 20px 0 30px 0;
  background: #f3f3f3;
}
.whatandhow__gray .wrap {
  flex-direction: column;
}
.whatandhow__switcher {
  display: flex;
  justify-content: space-between;
  width: 610px;
  margin: auto;
}
.whatandhow__switcher__one {
  position: relative;
  cursor: pointer;
}
.whatandhow__switcher__one img {
  display: block;
}
.whatandhow__switcher__one.active:before {
  display: block;
  content: " ";
  height: 7px;
  margin-top: 23px;
  background: #000000;
  position: absolute;
  top: 100%;
  right: 0;
  left: 0;
}
.whatandhow__switcher__one.active:after {
  display: block;
  content: " ";
  width: 0;
  height: 0;
  margin: 30px 0 0 -10px;
  border: 10px solid transparent;
  border-top-color: #000000;
  position: absolute;
  top: 100%;
  left: 50%;
}
.whatandhow__items {
  display: none;
  width: 100%;
  max-width: 960px;
  margin: auto;
  padding-top: 15px;
  font-size: 13px;
  line-height: 17px;
}
.whatandhow__items.active {
  display: flex;
}
.whatandhow__items__one {
  display: flex;
  flex: 0 0 25%;
  padding: 0 18px;
}
.whatandhow__items__one a {
  display: block;
}
.whatandhow__items__one a img {
  display: block;
  width: 100%;
  height: auto;
  margin-bottom: 25px;
}
.whatandhow__items__one__brand {
  display: block;
  margin-bottom: 5px;
  font-size: 12px;
  line-height: 16px;
  font-weight: 700;
  text-transform: uppercase;
}
.whatandhow__items__one__name {
  display: block;
}
.whatandhow__items__one__price {
  font-weight: 700;
}
.categories {
  flex-direction: column;
  margin-bottom: 50px;
  text-align: center;
}
.categories__title {
  font-size: 50px;
  line-height: 60px;
}
.categories__descr {
  font-size: 16px;
  line-height: 24px;
}
.categories__list {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  margin: 0 -23px;
  font-size: 13px;
  line-height: 22px;
  font-weight: 700;
  text-transform: uppercase;
}
.categories__list__one {
  margin: 60px 20px 10px 20px;
  max-width: 145px;
}
.categories__list__one a {
  display: block;
  width: 100%;
  background-repeat: no-repeat;
  -webkit-background-size: cover;
  background-size: cover;
  overflow: hidden;
}
.categories__list__one a img {
  display: block;
  margin-bottom: 25px;
}
.categories__list__one.double {
  max-width: 330px;
}
.about {
  flex-direction: column;
  padding-top: 55px;
  padding-bottom: 55px;
}
.about__logo {
  margin-bottom: 60px;
  font-size: 13px;
  line-height: 16px;
  font-weight: 700;
  text-align: center;
  letter-spacing: 0.05em;
  text-transform: uppercase;
}
.about__logo:before {
  display: block;
  content: " ";
  width: 255px;
  height: 31px;
  margin: 0 auto 10px auto;
  background: url("../i/sprite.png") no-repeat -340px 0;
}
.about__text {
  margin-bottom: 50px;
  font-size: 16px;
  line-height: 29px;
  text-align: center;
  letter-spacing: 0.05em;
}
.about__banner {
  margin-bottom: 65px;
}
.about__bottom + .about__banner {
  margin-top: 65px;
  margin-bottom: 0;
}
.about__banner a {
  display: block;
}
.about__banner a img {
  display: block;
  width: 100%;
  height: auto;
}
.about__bottom {
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.about__bottom__left,
.about__bottom__right {
  display: flex;
  justify-content: space-around;
  flex: 0 0 29%;
}
.about__bottom__logo {
  width: 116px;
  height: 21px;
  background: url("../i/sprite.png") no-repeat -600px 0;
}
.about__bottom__socials {
  display: flex;
  justify-content: space-between;
  flex: 0 0 34%;
}
.about__bottom__socials a {
  display: block;
}
.widgets {
  padding: 25px 0;
  background: #e0eded;
}
.widgets__wrap {
  padding: 35px 0;
  background: #ffffff;
}
.widgets__one {
  flex: 1 1 1px;
  margin: 0 20px;
}
.widgets__one__title {
  margin-bottom: 30px;
  font-size: 12px;
  line-height: 18px;
  font-weight: 700;
  text-align: center;
  letter-spacing: 0.08em;
  text-transform: uppercase;
}
.widgets__one__title .db {
  display: block;
}
.widgets__one__title .db .colored {
  font-size: 20px;
  line-height: 28px;
}
.widgets__one__title .db .colored span {
  font-size: 17px;
}
.widgets__one__title sup {
  margin-left: 3px;
  font-size: 1em;
  line-height: 1.5em;
  font-family: "Times New Roman", serif;
}
.widgets__one__divider {
  width: 45px;
  height: 1px;
  margin-right: auto;
  margin-left: auto;
  background: #000000;
}
.widgets__one_text {
  text-align: center;
}
.widgets__one_text__title {
  margin-bottom: 10px;
  padding-top: 45px;
  font-size: 12px;
  line-height: 25px;
  font-weight: 700;
  letter-spacing: 0.12em;
  text-transform: uppercase;
  position: relative;
}
.widgets__one_text__info {
  margin-bottom: 10px;
  color: #515555;
  font-size: 11px;
  line-height: 18px;
  letter-spacing: 0.08em;
}
.widgets__one_text__link a {
  color: #a5a5a5;
  font-size: 14px;
  line-height: 22px;
  font-style: italic;
  text-decoration: underline;
  font-family: "Times New Roman", serif;
}
.widgets__divider {
  flex: 0 0 90px;
  position: relative;
}
.widgets__divider:before {
  display: block;
  content: " ";
  width: 1px;
  background: #000000;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 50%;
}
.widgets__subscribe__form:after {
  display: block;
  content: " ";
  width: 93px;
  height: 33px;
  margin: 15px auto 0 auto;
  background: url("../i/sprite.png") no-repeat -700px -40px;
}
.widgets__subscribe__form__field {
  margin-top: 10px;
}
.widgets__subscribe__form__label {
  margin-top: 20px;
  font-size: 14px;
  line-height: 18px;
  font-style: italic;
  text-align: center;
  font-family: "Times New Roman", serif;
}
.widgets__subscribe__form__radios {
  display: flex;
  justify-content: space-between;
  margin-top: 25px;
}
.widgets__subscribe__form__radios input + label {
  flex: 0 0 auto;
  padding: 22px 0 0 0;
}
.widgets__subscribe__form__radios input + label:before {
  margin-left: -7px;
  left: 50%;
}
.widgets__subscribe__form__radios input + label:after {
  margin-left: -4px;
  left: 50%;
}
.widgets__subscribe__form__submit {
  margin-top: 35px;
}
.widgets__subscribe__form__note {
  margin-top: 20px;
  color: #d9dada;
  font-size: 14px;
  line-height: 18px;
  font-style: italic;
  text-align: center;
  font-family: "Times New Roman", serif;
}
.widgets__promo {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  text-align: center;
}
.widgets__promo:after {
  display: block;
  content: " ";
  width: 100%;
  height: 40px;
}
.widgets__promo__icon {
  margin: 20px auto 20px auto;
}
.widgets__promo__list {
  font-size: 12px;
  line-height: 25px;
  font-weight: 700;
  text-transform: uppercase;
}
.widgets__persons {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  text-align: center;
}
.widgets__persons .widgets__one__title {
  margin-bottom: 0;
}
.widgets__persons__list {
  width: 190px;
  margin-right: auto;
  margin-left: auto;
  padding-bottom: 40px;
  position: relative;
}
.owl-item > .widgets__persons__list__item {
  width: 100%;
}
.widgets__persons__list__item__userpic {
  margin-bottom: 50px;
}
.widgets__persons__list__item__userpic img {
  display: block;
  margin: auto;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
}
.widgets__persons__list__item__name {
  margin-bottom: 10px;
  font-size: 16px;
  line-height: 20px;
}
.widgets__persons__list__item__job {
  width: 150px;
  margin: 0 auto 25px auto;
  font-size: 14px;
  line-height: 18px;
  font-style: italic;
  font-family: "Times New Roman", serif;
}
.widgets__persons__list__item__social a {
  display: block;
  margin-right: auto;
  margin-left: auto;
}
.widgets__persons .owl-item {
  flex: 0 0 100%;
  width: 100%;
}
.widgets__persons .owl-nav {
  margin-top: -37px;
}
.widgets__persons .owl-prev,
.widgets__persons .owl-next {
  left: -37px;
}
.widgets__persons .owl-prev:before,
.widgets__persons .owl-next:before {
  width: 5px;
  height: 7px;
  margin: -4px 0 0 -3px;
  background-position: 0 -30px;
}
.widgets__persons .owl-next {
  right: -37px;
  left: auto;
}
.widgets__persons .owl-next:before {
  margin-left: -2px;
  background-position: -10px -30px;
}
.adnav {
  display: none;
}
.breadcrumbs {
  flex: 0 0 100%;
  margin-bottom: 24px;
  color: #9e9f9f;
  font-size: 14px;
  line-height: 16px;
  font-style: italic;
  font-family: "Times New Roman", serif;
}
.breadcrumbs a {
  color: #9e9f9f;
  text-decoration: none;
}
.pagination {
  font-size: 12px;
  line-height: 18px;
}
.pagination_right {
  text-align: right;
}
.pagination a,
.pagination span {
  display: inline-block;
  margin: 0 5px;
  vertical-align: top;
  color: #636363;
  text-decoration: none;
}
.pagination a.active,
.pagination span.active {
  color: #000000;
}
.pagination__prev,
.pagination__next {
  width: 8px;
  height: 11px;
  margin-top: 3px !important;
  background: url("../i/sprite.png") no-repeat;
}
.pagination__prev {
  background-position: -980px -80px;
}
.pagination__prev.disabled {
  background-position: -980px -100px;
}
.pagination__next {
  background-position: -990px -80px;
}
.pagination__next.disabled {
  background-position: -990px -100px;
}
.go2top {
  padding: 5px 0;
  border-top: 1px solid #9e9f9f;
  color: #9e9f9f;
  font-size: 14px;
  line-height: 18px;
  font-style: italic;
  text-align: center;
  font-family: "Times New Roman", serif;
}
.go2top a {
  color: #9e9f9f;
  text-decoration: underline;
}
@media screen and (min-width: 1250px) {
  .mixedblock .wrap {
    flex-direction: row;
  }
  .mixedblock .lastchance {
    flex: 0 0 300px;
    width: 300px;
    padding: 30px;
    background: #e0eded;
    text-align: center;
  }
  .mixedblock .lastchance__wrap {
    padding: 30px;
    background: #ffffff;
  }
  .mixedblock .lastchance__title {
    margin-bottom: 13px;
    font-size: 33px;
    line-height: 40px;
  }
  .mixedblock .lastchance__descr {
    margin-bottom: 22px;
    font-size: 14px;
    line-height: 26px;
  }
  .mixedblock .lastchance__items__wrap {
    width: 165px;
    margin: auto;
    position: relative;
  }
  .mixedblock .lastchance__items__one {
    margin: 0 15px;
  }
  .mixedblock .lastchance .owl-item {
    flex: 0 0 100%;
    width: 100%;
  }
  .mixedblock .lastchance .owl-nav {
    top: 170px;
  }
  .mixedblock .lastchance .owl-prev,
  .mixedblock .lastchance .owl-next {
    left: -30px;
  }
  .mixedblock .lastchance .owl-prev:before,
  .mixedblock .lastchance .owl-next:before {
    width: 5px;
    height: 7px;
    margin: -4px 0 0 -3px;
    background-position: 0 -30px;
  }
  .mixedblock .lastchance .owl-next {
    right: -30px;
    left: auto;
  }
  .mixedblock .lastchance .owl-next:before {
    margin-left: -2px;
    background-position: -10px -30px;
  }
}
@media screen and (max-width: 1249px) {
  .mixedblock .lastchance {
    margin-top: 40px;
    padding-top: 40px;
    position: relative;
  }
  .mixedblock .lastchance:before {
    display: block;
    content: " ";
    height: 6px;
    background: #e0eded;
    position: absolute;
    top: 0;
    right: -100%;
    left: -100%;
  }
  .widgets__divider {
    flex: 0 0 40px;
  }
}
@media screen and (min-width: 1000px) {
  .nodesktop {
    display: none !important;
  }
}
@media screen and (min-width: 768px) and (max-width: 999px) {
  .notablet {
    display: none !important;
  }
}
@media screen and (max-width: 999px) {
  .flex-btt {
    display: flex;
    flex-direction: column-reverse;
  }
  .sitepanel .wrap {
    padding: 0;
  }
  .sitepanel__promo {
    width: 100%;
    margin: -5px 0;
    padding: 5px 0;
    font-size: 10px;
  }
  .sitepanel__promo__offer {
    width: 100%;
  }
  .sitepanel__search {
    position: absolute;
    top: 4px;
    right: 7px;
  }
  .userpanel {
    background: none;
    padding: 12px 0;
  }
  .userpanel.alternate {
    background: #ffffff;
    border-bottom: 1px solid #d8d9d9;
  }
  .userpanel .wrap {
    justify-content: space-between;
    align-items: center;
  }
  .userpanel__navtrigger {
    flex: 0 0 33.33%;
  }
  .userpanel__navtrigger span {
    display: block;
    width: 16px;
    height: 10px;
    cursor: pointer;
  }
  .userpanel__navtrigger i {
    display: block;
    width: 16px;
    height: 2px;
    background: #232323;
    position: relative;
  }
  .userpanel__navtrigger i:before,
  .userpanel__navtrigger i:after {
    display: block;
    content: " ";
    width: 100%;
    height: 100%;
    background: inherit;
    position: absolute;
  }
  .userpanel__navtrigger i:before {
    top: 4px;
    left: 0;
  }
  .userpanel__navtrigger i:after {
    top: 8px;
    left: 0;
  }
  .userpanel__logo {
    flex: 0 0 128px;
    width: 128px;
    height: 24px;
    background: url("../i/sprite.png") no-repeat -500px -100px;
  }
  .userpanel__block {
    flex: 0 0 33.33%;
    justify-content: flex-end;
    position: relative;
  }
  .userpanel__block__one,
  .userpanel__block a,
  .userpanel__block .link {
    position: static;
  }
  .userpanel__block__one + .userpanel__block__one,
  .userpanel__block__one + .userpanel__block a,
  .userpanel__block__one + .userpanel__block .link,
  .userpanel__block a + .userpanel__block__one,
  .userpanel__block a + .userpanel__block a,
  .userpanel__block a + .userpanel__block .link,
  .userpanel__block .link + .userpanel__block__one,
  .userpanel__block .link + .userpanel__block a,
  .userpanel__block .link + .userpanel__block .link {
    margin-left: 15px;
  }
  .userpanel__block__info {
    right: -6px;
    left: auto;
  }
  .header {
    margin: 0;
  }
  .header__navbar {
    display: none;
    margin: 0;
  }
  .freeshipping {
    height: 14px;
    border-top: 1px solid;
    border-bottom: 1px solid;
    color: #9e9f9f;
    font-size: 8px;
    line-height: 14px;
    text-align: center;
    letter-spacing: 0.08em;
  }
  .mobilenav {
    display: flex;
  }
  .mobilenav a {
    display: block;
    flex: 0 0 33.33%;
    padding: 11px 40px 11px 40px;
    background: #e0eded;
    font-size: 11px;
    line-height: 18px;
    font-weight: 700;
    text-align: center;
    letter-spacing: 0.12em;
    position: relative;
  }
  .mobilenav a:after {
    margin: -5px 0 0 0;
    position: absolute;
    top: 50%;
    right: 30px;
  }
  .mobilenav a + a {
    border-left: 1px solid #ffffff;
  }
  .advantages .wrap {
    padding: 0 40px;
  }
  .advantages__one {
    flex: 0 0 216px;
    padding-right: 46px;
  }
  .actual {
    margin-bottom: 0;
    padding-bottom: 55px;
  }
  .actual__title {
    margin-bottom: 5px;
    font-size: 35px;
    line-height: 32px;
  }
  .actual__text {
    margin-bottom: 20px;
    font-size: 11px;
    line-height: 13px;
  }
  .actual__carousel {
    margin: auto;
    padding: 0 20px;
  }
  .actual__carousel__one {
    padding-right: 7px;
    padding-left: 7px;
  }
  .actual__carousel__one__info {
    padding-right: 10px;
    padding-left: 10px;
  }
  .actual__carousel__one__title {
    font-size: 19px;
    line-height: 24px;
  }
  .actual__carousel__one__text {
    font-size: 10px;
    line-height: 12px;
  }
  .actual .owl-prev {
    left: -12px;
  }
  .actual .owl-next {
    right: -12px;
  }
  .mixedblock .bestsellers__title,
  .mixedblock .lastchance__title {
    margin-bottom: 10px;
    font-size: 35px;
    line-height: 32px;
  }
  .mixedblock .bestsellers__categories,
  .mixedblock .lastchance__categories {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    margin-bottom: 20px;
  }
  .mixedblock .bestsellers__categories a,
  .mixedblock .lastchance__categories a {
    margin: 5px 0;
    font-size: 11px;
  }
  .mixedblock .bestsellers__descr,
  .mixedblock .lastchance__descr {
    margin-bottom: 20px;
    font-size: 12px;
    line-height: 16px;
  }
  .mixedblock .bestsellers__items__wrap,
  .mixedblock .lastchance__items__wrap {
    width: 660px;
  }
  .whatandhow__title {
    font-size: 35px;
    line-height: 40px;
  }
  .about {
    padding: 0 0 45px 0;
  }
  .about__banner {
    margin-bottom: 0;
    padding-right: 9px;
    padding-left: 9px;
  }
  .about__bottom {
    margin-top: 35px;
  }
  .about__bottom__socials {
    flex: 0 0 250px;
  }
  .about__bottom + .about__banner {
    margin-top: 35px;
  }
  .widgets {
    padding: 6px 0 0 0;
  }
  .widgets__one {
    flex: 0 0 335px;
    margin: auto;
  }
  .footer {
    padding: 0;
  }
  .footer__nav {
    flex-direction: column;
    padding: 0;
  }
  .footer__nav__section {
    border-top: 1px solid #ffffff;
  }
  .footer__nav__section:first-child {
    border: none;
  }
  .footer__nav__section ul {
    display: none;
    padding: 6px 0 16px 0;
  }
  .footer__nav__section ul li {
    margin: 0;
    padding: 8px 0;
    font-size: 12px;
    line-height: 18px;
  }
  .footer__nav__section .parent {
    margin: 0;
    padding: 11px 0;
    font-size: 11px;
    line-height: 15px;
    position: relative;
    cursor: pointer;
  }
  .footer__nav__section .parent:after {
    display: block;
    content: " ";
    width: 10px;
    height: 6px;
    margin-top: -3px;
    background: url("../i/sprite.png") no-repeat -30px 0;
    position: absolute;
    top: 50%;
    right: 0;
  }
  .footer__nav__section .parent.active:after {
    width: 9px;
    height: 9px;
    margin-top: -4px;
    background-position: -40px 0;
  }
  .footer__nav__section .parent.active + ul {
    display: block;
  }
  .footer-payments {
    flex-direction: column;
  }
  .footer-payments__text {
    padding: 0 15px;
    font-size: 10px;
    line-height: 18px;
  }
  .footer-payments__methods {
    margin: 20px auto 0 auto;
  }
  .footer-payments__methods:after {
    display: none;
  }
  .mobilesearch {
    display: none;
    background: #ffffff;
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    z-index: 7;
  }
  .adnav {
    display: block;
    position: fixed;
    width: 320px;
    height: 100%;
    background: #e0eded;
    top: 0;
    left: -320px;
    overflow-y: auto;
    z-index: 3;
    -webkit-transition: all 1s;
    -moz-transition: all 1s;
    -ms-transition: all 1s;
    -o-transition: all 1s;
    transition: all 1s;
  }
  .adnav.active {
    left: 0;
    box-shadow: 0 0 5px 0 rgba(99, 181, 178, 0.5);
  }
  .adnav__signin {
    display: block;
    height: 50px;
    margin-right: 50px;
    padding: 14px 20px;
    background: #ffffff;
    color: #63b5b2;
    font-size: 12px;
    line-height: 22px;
    font-weight: 700;
  }
  .adnav__close {
    width: 50px;
    height: 50px;
    background: #63b5b2;
    position: absolute;
    top: 0;
    right: 0;
    cursor: pointer;
  }
  .adnav__close:before {
    display: block;
    content: " ";
    width: 14px;
    height: 14px;
    background: url("../i/sprite.png") no-repeat -700px -80px;
    position: absolute;
    top: 18px;
    left: 18px;
  }
  .adnav__close.black:before {
    background-position: -720px -80px;
  }
  .adnav__flex {
    display: flex;
    -webkit-transition: all 1s;
    -moz-transition: all 1s;
    -ms-transition: all 1s;
    -o-transition: all 1s;
    transition: all 1s;
  }
  .adnav__flex.active {
    margin-left: -320px;
  }
  .adnav__flex__one {
    flex: 0 0 320px;
  }
  .adnav__geo {
    display: flex;
    justify-content: space-between;
    padding: 15px;
    font-size: 12px;
    line-height: 16px;
    font-weight: 700;
    letter-spacing: 0.05em;
  }
  .adnav__geo span {
    flex: 0 0 auto;
  }
  .adnav__geo .link {
    color: #000000;
    letter-spacing: normal;
  }
  .adnav__geo .link:after {
    margin-top: -2px;
  }
  .adnav__locale {
    display: flex;
    background: #63b5b2;
  }
  .adnav__locale__one {
    flex: 0 0 50%;
    position: relative;
  }
  .adnav__locale__one + .adnav__locale__one {
    border-left: 1px solid #ffffff;
  }
  .adnav__locale .sitepanel__settings__one__dropdown {
    margin-top: -8px;
    left: 15px;
  }
  .adnav__locale .link {
    display: block;
    padding: 15px 10px 15px 15px;
    color: #ffffff;
    font-size: 12px;
    line-height: 16px;
    font-weight: 700;
    letter-spacing: 0.05em;
  }
  .adnav__locale .link:after {
    float: right;
    margin-top: 6px;
  }
  .adnav__locale .link:hover + .sitepanel__settings__one__dropdown {
    max-height: 300px;
  }
  .adnav__locale .link:hover + .sitepanel__settings__one__dropdown > ul:before,
  .adnav__locale .link:hover + .sitepanel__settings__one__dropdown__wrap:before {
    top: -10px;
  }
  .adnav__nav {
    font-size: 11px;
    line-height: 17px;
    font-weight: 700;
    letter-spacing: 0.14em;
  }
  .adnav__nav.parent {
    text-transform: uppercase;
  }
  .adnav__nav a,
  .adnav__nav .link {
    display: block;
    padding: 14px 30px;
    color: #050708;
  }
  .adnav__nav__section {
    margin-top: 20px;
    padding: 20px 0;
    border-top: 1px solid #757676;
    border-bottom: 1px solid #757676;
  }
  .adnav__nav__section a,
  .adnav__nav__section .link {
    padding: 7px 30px;
  }
  .adnav__black {
    margin-top: 35px;
    padding: 15px 0;
    background: #000000;
  }
  .adnav__black a,
  .adnav__black .link {
    padding: 7px 30px;
    color: #ffffff;
  }
  .adnav__black__giftcard {
    margin: 15px 0;
    border-top: 1px solid #757676;
    border-bottom: 1px solid #757676;
  }
  .adnav__black__giftcard a,
  .adnav__black__giftcard .link {
    padding: 19px 30px;
  }
  .adnav__black__more {
    text-transform: none;
    letter-spacing: 0.05em;
  }
  .adnav__black__more a,
  .adnav__black__more .link {
    padding: 5px 30px;
  }
  .adnav__black__more a.tdu,
  .adnav__black__more .link.tdu {
    text-decoration: underline;
  }
  .adnav__black__more a span,
  .adnav__black__more .link span {
    margin-left: 10px;
  }
  .adnav__back {
    margin-bottom: 10px;
    padding: 19px 30px;
    border-bottom: 1px solid #878e8e;
    color: #000000;
    font-size: 11px;
    line-height: 15px;
    font-weight: 700;
    text-decoration: underline;
  }
  .popup.alternate {
    width: 320px;
    margin-top: 0;
    padding: 0;
    border: none;
    left: 0;
    -webkit-transform: none;
    -moz-transform: none;
    -ms-transform: none;
    -o-transform: none;
    transform: none;
  }
  .popup.alternate:after {
    display: none;
  }
  .popup .userpanel__block__dropdown__wrap {
    border: none;
  }
  .breadcrumbs {
    padding-top: 10px;
    font-size: 12px;
    line-height: 18px;
    text-align: center;
  }
}
@media screen and (max-width: 767px) {
  .nomobile {
    display: none !important;
  }
  .userpanel {
    padding: 0;
  }
  .userpanel__logo {
    flex: 0 0 94px;
    width: 94px;
    height: 18px;
    background: url("../i/sprite.png") no-repeat -440px -81px;
  }
  .userpanel__block {
    margin-right: -9px;
  }
  .userpanel__block__one,
  .userpanel__block a,
  .userpanel__block .link {
    padding: 8px 0;
  }
  .userpanel__block__one + .userpanel__block__one,
  .userpanel__block__one + .userpanel__block a,
  .userpanel__block__one + .userpanel__block .link,
  .userpanel__block a + .userpanel__block__one,
  .userpanel__block a + .userpanel__block a,
  .userpanel__block a + .userpanel__block .link,
  .userpanel__block .link + .userpanel__block__one,
  .userpanel__block .link + .userpanel__block a,
  .userpanel__block .link + .userpanel__block .link {
    margin-left: 3px;
  }
  .userpanel__block__one .icon,
  .userpanel__block a .icon,
  .userpanel__block .link .icon {
    margin-right: 3px;
    margin-left: 0;
  }
  .userpanel__block__one .iconed:before,
  .userpanel__block a .iconed:before,
  .userpanel__block .link .iconed:before {
    margin-right: 3px;
    margin-left: 0;
  }
  .mobilenav {
    flex-direction: column;
  }
  .mobilenav a {
    border-left: none !important;
    border-bottom: 1px solid #ffffff;
  }
  .actual {
    margin-bottom: 0;
    padding-bottom: 45px;
  }
  .actual__title {
    margin-bottom: 10px;
    font-size: 30px;
    line-height: 27px;
  }
  .actual__text {
    margin-bottom: 15px;
  }
  .actual__carousel {
    padding: 0 28px;
  }
  .actual__carousel__one__title {
    font-size: 19px;
    line-height: 24px;
  }
  .actual__carousel__one__text {
    font-size: 10px;
    line-height: 12px;
  }
  .actual .owl-item {
    flex: 0 0 100%;
  }
  .actual .owl-prev {
    left: -10px;
  }
  .actual .owl-next {
    right: -10px;
  }
  .mixedblock .bestsellers__title,
  .mixedblock .lastchance__title {
    font-size: 30px;
    line-height: 27px;
  }
  .mixedblock .bestsellers__items__wrap,
  .mixedblock .lastchance__items__wrap {
    width: 165px;
  }
  .mixedblock .bestsellers .owl-stage-outer,
  .mixedblock .lastchance .owl-stage-outer {
    overflow: visible;
  }
  .mixedblock .bestsellers .owl-nav,
  .mixedblock .lastchance .owl-nav {
    display: none;
  }
  .advantages {
    margin-bottom: 30px;
    padding: 8px 0;
  }
  .advantages .wrap {
    padding-right: 15px;
    padding-left: 15px;
  }
  .advantages__one {
    flex: 0 0 130px;
    width: 50%;
    padding-right: 0;
  }
  .advantages__one:before {
    top: 4px;
  }
  .advantages__one__title {
    margin-bottom: 0;
    font-size: 8px;
    line-height: 12px;
  }
  .about__banner {
    margin-top: 45px;
    padding-right: 0;
    padding-left: 0;
  }
  .about__banner.alternate {
    margin-top: 0;
  }
  .about__bottom + .about__banner {
    margin-top: 35px;
  }
  .whatandhow__title {
    margin-bottom: 20px;
    font-size: 30px;
  }
  .whatandhow__switcher {
    width: auto;
    margin-right: -24px;
    margin-left: -24px;
  }
  .whatandhow__switcher__one {
    width: 50%;
    padding: 0 9px;
  }
  .whatandhow__switcher__one.active:before {
    right: 9px;
    left: 9px;
  }
  .whatandhow__switcher__one img {
    display: block;
    width: 100%;
    height: auto;
  }
  .whatandhow__items {
    flex-wrap: wrap;
  }
  .whatandhow__items__one {
    flex: 0 0 50%;
  }
  .widgets__one {
    flex: 0 0 100%;
  }
  .popup {
    margin-top: 0;
    padding: 0;
    border: none;
    font-size: 12px;
    line-height: 14px;
    box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.1);
  }
  .popup__pretitle {
    padding: 8px 40px 8px 20px;
    font-size: 13px;
    line-height: 18px;
  }
  .popup__wrap {
    padding: 20px;
  }
  .popup__title {
    margin-bottom: 5px;
    font-size: 18px;
    line-height: 20px;
  }
  .popup__subtitle {
    font-size: 12px;
    line-height: 14px;
  }
  .upload__form__list {
    flex-wrap: wrap;
    justify-content: space-around;
    padding-top: 10px;
  }
  .upload__form__one {
    margin-top: 25px;
  }
}
.slick-prev,
.slick-next {
  width: 100%;
  height: 31px;
  margin-top: -10px;
  border: none;
  background: none;
  position: relative;
  cursor: pointer;
}
.slick-prev:before,
.slick-next:before {
  display: block;
  content: " ";
  width: 31px;
  height: 11px;
  margin: -5px 0 0 -15px;
  background: url("../i/sprite.png") no-repeat -160px -280px;
  position: absolute;
  top: 50%;
  left: 50%;
}
.slick-prev.slick-disabled,
.slick-next.slick-disabled {
  -webkit-opacity: 0.25;
  opacity: 0.25;
  filter: alpha(opacity=25);
  cursor: default;
}
.slick-next {
  margin-top: 0;
}
.slick-next:before {
  background-position: -160px -260px;
}
.separator {
  width: 100%;
  max-width: 950px;
  height: 8px;
  margin: auto;
  background: #000000;
}
/* UPD 11.06.2020 */
.cart__element {
  position: relative;
}
.cart__element:hover .cart__box {
  display: block;
}
.cart__box {
  display: none;
  position: absolute;
  top: 44px;
  right: 0;
  width: 288px;
  height: 496px;
  padding: 24px;
  background: #e3edee;
}
.cart__box:before {
  display: block;
  content: '';
  position: absolute;
  top: 7px;
  right: 35px;
  width: 0;
  height: 0;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-bottom: 10px solid #fff;
}
.cart__box_inner {
  background: #fff;
  padding-bottom: 15px;
}
.cart__box_title {
  font-size: 17px;
  font-weight: 800;
  color: #000;
  text-transform: uppercase;
  padding: 25px 15px 20px;
  border-bottom: 2px solid #e3edee;
}
.cart__box_content {
  height: 318px;
  padding: 0 15px;
  overflow: hidden;
  overflow-y: auto;
}
.cart__box_item {
  display: flex;
  align-items: flex-start;
  padding: 25px 0 !important;
}
.cart__box_item:not(:last-child) {
  border-bottom: 2px solid #e3edee;
}
.cart__box_left {
  width: 86px;
  min-width: 86px;
  height: 109px;
  overflow: hidden;
  margin-right: 25px;
}
.cart__box_left img {
  width: 100%;
  height: auto;
  margin: 0 auto;
}
.cart__box_name {
  margin-bottom: 10px;
  font-size: 10px;
  line-height: 14px;
  font-weight: 700;
  text-align: center;
  text-transform: uppercase;
}
.cart__box_desc {
  -webkit-box-flex: 1;
  -webkit-flex: 1 0 auto;
  -ms-flex: 1 0 auto;
  flex: 1 0 auto;
  margin-bottom: 10px;
  font-size: 11px;
  line-height: 14px;
  text-align: center;
}
.cart__box_price {
  height: 36px;
  font-size: 11px;
  line-height: 16px;
  font-weight: 700;
  text-align: center;
}
.cart__box_price .old {
  display: block;
  color: #7d7d7d;
  text-decoration: line-through;
}
.cart__box_button {
  display: block;
  color: #fff !important;
  margin: 10px 15px 0;
}
@media screen and (max-width: 680px) {
  .cart__box {
    display: none !important;
  }
}
.size__popup_title {
  font-size: 34px;
  font-weight: 300;
  color: #444142;
  line-height: 35px;
  margin-bottom: 15px;
}
.size__popup_pic {
  display: block;
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;
}
.size__popup_pic:not(:last-child) {
  margin-bottom: 35px;
}
.size__popup_wrap:not(:last-child) {
  margin-bottom: 52px;
}
.size__popup {
  width: 597px;
  top: -3000px;
}
.thanx {
  margin: 114px 0 114px;
}
.thanx__inner {
  width: 100%;
}
.thanx__title {
  display: block;
  font-family: "CircusDidot", Georgia, serif;
  font-size: 50px;
  line-height: 60px;
  text-align: center;
  margin-bottom: 35px;
}
.thanx__desc {
  font-size: 20px;
  line-height: 35px;
  margin-bottom: 87px;
  text-align: center;
}
.thanx__buttons {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: center;
}
.thanx__btn {
  display: block;
  min-width: 360px;
  width: 366px;
  margin: 0 10px;
}
.thanx__btn:first-child {
  margin-left: 0;
}
.thanx__btn:last-child {
  margin-right: 0;
}
.intro__slider {
  width: 100%;
}
.intro__slider .owl-nav {
  top: 40%;
  transform: translateY(-50%);
  margin: 0 30px;
}
.intro__slider .owl-stage {
  margin: 0 auto;
}
.intro__slider .owl-item {
  min-width: 100%;
}
.intro__slider .carousel__item {
  margin: 0 auto;
}
.intro__slider .owl-stage-outer {
  width: 100%;
}
.intro__slider_item {
  flex-basis: 100%;
}
.indexblock__slider {
  background: #fff;
}
.intro__after {
  margin: 40px auto 80px;
}
.intro__after_banners {
  display: flex;
  flex-wrap: wrap;
  margin-left: -15px;
  margin-right: -15px;
}
.intro__after_banner {
  display: block;
  text-align: center;
  position: relative;
  min-height: 1px;
  padding-left: 15px;
  padding-right: 15px;
}
.intro__after_banner:hover {
  text-decoration: underline;
}
.intro__after_banner img {
  display: block;
  margin-bottom: 22px;
  margin-left: auto;
  margin-right: auto;
}
.intro__after_banner .title {
  font-family: "Times New Roman";
  font-size: 25px;
  font-weight: bold;
  color: #010101;
}
.intro__after_banner .podpis {
  font-family: Arial;
  font-size: 12px;
  font-style: italic;
  color: #010101;
}
@media screen and (min-width: 768px) {
  .intro__after_banner {
    width: 33.33333333%;
  }
}
@media screen and (max-width: 768px) {
  .intro__after_banner {
    width: 100%;
  }
  .intro__after {
    margin: 30px auto 40px;
  }
}
/*
 --- PAGE HELP ----------------------------------
*/
.help {
  border-bottom: 7px solid #000000;
}
.help .wrap {
  flex-direction: column;
  max-width: 990px;
}
.help__title {
  margin-bottom: 25px;
  font-size: 35px;
  line-height: 40px;
  text-align: center;
}
.help__search {
  display: flex;
  width: 100%;
  max-width: 525px;
  margin: 0 auto 85px auto;
}
.help__search__submit {
  flex: 0 0 auto;
  margin-left: 5px;
}
.help__search__field {
  flex: 1 1 auto;
}
.help__search__field.input input {
  margin-bottom: 0;
}
.help__categories {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -3px 90px -3px;
}
.help__categories__one {
  flex: 0 0 50%;
  padding: 0 3px 6px 3px;
  position: relative;
}
.help__categories__one span {
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 17px 14px 13px 120px;
  background: #e0eded;
  font-size: 12px;
  line-height: 23px;
  font-weight: 700;
  letter-spacing: 0.08em;
  position: relative;
  cursor: pointer;
}
.help__categories__one.double {
  flex: 0 0 100%;
}
.help__categories__one.red span {
  background: #f4e8ea;
}
.help__categories__one__list {
  display: none;
  margin-top: -6px;
  padding: 3px 22px 34px 120px;
  background: #e0eded;
  font-size: 12px;
  line-height: 16px;
  position: absolute;
  top: 100%;
  right: 3px;
  left: 3px;
  z-index: 1;
}
.active > .help__categories__one__list {
  display: block;
}
.help__categories__one__list ul li {
  padding: 8px 0;
}
.help__categories__one__list ul li a {
  color: #000000;
  text-decoration: none;
}
.help__categories__one__list ul li a:hover {
  color: #000000;
  text-decoration: underline;
}
.help__faq {
  padding: 30px 105px 98px 105px;
  background: #f4e8ea;
}
.help__faq__title {
  margin-bottom: 30px;
  font-size: 35px;
  line-height: 45px;
  font-weight: 200;
  text-align: center;
}
.help__faq__one {
  margin-bottom: 7px;
}
.help__faq__one span {
  display: block;
  padding: 24px 22px 20px 22px;
  background: #ffffff;
  font-size: 12px;
  line-height: 18px;
  font-weight: 700;
  text-align: center;
  letter-spacing: 0.08em;
  cursor: pointer;
}
.help__other {
  padding: 30px 105px 98px 105px;
}
.help__other__title {
  margin-bottom: 30px;
  font-size: 35px;
  line-height: 45px;
  font-weight: 200;
  text-align: center;
}
.help__other__list {
  display: flex;
  justify-content: space-around;
}
.help__other__one {
  flex: 0 0 180px;
}
.help__other__one a {
  display: block;
  padding-top: 63px;
  color: #000000;
  font-size: 12px;
  line-height: 18px;
  font-weight: 700;
  text-align: center;
  text-decoration: underline;
  letter-spacing: 0.08em;
  position: relative;
}
@media screen and (max-width: 999px) {
  .help {
    padding-top: 15px;
  }
  .help__search {
    margin-bottom: 30px;
  }
  .help__search__field.input input {
    padding-top: 2px;
    padding-bottom: 0;
    margin-bottom: 0;
    height: 40px;
  }
  .help__search__submit .button {
    padding-top: 9px;
    padding-bottom: 6px;
  }
  .help__categories {
    margin-bottom: 45px;
  }
  .help__faq__title {
    font-size: 26px;
    line-height: 1;
  }
}
@media screen and (max-width: 767px) {
  .help__search {
    flex-wrap: wrap;
  }
  .help__search__field {
    flex: 0 0 100%;
  }
  .help__search__field.input input {
    height: 40px;
    padding-top: 0;
    padding-bottom: 0;
    margin-bottom: 5px;
  }
  .help__search__submit {
    flex: 0 0 100%;
    margin-left: 0;
  }
  .help__search__submit input {
    width: 100%;
  }
  .help__categories__one {
    flex: 0 0 100%;
  }
  .help__categories__one span {
    padding-left: 95px;
    line-height: 1.4;
  }
  .help__categories__one .iconed_helptab:before {
    left: 25px;
  }
  .help__categories__one__list {
    padding-left: 30px;
  }
  .help__faq {
    padding: 50px 15px;
  }
  .help__faq__one span {
    padding: 15px 10px;
  }
  .help__other {
    padding: 30px 15px 20px;
  }
  .help__other__list {
    flex-wrap: wrap;
  }
  .help__other__one {
    flex: 0 0 50%;
    padding-bottom: 30px;
  }
}
/*
 --- ACCOUNT ------------------------------------
*/
.account__top {
  padding-bottom: 10px;
  font-size: 12px;
  line-height: 18px;
}
.account__top .wrap {
  align-items: flex-end;
}
.cart .account__top {
  border-bottom: 7px solid #f3f3f3;
}
.account__back {
  flex: 0 0 200px;
  font-size: 14px;
  font-style: italic;
  font-family: "Times New Roman", serif;
}
.account__back a {
  color: #63b5b2;
  text-decoration: underline;
}
.account__back a:hover {
  color: #63b5b2;
  text-decoration: none;
}
.account__total {
  flex: 0 0 200px;
  font-size: 13px;
  font-weight: 700;
  text-align: right;
}
.account__head {
  flex: 1 1 auto;
  text-align: center;
}
.account__head a {
  margin: 0 5px;
  color: #050708;
  text-decoration: none;
}
.account__head a:hover {
  color: #63b5b2;
  text-decoration: none;
}
.account__title {
  margin-bottom: 5px;
  font-size: 35px;
  line-height: 40px;
  text-align: center;
}
.account__main {
  display: flex;
  width: 100%;
  max-width: 1050px;
  margin: 20px auto 0 auto;
}
.account__main__nav {
  flex: 0 0 360px;
  margin-right: 15px;
  font-size: 12px;
  line-height: 15px;
  font-weight: 700;
  text-transform: uppercase;
}
.account__main__nav__head {
  padding: 15px 40px 10px 60px;
  background: #e0eded;
  position: relative;
}
.account__main__nav__head span {
  display: block;
  margin: 5px 0 0 -2px;
  font-size: 30px;
  line-height: 33px;
  font-weight: 200;
  text-transform: none;
}
.account__main__nav__head.withimage {
  padding-right: 110px;
}
.account__main__nav__head__userpic {
  width: 90px;
  height: 90px;
  margin: auto;
  position: absolute;
  right: 10px;
  top: 0;
  bottom: 0;
}
.account__main__nav__head__userpic img {
  display: block;
  width: 100%;
  height: auto;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
}
.account__main__nav ul li {
  padding-top: 5px;
}
.account__main__nav ul li.divider {
  padding-top: 10px;
}
.account__main__nav ul li a,
.account__main__nav ul li span {
  display: block;
  padding: 21px 20px 17px 130px;
  background: #e0eded;
  color: #000000;
  text-decoration: none;
  position: relative;
}
.account__main__nav ul li a.active,
.account__main__nav ul li span.active {
  background: #63b5b2;
  color: #ffffff;
  text-decoration: none;
}
.account__main__nav ul li a.black,
.account__main__nav ul li span.black {
  background: #000000;
  color: #ffffff;
}
.account__main__nav ul li a:before,
.account__main__nav ul li span:before,
.account__main__nav ul li a:after,
.account__main__nav ul li span:after {
  margin-top: auto;
  margin-bottom: auto;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 50px;
}
.account__main__nav ul li a:hover {
  color: #63b5b2;
  text-decoration: underline;
}
.account__main__image {
  flex: 1 1 auto;
  background: url("../i/account.jpg") no-repeat 100% 0;
  -webkit-background-size: cover;
  background-size: cover;
}
.account__main__block {
  flex: 1 1 auto;
}
.account__main__block__title {
  font-size: 12px;
  line-height: 23px;
  font-weight: 700;
  text-transform: uppercase;
}
.account__main__head {
  display: flex;
  align-items: center;
  margin-bottom: 4px;
  padding: 32px 30px 32px 45px;
  background: #ffffff;
}
.account__main__head.nopad {
  margin-bottom: 0;
}
.account__main__head.imaged {
  height: auto;
  padding: 0;
}
.account__main__head.imaged img {
  display: block;
  width: 100%;
  height: auto;
}
.account__main__head__icon {
  flex: 0 0 auto;
}
.account__main__head__title {
  flex: 1 1 auto;
  font-size: 30px;
  line-height: 36px;
  font-weight: 200;
}
.account__main__head__text {
  flex: 0 0 240px;
  font-size: 12px;
  line-height: 14px;
}
.account__main__content {
  padding: 0 30px 0 45px;
  background: #ffffff;
}
.account__main__chat-list {
  display: flex;
  justify-content: space-between;
  padding-bottom: 60px;
  color: #7d7d7d;
  font-size: 12px;
  line-height: 16px;
  text-align: center;
}
.account__main__chat-list__one {
  flex: 0 0 auto;
  max-width: 130px;
  display: flex;
  flex-direction: column;
  margin-top: 45px;
}
.account__main__chat-list__one__person {
  flex: 1 1 auto;
  margin-bottom: 5px;
  padding: 0 10px;
  letter-spacing: 0.05em;
}
.account__main__chat-list__one__image {
  flex: 0 0 125px;
}
.account__main__chat-list__one__image img {
  display: block;
  width: 110px;
  height: 110px;
  margin: auto;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
}
.account__main__chat-list__one__action {
  flex: 0 0 50px;
  padding: 8px 10px 6px 10px;
  border: 1px solid #f3f3f3;
  color: #7d7d7d;
  font-size: 11px;
  line-height: 18px;
  font-weight: 700;
  letter-spacing: 0.12em;
}
.account__main__chat-list__one__action:hover {
  border-color: #e0eded;
  color: #63b5b2;
}
.account__main__chat-list__one__action.soon {
  border-color: #63b5b2;
  background: #63b5b2;
  color: #ffffff;
}
.account__main__chat {
  margin-top: 10px;
  border-top: 3px solid #63b5b2;
  border-bottom: 3px solid #63b5b2;
  background: #ffffff url("../i/bg-chat.gif") repeat 2px 0;
}
.account__main__chat__wrap {
  padding: 60px 18px 60px 20px;
  border-right: 2px solid #ffffff;
  border-left: 2px solid #b1dfdb;
}
.account__main__chat__wrap:after {
  display: block;
  content: " ";
  overflow: hidden;
  clear: both;
}
.account__main__chat__message {
  display: flex;
  flex-wrap: nowrap;
  float: left;
  width: 100%;
  max-width: 444px;
  margin-bottom: 30px;
  padding: 30px 30px 30px 5px;
  border: 1px solid #b1dfdb;
  background: #ffffff;
  overflow: hidden;
  clear: both;
}
.account__main__chat__message.answer {
  flex-direction: row-reverse;
  float: right;
  padding-right: 5px;
  padding-left: 30px;
  border: 1px solid #ffffff;
  background: #b1dfdb;
}
.account__main__chat__message__userpic {
  flex: 0 0 100%;
  max-width: 110px;
  height: 110px;
  margin-right: 25px;
  color: #515555;
  font-size: 13px;
  line-height: 15px;
  text-align: center;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
}
.account__main__chat__message__userpic.iconed {
  background: #b1dfdb;
}
.account__main__chat__message__userpic.iconed:before {
  margin: 10px auto 5px auto;
}
.account__main__chat__message__userpic img {
  display: block;
  width: 100%;
  max-width: 110px;
  height: auto;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
}
.account__main__chat__message__text {
  flex: 1 1 auto;
  color: #636363;
  font-size: 13px;
  line-height: 25px;
}
textarea.account__main__chat__message__text {
  margin-right: -20px;
  border: none;
  font-weight: 400;
  resize: none;
}
.account__main__chat__submit {
  width: 215px;
  padding-top: 20px;
  overflow: hidden;
  clear: both;
}
.account__main__bonuses {
  padding: 35px 0 75px 0;
}
.account__main__bonuses__cardinfo {
  display: flex;
  margin-bottom: 30px;
}
.account__main__bonuses__cardinfo__text {
  flex: 1 1 auto;
  font-size: 30px;
  line-height: 33px;
  font-weight: 200;
}
.account__main__bonuses__cardinfo__date {
  margin-top: 5px;
  font-size: 13px;
  line-height: 18px;
  font-weight: 400;
}
.account__main__bonuses__cardinfo__image {
  flex: 0 0 auto;
  margin-left: 30px;
}
.account__main__bonuses__cardinfo__image img {
  display: block;
  margin: 0;
  padding: 0;
}
.account__main__bonuses__gratz {
  margin-bottom: 30px;
  color: #a5a5a5;
  font-size: 13px;
  line-height: 16px;
}
.account__main__bonuses__gratz__title {
  margin-bottom: 5px;
  color: #63b5b2;
  font-size: 30px;
  line-height: 33px;
  font-weight: 200;
}
.account__main__bonuses__gratz a {
  color: inherit;
  text-decoration: underline;
}
.account__main__bonuses__gratz a:hover {
  color: #63b5b2;
  text-decoration: underline;
}
.account__main__bonuses__terms {
  margin-bottom: 30px;
}
.account__main__bonuses__terms__one {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
  padding: 8px 10px 8px 25px;
  background: #e0eded;
}
.account__main__bonuses__terms__one:after {
  display: none;
}
.account__main__bonuses__terms__one__name {
  flex: 0 0 165px;
  font-size: 12px;
  line-height: 16px;
  font-weight: 700;
  text-transform: uppercase;
}
.account__main__bonuses__terms__one__where {
  flex: 1 1 170px;
  font-size: 13px;
  line-height: 16px;
}
.account__main__bonuses__terms__one__where + .account__main__bonuses__terms__one__where {
  flex: 0 0 auto;
}
.account__main__bonuses__terms__one__where a {
  color: inherit;
  text-decoration: underline;
}
.account__main__bonuses__terms__one__where a:hover {
  text-decoration: none;
}
.account__main__bonuses__terms__one__where span {
  display: inline-block;
  vertical-align: middle;
}
.account__main__bonuses__terms__one__percents {
  flex: 0 0 140px;
  height: 32px;
  font-size: 40px;
  line-height: 36px;
  font-weight: 200;
}
.account__main__bonuses__terms__one__info {
  font-size: 13px;
  line-height: 16px;
}
.account__main__bonuses__stats {
  margin-bottom: 30px;
}
.account__main__bonuses__stats__one {
  display: flex;
  flex-wrap: wrap;
  padding: 10px 0 10px 25px;
}
.account__main__bonuses__stats__one__title {
  flex: 0 0 100%;
  font-size: 12px;
  line-height: 18px;
  font-weight: 700;
  text-transform: uppercase;
}
.account__main__bonuses__stats__one__text {
  flex: 0 0 auto;
  color: #a5a5a5;
  font-size: 11px;
  line-height: 16px;
}
.account__main__bonuses__stats__one__dots {
  flex: 1 1 auto;
  margin-left: 7px;
  background: url("../i/bg-dots.gif") repeat-x 0 10px;
}
.account__main__bonuses__summ {
  width: 130px;
  flex: 0 0 130px;
  margin-left: 10px;
  font-size: 18px;
  line-height: 14px;
  font-weight: 700;
}
.account__main__bonuses__forupgrade {
  display: flex;
  padding: 17px 0 15px 50px;
  background: #f4e8ea;
  font-size: 13px;
  line-height: 18px;
  position: relative;
}
.account__main__bonuses__forupgrade:before {
  margin-top: -12px;
  position: absolute;
  top: 50%;
  left: 25px;
}
.account__main__bonuses__forupgrade__text {
  flex: 1 1 auto;
  text-align: center;
}
.account__main__bonuses__faqlink {
  padding-top: 50px;
  font-size: 14px;
  line-height: 18px;
  text-align: right;
  font-style: italic;
  font-family: "Times New Roman", serif;
}
.account__main__bonuses__faqlink a {
  text-decoration: underline;
}
.account__main__bonuses__faqlink a:hover {
  text-decoration: none;
}
.account__block {
  padding: 35px 45px;
  background: #ffffff;
}
.account__block + .account__block {
  margin-top: 10px;
}
.account__section {
  padding: 30px 0;
}
.account__section__title {
  font-size: 30px;
  line-height: 36px;
  font-weight: 200;
}
.account__section__descr {
  padding-right: 60px;
  font-size: 13px;
  line-height: 18px;
}
.account__section__fields {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}
.account__section__fields + .account__section__fields {
  margin-top: 25px;
  border-top: 1px solid #c6c7c7;
}
.account__section__fields + .account__section__fields.nobd {
  border: none;
}
.account__section__fields__title {
  flex: 0 0 100%;
  margin-top: 25px;
  font-size: 12px;
  line-height: 18px;
  font-weight: 700;
}
.account__section__fields__one {
  flex: 0 0 33.33%;
  margin-top: 25px;
  font-size: 13px;
  line-height: 16px;
}
.account__section__fields__one.upload {
  font-size: 12px;
  line-height: 16px;
  font-weight: 700;
}
.account__section__fields__one.upload a {
  color: inherit;
  text-decoration: underline;
}
.account__section__fields__one.upload a:hover {
  color: inherit;
  text-decoration: none;
}
.account__section__fields__one.lowpad {
  margin-top: 10px;
}
.account__section__fields__one.wide {
  flex: 0 0 100%;
}
.account__submit {
  padding: 30px 0 50px 0;
}
.account__card {
  padding: 80px 35px 80px 50px;
}
.account__card__title {
  font-size: 12px;
  line-height: 18px;
  font-weight: 700;
}
.account__card__title.pad {
  margin-top: 15px;
}
.account__card__stroke {
  display: flex;
  justify-content: space-between;
  padding: 3px 0;
  font-size: 13px;
  line-height: 18px;
}
.account__card__stroke__action a {
  font-size: 14px;
  line-height: 18px;
  font-style: italic;
  text-decoration: underline;
  font-family: "Times New Roman", serif;
}
.account__card__stroke__action a:hover {
  text-decoration: none;
}
.account__card__note {
  margin-top: 30px;
  color: #9e9f9f;
  font-size: 11px;
  line-height: 13px;
}
.account__card_form {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding-right: 38px;
  padding-left: 23px;
}
.account__card_form .account__submit {
  margin-top: 30px;
}
.account__card__field,
.account__card_form .account__card__title,
.account__card_form .account__card__note,
.account__card_form .account__submit {
  flex: 0 0 100%;
  padding: 0 7px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.account__card__field.half,
.account__card_form .account__card__title.half,
.account__card_form .account__card__note.half,
.account__card_form .account__submit.half {
  flex: 0 0 50%;
}
.account__card__field.third,
.account__card_form .account__card__title.third,
.account__card_form .account__card__note.third,
.account__card_form .account__submit.third {
  flex: 0 0 33.33%;
}
.account__card__field input[type=checkbox] + label {
  margin-bottom: 15px;
}
.account__card_form .account__card__note {
  margin-top: 0;
  margin-bottom: 15px;
}
.account__card_cards {
  padding-top: 10px;
  text-align: center;
}
.account__card_cards__wrap {
  width: 100%;
  max-width: 390px;
  margin: 15px auto 0 auto;
}
.account__card_cards__text {
  font-size: 12px;
  line-height: 23px;
  font-weight: 700;
  letter-spacing: 0.08em;
  text-transform: uppercase;
}
.account__card_cards__icon {
  display: block;
  margin: 35px auto 45px auto;
}
.account__card_cards__button {
  margin: 20px 20px 0 20px;
}
.account__card_cards__note {
  margin: 30px 20px 0 20px;
  text-align: right;
}
.account__card_cards__note a {
  font-size: 14px;
  line-height: 18px;
  font-style: italic;
  text-decoration: underline;
  font-family: "Times New Roman", serif;
}
.account__card_cards__note a:hover {
  text-decoration: none;
}
.account__wishlist {
  background: none;
  padding: 0;
}
.account__wishlist__item {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 10px;
  padding: 20px 20px 30px 30px;
  background: #ffffff;
}
.account__wishlist__item .catalogue__item__price {
  margin-bottom: 10px;
}
.account__wishlist__item .catalogue__item__colors {
  margin-bottom: 12px;
}
.account__wishlist__item .catalogue__item__sizes {
  margin-bottom: 10px;
}
.account__wishlist__item__old {
  flex: 0 0 100%;
  margin-bottom: 5px;
  color: #63b5b2;
  font-size: 14px;
  line-height: 18px;
  font-style: italic;
  font-family: "Times New Roman", serif;
}
.account__wishlist__item__image {
  flex: 0 0 222px;
  margin-right: 20px;
  position: relative;
}
.account__wishlist__item__image .img {
  display: block;
  width: 100%;
  height: auto;
  margin: 0;
  padding: 0;
}
.account__wishlist__item__image a.link {
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
}
.account__wishlist__item__status {
  padding: 18px 35px;
  background: rgba(98, 181, 177, 0.59);
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0.05em;
  text-align: center;
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
}
.account__wishlist__item__status.red {
  background: rgba(233, 196, 203, 0.81);
}
.account__wishlist__item__status:before {
  display: block;
  margin: auto;
  position: absolute;
  top: 0;
  right: 12px;
  bottom: 0;
}
.account__wishlist__item__info {
  flex: 1 1 200px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}
.account__wishlist__item__info__wrap {
  display: flex;
}
.account__wishlist__item__info__params {
  flex: 1 1 100px;
}
.account__wishlist__item__info__promo {
  flex: 0 0 120px;
  margin-left: 20px;
  color: #63b5b2;
  font-size: 14px;
  line-height: 18px;
  text-align: right;
  font-style: italic;
  font-family: "Times New Roman", serif;
}
.account__wishlist__item__info__promo a {
  color: inherit;
  text-decoration: underline;
}
.account__wishlist__item__info__promo a:hover {
  color: inherit;
  text-decoration: none;
}
.account__wishlist__item__buttons {
  display: flex;
  padding: 0 5px;
}
.account__wishlist__item__buttons .button {
  flex: 0 0 50%;
  height: auto;
  margin-left: -5px;
  padding: 17px 0 15px 0;
}
.account__wishlist__item__buttons .button + .button {
  margin-right: -5px;
  margin-left: 0;
}
.account__wishlist__item__remove {
  position: absolute;
  top: 10px;
  left: 10px;
  z-index: 2;
  cursor: pointer;
}
.account__wishlist__item__remove:before {
  margin: auto;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
.account__wishlist__item__gone {
  flex: 0 0 100%;
  min-height: 14px;
  margin-top: 5px;
  color: #ee7086;
  font-size: 11px;
  line-height: 14px;
}
.catalogue__item__colors .account__wishlist__item__gone {
  margin-left: 6px;
}
@media screen and (max-width: 999px) {
  .account__top {
    padding-top: 20px;
  }
  .account__top .wrap {
    align-items: center;
  }
  .account__back {
    flex: 0 0 125px;
  }
  .account__total {
    flex: 0 0 125px;
    font-size: 12px;
    line-height: 16px;
  }
  .account__title {
    font-size: 25px;
    line-height: 30px;
  }
  .account__main__nav {
    flex: 0 0 275px;
    margin-right: 8px;
  }
  .account__main__nav__head {
    padding-right: 20px !important;
    padding-left: 30px;
  }
  .account__main__nav ul li a,
  .account__main__nav ul li span {
    padding-left: 95px;
  }
  .account__main__nav ul li a:before,
  .account__main__nav ul li span:before,
  .account__main__nav ul li a:after,
  .account__main__nav ul li span:after {
    left: 20px;
  }
  .account__main__head {
    padding: 13px 25px;
  }
  .account__main__head__title {
    font-size: 25px;
    line-height: 25px;
  }
  .account__main__head__text {
    flex: 0 0 190px;
  }
  .account__main__content {
    padding: 0 25px;
  }
  .account__main__bonuses {
    padding-bottom: 25px;
  }
  .account__main__bonuses__cardinfo__text {
    font-size: 24px;
    line-height: 26px;
  }
  .account__main__bonuses__gratz {
    margin-bottom: 15px;
  }
  .account__main__bonuses__gratz__title {
    font-size: 25px;
    line-height: 25px;
  }
  .account__main__bonuses__terms {
    margin: 35px 0 0 0;
  }
  .account__main__bonuses__terms__one {
    flex-wrap: wrap;
    padding: 15px 35px 15px 25px;
    position: relative;
  }
  .account__main__bonuses__terms__one:after {
    display: block;
    position: absolute;
    top: 18px;
    right: 15px;
  }
  .account__main__bonuses__terms__one__name {
    flex: 0 0 100%;
  }
  .account__main__bonuses__terms__one__where,
  .account__main__bonuses__terms__one__percents,
  .account__main__bonuses__terms__one__info {
    display: none;
    flex: 0 0 100%;
    margin-top: 10px;
  }
  .active > .account__main__bonuses__terms__one__where,
  .active > .account__main__bonuses__terms__one__percents,
  .active > .account__main__bonuses__terms__one__info {
    display: block;
  }
  .account__main__bonuses__stats {
    margin-bottom: 10px;
  }
  .account__main__bonuses__stats__one {
    padding-left: 0;
  }
  .account__main__bonuses__forupgrade {
    align-items: flex-end;
    padding: 11px 0 9px 55px;
    font-size: 13px;
    line-height: 16px;
  }
  .account__main__bonuses__forupgrade:before {
    left: 15px;
  }
  .account__main__bonuses__forupgrade__text {
    text-align: left;
  }
  .account__main__bonuses__forupgrade .account__main__bonuses__summ {
    margin-bottom: 5px;
  }
  .account__main__bonuses__faqlink {
    padding-top: 5px;
    font-size: 12px;
    line-height: 18px;
  }
  .account__main__chat-list {
    flex-wrap: wrap;
  }
  .account__main__chat-list__one {
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    flex: 1 0 100%;
    max-width: 100%;
    margin-top: 30px;
    position: relative;
    padding-left: 80px;
  }
  .account__main__chat-list__one__person {
    flex: 1 0 100%;
    max-width: 50%;
    text-align: left;
    padding-left: 0;
  }
  .account__main__chat-list__one__image {
    flex: 0 1 125px;
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
  }
  .account__main__chat-list__one__image img {
    margin: 0;
    max-width: 60px;
    height: auto;
  }
  .account__main__chat-list__one__action {
    margin: 0 auto;
    flex: 1 0 100%;
    max-width: 50%;
  }
  .account__main__chat__wrap {
    padding: 30px 15px;
  }
  .account__main__chat__message {
    padding: 10px 10px 10px 5px;
  }
  .account__main__chat__message__userpic {
    width: 100%;
    height: 60px;
    max-width: 60px;
    margin-right: 15px;
  }
  .account__main__chat__message__userpic.iconed {
    color: rgba(255, 255, 255, 0);
  }
  .account__main__chat__message__text {
    line-height: 1.4;
  }
  textarea.account__main__chat__message__text {
    margin-right: 0;
    padding: 0;
  }
  .account__section {
    padding: 25px 0;
  }
  .account__section__title {
    font-size: 23px;
    line-height: 24px;
  }
  .account__section__fields__one {
    flex: 0 0 50%;
  }
  .account__section__fields__one.upload {
    flex: 0 0 33.33%;
    font-size: 9px;
    line-height: 12px;
  }
  .account__submit .button_big {
    font-size: 13px;
  }
  .account__wishlist {
    padding: 0;
  }
  .account__wishlist__item {
    align-items: flex-start;
    padding: 10px 20px 30px 20px;
  }
  .account__wishlist__item__info {
    flex: 1 1 100px;
    position: relative;
  }
  .account__wishlist__item__info .catalogue__item__brand {
    margin-bottom: 10px;
  }
  .account__wishlist__item__info .catalogue__item__colors {
    min-height: 39px;
  }
  .account__wishlist__item__info .catalogue__item__sizes {
    min-height: 33px;
  }
  .account__wishlist__item__info__params {
    flex: 0 0 100%;
  }
  .account__wishlist__item__info__promo {
    width: 222px;
    margin: 0;
    font-size: 12px;
    line-height: 18px;
    text-align: left;
    position: absolute;
    top: 289px;
    left: -242px;
  }
  .account__wishlist__item__buttons {
    flex-wrap: wrap;
    padding: 0;
  }
  .account__wishlist__item__buttons .button {
    flex: 0 0 100%;
    margin: 12px 0 0 0;
  }
  .account__card {
    padding: 40px 15px 0;
  }
  .account__card_cards {
    padding: 40px 0 0;
  }
  .account__card_cards__button {
    margin: 20px 0;
  }
  .account__card_form {
    padding: 40px 0;
  }
}
@media screen and (max-width: 767px) {
  .account .wrap {
    justify-content: center;
  }
  .account__main {
    flex-direction: column-reverse;
    width: auto;
    margin: 0 -15px;
  }
  .account__main.alternate {
    flex-direction: column;
  }
  .account__main__nav {
    flex: 0 0 auto;
    margin-right: 0;
  }
  .account__main__currentpage {
    padding: 21px 70px 17px 70px;
    background: #63b5b2;
    color: #ffffff;
    font-size: 12px;
    line-height: 14px;
    font-weight: 700;
    text-transform: uppercase;
    text-align: center;
    position: relative;
  }
  .account__main__currentpage a,
  .account__main__currentpage span {
    color: #ffffff;
    text-decoration: none;
  }
  .account__main__currentpage .iconed:before,
  .account__main__currentpage .iconed:after {
    margin: auto;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 15px;
  }
  .account__main__currentpage .iconed:after {
    left: 35px;
  }
  .account__main__image {
    flex: 0 0 155px;
    height: 155px;
    background-position: 100% -50px;
    margin-bottom: 5px;
  }
  .account__main__content {
    padding: 0 15px;
  }
  .account__main__head {
    height: auto;
    padding: 15px;
  }
  .account__main__head__text {
    flex: 0 0 100%;
  }
  .account__main__bonuses__cardinfo {
    flex-wrap: wrap;
  }
  .account__main__bonuses__cardinfo__text {
    margin-bottom: 10px;
    font-size: 30px;
    line-height: 33px;
  }
  .account__main__bonuses__cardinfo__image {
    margin-left: 0;
  }
  .account__main__bonuses__stats {
    margin-right: -15px;
    margin-bottom: 0;
    margin-left: -15px;
  }
  .account__main__bonuses__stats__one {
    padding: 15px;
    border-top: 1px solid #000000;
  }
  .account__main__bonuses__forupgrade {
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px;
    padding: 15px 50px 15px 15px;
    border-top: 1px solid #000000;
  }
  .account__main__bonuses__forupgrade:before {
    right: 10px;
    left: auto;
  }
  .account__main__bonuses__summ {
    flex: 0 0 100%;
    margin: 10px 0 0 0;
  }
  .account__main__bonuses__terms {
    margin: 10px -15px 0 -15px;
  }
  .account__main__chat-list__one__person {
    max-width: 100%;
  }
  .account__main__chat-list__one__action {
    margin: 0;
    flex: 0 0 auto;
    max-width: 100%;
  }
  .account__section__fields.upload {
    justify-content: space-between;
  }
  .account__section__fields__one {
    flex: 0 0 100%;
  }
  .account__section__fields__one.upload {
    flex: 0 0 auto;
    font-size: 11px;
    line-height: 14px;
    text-align: center;
  }
  .account__section__fields__one.upload .iconed:before {
    display: block;
    margin-right: auto;
    margin-left: auto;
  }
  .account__wishlist {
    padding: 0;
  }
  .account__wishlist__item {
    margin-bottom: 4px;
  }
  .account__wishlist__item__image {
    flex: 0 0 135px;
    margin-right: 10px;
  }
  .account__wishlist__item__image img {
    width: 100%;
    height: auto;
  }
  .account__wishlist__item__status {
    padding: 10px;
  }
  .account__wishlist__item__status:before {
    display: none;
  }
  .account__wishlist__item__info__wrap {
    flex-direction: column;
  }
  .account__wishlist__item__info__promo {
    flex: 0 0 auto;
    width: auto;
    margin-right: -10px;
    margin-left: -155px;
    text-align: center;
    position: static;
    top: auto;
    right: auto;
    bottom: auto;
    left: auto;
  }
  .account__wishlist__item__buttons {
    justify-content: space-between;
    flex-direction: row-reverse;
    margin-left: -145px;
    padding: 0 2px;
  }
  .account__wishlist__item__buttons .button {
    flex: 0 0 50%;
    margin-right: -2px;
    font-size: 9px;
    line-height: 15px;
  }
  .account__wishlist__item__buttons .button + .button {
    margin-right: 0;
    margin-left: -2px;
  }
  .account__card__field.half {
    flex: 0 0 100%;
  }
  .account__card__field.third {
    flex: 0 0 100%;
  }
  .account__card_form .account__card__field.phone {
    flex: 0 0 100%;
  }
  .account__card_form .account__card__note.half {
    flex: 0 0 100%;
  }
}
/*
 --- PAGE CART ----------------------------------
*/
.cart .account__card_form {
  padding: 50px 0px;
}
.cart .account__card_form .plain {
  padding: 0px !important;
}
.cart .account__card__field {
  padding: 0px;
}
.cart .account__card__field .bx-ui-sls-fake {
  margin: 0px !important;
}
.cart .account__card__field .bx-ui-sls-pane {
  position: absolute;
  width: 100%;
  background-color: #63b5b2;
}
.cart .account__card__field .dropdown-item {
  padding: 16px 20px 12px 20px;
}
.cart .account__card__field .bx-ui-sls-error {
  padding: 16px 20px 12px 20px;
}
.cart .account__card__field .dropdown-item-text,
.cart .account__card__field .bx-ui-sls-error {
  font-size: 12px;
  line-height: 23px;
  font-weight: 700;
  text-align: left;
  font-style: normal;
  font-family: Circe, sans-serif;
  color: white;
}
.cart .account__card__field:nth-child(odd) {
  padding-right: 7px;
}
.cart .account__card__field:nth-child(even) {
  padding-left: 7px;
}
.cart form > .wrap {
  flex-direction: column;
}
.cart .bx-soa-section > .wrap {
  flex-direction: column;
}
.cart .bx-soa-section > .wrap.delivery_content_block {
  flex-direction: row;
}
.cart .bx-soa-section > .wrap.payment_content_block {
  flex-direction: row;
}
.cart__items {
  width: 100%;
}
.cart__item {
  display: flex;
  width: 100%;
  padding: 45px 0 20px;
  border-top: 3px solid #f3f3f3;
}
.cart__item:first-child {
  border-top: none;
}
.cart__item__image {
  display: flex;
  flex-direction: column;
  flex: 0 0 122px;
  margin-right: 45px;
}
.cart__item__image img {
  display: block;
  margin: 0;
  padding: 0;
}
.cart__item__image__info {
  padding: 3px 10px 1px 10px;
  background: #63b5b2;
  color: #ffffff;
  font-size: 9px;
  line-height: 14px;
  text-align: center;
}
.cart__item__image__info.red {
  background: #e23c55;
}
.cart__item__image__info.pink {
  background: #fc7488;
}
.cart__item__info {
  flex: 1 1 auto;
}
.cart__item__info__wrap {
  display: flex;
  width: 100%;
}
.cart__item__params {
  display: flex;
  justify-content: space-between;
  flex: 1 1 auto;
}
.cart__item__params__name {
  display: flex;
  flex-direction: column;
  flex: 1 0 220px;
  margin-right: 20px;
}
.cart__item__params__color {
  display: flex;
  flex-direction: column;
  flex: 0 1 100px;
  margin-right: 20px;
}
.cart__item__params__size {
  display: flex;
  flex-direction: column;
  flex: 0 1 100px;
}
.cart__item__params__quantity {
  display: flex;
  flex-direction: column;
  flex: 0 1 230px;
}
.cart__item__price {
  display: flex;
  flex-direction: column;
  flex: 0 0 120px;
  margin-left: 25px;
}
.cart__item__price__old {
  min-height: 16px;
  color: #7d7d7d;
  font-size: 13px;
  line-height: 16px;
  font-weight: 700;
  text-decoration: line-through;
}
.cart__item__price__value {
  font-size: 18px;
  line-height: 22px;
  font-weight: 700;
  letter-spacing: 0.08em;
}
.cart__item__price__bonuses {
  min-height: 17px;
}
.cart__item__price__bonuses span {
  display: inline-block;
  padding: 1px 10px 0 10px;
  border: 1px solid #d9dada;
  color: #63b5b2;
  font-size: 11px;
  line-height: 14px;
}
.cart__item__price__value + .cart__item__price__bonuses {
  margin-top: 5px;
}
.cart__item__title {
  flex: 0 0 auto;
  margin-bottom: 10px;
  font-size: 12px;
  line-height: 14px;
  font-weight: 700;
  text-transform: uppercase;
}
.cart__item__descr {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  flex: 1 1 auto;
}
.cart__item__name {
  margin-bottom: 5px;
  font-size: 13px;
  line-height: 14px;
}
.cart__item__size {
  display: flex;
}
.cart__item__size__minus,
.cart__item__size__plus {
  width: 25px;
  height: 25px;
  border: 1px solid #000000;
  position: relative;
  cursor: pointer;
}
.cart__item__size__minus:before,
.cart__item__size__plus:before,
.cart__item__size__minus:after,
.cart__item__size__plus:after {
  display: block;
  content: " ";
  width: 7px;
  height: 1px;
  background: #000000;
  position: absolute;
  top: 11px;
  left: 8px;
}
.cart__item__size__minus:after,
.cart__item__size__plus:after {
  width: 1px;
  height: 7px;
  top: 8px;
  left: 11px;
}
.cart__item__size__minus.disabled,
.cart__item__size__plus.disabled {
  border-color: #f3f3f3;
  background: #f3f3f3;
  cursor: default;
}
.cart__item__size__minus:after {
  display: none;
}
.cart__item__size__value {
  float: left;
  width: 35px;
  font-size: 12px;
  line-height: 25px;
  text-align: center;
  letter-spacing: 0.08em;
}
.cart__item__size__value span {
  display: none;
}
.cart__item__size__value span.active {
  display: block;
}
.cart__item__promo {
  color: #63b5b2;
  font-size: 14px;
  line-height: 18px;
  font-style: italic;
  font-family: "Times New Roman", serif;
}
.cart__item__promo a {
  color: #63b5b2;
  text-decoration: underline;
}
.cart__item__promo a:hover {
  color: #63b5b2;
  text-decoration: none;
}
.cart__item__discount {
  display: inline-block;
  padding: 4px 10px;
  background: #63b5b2;
  color: #ffffff;
  font-size: 10px;
  line-height: 14px;
}
.cart__item__tofav {
  margin-top: 15px;
  color: #9e9f9f;
  font-size: 11px;
  line-height: 14px;
}
.cart__item__tofav .iconed:before {
  display: inline-block;
  margin: 0 10px 0 0;
  vertical-align: middle;
}
.cart__item__tofav .iconed span {
  display: inline-block;
  vertical-align: middle;
}
.cart__item__delete {
  float: right;
  width: 210px;
  font-size: 14px;
  line-height: 18px;
  font-style: italic;
  font-family: "Times New Roman", serif;
}
.cart__item__delete a {
  color: #63b5b2;
  text-decoration: underline;
}
.cart__item__delete a:hover {
  color: #63b5b2;
  text-decoration: none;
}
.cart__message {
  margin-top: 30px;
  padding: 25px;
  background: #e0eded;
  color: #63b5b2;
  font-size: 15px;
  line-height: 18px;
  font-weight: 700;
  text-align: center;
  letter-spacing: 0.12em;
}
.cart__message.red {
  background: #f4e8ea;
  color: #050708;
}
.cart__warning {
  padding: 15px 55px 20px 35px;
  background: #f4e8ea;
  font-size: 13px;
  line-height: 18px;
  position: relative;
}
.cart__warning:before {
  margin-top: -12px;
  position: absolute;
  top: 50%;
  right: 15px;
}
.cart__summary__bg {
  display: flex;
  flex-direction: row-reverse;
  margin: 50px 0 70px 0;
  background: #e0eded;
}
.cart__summary__info {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 0 0 50%;
  padding-left: 15px;
  background: #ffffff;
}
.cart__summary__info__top {
  display: flex;
  justify-content: space-between;
}
.cart__summary__info__top__values {
  flex: 0 0 210px;
  margin: -8px 0 0 20px;
}
.cart__summary__info__title {
  font-size: 13px;
  line-height: 13px;
  font-weight: 700;
  text-transform: uppercase;
}
.cart__summary__note {
  max-width: 385px;
  padding-left: 30px;
  color: #9e9f9f;
  font-size: 11px;
  line-height: 14px;
  position: relative;
}
.cart__summary__note:before {
  display: block;
  position: absolute;
  top: -3px;
  left: 0;
}
.cart__summary__payment__methods {
  padding-top: 15px;
}
.cart__summary__payment__methods .icon_caption {
  padding-bottom: 15px;
  float: left;
}
.cart__summary__payment__methods i {
  margin-right: 20px;
  -webkit-opacity: 0.7;
  opacity: 0.7;
  filter: alpha(opacity=70);
}
.cart__summary__auth {
  flex: 0 0 50%;
  padding: 40px 40px 20px 40px;
}
.cart__summary__auth__note {
  margin-bottom: 7px;
  color: #9e9f9f;
  font-size: 11px;
  line-height: 14px;
}
.cart__summary__auth .button {
  margin-bottom: 20px;
}
.cart__shipping.bx-step-completed,
.cart__shipping[data-visited=false] {
  padding: 0px !important;
  border-top: 0px solid #f3f3f3;
}
.cart__shipping {
  padding: 40px 0 60px 0;
  border-top: 7px solid #f3f3f3;
}
.cart__shipping .wrap {
  flex-direction: row;
  flex-wrap: wrap;
}
.cart__shipping__title {
  flex: 0 0 100%;
  margin-bottom: 65px;
  font-size: 35px;
  line-height: 40px;
  text-align: center;
}
.cart__shipping__methods {
  flex: 1 1 auto;
}
.cart__shipping__methods label {
  display: block;
  margin-bottom: 25px;
  padding-top: 5px;
  font-size: 12px;
  line-height: 18px;
  font-weight: 700;
}
.cart__shipping__methods label .title-method {
  font-size: 12px;
  line-height: 18px;
  font-weight: 700;
  text-transform: uppercase;
}
.cart__shipping__methods label a {
  color: #050708;
  font-weight: 400;
  text-decoration: underline;
}
.cart__shipping__methods label a:hover {
  color: #63b5b2;
  text-decoration: underline;
}
.cart__shipping__methods label .price {
  font-size: 18px;
}
.cart__shipping__methods label .free {
  margin-left: 5px;
  color: #63b5b2;
  font-size: 12px;
  text-transform: uppercase;
}
.cart__shipping__methods label .small {
  display: block;
  padding-top: 5px;
  color: #9e9f9f;
  font-size: 11px;
  line-height: 14px;
  font-weight: 400;
}
.cart__shipping__methods label .small.iconed:before {
  margin: -3px 10px 0 0;
}
.cart__shipping__methods.alternate > label {
  padding-right: 200px;
  position: relative;
}
.cart__shipping__methods__icon {
  width: 200px;
  position: absolute;
  top: 0;
  right: 0;
}
.cart__shipping__methods__icon .icon {
  margin-left: 25px;
}
.cart__shipping__price {
  flex: 0 0 500px;
  padding-left: 15px;
}
.cart__shipping__price__top {
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;
}
.cart__shipping__price__top.alternate {
  align-items: center;
}
.cart__shipping__price__top__left {
  flex: 0 0 140px;
  padding: 5px 0;
  color: #63b5b2;
  font-size: 13px;
  line-height: 18px;
}
.cart__shipping__price__top__left__total {
  color: #050708;
  font-size: 13px;
  line-height: 23px;
  font-weight: 700;
  text-transform: uppercase;
}
.cart__shipping__price__top__icon {
  display: flex;
  flex: 0 0 75px;
  width: 75px;
  height: 75px;
  background: #e0eded;
  position: relative;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
}
.cart__shipping__price__top__icon .icon {
  margin: auto;
}
.cart__shipping__price__top__right {
  flex: 0 0 210px;
  padding: 5px 0;
  color: #9e9f9f;
  font-size: 11px;
  line-height: 14px;
}
.cart__shipping__price__top__right__price {
  margin-bottom: 5px;
  color: #63b5b2;
  font-size: 18px;
  line-height: 20px;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 0.08em;
}
.cart__shipping__price .cart__warning {
  margin: 10px 0;
}
.cart__codes {
  padding: 30px 0 40px 0;
  background: #e0eded;
}
.cart__codes .wrap {
  justify-content: space-around;
}
.cart__codes__one {
  flex: 0 0 430px;
}
.cart__codes__title {
  margin-bottom: 5px;
  font-size: 35px;
  line-height: 40px;
}
.cart__codes__notice {
  margin-bottom: 25px;
  padding: 2px 0 0 25px;
  color: #9e9f9f;
  font-size: 11px;
  line-height: 14px;
  position: relative;
}
.cart__codes__notice:before {
  position: absolute;
  top: 0;
  left: 0;
}
.cart__codes__label {
  margin-bottom: 10px;
  font-size: 12px;
  line-height: 18px;
  font-weight: 700;
  text-transform: uppercase;
}
.cart__codes__field {
  margin-bottom: 30px;
}
.cart__bottom {
  padding: 55px 0 30px 0;
  border-top: 7px solid #f3f3f3;
}
.cart__bottom .wrap {
  align-items: center;
}
.cart__bottom__notice {
  flex: 1 1 auto;
}
.cart__bottom__notice__text {
  width: 425px;
  margin: auto;
  padding-left: 40px;
  color: #7d7d7d;
  font-size: 13px;
  line-height: 18px;
  position: relative;
}
.cart__bottom__notice__text:before {
  margin: auto;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
}
.cart__bottom__notice__text a {
  color: #7d7d7d;
  text-decoration: underline;
}
.cart__bottom__notice__text a:hover {
  color: #63b5b2;
  text-decoration: underline;
}
.cart__bottom__controls {
  flex: 0 0 500px;
  padding-left: 15px;
}
.cart__bottom__title {
  padding-bottom: 10px;
  border-bottom: 3px solid #f3f3f3;
  font-size: 13px;
  line-height: 18px;
  font-weight: 700;
  text-transform: uppercase;
}
.cart__bottom__priceinfo {
  margin: 5px 0;
  color: #9e9f9f;
  font-size: 11px;
  line-height: 14px;
}
.cart__bottom .cart__item__price__value {
  margin-top: 20px;
}
.cart__bottom__button {
  margin-top: 25px;
}
.cart__bottom__attention {
  margin-top: 12px;
  color: #9e9f9f;
  font-size: 11px;
  line-height: 14px;
}
.cart__bottom__attention:before {
  margin: -2px 10px 0 0;
}
.cart__controls {
  padding: 45px 0;
  border-top: 7px solid #f3f3f3;
}
.cart__controls .wrap {
  justify-content: space-between;
}
.cart__controls__gifts a {
  display: block;
  padding: 14px 13px 13px 43px;
  background: #e9c4cb;
  color: #ffffff;
  font-size: 11px;
  line-height: 13px;
  text-decoration: none;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  position: relative;
}
.cart__controls__gifts a:before {
  display: block;
  content: " ";
  width: 22px;
  height: 27px;
  margin: auto;
  background: url("../i/sprite.png") no-repeat -400px -140px;
  position: absolute;
  top: 0;
  bottom: 3px;
  left: 11px;
}
.cart__controls__submit {
  flex: 0 0 500px;
  padding-left: 15px;
}
.cart__subtotal {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 15px;
}
.cart__subtotal__summary {
  flex: 0 0 auto;
  margin-right: 20px;
}
.cart__subtotal__summary__text {
  font-size: 12px;
  line-height: 14px;
  font-weight: 700;
  text-align: right;
  letter-spacing: 0.08em;
}
.cart__subtotal__summary__text__label {
  float: left;
  text-transform: uppercase;
}
.cart__subtotal__attention {
  flex: 0 0 275px;
  padding-left: 30px;
  color: #9e9f9f;
  font-size: 11px;
  line-height: 14px;
  position: relative;
}
.cart__subtotal__attention:before {
  position: absolute;
  top: 0;
  left: 0;
}
.cart__subtotal__button {
  flex: 0 0 auto;
  margin-left: 20px;
}
.cart__gotofav {
  padding: 35px 0 50px 0;
  border-top: 7px solid #f3f3f3;
}
.cart__gotofav .wrap {
  flex-direction: column;
}
.cart__gotofav__note {
  margin-bottom: 12px;
  color: #9e9f9f;
  font-size: 11px;
  line-height: 14px;
  text-align: center;
}
.cart__gotofav__button .button {
  width: 100%;
}
@media screen and (max-width: 999px) {
  .cart form > .wrap {
    flex-direction: column-reverse;
  }
  .cart__message {
    margin: 15px -15px 0 -15px;
    padding: 10px 15px;
    font-size: 12px;
    line-height: 18px;
  }
  .cart__items {
    width: 100%;
  }
  .cart__item {
    min-height: 278px;
    position: relative;
  }
  .cart__item__image {
    margin-right: 65px;
  }
  .cart__item__info__wrap {
    height: 100%;
  }
  .cart__item__title {
    margin-bottom: 5px;
  }
  .cart__item__params {
    flex: 1 1 auto;
    justify-content: flex-start;
    flex-direction: column;
    padding-bottom: 54px;
    position: relative;
  }
  .cart__item__params__name {
    flex: 0 0 auto;
  }
  .cart__item__params__color {
    flex: 0 0 auto;
    order: 50;
    margin-top: 15px;
  }
  .cart__item__params__size {
    flex: 0 0 auto;
    margin-top: 15px;
  }
  .cart__item__params__quantity {
    flex: 0 0 auto;
    margin-top: 15px;
  }
  .cart__item__name {
    margin: 0;
  }
  .cart__item__price {
    flex: 0 0 170px;
  }
  .cart__item__descr {
    flex: 0 0 auto;
  }
  .cart__item__promo,
  .cart__item__discount {
    position: absolute;
    bottom: 0;
    left: 0;
  }
  .cart__item__tofav {
    margin: 0;
    position: absolute;
    top: 230px;
    left: 0;
  }
  .cart__item__delete {
    width: 170px;
    padding-right: 62px;
    position: absolute;
    right: 0;
    bottom: 20px;
  }
  .cart__warning {
    padding: 15px 15px 15px 50px;
  }
  .cart__warning:before {
    right: auto;
    left: 15px;
  }
  .cart__summary {
    border-top: 7px solid #f3f3f3;
  }
  .cart__summary__bg {
    flex-direction: column;
    margin: 30px -15px 0 -15px;
  }
  .cart__summary__info {
    flex: 0 0 auto;
    padding-left: 0;
  }
  .cart__summary__info__top {
    margin-bottom: 10px;
    padding: 0 15px;
  }
  .cart__summary__info__top__values {
    flex: 0 0 170px;
  }
  .cart__summary__note {
    max-width: none;
    margin-bottom: 15px;
    margin-left: 15px;
    padding-left: 35px;
    padding-right: 280px;
  }
  .cart__summary__auth {
    max-width: 540px;
    margin: auto;
    padding: 35px 25px 25px 25px;
  }
  .cart__summary__auth .button {
    padding: 17px 20px 16px 20px;
    font-size: 13px;
    line-height: 18px;
    letter-spacing: 0.05em;
  }
  .cart__summary .cart__warning:before {
    margin: 0;
    top: 17px;
    left: 15px;
  }
  .cart__shipping {
    border-top: none;
    padding: 25px 0 0 0;
  }
  .cart__shipping .wrap {
    flex-direction: column;
  }
  .cart__shipping__title {
    margin-bottom: 30px;
  }
  .cart__shipping__methods {
    flex: 0 0 auto;
  }
  .cart__shipping__price {
    flex: 0 0 auto;
    margin: 10px -15px 0 -15px;
    padding: 0;
  }
  .cart__shipping__price__top {
    margin: 0;
    padding: 20px 15px;
    background: #e0eded;
  }
  .cart__shipping__price__top__left {
    flex: 1 1 auto;
  }
  .cart__shipping__price__top__icon {
    margin-right: 40px;
  }
  .cart__shipping__price .cart__warning {
    margin: 0;
  }
  .cart__codes__one {
    flex: 0 0 260px;
  }
  .cart__codes__title {
    line-height: 30px;
  }
  .cart__codes__notice {
    line-height: 15px;
  }
  .cart__codes__button .button {
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
  }
  .cart__bottom__notice__text {
    width: 350px;
  }
  .cart__bottom__controls {
    flex: 0 0 340px;
  }
  .cart__bottom__attention {
    padding-left: 25px;
    position: relative;
  }
  .cart__bottom__attention:before {
    margin: auto;
    position: absolute;
    top: -4px;
    bottom: 0;
    left: 0;
  }
  .cart__controls__submit {
    flex: 0 0 340px;
  }
}
@media screen and (max-width: 767px) {
  .cart__subtotal {
    flex-direction: column;
    margin-right: -15px;
    margin-left: -15px;
  }
  .cart__subtotal__summary {
    width: 100%;
    margin: 0 0 10px 0;
    padding: 0 15px;
  }
  .cart__subtotal__summary__bg {
    margin-top: 15px;
  }
  .cart__subtotal__attention {
    flex: 0 0 auto;
    margin: 0 15px 10px 15px;
  }
  .cart__subtotal__button {
    width: 100%;
    margin: 0;
  }
  .cart__subtotal__button .button {
    display: block;
    width: 100%;
  }
  .cart__message {
    margin-top: 0;
  }
  .cart__item__image {
    margin-right: 20px;
  }
  .cart__item__tofav .iconed:before {
    margin-right: 5px;
  }
  .cart__item__delete {
    width: 123px;
    margin: 0;
    padding: 0;
    font-size: 12px;
    line-height: 18px;
    position: absolute;
    top: 265px;
    left: 0;
  }
  .cart__item__params {
    padding-bottom: 0;
  }
  .cart__item__params__name {
    margin-right: 0;
  }
  .cart__item__price {
    flex: 0 0 auto;
    margin: 0 0 10px 0;
  }
  .cart__item__promo,
  .cart__item__discount {
    margin-top: 10px;
    position: static;
    top: auto;
    right: auto;
    bottom: auto;
    left: auto;
  }
  .cart__item__info__wrap {
    flex-direction: column-reverse;
  }
  .cart__warning {
    font-size: 11px;
    line-height: 18px;
  }
  .cart__summary__note {
    margin-right: 15px;
    padding: 20px 0 0 0;
  }
  .cart__summary__info .cart__warning {
    padding-left: 15px;
  }
  .cart__summary__info .cart__warning:before {
    display: none;
  }
  .cart__summary__auth {
    padding: 15px 15px 0 15px;
  }
  .cart__shipping + .cart__shipping {
    margin-top: 25px;
  }
  .cart__shipping__title {
    font-size: 30px;
    line-height: 34px;
  }
  .cart__shipping__methods.alternate label {
    display: flex;
    flex-direction: column;
    padding-right: 0;
  }
  .cart__shipping__methods.alternate .iconed_attention {
    display: block;
    order: 5;
    margin: 10px 0 0 -26px;
    padding: 0 0 0 26px;
    position: relative;
  }
  .cart__shipping__methods.alternate .iconed_attention:before {
    margin: auto !important;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
  }
  .alternate .cart__shipping__methods__icon {
    order: 3;
    margin-top: 10px;
    position: static;
    top: auto;
    right: auto;
    bottom: auto;
    left: auto;
  }
  .alternate .cart__shipping__methods__icon .icon:first-child {
    margin-left: 0;
  }
  .cart__shipping__price__top {
    position: relative;
  }
  .cart__shipping__price__top.alternate {
    align-items: flex-start;
    padding-top: 25px;
  }
  .cart__shipping__price__top__left {
    flex: 0 0 50%;
    padding: 25px 5px 0 0;
  }
  .cart__shipping__price__top__left__total {
    margin-bottom: 5px;
    font-size: 14px;
    line-height: 16px;
  }
  .cart__shipping__price__top__icon {
    flex: 0 0 auto;
    width: auto;
    height: auto;
    margin: 0 0 0 5px;
    padding: 0;
    position: absolute;
    top: 20px;
    left: 50%;
  }
  .cart__shipping__price__top__right {
    flex: 0 0 50%;
    padding: 25px 0 0 5px;
  }
  .cart__shipping__price__top__right__price {
    font-size: 14px;
    line-height: 16px;
  }
  .cart__codes .wrap {
    flex-direction: column;
  }
  .cart__codes__one + .cart__codes__one {
    margin-top: 35px;
  }
  .cart__codes__title {
    margin-bottom: 15px;
    font-size: 30px;
  }
  .cart__bottom {
    padding: 25px 0;
  }
  .cart__bottom .wrap {
    flex-direction: column;
  }
  .cart__bottom__notice__text {
    width: auto;
    margin-bottom: 20px;
  }
  .cart__bottom__controls {
    flex: 0 0 auto;
    padding: 0;
  }
  .cart__bottom__button {
    margin-right: -15px;
    margin-left: -15px;
  }
  .cart__bottom__button .button {
    padding-right: 10px;
    padding-left: 10px;
  }
  .cart__controls {
    padding: 25px 0;
  }
  .cart__controls .wrap {
    flex-direction: column;
  }
  .cart__controls__back .button {
    width: 100%;
  }
  .cart__controls__gifts {
    margin: 10px 0 0 0;
  }
  .cart__controls__submit {
    flex: 0 0 auto;
    margin: 10px 0 0 0;
    padding: 0;
  }
  .cart__gotofav__button {
    margin-right: -5px;
    margin-left: -5px;
  }
  .cart__gotofav__button .button {
    padding-right: 5px;
    padding-left: 5px;
    font-size: 12px;
    letter-spacing: 0.08em;
  }
}
/*
 --- PAGE CATALOGUE -----------------------------
*/
.catalogue .wrap {
  flex-direction: column;
}
.catalogue__top {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}
.catalogue__top__title {
  font-size: 35px;
  line-height: 42px;
}
.catalogue__top__text {
  flex: 0 0 290px;
  width: 290px;
  margin-right: 25px;
  font-size: 14px;
  line-height: 25px;
}
.catalogue__top__text a {
  color: inherit;
  text-decoration: underline;
}
.catalogue__top__text a:hover {
  color: #63b5b2;
  text-decoration: underline;
}
.catalogue__top__banner {
  flex: 1 1 auto;
  overflow: hidden;
}
.catalogue__top__banner img {
  display: block;
  width: auto;
  height: auto;
  min-width: 100%;
  min-height: 100%;
}
.catalogue__settings {
  display: flex;
  height: 23px;
  margin-bottom: 10px;
}
.catalogue__settings__view {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex: 0 0 290px;
  width: 290px;
  margin-right: 25px;
}
.catalogue__settings__view span {
  flex: 0 0 142px;
  width: 142px;
  border: 1px solid #63b5b2;
  font-size: 13px;
  line-height: 21px;
  text-align: center;
  letter-spacing: 0.05em;
  cursor: pointer;
  background: none;
  color: #63b5b2;
}
.catalogue__settings__view span.active {
  background: #63b5b2;
  color: #ffffff;
}
.catalogue__settings__view.alternate {
  flex: 0 0 auto;
  width: auto;
  color: #9e9f9f;
  font-size: 14px;
  line-height: 23px;
  font-style: italic;
  font-family: "Times New Roman", serif;
}
.catalogue__settings__view.alternate span {
  display: flex;
  flex: 0 0 auto;
  width: auto;
  margin-left: 8px;
  padding: 4px 4px 4px 0;
  background: none;
  border-color: #e1eeee;
}
.catalogue__settings__view.alternate span:before,
.catalogue__settings__view.alternate span:after,
.catalogue__settings__view.alternate span i {
  display: block;
  content: " ";
  width: 13px;
  height: 13px;
  margin-left: 4px;
  background: #e1eeee;
}
.catalogue__settings__view.alternate span.active {
  border-color: #63b5b2;
}
.catalogue__settings__view.alternate span.active:before,
.catalogue__settings__view.alternate span.active:after,
.catalogue__settings__view.alternate span.active i {
  background: #63b5b2;
}
.catalogue__settings__other {
  display: flex;
  justify-content: space-between;
  flex: 1 1 auto;
}
.catalogue__settings__other__right {
  display: flex;
  align-items: center;
}
.catalogue__settings__other__sort {
  display: block;
}
.catalogue__settings__other__sort select {
  display: block;
  margin: 0;
  padding: 0 15px 0 10px;
  background: #f8f8f8 url("../i/select2.png") no-repeat 100% 50%;
  color: #9e9f9f;
  font-size: 14px;
  line-height: 18px;
  font-style: italic;
  font-family: "Times New Roman", serif;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
}
.catalogue__settings__other__result {
  margin-left: 20px;
  color: #9e9f9f;
  font-size: 14px;
  line-height: 23px;
  font-style: italic;
  font-family: "Times New Roman", serif;
}
.catalogue__settings__other .pagination {
  margin-left: 15px;
}
.catalogue__main {
  padding-top: 10px;
}
.catalogue__main__title {
  margin: 0 0 10px 315px;
  font-size: 25px;
  line-height: 30px;
  text-align: center;
}
.catalogue__main__title span {
  padding: 0 10px;
  position: relative;
}
.catalogue__main__title span:before,
.catalogue__main__title span:after {
  display: block;
  content: " ";
  width: 2000px;
  height: 1px;
  background: #d9dada;
  position: absolute;
  top: 33%;
  left: 100%;
}
.catalogue__main__title span:after {
  right: 100%;
  left: auto;
}
.catalogue__main__wrap {
  display: flex;
}
.catalogue__filter {
  flex: 0 0 290px;
  width: 290px;
  margin-right: 10px;
  background: #ffffff;
}
.catalogue__filter__title {
  margin-bottom: 30px;
  font-size: 35px;
  line-height: 40px;
}
.catalogue__filter__section {
  margin-bottom: 10px;
  background: #f6fafa;
}
.catalogue__filter__section:last-child {
  margin-bottom: 0;
}
.catalogue__filter__section__name {
  padding: 13px 40px 11px 25px;
  font-size: 12px;
  line-height: 18px;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 0.08em;
  position: relative;
  cursor: pointer;
}
.catalogue__filter__section__name:after {
  display: block;
  content: " ";
  width: 14px;
  height: 14px;
  margin-top: -7px;
  background: url("../i/sprite.png") no-repeat -920px -80px;
  position: absolute;
  top: 50%;
  right: 15px;
}
.active > .catalogue__filter__section__name:after {
  background-position: -940px -80px;
}
.catalogue__filter__section__list {
  display: none;
  padding-bottom: 25px;
}
.active > .catalogue__filter__section__list {
  display: block;
}
.catalogue__filter__section__list ul li {
  margin-top: 3px;
  padding: 4px 25px 2px 25px;
  font-size: 13px;
  line-height: 16px;
}
.catalogue__filter__section__list ul li a {
  color: #050708;
}
.catalogue__filter__section__list ul li li {
  padding-left: 14px;
}
.catalogue__filter__section__list ul li.active {
  background: #e0eded;
  color: #63b5b2;
  font-weight: 700;
}
.catalogue__filter__section__list ul li.active li {
  font-weight: 400;
}
.catalogue__filter__section__list ul li.active a {
  color: #63b5b2;
}
.catalogue__filter__section__list.checks {
  padding-right: 25px;
  padding-left: 5px;
}
.catalogue__filter__section__list.checks ul li {
  margin: 0 0 7px 20px;
  padding: 0;
}
.catalogue__filter__section__list.checks.cols {
  padding-right: 5px;
  padding-left: 5px;
}
.catalogue__filter__section__list.checks.cols ul {
  display: flex;
  flex-wrap: wrap;
}
.catalogue__filter__section__list.checks.cols ul li {
  flex: 0 0 auto;
  width: 110px;
}
.catalogue__filter__section__list.inline {
  padding-right: 25px;
  padding-left: 20px;
}
.catalogue__filter__section__list.inline ul {
  display: flex;
  flex-wrap: wrap;
}
.catalogue__filter__section__list.inline ul li {
  margin: 0 0 0 5px;
  padding: 0;
  font-size: 15px;
  line-height: 22px;
  letter-spacing: 0.08em;
}
.catalogue__filter__section__list.inline ul li a {
  padding-right: 5px;
  padding-left: 5px;
}
.catalogue__filter__section__list .nums {
  display: flex;
  justify-content: space-between;
  width: 160px;
}
.catalogue__filter__section__list .nums__one {
  width: 77px;
}
.catalogue__filter__section__list .nums__one input {
  border: 1px solid #232323;
  background: none;
}
.catalogue__filter__section__list__label {
  margin: 0 0 10px 5px;
  font-size: 13px;
  line-height: 16px;
}
.catalogue__filter__section__list__links {
  padding-top: 25px;
}
.checks > .catalogue__filter__section__list__links {
  margin-left: 20px;
  padding-top: 0;
}
.catalogue__filter__section__list__links ul li {
  margin: 0 !important;
  padding: 6px 0 !important;
  font-size: 13px;
  line-height: 16px;
}
.catalogue__filter__section__list__links a {
  color: #050708;
  font-size: 13px;
  line-height: 16px;
  text-decoration: underline;
}
.catalogue__filter__section__list__links a:hover {
  color: #63b5b2;
  text-decoration: underline;
}
.catalogue__list {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-content: flex-start;
  flex: 1 1 auto;
}
.catalogue__list__wide {
  display: flex;
  justify-content: space-between;
  align-content: flex-start;
  flex: 1 0 100%;
  margin-left: -20px;
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
}
.catalogue__list__more {
  flex: 0 0 100%;
  padding: 45px 0 0 15px;
}
.catalogue__list__nav {
  display: flex;
  justify-content: space-between;
  flex: 0 0 100%;
  padding: 25px 0 0 15px;
}
.catalogue__list__nav__totop {
  color: #9e9f9f;
  font-size: 14px;
  line-height: 18px;
  font-style: italic;
  font-family: "Times New Roman", serif;
}
.catalogue__list__nav__totop a {
  color: #9e9f9f;
  text-decoration: underline;
}
.catalogue__list__nav__pag {
  display: flex;
  align-items: center;
}
.catalogue__list__nav__pag .pagination {
  margin-left: 15px;
}
.catalogue__item {
  flex: 1 1 25%;
  padding: 0 0 45px 15px;
}
.catalogue__list.wide .catalogue__item {
  flex: 1 1 33.33%;
  padding: 0 0 45px 35px;
}
.catalogue__item__image {
  margin-bottom: 10px;
  position: relative;
}
.catalogue__item__image .img {
  display: block;
  width: 100%;
  height: auto;
}
.catalogue__item__image .link {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
}
.catalogue__item__panel {
  display: none;
  justify-content: space-between;
  padding: 5px 10px;
  background: rgba(255, 255, 255, 0.59);
  font-size: 11px;
  line-height: 14px;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
}
.catalogue__list.wide .catalogue__item__panel {
  padding: 10px 30px;
}
.catalogue__item__panel a {
  color: #050708;
  text-decoration: none;
}
.catalogue__item__panel a:before {
  display: block;
}
.catalogue__item__panel a:hover {
  color: #63b5b2;
  text-decoration: none;
}
.catalogue__item__image:hover .catalogue__item__panel {
  display: flex;
}
.catalogue__item__info {
  padding: 2px 10px;
  background: #e0eded;
  font-size: 9px;
  line-height: 13px;
  position: absolute;
  top: 0;
  left: 0;
}
.catalogue__item__info.gone {
  background: #e23c55;
  color: #ffffff;
}
.catalogue__item__info.almost {
  background: #fc7488;
  color: #ffffff;
}
.catalogue__item__discount {
  padding: 6px 10px 4px 10px;
  background: #63b5b2;
  color: #ffffff;
  font-size: 10px;
  line-height: 13px;
  text-align: center;
  position: absolute;
  bottom: 0;
  left: 0;
}
.catalogue__item__discount.wide {
  right: 0;
}
.catalogue__list.wide .catalogue__item__discount.wide {
  right: auto;
}
.catalogue__item__featured {
  font-size: 13px;
  line-height: 15px;
  position: absolute;
  bottom: 35px;
  left: 10px;
}
.catalogue__item__featured span {
  display: block;
  width: 15px;
  white-space: nowrap;
  -webkit-transform: rotate(-90deg);
  -moz-transform: rotate(-90deg);
  -ms-transform: rotate(-90deg);
  -o-transform: rotate(-90deg);
  transform: rotate(-90deg);
}
.catalogue__item__featured:after {
  margin-top: 5px;
}
.catalogue__item__brand {
  margin-bottom: 5px;
  font-size: 12px;
  line-height: 16px;
  font-weight: 700;
  text-transform: uppercase;
}
.catalogue__item__name {
  margin-bottom: 5px;
  font-size: 13px;
  line-height: 16px;
}
.catalogue__item__price {
  margin-bottom: 5px;
  font-size: 13px;
  line-height: 18px;
  font-weight: 700;
}
.catalogue__item__price .old {
  margin-right: 10px;
  color: #9e9f9f;
  text-decoration: line-through;
}
.catalogue__item__colors {
  display: flex;
  flex-wrap: wrap;
  margin: 0 0 10px -6px;
}
.catalogue__item__colors span {
  display: block;
  width: 14px;
  height: 14px;
  margin-left: 6px;
  background-position: 50% 50%;
  position: relative;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
}
.catalogue__item__colors span.active {
  margin-left: 9px;
  margin-right: 3px;
}
.catalogue__item__colors span.active:before {
  display: block;
  content: " ";
  border: 1px solid;
  position: absolute;
  top: -3px;
  right: -3px;
  bottom: -3px;
  left: -3px;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
}
.catalogue__item__colors span.gone {
  color: #ee7086;
}
.catalogue__item__colors.big > span {
  width: 20px;
  height: 20px;
}
.catalogue__item__sizes {
  color: #636363;
  font-size: 11px;
  line-height: 14px;
  word-spacing: 5px;
}
.catalogue__item__sizes span {
  position: relative;
}
.catalogue__item__sizes span.active {
  margin-left: 4px;
  margin-right: 3px;
}
.catalogue__item__sizes span.active:before {
  display: block;
  content: " ";
  border: 1px solid;
  position: absolute;
  top: -4px;
  right: -3px;
  bottom: -3px;
  left: -4px;
}
.catalogue__item__sizes span.gone {
  color: #ee7086;
}
@media screen and (max-width: 999px) {
  .catalogue {
    position: relative;
  }
  .catalogue__settings {
    height: auto;
    margin-right: -5px;
    margin-bottom: -10px;
    margin-left: -5px;
  }
  .catalogue__settings__other {
    display: block;
  }
  .catalogue__settings__other__right {
    justify-content: space-between;
    padding-top: 10px;
  }
  .catalogue__settings__filter {
    padding-top: 2px;
    color: #9e9f9f;
    font-size: 11px;
    line-height: 18px;
    text-decoration: underline;
  }
  .catalogue__main__title {
    margin-left: 0;
  }
  .catalogue__filter {
    margin: 0;
    padding: 10px;
    position: absolute;
    top: 35px;
    right: -320px;
    z-index: 9;
    -webkit-transition: right 1s;
    -moz-transition: right 1s;
    -ms-transition: right 1s;
    -o-transition: right 1s;
    transition: right 1s;
    -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    -moz-box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
  .catalogue__filter.active {
    right: 0;
  }
  .catalogue__list {
    padding-right: 20px;
    padding-left: 5px;
  }
  .catalogue__list__total {
    flex: 0 0 100%;
    margin: -5px 0 10px 0;
    color: #9e9f9f;
    font-size: 12px;
    line-height: 18px;
    text-align: center;
    font-style: italic;
    font-family: "Times New Roman", serif;
  }
  .catalogue__item {
    flex: 0 0 33.33%;
  }
}
@media screen and (max-width: 767px) {
  .catalogue__settings {
    margin-bottom: 0;
  }
  .catalogue__settings__other__result {
    margin: -125px 0 0 0;
    padding: 0;
    text-align: center;
    position: absolute;
    right: 0;
    left: 0;
  }
  .catalogue__main__title {
    font-size: 19px;
    line-height: 19px;
  }
  .catalogue__list {
    margin-right: -5px;
    margin-left: -15px;
    padding-right: 0;
    padding-left: 0;
  }
  .catalogue__list__wide {
    margin-left: 0;
  }
  .catalogue__list__more {
    flex: 0 0 100%;
    padding-left: 0;
  }
  .catalogue__list__more .button {
    padding-right: 5px;
    padding-left: 5px;
    -webkit-box-sizing: content-box;
    -moz-box-sizing: content-box;
    box-sizing: content-box;
  }
  .catalogue__item {
    flex: 0 0 50%;
    padding: 0 0 35px 10px;
  }
  .catalogue__list.wide .catalogue__item {
    flex: 0 0 50%;
    padding: 0 0 35px 10px;
  }
  .catalogue__item__panel {
    padding: 5px 10px;
    font-size: 7px;
    line-height: 10px;
  }
  .catalogue__item__featured {
    font-size: 9px;
    line-height: 9px;
    left: 5px;
  }
  .catalogue__item__discount {
    font-size: 7px;
    line-height: 9px;
  }
}
/*
 --- PAGE ITEM CARD -----------------------------
*/
.lookalso {
  padding-top: 40px;
  border-top: 1px solid #d9dada;
}
.whatandhow + .lookalso {
  margin-top: -45px;
}
.lookalso .wrap {
  flex-direction: column;
}
.lookalso__controls {
  display: flex;
  align-items: center;
  padding: 80px 0 70px 0;
}
.lookalso__controls__left {
  flex: 0 0 50%;
  padding-right: 15px;
  text-align: right;
}
.lookalso__controls__right {
  flex: 0 0 50%;
  padding-left: 15px;
  font-size: 11px;
  line-height: 16px;
  font-weight: 700;
  letter-spacing: 0.05em;
}
.lookalso__controls__right a {
  margin: 05px;
  color: inherit;
  text-decoration: none;
}
.lookalso__controls__right a:hover {
  color: inherit;
  text-decoration: underline;
}
.history {
  padding: 35px 0 65px 0;
  border-top: 1px solid #d9dada;
}
.history .wrap {
  flex-direction: column;
}
.history__title {
  margin-bottom: 50px;
  font-size: 50px;
  line-height: 60px;
  text-align: center;
}
.history__carousel {
  display: flex;
  width: 100%;
  max-width: 900px;
  margin: auto;
  position: relative;
}
.history__carousel__one {
  flex: 0 0 150px;
  position: relative;
}
.history__carousel__one > a {
  display: block;
  width: 135px;
  margin: auto;
}
.history__carousel__one__actions {
  display: none;
  width: 129px;
  margin: auto;
  position: absolute;
  top: 3px;
  right: 0;
  bottom: auto;
  left: 0;
  z-index: 1;
}
.history__carousel__one__actions a {
  display: block;
  position: absolute;
  top: 0;
  right: 0;
}
.history__carousel__one__actions a + a {
  right: auto;
  left: 0;
}
.history__carousel__one__actions a img {
  display: block;
  margin: auto;
}
.history__carousel__one:hover .history__carousel__one__actions {
  display: block;
}
.history__carousel .owl-stage {
  display: flex;
  padding-bottom: 50px;
}
.history__carousel .owl-item {
  flex: 0 0 150px;
}
.history__carousel .owl-nav {
  margin: -59px -30px 0 -30px;
}
.history__carousel .owl-dots {
  display: flex;
  justify-content: center;
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
}
.history__carousel .owl-dot {
  width: 14px;
  height: 14px;
  margin: 0 7px;
  background: #e0eded;
  cursor: pointer;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
}
.history__carousel .owl-dot.active {
  background-color: #63b5b2;
  cursor: default;
}
.itemcard .wrap {
  flex-wrap: wrap;
  justify-content: space-between;
}
.itemcard__left {
  flex: 0 0 100px;
  width: 100px;
  padding-right: 40px;
  margin-bottom: 45px;
}
.itemcard__left__carousel {
  width: 100%;
  margin-bottom: 30px;
}
.itemcard__left__carousel__one {
  margin: 6px 0;
  cursor: pointer;
}
.itemcard__left__carousel__one img {
  display: block;
  width: 100%;
  height: auto;
}
.itemcard__left__carousel__one.active {
  position: relative;
  cursor: default;
}
.itemcard__left__carousel__one.active:before {
  display: block;
  content: " ";
  border: 1px solid #c6c7c7;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
.itemcard__left__video {
  width: 47px;
  margin: auto;
  color: #9e9f9f;
  font-size: 14px;
  line-height: 18px;
  font-style: italic;
  text-align: center;
  font-family: "Times New Roman", serif;
}
.itemcard__left__video a {
  display: block;
}
.itemcard__left__video a:after {
  margin-top: 12px;
}
.itemcard__image {
  flex: 1 0 425px;
  padding-right: 40px;
  margin-bottom: 45px;
}
.itemcard__image img {
  display: block;
  width: 100%;
  height: auto;
  margin: 0;
  padding: 0;
}
.itemcard__image__share {
  display: flex;
  align-items: center;
  padding-top: 15px;
}
.itemcard__image__share a {
  margin-right: 15px;
}
.itemcard__image__share span {
  margin-left: 5px;
  color: #9e9f9f;
  font-size: 14px;
  line-height: 18px;
  font-style: italic;
  font-family: "Times New Roman", serif;
}
.itemcard__right {
  flex: 1 1 645px;
  position: relative;
  margin-bottom: 45px;
}
.itemcard__info {
  flex: 0 0 100%;
}
.itemcard .catalogue__item__brand {
  padding: 0 180px;
  font-size: 25px;
  line-height: 30px;
  font-weight: 400;
  text-align: center;
  text-transform: none;
}
.itemcard .catalogue__item__name {
  font-size: 14px;
  line-height: 22px;
  text-align: center;
}
.itemcard .catalogue__item__price {
  display: flex;
  flex-direction: column-reverse;
  margin-top: 5px;
  font-size: 18px;
  text-align: center;
}
.itemcard .catalogue__item__price span {
  margin-top: 10px;
  font-size: 13px;
}
.itemcard__subtitle {
  margin-top: 25px;
  font-size: 13px;
  line-height: 23px;
  font-weight: 700;
  text-align: center;
  text-transform: uppercase;
}
.itemcard__subtitle.colored {
  color: #63b5b2;
}
.itemcard__colors {
  text-align: center;
}
.itemcard__colors label {
  display: inline-block !important;
  float: none !important;
  width: 35px !important;
  height: 35px !important;
}
.itemcard__colors label:before {
  width: 33px !important;
  height: 33px !important;
}
.itemcard__colors label:after {
  width: 39px !important;
  height: 39px !important;
  background: none !important;
}
.itemcard__sizes {
  text-align: center;
  position: relative;
}
.itemcard__sizes input[type=radio] + label {
  display: inline-block;
  margin: 0 7px !important;
  padding: 8px 8px 4px 8px;
  font-size: 19px;
  line-height: 25px;
}
.itemcard__sizes input[type=radio] + label:before,
.itemcard__sizes input[type=radio] + label:after {
  display: none !important;
}
.itemcard__sizes input[type=radio]:checked + label {
  position: relative;
}
.itemcard__sizes input[type=radio]:checked + label:before {
  display: block !important;
  width: auto !important;
  height: auto !important;
  border: 1px solid;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  -webkit-border-radius: 0 !important;
  -moz-border-radius: 0 !important;
  border-radius: 0 !important;
}
.itemcard__sizes__guide {
  margin: auto;
  color: #9e9f9f;
  font-size: 14px;
  line-height: 18px;
  font-style: italic;
  font-family: "Times New Roman", serif;
  position: absolute;
  top: 35px;
  right: 0;
  bottom: 0;
}
.itemcard__sizes__guide a {
  text-decoration: underline;
}
.itemcard__sizes__guide a:hover {
  text-decoration: underline;
}
.itemcard__sizes__note {
  margin: 3px 0 10px 0;
  font-size: 14px;
  line-height: 22px;
  text-align: center;
}
.itemcard__sizes__note .icon {
  margin: 5px auto;
}
.itemcard__sizes__note a {
  color: #9e9f9f;
  font-size: 14px;
  line-height: 18px;
  font-style: italic;
  text-decoration: underline;
  font-family: "Times New Roman", serif;
}
.itemcard__sizes__note a:hover {
  color: #63b5b2;
  text-decoration: underline;
}
.itemcard__bonuses {
  width: 180px;
  line-height: 18px;
  position: absolute;
  top: 0;
  left: 0;
}
.itemcard__bonuses__info {
  margin-top: 5px;
  color: #9e9f9f;
  font-size: 14px;
  line-height: 18px;
  font-style: italic;
  font-family: "Times New Roman", serif;
}
.itemcard__bonuses__info a {
  text-decoration: underline;
}
.itemcard__bonuses__info a:hover {
  text-decoration: underline;
}
.itemcard__shipping {
  width: 180px;
  color: #9e9f9f;
  font-size: 14px;
  line-height: 18px;
  text-align: right;
  font-style: italic;
  font-family: "Times New Roman", serif;
  position: absolute;
  top: 0;
  right: 0;
}
.itemcard__shipping a {
  text-decoration: underline;
}
.itemcard__shipping a span {
  color: #63b5b2;
  text-decoration: underline;
}
.itemcard__shipping a:hover {
  text-decoration: underline;
}
.itemcard__buttons {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -5px;
}
.itemcard__buttons__one {
  flex: 0 0 100%;
  padding: 10px 5px 0 5px;
}
.itemcard__buttons__one + .itemcard__buttons__one {
  flex: 0 0 50%;
}
.itemcard__buttons__one .button {
  display: block;
  width: 100%;
  padding-right: 0;
  padding-left: 0;
}
.itemcard__buttons__one .button_blank {
  line-height: 23px;
}
.itemcard__promo {
  margin-top: 30px;
  font-size: 14px;
  line-height: 18px;
  font-style: italic;
  text-align: center;
  font-family: "Times New Roman", serif;
}
.itemcard__promo a {
  text-decoration: underline;
}
.itemcard__about {
  margin-top: 35px;
  text-align: center;
}
.itemcard__about__title {
  margin-bottom: 10px;
  font-size: 13px;
  line-height: 23px;
  font-weight: 700;
  text-transform: uppercase;
}
.itemcard__about__list {
  color: #a5a5a5;
  font-size: 14px;
  line-height: 22px;
  font-style: italic;
  font-family: "Times New Roman", serif;
}
.itemcard__about__list a {
  text-decoration: underline;
}
.itemcard__info {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 35px;
  font-size: 12px;
  line-height: 28px;
}
.itemcard__info__title {
  margin: 20px 0 5px 0;
  font-size: 25px;
}
.itemcard__info__title:after {
  display: none;
}
.itemcard__info__descr {
  margin-bottom: 5px;
  color: #9e9f9f;
  font-size: 14px;
  line-height: 22px;
  font-style: italic;
  font-family: "Times New Roman", serif;
}
.itemcard__info__descr a {
  text-decoration: underline;
}
.itemcard__info__descr a:hover {
  text-decoration: underline;
}
.itemcard__info__list ul li {
  margin-top: 12px;
  padding-left: 20px;
  line-height: 16px;
}
.itemcard__info__list ul li:before {
  display: block;
  content: " ";
  float: left;
  width: 4px;
  height: 4px;
  margin: 6px 0 0 -20px;
  background: #000000;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
}
.itemcard__info__about {
  flex: 0 0 380px;
}
.itemcard__info__aboutbrand {
  flex: 0 0 315px;
}
.itemcard__info__care {
  flex: 0 0 255px;
}
.itemcard__info__button {
  flex: 0 0 100%;
  margin-top: 55px;
  text-align: center;
}
.itemcard__info__button .button {
  display: inline-block;
  width: auto;
  max-width: none;
}
@media screen and (max-width: 999px) {
  .itemcard__left,
  .itemcard__image {
    display: none;
  }
  .itemcard__right {
    flex: 0 0 100%;
    width: 100%;
  }
  .itemcard__info {
    margin-bottom: 0;
  }
  .itemcard__info__about,
  .itemcard__info__aboutbrand,
  .itemcard__info__care {
    flex: 0 0 100%;
  }
  .itemcard__info__title {
    margin: 0;
    padding: 15px 0;
    border-top: 1px solid #c6c7c7;
    text-align: center;
    position: relative;
  }
  .itemcard__info__title:after {
    display: block;
    margin: auto;
    position: absolute;
    top: 0;
    right: 30px;
    bottom: 0;
  }
  .itemcard__info__section__content {
    display: none;
    padding: 10px 40px 30px 40px;
  }
  .itemcard__info__section.active .itemcard__info__section__content {
    display: block;
  }
  .itemcard__shipping,
  .itemcard__bonuses__info {
    font-size: 12px;
    line-height: 18px;
  }
  .itemcard__gallery {
    display: flex;
    margin-right: -15px;
    margin-left: -15px;
    position: relative;
  }
  .itemcard__gallery .owl-stage-outer {
    padding: 0 25%;
  }
  .itemcard__gallery__one {
    flex: 0 0 50%;
    padding: 0 5px;
  }
  .owl-item > .itemcard__gallery__one {
    flex: 0 0 100%;
  }
  .itemcard__gallery__one img {
    display: block;
    width: 100%;
    height: auto;
  }
  .itemcard__gallery .owl-item {
    flex: 0 0 100%;
    width: 100%;
  }
  .itemcard__gallery .owl-nav {
    margin-top: -24px;
  }
  .itemcard__gallery .owl-prev,
  .itemcard__gallery .owl-next {
    width: 65px;
    height: 65px;
    background: rgba(255, 255, 255, 0.5);
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;
  }
  .itemcard__gallery .owl-prev {
    left: -20px;
  }
  .itemcard__gallery .owl-next {
    right: -20px;
  }
  .itemcard__videolink {
    margin-right: -15px;
    margin-left: -15px;
    padding: 3px 0;
    background: #f3f3f3;
    font-size: 14px;
    line-height: 18px;
    font-style: italic;
    text-align: center;
    font-family: "Times New Roman", serif;
  }
  .itemcard__videolink a {
    color: #9e9f9f;
    text-decoration: none;
  }
  .itemcard__videolink a:hover {
    color: #9e9f9f;
    text-decoration: underline;
  }
  .itemcard__colors,
  .itemcard__sizes {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px 0;
  }
  .itemcard__sizes {
    border-top: 1px solid #c6c7c7;
  }
  .itemcard__sizes input[type=radio] + label {
    margin: 0 4px !important;
    padding: 6px 5px 4px 5px;
    font-size: 16px;
    line-height: 20px;
  }
  .itemcard__sizes__guide {
    font-size: 12px;
    line-height: 18px;
    top: 22px;
  }
  .itemcard__subtitle {
    margin: 0 20px 0 0;
    color: inherit !important;
    font-size: 11px;
  }
  .history__title {
    font-size: 35px;
    line-height: 40px;
  }
  .history__carousel .owl-item {
    flex: 0 0 20%;
  }
  .history__carousel .owl-nav {
    display: none;
  }
  .history__carousel__one__actions {
    display: block;
  }
}
@media screen and (max-width: 767px) {
  .itemcard__right {
    margin-top: 15px;
    padding-top: 25px;
  }
  .itemcard .catalogue__item__brand {
    padding: 0;
    font-size: 19px;
    line-height: 29px;
  }
  .itemcard__gallery__one {
    padding: 0;
  }
  .itemcard__gallery .owl-stage-outer {
    padding: 0;
  }
  .itemcard__sizes {
    margin-right: -15px;
    margin-left: -15px;
  }
  .itemcard__sizes .itemcard__subtitle {
    padding-bottom: 10px;
  }
  .itemcard__sizes__guide {
    right: auto;
    top: 27px;
    left: 15px;
  }
  .itemcard__buttons {
    margin-right: -15px;
    margin-left: -15px;
  }
  .itemcard__buttons__one:first-child .button {
    font-size: 14px;
  }
  .itemcard__buttons__one + .itemcard__buttons__one {
    flex: 1 1 auto;
  }
  .itemcard__buttons__one + .itemcard__buttons__one + .itemcard__buttons__one {
    flex: 0 0 60px;
  }
  .itemcard__buttons__one + .itemcard__buttons__one + .itemcard__buttons__one .iconed:after {
    margin: 0;
  }
  .itemcard__promo {
    margin-top: 15px;
  }
  .itemcard__info {
    flex: 1 1 100%;
    margin-right: -15px;
    margin-left: -15px;
  }
  .itemcard__info__title:after {
    right: 15px;
  }
  .itemcard__info__section__content {
    padding-right: 15px;
    padding-left: 15px;
  }
  .lookalso__controls {
    flex-direction: column;
    padding: 50px 0 30px 0;
  }
  .lookalso__controls__left {
    flex: 0 0 100%;
    margin: 0;
    padding: 0;
    text-align: center;
  }
  .lookalso__controls__right {
    margin-top: 20px;
  }
  .history {
    padding-bottom: 35px;
  }
  .history__title {
    margin-bottom: 30px;
  }
  .history .owl-item {
    flex: 0 0 50%;
  }
}
/*
 --- GET A LOOK ---------------------------------
*/
.getalook {
  padding-top: 20px;
}
.getalook > .wrap {
  flex-direction: column;
}
.getalook__title {
  font-size: 35px;
  line-height: 40px;
  text-align: center;
}
.getalook__subtitle {
  width: 100%;
  max-width: 1090px;
  margin: auto;
  padding: 0 15px;
  font-size: 30px;
  line-height: 33px;
  font-weight: 200;
  text-align: center;
  overflow: hidden;
}
.getalook__subtitle span {
  display: inline-block;
  position: relative;
}
.getalook__subtitle span:before,
.getalook__subtitle span:after {
  display: block;
  content: " ";
  width: 2000px;
  height: 1px;
  background: #232323;
  position: absolute;
  top: 10px;
}
.getalook__subtitle span:before {
  margin-right: -4px;
  right: 100%;
}
.getalook__subtitle span:after {
  margin-left: -9px;
  top: 24px;
  left: 100%;
}
.getalook__list {
  padding-top: 20px;
}
.getalook__list .wrap {
  justify-content: space-between;
  width: 100%;
  max-width: 1120px;
  flex-wrap: wrap;
}
.getalook__list__one {
  flex: 0 0 33.33333333%;
  max-width: 336px;
  margin-bottom: 70px;
}
.getalook__list__one__name {
  min-height: 40px;
  margin-bottom: 15px;
  font-size: 18px;
  line-height: 20px;
  font-weight: 300;
  text-align: center;
}
.getalook__list__one img {
  display: block;
  width: 100%;
  height: auto;
}
.getalook__list__one__info {
  padding-bottom: 45px;
  border: 1px solid #f3f3f3;
  border-top: none;
}
.getalook__list__one__items {
  display: flex;
  justify-content: space-around;
  padding: 15px 0 20px 0;
}
.getalook__list__one__items__one {
  flex: 0 0 33.33333333%;
}
.getalook__list__one__date {
  width: 100%;
  max-width: 230px;
  margin: auto;
  padding: 10px 0;
  border-top: 1px solid #232323;
  color: #636363;
  font-size: 12px;
  line-height: 14px;
  font-weight: 700;
  text-align: center;
}
.getalook__list__one__lookhow {
  font-size: 12px;
  line-height: 23px;
  font-weight: 700;
  text-align: center;
}
.getalook__list__one__lookhow a {
  color: inherit;
  text-decoration: underline;
}
.getalook__list__one__lookhow a:hover {
  color: inherit;
  text-decoration: none;
}
.getalook__list__divider {
  margin-bottom: 45px;
  border-top: 1px solid #d9dada;
}
.getalook-item {
  padding-top: 20px;
  position: relative;
}
.getalook-item .wrap {
  flex-direction: column;
}
.getalook-item__title {
  font-size: 60px;
  line-height: 70px;
  text-align: center;
}
.getalook-item__subtitle {
  margin-bottom: 35px;
  font-size: 35px;
  line-height: 40px;
  font-weight: 200;
  text-align: center;
}
.getalook-item__divider {
  width: 100%;
  max-width: 230px;
  margin: 0 auto 35px auto;
  border-top: 1px solid #232323;
}
.getalook-item__description {
  color: #636363;
  font-size: 16px;
  line-height: 30px;
  text-align: center;
}
.getalook-item__main {
  display: flex;
  justify-content: space-between;
  width: 100%;
  max-width: 1020px;
  margin: auto;
  padding-top: 80px;
  position: relative;
}
.getalook-item__main__name {
  margin-right: -92px;
  font-size: 110px;
  line-height: 110px;
  font-weight: 900;
  text-align: right;
  position: absolute;
  top: 35px;
  right: 50%;
}
.getalook-item__main__photos {
  flex: 0 0 500px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 200px;
}
.getalook-item__main__photos__one {
  margin-bottom: 45px;
}
.getalook-item__main__photos__one img {
  display: block;
}
.getalook-item__main__bigphoto {
  flex: 0 0 504px;
}
.getalook-item__main__bigphoto img {
  display: block;
  width: 100%;
  height: auto;
}
.getalook-item__main__items {
  display: flex;
  justify-content: space-between;
  margin-top: 45px;
}
.getalook-item__main__items__one {
  text-align: center;
}
.getalook-item__main__items__one__name {
  margin-top: 10px;
  font-size: 15px;
  line-height: 19px;
}
.getalook-item__main__items__one__buy {
  margin-top: 10px;
  font-size: 12px;
  line-height: 16px;
  font-weight: 700;
}
.getalook-item__main__items__one__buy a {
  text-decoration: underline;
}
.getalook-item__nav {
  display: block;
  width: 275px;
  min-height: 140px;
  margin-top: 100px;
  padding: 20px;
  background: #ffffff;
  color: inherit;
  position: absolute;
  top: 50%;
  -webkit-box-shadow: 0 0 8px rgba(0, 0, 0, 0.4);
  -moz-box-shadow: 0 0 8px rgba(0, 0, 0, 0.4);
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.4);
}
.getalook-item__nav img {
  margin: auto;
  position: absolute;
  top: 0;
  bottom: 0;
}
.getalook-item__nav.prev {
  padding-right: 15px;
  padding-left: 135px;
  text-align: right;
  left: 0;
}
.getalook-item__nav.prev img {
  left: 20px;
}
.getalook-item__nav.next {
  padding-right: 135px;
  padding-left: 15px;
  right: 0;
}
.getalook-item__nav.next img {
  right: 20px;
}
.getalook-item__nav__title {
  display: block;
  margin-bottom: 10px;
  font-size: 12px;
  line-height: 14px;
  font-weight: 700;
  position: relative;
}
.getalook-item__nav__title:before,
.getalook-item__nav__title:after {
  position: absolute;
  top: 50%;
  margin: -4px 0 0 0;
}
.prev .getalook-item__nav__title {
  margin-left: 25px;
}
.prev .getalook-item__nav__title:before {
  left: 0;
}
.next .getalook-item__nav__title {
  margin-right: 25px;
}
.next .getalook-item__nav__title:after {
  right: 0;
}
.getalook-item__nav__name {
  display: block;
  margin-bottom: 5px;
  font-size: 12px;
  line-height: 23px;
  font-weight: 700;
}
.getalook-item__nav__category {
  display: block;
  color: #636363;
  font-size: 13px;
  line-height: 16px;
}
.getalook-item.alternate .wrap {
  max-width: 1010px;
}
.alternate .getalook-item__title {
  font-size: 85px;
  line-height: 95px;
  font-weight: 100;
}
.alternate .getalook-item__main__photos {
  flex: 0 0 360px;
}
.alternate .getalook-item__main__bigphoto {
  flex: 0 0 590px;
}
.alternate .getalook-item__main__items {
  justify-content: space-around;
}
@media screen and (max-width: 1249px) {
  .getalook-item {
    padding-bottom: 230px;
  }
  .getalook-item__main {
    flex-wrap: wrap;
  }
  .getalook-item__main__name {
    margin-right: 0;
    font-size: 50px;
    line-height: 1.4;
    position: static;
    text-align: left;
    flex: 0 0 100%;
  }
  .getalook-item__main__name br {
    display: none;
  }
  .getalook-item__main__bigphoto {
    flex: 0 0 50%;
    padding: 0 5px;
  }
  .getalook-item__main__photos {
    padding: 0 5px;
    flex: 0 0 50%;
  }
  .getalook-item__main__photos__one {
    margin-bottom: 15px;
  }
  .getalook-item__main__photos__one img {
    width: 100%;
    height: auto;
  }
  .getalook-item__nav {
    top: auto;
    bottom: 15px;
    margin-top: 0;
  }
  .getalook__list__one {
    padding: 0 5px;
  }
}
@media screen and (max-width: 999px) {
  .getalook-item .wrap {
    margin-bottom: 50px;
  }
  .getalook-item__title {
    font-size: 25px;
    line-height: 1.6;
  }
  .getalook-item__subtitle {
    font-size: 20px;
    line-height: 1.3;
    margin-bottom: 15px;
  }
  .getalook-item__description {
    line-height: 1.5;
  }
  .getalook-item__main {
    padding-top: 30px;
  }
  .getalook-item__main__name {
    font-size: 28px;
  }
  .getalook-item__main__bigphoto {
    padding: 0;
  }
  .getalook-item__main__photos {
    padding: 0;
  }
  .getalook-item__main__items__one {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .getalook-item__main__items__one__name {
    font-size: 12px;
  }
  .getalook-item.alternate .getalook-item__divider {
    margin-bottom: 0;
  }
  .getalook-item.alternate .getalook-item__title {
    font-size: 25px;
    line-height: 1.6;
  }
  .getalook-item.alternate .getalook-item__main__bigphoto {
    flex: 0 0 50%;
    padding: 0 5px;
  }
  .getalook-item.alternate .getalook-item__main__photos {
    flex: 0 0 50%;
    padding: 0 5px;
  }
  .getalook-item.alternate .getalook-item__main__photos__one {
    width: 100%;
  }
}
@media screen and (max-width: 767px) {
  .getalook-item {
    padding-bottom: 0;
  }
  .getalook-item__main {
    flex-direction: row;
    padding-top: 40px;
  }
  .getalook-item__main__bigphoto {
    flex: 0 0 100%;
  }
  .getalook-item__main__photos {
    flex: 0 0 100%;
  }
  .getalook-item__nav {
    position: relative;
    bottom: 0;
    margin-top: 15px;
    width: 100%;
  }
  .getalook-item__nav.next {
    margin-left: auto;
    margin-bottom: 15px;
  }
  .getalook-item__nav.prev {
    margin-bottom: 15px;
  }
  .getalook-item__nav__name {
    line-height: 1.5;
  }
  .getalook-item.alternate .getalook-item__main__bigphoto {
    flex: 0 0 100%;
    padding: 0;
  }
  .getalook-item.alternate .getalook-item__main__photos {
    flex: 0 0 100%;
    padding: 0;
  }
  .getalook__list .wrap {
    justify-content: center;
  }
  .getalook__list__one {
    flex: 0 0 100%;
    margin-bottom: 50px;
  }
  .getalook__list__one__info {
    padding-bottom: 15px;
  }
}
/*
 --- PAGE GUEST BOOK ----------------------------
*/
.guestbook > .wrap {
  flex-direction: column;
  max-width: 1060px;
}
.guestbook .account__top {
  border-bottom: 1px solid #232323;
}
.guestbook__list__one {
  display: flex;
  padding: 25px 0;
}
.guestbook__list__one__image {
  margin-right: 60px;
}
.guestbook__list__one__image img {
  display: block;
}
.guestbook__list__one__chat {
  flex: 1 1 auto;
  padding-top: 50px;
  border-top: 3px solid #f3f3f3;
}
.guestbook__list__one__chat__list__question {
  display: flex;
}
.guestbook__list__one__chat__list__question .account__main__chat__message__userpic {
  margin: 23px 45px 0 0;
}
.guestbook__list__one__chat__list__question__message__name {
  font-size: 19px;
  line-height: 35px;
  font-weight: 200;
  text-align: right;
}
.guestbook__list__one__chat__list__question__message__text {
  padding: 15px 10px;
  border-top: 3px solid #b1dfdb;
  border-bottom: 3px solid #b1dfdb;
  color: #636363;
  font-size: 13px;
  line-height: 25px;
  position: relative;
}
.guestbook__list__one__chat__list__question__message__text img {
  vertical-align: middle;
}
.guestbook__list__one__chat__list__question__message__text:before,
.guestbook__list__one__chat__list__question__message__text:after {
  display: block;
  content: " ";
  width: 35px;
  height: 28px;
  background: url("../i/sprite.png") no-repeat -260px -280px;
  position: absolute;
  top: -30px;
  left: 0;
}
.guestbook__list__one__chat__list__question__message__text:after {
  background-position: -300px -280px;
  top: auto;
  right: -1px;
  bottom: -30px;
  left: auto;
}
.guestbook__list__one__chat__list__question__message__date {
  color: #63b5b2;
  font-size: 11px;
  line-height: 25px;
  font-weight: 700;
}
.guestbook__list__one__chat__list__answer {
  display: flex;
  flex-direction: row-reverse;
  margin-top: 35px;
  padding-bottom: 25px;
}
.guestbook__list__one__chat__list__answer .account__main__chat__message__userpic {
  margin: 7px 0 0 60px;
}
.guestbook__list__one__chat__list__answer__message {
  padding: 4px 20px 15px 15px;
  background: #b1dfdb;
  position: relative;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;
}
.guestbook__list__one__chat__list__answer__message:before {
  display: block;
  content: " ";
  width: 0;
  height: 0;
  margin-top: -15px;
  border: 15px solid transparent;
  border-left-color: #b1dfdb;
  position: absolute;
  top: 50%;
  left: 100%;
}
.guestbook__list__one__chat__list__answer__message:after {
  display: block;
  content: " ";
  width: 42px;
  height: 31px;
  background: url("../i/sprite.png") no-repeat -940px -320px;
  position: absolute;
  right: -17px;
  bottom: -27px;
}
.guestbook__list__one__chat__list__answer__message__name {
  margin-left: 50px;
  border-bottom: 3px solid #ffffff;
  font-size: 19px;
  line-height: 35px;
  font-weight: 200;
  text-align: right;
}
.guestbook__list__one__chat__list__answer__message__text {
  padding-top: 15px;
  color: #636363;
  font-size: 13px;
  line-height: 25px;
}
.guestbook__list__one__chat__list__answer__message__text img {
  vertical-align: middle;
}
.guestbook__list__one__chat__list__answer__message__text:before {
  display: block;
  content: " ";
  width: 42px;
  height: 34px;
  background: url("../i/sprite.png") no-repeat -200px -280px;
  position: absolute;
  top: 9px;
  left: 15px;
}
.guestbook__list__one__chat__list__answer__message .guestbook__list__one__chat__list__question__message__date {
  position: absolute;
  bottom: -30px;
  left: 15px;
}
.guestbook__list__one__chat__doreg {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-bottom: 70px;
}
.guestbook__list__one__chat__doreg__warning {
  flex: 0 0 430px;
  padding-left: 50px;
  color: #63b5b2;
  font-size: 11px;
  line-height: 14px;
  position: relative;
}
.guestbook__list__one__chat__doreg__warning:before {
  display: block;
  margin: auto;
  position: absolute;
  top: 0;
  right: auto;
  bottom: 0;
  left: 0;
}
.guestbook__list__one__chat__doreg__warning a {
  text-decoration: underline;
}
.guestbook__list__one__chat__doreg__start {
  flex: 0 0 auto;
  width: auto;
  margin-left: 25px;
  padding-left: 80px;
  font-size: 12px;
  line-height: 18px;
  font-weight: 700;
  text-decoration: underline;
  position: relative;
  cursor: pointer;
}
.guestbook__list__one__chat__doreg__start:before {
  left: 0;
  right: auto;
}
.guestbook__list__one__chat__doreg__start:hover {
  text-decoration: none;
}
.guestbook__list__one:first-child .guestbook__list__one__chat {
  padding-top: 0;
  border: none;
}
.guestbook__list__one:last-child .guestbook__list__one__chat {
  padding-bottom: 50px;
  border-bottom: 3px solid #f3f3f3;
}
.guestbook-popup {
  width: 600px;
  padding: 29px 39px 49px 39px;
  border: none;
}
.guestbook-popup:after {
  display: none;
}
.guestbook-popup__title {
  margin-bottom: 10px;
  font-size: 34px;
  line-height: 40px;
  font-weight: 200;
  padding-right: 30px;
}
.guestbook-popup__note {
  margin-bottom: 25px;
  color: #63b5b2;
  font-size: 14px;
  line-height: 18px;
  font-style: italic;
  font-family: "Times New Roman", serif;
}
.guestbook-popup__form__smiles {
  display: flex;
  justify-content: space-between;
  margin-bottom: 40px;
  overflow: hidden;
}
.guestbook-popup__form__smiles > * {
  display: block;
  margin-bottom: 10px;
}
.ui-widget.ui-autocomplete {
  padding: 10px 0 !important;
  border: 4px solid #e0eded !important;
  border-top: none !important;
}
.ui-widget.ui-autocomplete li {
  padding: 0 !important;
  border: none !important;
  font-size: 12px;
  line-height: 20px;
  font-family: "Circe", sans-serif;
}
.ui-widget.ui-autocomplete li:before {
  display: none;
}
.ui-widget.ui-autocomplete li.ui-autocomplete-divider {
  height: 4px;
  margin: 10px 0 !important;
  padding: 0 !important;
  background: #e0eded;
}
.ui-widget.ui-autocomplete li a {
  display: block !important;
  margin: 0 !important;
  padding: 10px !important;
  background: none !important;
  border: none !important;
  color: #232323 !important;
}
.ui-widget.ui-autocomplete li a:hover {
  background: #e0eded !important;
}
@media screen and (max-width: 999px) {
  .guestbook__list__one__image {
    margin-right: 30px;
  }
  .guestbook__list__one__image img {
    width: 100%;
    height: auto;
  }
  .guestbook__list__one__chat__list__question .account__main__chat__message__userpic {
    margin-right: 15px;
  }
  .guestbook__list__one__chat__list__answer .account__main__chat__message__userpic {
    margin-left: 30px;
  }
}
@media screen and (max-width: 767px) {
  .guestbook__list__one__chat {
    padding-top: 30px;
    padding-bottom: 0;
  }
  .guestbook__list__one__chat__doreg {
    flex-wrap: wrap;
    margin-bottom: 50px;
  }
  .guestbook__list__one__chat__doreg__warning {
    flex: 0 0 100%;
    margin-bottom: 15px;
  }
  .guestbook__list__one__chat__list__question .account__main__chat__message__userpic {
    margin-right: 5px;
  }
  .guestbook__list__one__chat__list__question__message__name {
    padding-left: 40px;
    line-height: 1.2;
    min-height: 28px;
  }
  .guestbook__list__one__chat__list__question__message__text {
    line-height: 1.5;
  }
  .guestbook__list__one__chat__list__answer .account__main__chat__message__userpic {
    margin-left: 15px;
  }
  .guestbook__list__one__chat__list__answer__message {
    padding-top: 10px;
  }
  .guestbook__list__one__chat__list__answer__message__name {
    line-height: 1.2;
    min-height: 34px;
  }
  .guestbook__list__one__chat__list__answer__message__text {
    line-height: 1.5;
  }
  .guestbook-popup {
    padding: 15px;
  }
  .guestbook-popup__title {
    font-size: 26px;
  }
}
/*
 --- PAGE KAK NA MNE ----------------------------
*/
.kaknamne .wrap {
  flex-direction: column;
}
.kaknamne__title {
  font-size: 60px;
  line-height: 70px;
  text-align: center;
}
.kaknamne__subtitle {
  max-width: 535px;
  margin: auto;
  font-size: 12px;
  line-height: 20px;
  font-weight: 700;
  text-align: center;
  letter-spacing: 0.07em;
}
.kaknamne__text {
  max-width: 535px;
  margin: auto;
  font-size: 13px;
  line-height: 18px;
  font-weight: 300;
  text-align: center;
  letter-spacing: 0.08em;
}
.kaknamne__morelink {
  width: 100%;
  max-width: 950px;
  margin: -18px auto 12px auto;
  font-size: 14px;
  line-height: 18px;
  font-style: italic;
  text-align: right;
  font-family: "Times New Roman", serif;
}
.kaknamne__morelink a {
  text-decoration: underline;
}
.kaknamne__note {
  max-width: 950px;
  margin: 10px auto 25px auto;
  font-size: 11px;
  line-height: 16px;
  text-align: center;
  letter-spacing: 0.095em;
}
.kaknamne__heart {
  margin: 0 auto 15px auto;
}
.kaknamne__uploadlink {
  font-size: 11px;
  line-height: 15px;
  font-weight: 900;
  text-align: center;
  letter-spacing: 0.05em;
}
.kaknamne__uploadlink a,
.kaknamne__uploadlink .link {
  color: inherit;
  text-decoration: underline;
}
.kaknamne__uploadlink a:hover,
.kaknamne__uploadlink .link:hover {
  color: inherit;
  text-decoration: none;
}
.kaknamne__list {
  max-width: 910px;
  margin: auto;
  padding: 25px 15px 0 0;
  flex-wrap: wrap;
}
.kaknamne__list__one {
  display: block;
  float: left;
  width: 33.33%;
  padding: 15px 0 0 15px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.kaknamne__list__one.double {
  width: 66.66%;
}
.kaknamne__list__one.right {
  float: right;
}
.kaknamne__list__one a {
  display: block;
}
.kaknamne__list__one img {
  display: block;
  width: 100%;
  height: auto;
}
.kaknamne__list__morelink {
  margin: 55px 0 55px 15px;
}
@media screen and (max-width: 999px) {
  .kaknamne .wrap {
    position: relative;
  }
  .kaknamne__title {
    margin-top: 45px;
    font-size: 45px;
    line-height: 50px;
  }
  .kaknamne__morelink {
    width: 80px;
    margin: 0;
    font-size: 12px;
    line-height: 18px;
    text-align: right;
    position: absolute;
    top: 45px;
    right: 15px;
  }
  .kaknamne__list {
    margin-left: -10px;
    padding-right: 0;
  }
  .kaknamne__list__one {
    padding: 10px 0 0 10px;
  }
  .kaknamne__list__morelink {
    margin-left: 10px;
  }
}
@media screen and (max-width: 767px) {
  .kaknamne__title {
    margin-top: 12px;
    font-size: 25px;
    line-height: 29px;
  }
  .kaknamne__subtitle {
    font-size: 11px;
    line-height: 20px;
  }
  .kaknamne__morelink {
    width: auto;
    margin-top: 5px;
    text-align: center;
    position: static;
    top: auto;
    right: auto;
    bottom: auto;
    left: auto;
  }
  .kaknamne .separator {
    width: auto;
    height: 5px;
    margin: 10px -15px;
  }
  .kaknamne__heart {
    margin-top: 10px;
  }
  .kaknamne__list {
    margin-right: -15px;
    margin-left: -16px;
  }
  .kaknamne__list__one {
    padding: 1px 0 0 1px;
  }
  .kaknamne__list__morelink {
    margin: 70px 15px 20px 15px;
  }
  .kaknamne__list__morelink .button {
    font-size: 15px;
    line-height: 18px;
  }
}
/*
 --- PAGE REGISTER ------------------------------
*/
.register {
  padding-bottom: 100px;
  border-bottom: 2px solid #f3f3f3;
}
.register .wrap {
  padding: 0 40px;
  justify-content: space-around;
}
.register__one {
  flex: 0 0 420px;
  width: 420px;
}
.register__title {
  margin-bottom: 15px;
  font-size: 35px;
  line-height: 40px;
  font-weight: 200;
}
.register__descr {
  margin-bottom: 25px;
  font-size: 12px;
  line-height: 23px;
  font-weight: 700;
  text-transform: uppercase;
}
.register__form__restore {
  margin-bottom: 10px;
  font-size: 11px;
  line-height: 14px;
  text-align: right;
}
.register__form__restore a,
.register__form__restore .link {
  color: #9e9f9f;
  text-decoration: underline;
}
.register__form__restore a:hover,
.register__form__restore .link:hover {
  color: #63b5b2;
}
.register__form__submit {
  margin-top: 10px;
}
.register__note {
  margin-top: 35px;
  color: #636363;
  font-size: 13px;
  line-height: 16px;
}
.register__note__title {
  margin-bottom: 15px;
  color: #232323;
  font-size: 12px;
  line-height: 16px;
  font-weight: 700;
  text-transform: uppercase;
}
.register__socials {
  display: flex;
  justify-content: space-between;
  margin-top: 35px;
}
.register__doreg {
  margin-top: 35px;
}
@media screen and (max-width: 999px) {
  .register {
    padding-top: 30px;
    padding-bottom: 60px;
  }
  .register .wrap {
    padding: 0 30px;
    justify-content: space-between;
  }
  .register__one {
    flex: 0 0 335px;
    width: 335px;
  }
  .register__title {
    font-size: 28px;
    line-height: 34px;
  }
  .register__descr {
    font-size: 10px;
    line-height: 18px;
  }
  .register__note {
    margin-top: 25px;
    font-size: 10px;
    line-height: 13px;
  }
  .register__note__title {
    font-size: 10px;
    line-height: 13px;
  }
  .register__socials {
    margin-top: 25px;
  }
  .register__doreg {
    margin-top: 25px;
  }
  .notyou {
    background: #e0eded;
  }
  .notyou + .about {
    padding-bottom: 10px;
  }
  .notyou__head {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .notyou__title {
    padding: 17px 35px;
    font-size: 30px;
    line-height: 34px;
    font-weight: 200;
  }
  .notyou__signout {
    padding-right: 35px;
    font-size: 14px;
    line-height: 18px;
    font-style: italic;
    font-family: "Times New Roman", serif;
  }
  .notyou__signout a {
    color: #9e9f9f;
    text-decoration: underline;
  }
  .notyou__links a {
    display: block;
    padding: 21px 35px;
    border-top: 1px solid #232323;
    color: #232323;
    font-size: 12px;
    line-height: 18px;
    font-weight: 700;
    letter-spacing: 0.15em;
    text-transform: uppercase;
  }
  .notyou__links a:after {
    float: right;
    margin: 4px 0 0 0;
  }
}
@media screen and (max-width: 767px) {
  .register {
    padding: 0;
    border: none;
  }
  .register .wrap {
    flex-direction: column;
    padding: 0;
  }
  .register__one {
    flex: 0 0 100%;
    width: 100%;
    padding: 30px 5px 0 5px;
  }
  .register__signin {
    padding-bottom: 45px;
    border-bottom: 2px solid #f3f3f3;
  }
  .register__title {
    margin-bottom: 15px;
    font-size: 24px;
    line-height: 28px;
    text-align: center;
  }
  .register__descr {
    margin-bottom: 15px;
    font-size: 11px;
    line-height: 18px;
    text-align: center;
  }
  .register__form .input,
  .register__form__submit {
    margin-right: -5px;
    margin-left: -5px;
  }
  .register__form .input input,
  .register__form .input textarea {
    margin-bottom: 2px;
  }
  .register__form__restore {
    padding-top: 8px;
    padding-right: 10px;
  }
  .register__note {
    margin-top: 15px;
    text-align: center;
  }
  .register__note ul {
    text-align: left;
  }
  .register__socials {
    margin-top: 15px;
  }
  .register__doreg {
    margin: 15px -5px 0 -5px;
  }
  .notyou + .about {
    padding-bottom: 50px;
  }
  .notyou__head {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 15px 8px;
  }
  .notyou__title {
    padding: 0;
    font-size: 20px;
    line-height: 25px;
  }
  .notyou__title span {
    display: block;
  }
  .notyou__signout {
    padding: 5px 0;
  }
  .notyou__links a {
    padding: 20px 15px 18px 8px;
    font-size: 11px;
    line-height: 16px;
  }
  .notyou__links a:after {
    margin: 2px 0 0 0;
  }
}
/*
 --- PAGE TRENDS --------------------------------
*/
.trends {
  background: none;
}
.trends__title {
  font-size: 60px;
  line-height: 70px;
  text-align: center;
}
.trends-dresscode {
  max-width: 990px;
  margin: auto;
  padding-top: 20px;
  text-align: center;
}
.trends-dresscode__title {
  margin-top: 45px;
  font-size: 60px;
  line-height: 70px;
}
.trends-dresscode__subtitle {
  margin-bottom: 40px;
  font-size: 18px;
  line-height: 20px;
  text-transform: uppercase;
}
.trends-dresscode__image img {
  display: block;
  width: 100%;
  height: auto;
}
/*
 --- NEWS ---------------------------------------
*/
.news {
  padding-top: 35px;
}
.news .wrap {
  max-width: 1100px;
}
.news__pretitle {
  margin-bottom: 5px;
  font-size: 12px;
  line-height: 18px;
  font-weight: 700;
  letter-spacing: 0.05em;
}
.news__title {
  margin-bottom: 10px;
  font-size: 33px;
  line-height: 36px;
  font-weight: 200;
}
.news__author {
  margin-bottom: 10px;
  color: #63b5b2;
  font-size: 12px;
  line-height: 14px;
}
.news__main__side {
  flex: 0 0 330px;
  margin-left: 30px;
}
.news__main__side .lastchance {
  width: 100%;
}
.news__main__side .actual__carousel__one {
  width: auto;
  height: auto;
  padding: 60px 15px 0 15px;
}
.news__main__side .actual__carousel__one__text {
  margin-top: 5px;
  font-size: 11px;
  line-height: 16px;
}
.news__main__content {
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  padding-top: 22px;
  border-top: 4px solid #f3f3f3;
}
.news__main__text {
  margin-bottom: 35px;
  font-size: 12px;
  line-height: 25px;
  letter-spacing: 0.07em;
}
.news__main__carousel {
  width: 100%;
  max-width: 670px;
  margin: auto;
  padding-bottom: 70px;
  position: relative;
}
.news__main__carousel .owl-item {
  flex: 0 0 50%;
}
.news__main__carousel .owl-nav {
  top: 235px;
  right: -30px;
  left: -30px;
}
.news__main__carousel__one {
  padding: 0 5px;
}
.news__main__carousel__one__person {
  margin-bottom: 10px;
  color: #a5a5a5;
  font-size: 12px;
  line-height: 14px;
}
.news__main__carousel__one__photo {
  position: relative;
}
.news__main__carousel__one__photo img {
  display: block;
  width: 100%;
  height: auto;
}
.news__main__carousel__one__photo:before {
  display: block;
  content: " ";
  height: 7px;
  background: #000000;
  position: absolute;
  top: 100%;
  right: 0;
  left: 0;
}
.news__main__carousel__one__photo:after {
  display: block;
  content: " ";
  width: 0;
  height: 0;
  margin: 7px 0 0 -10px;
  border: 10px solid transparent;
  border-top-color: #000000;
  position: absolute;
  top: 100%;
  left: 50%;
}
.news__main__carousel__one__items {
  display: flex;
  padding-top: 50px;
}
.news__main__carousel__one__items a {
  flex: 0 0 50%;
}
.news__main__video {
  padding-bottom: 65px;
}
.news__main__video__video {
  padding-top: 20px;
}
.news__main__video__video iframe {
  display: block;
  width: 100%;
  height: 400px;
}
.news__rate {
  padding: 55px 0;
  border-top: 4px solid #f3f3f3;
  text-align: center;
}
.news__rate .wrap {
  flex-direction: column;
}
.news__rate__title {
  font-size: 25px;
  line-height: 29px;
}
.alternate .news__rate__title {
  font-size: 50px;
  line-height: 60px;
}
.news__rate__text {
  margin-top: 5px;
  font-size: 11px;
  line-height: 19px;
}
.alternate .news__rate__text {
  margin-top: 0;
  font-size: 35px;
  line-height: 40px;
  font-weight: 200;
}
.news__rate__actions {
  display: flex;
  justify-content: center;
  margin-top: 25px;
}
.news__rate__actions__one {
  flex: 0 0 115px;
  display: flex;
  flex-direction: column;
  padding-top: 75px;
  color: #000000;
  font-size: 11px;
  line-height: 16px;
  font-weight: 700;
  position: relative;
}
.news__rate__actions__one span {
  font-size: 25px;
  line-height: 35px;
  font-weight: 200;
}
.alternate .news__rate__actions__one {
  flex: 0 0 145px;
  padding-top: 105px;
  font-size: 12px;
}
.alternate .news__rate__actions__one span {
  font-size: 35px;
  line-height: 40px;
}
.news__tags {
  padding: 20px 0;
}
@media screen and (max-width: 1249px) {
  .news__main__side {
    margin-left: 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
  .news__main__side .lastchance {
    flex: 1 0 100%;
  }
}
@media screen and (max-width: 767px) {
  .news__main {
    margin-bottom: 25px;
  }
  .news__main__carousel .owl-item {
    flex: 0 0 100%;
  }
  .news__main__carousel .owl-next {
    right: 8px;
  }
  .news__main__carousel .owl-prev {
    left: 8px;
  }
  .news__main__carousel__one {
    margin: 0 auto;
  }
  .news__main__carousel__one__items {
    justify-content: center;
  }
  .news__main__carousel__one__items .carousel__item {
    margin: 0;
  }
  .news__main__side .actual__carousel__one {
    flex: 0 0 50%;
    padding-left: 5px;
    padding-right: 5px;
  }
  .news__main__side .actual__carousel__one__text {
    padding-left: 0;
    padding-right: 0;
  }
  .news__main__image img {
    max-width: 100%;
    height: auto;
  }
  .news__title {
    line-height: 1.5;
    font-size: 18px;
  }
  .news__title a {
    display: inline-block;
  }
  .news__rate {
    padding-bottom: 0;
  }
  .alternate .news__rate__title {
    font-size: 25px;
    line-height: 29px;
  }
  .alternate .news__rate__text {
    margin-top: 0;
    font-size: 11px;
    line-height: 19px;
    font-weight: 200;
  }
  .alternate .news__rate__actions__one {
    flex: 0 0 33.33333333%;
    padding-top: 75px;
    font-size: 12px;
  }
  .alternate .news__rate__actions__one span {
    font-size: 25px;
    line-height: 35px;
  }
}
